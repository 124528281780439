import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import Amplify from "aws-amplify";
import { Provider } from "react-redux";
import App from "containers/App/App";
import * as serviceWorker from "./serviceWorker";
import awsExports from "./aws-exports";
import store from "redux/store/store";
import { PasswordContextProvider } from "context/PasswordContext";
import "./i18n";

Amplify.configure({
  ...awsExports,
  aws_appsync_region: process.env.REACT_APP_AWS_REGION, // AWS AppSync region
  aws_appsync_graphqlEndpoint:
  process.env.REACT_APP_GRAPHQL_ENDPOINT, // AWS AppSync endpoint
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  API: {
    endpoints: [
      {
        name: process.env.REACT_APP_REST_API_NAME,
        endpoint: process.env.REACT_APP_REST_API_ENDPOINT,
      },
      {
        name: process.env.REACT_APP_REST_API_USER_MUTATION_NAME,
        endpoint: process.env.REACT_APP_REST_API_USER_MUTATION_ENDPOINT,
      },
      {
        name: process.env.REACT_APP_REST_API_MANUAL_DATA_OPERATIONS_NAME,
        endpoint: process.env.REACT_APP_REST_API_MANUAL_DATA_OPERATIONS_ENDPOINT,
      },
    ],
  },
});

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <Provider store={store}>
      <PasswordContextProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </PasswordContextProvider>
    </Provider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
