import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import Loading from "shared/components/Loading";
import PhoneInput from 'react-phone-input-2'
import Topbar from "../../Layout/topbar/Topbar";
import signUp from "containers/Account/LogIn/AwsCognito/CognitoSignUp";
import { v4 as uuidv4 } from 'uuid';
import {
    cognitoColoredWarningModalRenderHandler,
    cognitoColoredSuccessModalRenderHandler,
    cognitoPrimaryModalRenderHandler,


} from "redux/slices/modalSlice";

import {
    Card, CardBody, Col, Button,
} from 'reactstrap';
import { useNavigate } from "react-router-dom";

export default function SignUpNew() {
    const dispatch = useDispatch();
  const [userAgreementAccept, setUserAgreementAccept] = useState(false)

    const navigate = useNavigate()
    const { t, i18n } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [profileOrSettingsPath, setProfileOrSettingsPath] = useState()
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [formItemsErrors, setFormItemsErrors] = useState({})
    const [givenNameInputError, setGivenNameInputError] = useState(false)
    const [familyNameInputError, setFamilyNameInputError] = useState(false)
    const [confirmPasswordError, setConfirmPasswordError] = useState(false)
    const [passwordError, setPasswordError] = useState(false)
    const background = `${process.env.PUBLIC_URL}/img/landing/background/left_bg.png`;

    const openModal = (title, message) => {
        dispatch(
          cognitoPrimaryModalRenderHandler({
            name: title,
            message: message,
          })
        );
      }
    const onSubmit = async (dataOnSubmit) => {

        const warningMessage = {
            name: t(
                "modal.modalMessage.onSuccess.name.failedToCreateEquipment"
            ),
            message: t("pages.settings.errors.canNotBeEmpty"),
        };
        if (!dataOnSubmit.eMail) {
            dispatch(cognitoColoredWarningModalRenderHandler(warningMessage))
        } else if (!dataOnSubmit.givenName) {
            dispatch(cognitoColoredWarningModalRenderHandler(warningMessage));

        } else if (!dataOnSubmit.familyName) {
            dispatch(cognitoColoredWarningModalRenderHandler(warningMessage));

        } else if (!phoneNumber) {
            dispatch(cognitoColoredWarningModalRenderHandler(warningMessage))
        } else if (!dataOnSubmit.password) {
            dispatch(cognitoColoredWarningModalRenderHandler(warningMessage))
        } else if (!dataOnSubmit.confirmPassword) {
            dispatch(cognitoColoredWarningModalRenderHandler(warningMessage))
        } else {

            if (dataOnSubmit.password !== dataOnSubmit.confirmPassword) {
                setConfirmPasswordError(true)
            } else if (validateEmail(dataOnSubmit.eMail) === false) {
                const warningMessage = {
                    name: t(
                        "modal.modalMessage.onSuccess.name.failedToCreateEquipment"
                    ),
                    message: t("pages.settings.errors.emailFormatError"),
                };
                dispatch(cognitoColoredWarningModalRenderHandler(warningMessage));
            } else {
                setConfirmPasswordError(false)

                const username = dataOnSubmit.eMail.split('@')[0];

                // Harf ve sayıları temizle
                const cleanUsername = username.replace(/[^a-zA-Z0-9]/g, '');

                const signUpData = {    
                    tenantId: `TENANT#${uuidv4()}`,
                    // const tenantId = `TENANT#${randomUUID()}`;
                    email: dataOnSubmit.eMail,
                    givenName: dataOnSubmit.givenName,
                    familyName: dataOnSubmit.familyName,
                    phoneNumber: `+${phoneNumber}`,
                    password: dataOnSubmit.password,
                    // timezone : "Europe/Istanbul",
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    // TODO Fetch the locale from state and use setState & useEffect to update it
                    // TODO Instead of optional chaining, use switch case.
                    // We can add more languages in the future and switch case will be more appropriate
                    // default locale is en-US
                    locale: (i18n.language === "tr") ? "tr-TR" : "en-US",
                    username: `${cleanUsername}-${uuidv4()}`,
                }



                signUpButtonHandler(signUpData)

            }

        }


    }
    const validateEmail = (input) => {
        // to validate email format
        const emailPattern = /^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/
        return emailPattern.test(input);
    };
    useEffect(() => {
        // Tarayıcı otomatik doldurmayı devre dışı bırak
        const fields = document.querySelectorAll('input[autocomplete="off"]');
        fields.forEach((field) => {
            field.setAttribute('autocomplete', 'new-password');
        });
    }, []);





    // Handler function for login button
    const signUpButtonHandler = (signUpData) => {
        const tenantId = signUpData.tenantId
        // const tenantId = `TENANT#${randomUUID()}`;

        const email = signUpData.email;
        const givenName = signUpData.givenName;
        const familyName = signUpData.familyName;
        const phoneNumber = signUpData.phoneNumber;
        const password = signUpData.password;
        const timezone = signUpData.timezone

        const locale = signUpData.locale

        const username = signUpData.username;

        const cognitoResponse = signUp({
            username,
            password,
            phoneNumber,
            tenantId,
            givenName,
            familyName,
            email,
            locale,
            timezone,
        })
            .then((response) => {
                const successMessage = {
                    name: t(
                        "modal.modalMessage.onSuccess.name.attributeCreateSuccess"
                    ),
                    message: t(
                        "modal.modalMessage.onSuccess.message.attributeCreateSuccess"
                    ),
                    acceptFunc: () => navigate("/sign_in", { replace: true }),
                    rejectFunc: () => navigate("/sign_in", { replace: true })
                };
                dispatch(cognitoColoredSuccessModalRenderHandler(successMessage))
            });
    }


    return (
        <section
            className="landing__header"
            style={{
                backgroundImage: `url(${background}`,
                height: '100vh',
            }}
        >
            <Topbar />

            <Col md={12} lg={12} style={{ padding: 20 }} >
                <Card >
                    <CardBody style={{ backgroundColor: 'rgba(255,255,255,0.9)' }}>
                        <div className="card__title">
                            <h5 className="bold-text">{t(profileOrSettingsPath)}</h5>
                        </div>
                        <div >
                            <Form onSubmit={onSubmit}
                                validate={(values) => {
                                    const errors = {};
                                    if (values.givenName && values.givenName.length > 19) {
                                        setGivenNameInputError(true)
                                    } else {
                                        setGivenNameInputError(false)
                                    }
                                    if (values.familyName && values.familyName.length > 19) {
                                        setFamilyNameInputError(true)
                                    } else {
                                        setFamilyNameInputError(false)
                                    }

                                    if (values.password && values.password.length < 6) {
                                        setPasswordError(true)
                                    } else {
                                        setPasswordError(false)
                                    }


                                    return errors;
                                }}>

                                {({ handleSubmit, form }) => (
                                    <form className="form form--horizontal" onSubmit={handleSubmit} autoComplete='off'>
                                        <h3 className="account__title">
                                            {t("auth.signUp")}
                                        </h3>

                                        <div className="form__form-group" style={{ marginTop: 30 }}>
                                            <span className="form__form-group-label">{t("pages.formItems.eMail")}</span>
                                            <div className="form__form-group-field" style={{ display: 'block' }}>
                                                <Field
                                                    name="eMail"
                                                    component="input"
                                                    type="text"
                                                    placeholder={t("pages.formItems.eMail")}

                                                />
                                                {formItemsErrors?.eMail && <span className="form__form-group-error">{formItemsErrors.eMail}</span>}


                                            </div>
                                        </div>


                                        <div className="form__form-group">
                                            <span className="form__form-group-label">{t("pages.formItems.name")}</span>
                                            <div className="form__form-group-field" style={{ display: 'block' }}>
                                                <Field
                                                    name="givenName"
                                                    component="input"
                                                    type="text"
                                                    placeholder={t("pages.formItems.name")}
                                                    maxLength={20}

                                                />
                                                {formItemsErrors?.givenName && <span className="form__form-group-error">{formItemsErrors.givenName}</span>}
                                                {givenNameInputError && <span className="form__form-group-error">{t("pages.settings.errors.maxLengthError")}</span>}

                                            </div>
                                        </div>
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">{t("pages.formItems.familyName")}</span>
                                            <div className="form__form-group-field" style={{ display: 'block' }}>
                                                <Field
                                                    name="familyName"
                                                    component="input"
                                                    type="text"
                                                    placeholder={t("pages.formItems.familyName")}
                                                    maxLength={20}

                                                />
                                                {formItemsErrors?.familyName && <span className="form__form-group-error">{formItemsErrors.familyName}</span>}
                                                {familyNameInputError && <span className="form__form-group-error">{t("pages.settings.errors.maxLengthError")}</span>}

                                            </div>
                                        </div>
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">{t("pages.formItems.phoneNumber")}</span>
                                            <div className="form__form-group-field" style={{ display: 'block' }}>
                                                <PhoneInput
                                                    name="phoneNumber"
                                                    country={'tr'}
                                                    value={phoneNumber}
                                                    onChange={setPhoneNumber}
                                                    enableSearch={true}
                                                    disableSearchIcon={true}
                                                    inputStyle={{ display: 'flex', width: '100%', backgroundColor: "rgba(255,255,255,0)" }}
                                                    dropdownStyle={{ textAlign: 'left' }}

                                                />
                                                {formItemsErrors?.phoneNumber && <span className="form__form-group-error">{formItemsErrors.phoneNumber}</span>}
                                            </div>
                                        </div>
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">{t("pages.formItems.password")}</span>
                                            <div className="form__form-group-field" style={{ display: 'block' }}>
                                                <Field
                                                    name="password"
                                                    component="input"
                                                    type="password"
                                                    placeholder={t("pages.formItems.password")}
                                                    maxLength={20}
                                                    autoComplete='off'


                                                />
                                                {passwordError && <span className="form__form-group-error">{t("pages.settings.errors.passwordLenthError")}</span>}

                                            </div>
                                        </div>
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">{t("pages.formItems.confirmPassword")}</span>
                                            <div className="form__form-group-field" style={{ display: 'block' }}>
                                                <Field
                                                    name="confirmPassword"
                                                    component="input"
                                                    type="password"
                                                    placeholder={t("pages.formItems.confirmPassword")}
                                                    maxLength={20}
                                                    autoComplete='off'

                                                />

                                                {confirmPasswordError && <span className="form__form-group-error">{t("pages.settings.errors.passwordConfirmedError")}</span>}

                                            </div>
                                        </div>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <input
                                                type="checkbox"
                                                checked={userAgreementAccept}
                                                style={{ width: "12px", height: "12px", marginRight: "5px" }}
                                                onChange={() => setUserAgreementAccept(!userAgreementAccept)}
                                            />
                                            <label>
                                                <b>
                                                    <span onClick={() => openModal(t("pages.landing.termsAndConditions.title"), t("pages.landing.termsAndConditions.message"))} style={{ cursor: "pointer" }}>
                                                        {t("auth.userAgreement")}
                                                    </span>
                                                </b>
                                                {/* {t("auth.accept")} */}
                                            </label>
                                        </div>

                                        <div className="form__form-group">
                                            <div className="form__form-group-field">
                                                <div style={{ textAlign: 'end', flex: 1 }}>
                                                    <Button 
                                                    disabled={!userAgreementAccept}
                                                    color="primary" type="submit">{t("pages.formItems.save")}</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                )}
                            </Form>
                        </div>

                    </CardBody>
                </Card>
            </Col>



        </section>

    )
}
