import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Field, Form } from 'react-final-form';
import Select from 'react-select';
import { useLocation } from 'react-router-dom'
import getDeviceData from "containers/Router/WrappedRoutes/Dashboard/dashboardHelpers/getDeviceData";
import { deviceFirstRenderDataHandler } from "redux/slices/deviceDataSlice";
import { useDispatch, useSelector } from 'react-redux';
import manualDataOperationHandler from "../Settings/settingsHelpers/manualDataOperationHandler";
import Collapse from "shared/components/Collapse";
import tr from "date-fns/locale/tr";
import {
    cognitoErrorModalRenderHandler,
    cognitoColoredWarningModalRenderHandler,
    cognitoColoredSuccessModalRenderHandler,
    resetModalHandler
} from "redux/slices/modalSlice";
import {
    Card, CardBody, Col, Button, Table
} from 'reactstrap';
import { getFacilityManualData } from "graphql/mutations";
import { API, graphqlOperation } from "aws-amplify";

export default function DeleteManualData() {
    // TODO You can get the list of manual data
    // from the GraphQL Mutation getFacilityManualData
    // facilityId and tenantId are required
    // The expiry time of the data is 45 minutes
    // The input epoch time is in the object
    // You can compare before delete operation to avoid errors

    const dispatch = useDispatch();
    const { lang } = useSelector((state) => state.lang);

    const { t, i18n } = useTranslation();
    const [choosenFacilityData, setChoosenFacilityData] = useState(null);
    const [choosenMachineData, setChoosenMachineData] = useState(null);
    const [choosenDurationType, setChoosenDurationType] = useState(choosenMachineData?.value?.dataUtilizationCode == "otherEnteredData" ? null : { value: "day", label: "Day" });

    const themeData = useSelector((state) => state.theme.className);
    const { facilities } = useSelector((state) => state.facility.facilityState);
    const { devices } = useSelector((state) => state.deviceData.deviceDataState);
    const userData = useSelector((state) => state.user.userState);
    const [dataDate, setDataDate] = useState(new Date(Date.now()))
    const [facilityStatusOptions, setFacilityStatusOptions] = useState([])
    useEffect(() => {
        const newFacilityStatusOptions = facilities.map((facilityItem) => {
            return { value: facilityItem, label: facilityItem.unitName }
        })
        setFacilityStatusOptions(newFacilityStatusOptions)
    }, [facilities])
    const [machineStatusOptions, setMachineStatusOptions] = useState(null);
    const [otherObjectsTable, setOtherObjectsTable] = useState(null)
    const [electricityObjectsTable, setElectricityObjectsTable] = useState(null)
    const [noTableData, setNoTableData] = useState(false)


    const otherDataTableHeader = [
        { id: 1, title: "Ekipman Adı" },
        { id: 2, title: "Veri Tipi Süresi" },
        { id: 3, title: "Veri Süresi" },
        { id: 4, title: "Değer" },
        { id: 5, title: t("pages.formItems.delete") },

    ];


    const electricityDataTableHeader = [
        { id: 1, title: "Tek Zaman" },
        { id: 2, title: "Gündüz" },
        { id: 3, title: "Gece" },
        { id: 4, title: "Puant" },
        { id: 5, title: "Veri Süresi" },
        { id: 6, title: t("pages.formItems.delete") },

    ];



    const { currentFacility } = useSelector(
        (state) => state.facility.facilityState
    );
    const tenantId = currentFacility.id;

    let selectedLanguage;
    switch (lang) {
        case "tr":
            selectedLanguage = tr;
            break;
        default:
            selectedLanguage = tr;
            break;
    }
    useEffect(() => {
        if (facilityStatusOptions && facilityStatusOptions.length === 1) {
            setChoosenFacilityData(facilityStatusOptions[0])
        }

    }, [facilityStatusOptions])

    useEffect(() => {
        // This snippet is stopping the code after expiry time
        //const timer = setTimeout(() => timeOutHandler(), 30000);
        getDeviceData(tenantId, choosenFacilityData?.value.SK)
            .then((response) => {
                // Dispatch the API data to the redux store
                dispatch(deviceFirstRenderDataHandler(response.deviceList));

            })
            .catch((err) => {
                // setCodeHandler("NoDataError");
                // setStartErrorHandler(true);
            });

        // return () => cleanUpHandler(timer);
    }, [
        choosenFacilityData?.value
    ]);


    useEffect(() => {
        setChoosenMachineData(null)
        if (choosenFacilityData) {
            const filteredDevices = devices
                .filter(({ equipmentCategory }) => equipmentCategory === "manualDataEntry")
                .map((value, index) => ({ value: value, label: value.equipmentName }));
            setMachineStatusOptions(filteredDevices)
        }
    }, [devices]);

    useEffect(() => {
        //getFacilityManualData
        if (choosenFacilityData?.value) {
            getManualDataTableData()
        }

    }, [choosenFacilityData?.value])

    async function getManualDataTableData() {
        const facilityDeviceInput = {

            tenantId: tenantId,
            facilityId: choosenFacilityData.value.SK,
        };
        // API call to create manual device data
        await API.graphql(
            graphqlOperation(
                getFacilityManualData, {
                input: facilityDeviceInput
            })
        ).then((response) => {

            const filteredOtherDevices = Object.entries(response.data.getFacilityManualData)
                .filter(([key, value]) => JSON.parse(value.dataObject).dataUtilizationCode !== "electricity")
                .map(([key, value]) => value);


            if (filteredOtherDevices.length > 0) {

                setOtherObjectsTable(
                    <div >

                        <div style={{ flex: 1 }}>
                            <Collapse title={t("pages.deleteManuelData.otherTableTitle")} className="with-shadow" isOpen={true}>
                                <Table responsive hover>
                                    <thead>
                                        <tr>
                                            {otherDataTableHeader.map(item => (
                                                <th key={item.id}>{item.title} </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody >
                                        {filteredOtherDevices.map((item, userListIndex) => {
                                            const parseItem = JSON.parse(item.dataObject)
                                            return (
                                                <tr
                                                    style={{ backgroundColor: userListIndex % 2 == 0 && 'ButtonShadow', height: 50 }}
                                                    key={item.id + " - " + userListIndex} /*onClick={() => console.log("item", item)}*/>

                                                    <td style={{ padding: '0 20px', verticalAlign: 'middle' }}>
                                                        {parseItem.equipmentName}</td>
                                                    <td style={{ padding: '0 20px', verticalAlign: 'middle' }}>
                                                        {parseItem.dataDurationSeconds}</td>
                                                    <td style={{ padding: '0 20px', verticalAlign: 'middle' }}>
                                                        {parseItem.dataDurationSeconds}</td>
                                                    <td style={{ padding: '0 20px', verticalAlign: 'middle' }}>
                                                        {parseItem[parseItem.dataSendingDeviceChannelName]}</td>

                                                    <td onClick={() => askToDelete(item)} style={{ color: 'red' }}>
                                                        {t("pages.formItems.delete")}
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </Collapse>
                        </div>

                    </div>)

            } else {

                setOtherObjectsTable(null)

            }

            const filteredElectricityDevices = Object.entries(response.data.getFacilityManualData)
                .filter(([key, value]) => JSON.parse(value.dataObject).dataUtilizationCode === "electricity")
                .map(([key, value]) => value);


            if (filteredElectricityDevices.length > 0) {
                setElectricityObjectsTable(
                    <div >
                        <div style={{ flex: 1 }}>
                            <Collapse title={t("pages.deleteManuelData.electricityDataTable")} className="with-shadow" isOpen={true}>
                                <Table responsive hover>
                                    <thead>
                                        <tr>
                                            {electricityDataTableHeader.map(item => (
                                                <th key={item.id}>{item.title}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody >
                                        {filteredElectricityDevices.map((item, userListIndex) => {
                                            const parseItem = JSON.parse(item.dataObject)
                                            return (
                                                <tr
                                                    style={{ backgroundColor: userListIndex % 2 == 0 && 'ButtonShadow', height: 50 }}
                                                    key={item.id + " - " + userListIndex} /*onClick={() => console.log("item", item)}*/>

                                                    <td style={{ padding: '0 20px', verticalAlign: 'middle' }}>
                                                        {parseItem.singleTimeIndexValue}</td>
                                                    <td style={{ padding: '0 20px', verticalAlign: 'middle' }}>
                                                        {parseItem.dayTimeIndexValue}</td>
                                                    <td style={{ padding: '0 20px', verticalAlign: 'middle' }}>
                                                        {parseItem.nightTimeIndexValue}</td>
                                                    <td style={{ padding: '0 20px', verticalAlign: 'middle' }}>
                                                        {parseItem.peakIndexValue}</td>
                                                    <td style={{ padding: '0 20px', verticalAlign: 'middle' }}>
                                                        {parseItem.dataDurationSeconds}</td>


                                                    <td onClick={() => askToDelete(item)} style={{ color: 'red' }}>
                                                        {t("pages.formItems.delete")}
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </Collapse>
                        </div>

                    </div>)

            } else {
                setElectricityObjectsTable(null)
            }


            if (filteredOtherDevices.length == 0 && filteredElectricityDevices.length == 0) {
                setNoTableData(true);
            } else {
                setNoTableData(false);
            }

        }).catch((err) => {

        });

        return facilityDeviceInput;
    }
    const askToDelete = (choosenManualData) => {//It is used to obtain confirmation before deletion.

        const successMessage = {
            name: t(
                "modal.modalMessage.onSuccess.name.areYouSureToDelete"
            ),
            message: t(
                "modal.modalMessage.onSuccess.message.areYouSureToDelete"
            ),
            acceptFunc: () => deleteManualData(choosenManualData),
        };
        dispatch(cognitoColoredWarningModalRenderHandler(successMessage));

    }
    const deleteManualData = (choosenManualData) => {
        const deleteManualData = {
            choosenManualData,
            facilityId: choosenManualData.facilityId,
            tenantId: choosenManualData.tenantId,
            mutationType: "deleteData",
            operatorId: userData.subId,

            descriptiveCode: JSON.parse(choosenManualData.dataObject).descriptiveCode,
            dataSendingDeviceChannelName: JSON.parse(choosenManualData.dataObject).dataSendingDeviceChannelName,
            dataUtilizationCode: JSON.parse(choosenManualData.dataObject).dataUtilizationCode,
            equipmentName: JSON.parse(choosenManualData.dataObject).equipmentName,
            dataUnit: JSON.parse(choosenManualData.dataObject).dataUnit,
            industryCategory: JSON.parse(choosenManualData.dataObject).industryCategory,
            equipmentTimeZone: JSON.parse(choosenManualData.dataObject).equipmentTimeZone,
            dataCategory: JSON.parse(choosenManualData.dataObject).dataCategory,

            arrayOfDataObject: [JSON.parse(choosenManualData.dataObject)]


        }

        try {
            // // TODO The attribures to be sent to the API
            // tenantId,
            // facilityId,
            // descriptiveCode,  // descriptive code of the device
            // dataSendingDeviceChannelName,
            // dataCategory,
            // dataUtilizationCode,
            // equipmentName,
            // dataUnit,
            // mutationType,  // "newData" | "updateData"
            // operatorId,  // SubId of the user
            // arrayOfDataObject 
            manualDataOperationHandler(deleteManualData)
                .then((response) => {
                    getManualDataTableData()
                })
                .catch((err) => {
                });
        } catch (errorManualDataOperation) {
        }
    };

    return (
        <div style={{ marginLeft: 20, marginRight: 20 }}>
            <Col md={12} lg={12}>
                <Card>
                    <CardBody>
                        <div className="card__title">
                            <h5 className="bold-text">{t("sidebar.addManualData.title")}</h5>
                        </div>

                        <div className="form form--horizontal" >
                            {//ocation.state == null && userData.userStatusInt >= 5 &&
                                <div className="form__form-group">
                                    <span className="form__form-group-label">{t("pages.formItems.facilityName")}</span>
                                    <div className="form__form-group-field" style={{ display: 'block' }}>
                                        <Select
                                            name="facilityName"
                                            value={choosenFacilityData}
                                            onChange={setChoosenFacilityData}
                                            options={facilityStatusOptions}
                                            clearable={false}
                                            isDisabled={facilityStatusOptions.length === 1 ? true : false}

                                            className="react-select"
                                            placeholder={t("pages.formItems.facilityName")}
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    backgroundColor: themeData === "theme-dark" && "#232329",

                                                }),
                                                singleValue: (provided) => ({
                                                    ...provided,
                                                    color: themeData === "theme-dark" && "#dddddd",
                                                }),

                                            }}
                                        />

                                    </div>
                                </div>}
                        </div>

                        {otherObjectsTable}
                        {electricityObjectsTable}



                        {noTableData &&
                            <div style={{ display: 'flex', flexWrap: 'wrap' }} >
                                <div style={{ textAlign: 'center', backgroundColor: 'Highlight', color: 'black', width: '100%' }}>{t("error.dashboard.message.noManualEnteredData")}</div>
                            </div>}



                    </CardBody>
                </Card>
            </Col>
        </div >
    )
}
