import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, ButtonToolbar, Modal } from "reactstrap";
import classNames from "classnames";
import IconHandler from "./helpers/IconHandler";
// import CognitoExceptionDisplayHandler from "./helpers/CognitoExceptionDisplayHandler";
import { OnAccept, OnReject } from "./helpers/ModalOnClickHandler";
import {
  toggleModalHandler,
  resetModalHandler,
} from "redux/slices/modalSlice";
const ModalComponent = ({ onAcceptHandler, onRejectHandler }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  // Get the states from Redux store
  // Showing if the language is in right to left or opposite state
  const directionState = useSelector((state) => state.rtl.direction);
  // Showing if the modal is in open or close state
  const { openTheModal, modalType, errorFromService } = useSelector(
    (state) => state.modal
  );

  // Showing the details of Modal state
  const { colored, header, modalColor } = useSelector(
    (state) => state.modal.modalDetails
  );

  // Showing the modal message text states
  const { code } = useSelector((state) => state.modal.modalRender);
  let { name, message, acceptFunc, rejectFunc } = useSelector((state) => state.modal.modalRender);

  const modalClass = classNames({
    "modal-dialog--colored": colored,
    "modal-dialog--header": header,
  });

  const Icon = IconHandler(modalColor);

  if (modalType === "error") {
    // Second element of the array is default value
    name = t([
      `error.${errorFromService}.title.${code}`,
      `error.${errorFromService}.title.default`,
    ]);
    message = t([
      `error.${errorFromService}.message.${code}`,
      `error.${errorFromService}.message.default`,
    ]);
  }

  const stateResetHandler = () => {
    dispatch(resetModalHandler());
  };

  const modalToggleHandler = () => {
    dispatch(toggleModalHandler());
  };

  function handleModalKeyDown(keyEvent) {
    if (keyEvent.key === "Enter") {
      OnAccept(onAcceptHandler, modalToggleHandler);
    }

    if (keyEvent.key === "Escape") {
      OnReject(onRejectHandler, modalToggleHandler);
    }
  }

  useEffect(() => {
    window.addEventListener("keydown", (event) => handleModalKeyDown(event));
    // cleanup this component
    return () => {
      window.removeEventListener("keydown", (event) =>
        handleModalKeyDown(event)
      );
    };
  }, []);

  return (
    <div>
      <Modal
        isOpen={openTheModal}
        scrollable={openTheModal}
        toggle={() => modalToggleHandler()}
        onClosed={() => stateResetHandler()}
        modalClassName={`${directionState}-support`}
        className={`modal-dialog--${modalColor} ${modalClass}`}
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            aria-label="modal__close-btn"
            type="button"
            onClick={() => OnReject(onRejectHandler, modalToggleHandler)}
          />
          {header ? "" : Icon}
          {name && <h4 className="text-modal  modal__title">{name}</h4>}
        </div>
        {code && (
          <div className="modal__body">
            <b>
              {t("modal.title")}
              {": "}
            </b>
            {code}
          </div>
        )}
        {message && (
          <div className="modal__body" style={{ overflowY: 'auto' }}>
            <b>
              {t("modal.description")}
              {": "}
            </b>
            {message.split('\n').map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))}

          </div>
        )}
        <ButtonToolbar className="modal__footer">
          <Button
            className="modal_cancel"
            onClick={() => OnReject(rejectFunc ? rejectFunc : onRejectHandler, modalToggleHandler)}
          >
            {t("modal.cancel")}
          </Button>{" "}
          <Button
            className="modal_ok"
            outline={colored}
            color={"red"}
            onClick={() => OnAccept(acceptFunc ? acceptFunc : onAcceptHandler, modalToggleHandler)}
          >
            {t("modal.ok")}
          </Button>
        </ButtonToolbar>
      </Modal>
    </div>
  );
};

ModalComponent.propTypes = {
  onAcceptHandler: PropTypes.func,
  onRejectHandler: PropTypes.func,
};

ModalComponent.defaultProps = {
  onAcceptHandler: () => { },
  onRejectHandler: () => { },
};

export default ModalComponent;
