import React from "react";
import { useDispatch } from "react-redux";
import { Button, Col, Row, Container } from "reactstrap";
import { Link as LinkScroll, scroller } from "react-scroll";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import  { randomUUID } from 'crypto'; // ES Modules import
import LanguageDropdownButtonHandler from "./LanguageDropdownButtonHandler";
import { loginStatusHandler } from "redux/slices/logInStatusSlice";
import signUp from "containers/Account/LogIn/AwsCognito/CognitoSignUp";
import { timePickerDataHandler } from "redux/slices/timeSlice";

const logo = `${process.env.PUBLIC_URL}/img/landing/sanaiinfo_logo_light.svg`;

export default function LandingMenu({ contactPageLink }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  // Handler function for login button
  const loginButtonHandler = () =>{
    dispatch(timePickerDataHandler("last1Hour"));

    navigate("/sign_in");}
  const contactButtonHandler = () => navigate("/contact_page");


  function generateUserName(emailAddressOfUser) {

    // Get the part before @ character
    const firstPartOfEmail = emailAddressOfUser.split("@")[0]

    // Clean the special characters
    // Regular expression to match special characters
    const regex = /[^a-zA-Z0-9]/g;

    // Removing special characters from the string
    const cleanedText = firstPartOfEmail.replace(regex, '');

    // FIXME Crypto is not working
    // Generate UUID code for the user
    // const randomCode = randomUUID();
    const randomCode = "325v10";

    // Random code is quite long. We will use the first split
    const generatedUserNameOfTheUser = `${cleanedText}${randomCode.split("-")[0]}`

    // console.log("generatedUserNameOfTheUser", generatedUserNameOfTheUser);
    return generatedUserNameOfTheUser;
  }

  // Handler function for login button
  const signUpButtonHandler = () => {
    // FIXME Crypto is not working
    const tenantId = "TENANT#1234567890"
    // const tenantId = `TENANT#${randomUUID()}`;

    // TODO - get the following attributes from the user
    const email = "yetkinturken@hotmail.com";
    const givenName = "Deneme Name";
    const familyName = "Deneme Surname";
    const phoneNumber = "+905424840368";
    // TODO do not store the password in the code
    const password = "Sanai123";

    // TODO - get the timezone from the browser
    const timezone = "Europe/Istanbul"

    const locale = (i18n.language === "tr") ? "tr-TR" : "en-US";

    const username = generateUserName(email);

    const cognitoResponse = signUp({
      username,
      password,
      phoneNumber,
      tenantId,
      givenName,
      familyName,
      email,
      locale,
      timezone,
    })
      .then((response) => {
        // console.log("response from signUp:", response);
      });
  }

  // Handler function for register button
  // const registerButtonHandler = () =>
  //   scroller.scrollTo("register", {
  //     duration: 1000,
  //     smooth: "easeInOutQuart",
  //   });
  // const registerButtonHandler = () => scroller.scrollTo("register");
  const registerButtonHandler = () => {
    //sign_up
    dispatch(loginStatusHandler("signupInfoPage"));
    navigate("/signupInfoPage");
    /*dispatch(loginStatusHandler("sign_up"));
     navigate("/sign_up");*/
  };

  // Open the contact link in a new window
  function contactHandler() {
    window.open(contactPageLink);
  }

  return (
    <div className="landing__menu">
      <Container>
        {/* 
          https://getbootstrap.com/docs/4.0/layout/grid/#vertical-alignment
          On xs, sm and md screens, there will be two rows for each column 
        */}
        <Row className="landing__menu-wrap" xs="2" sm="2" md="2" lg="1" xl="1">
          <Col xs="12" sm="12" md="12" lg="3" xl="3">
            <div className="landing__menu-logo">
              <img src={logo} alt="www.sanaiinfo.com" />
            </div>
          </Col>
          <Col
            xs="12"
            sm="12"
            md="12"
            // There will be "1" column space offset between logo and navbar
            lg="12"
            xl="9"
          >
            <nav className="landing__menu-nav">
              <Col
                className="landing__menu-nav--button"
                xs="1"
                sm="1"
                md="1"
                lg="1"
                xl="1"
              />
              <Col
                className="landing__menu-nav--button"
                xs="12"
                sm="12"
                md="12"
                lg="6"
                xl="6"
              >
                <LinkScroll
                  activeClass="active"
                  to="benefits"
                  spy
                // smooth
                // duration={500}
                >
                  <button type="button">
                    <b>{t("pages.landing.benefits.topLinkHeader")}</b>
                  </button>
                </LinkScroll>
                <LinkScroll
                  activeClass="active"
                  to="aboutUs"
                  spy
                // smooth
                // duration={1500}
                >
                  <button type="button">
                    {t("pages.landing.aboutUs.topLinkHeader")}
                  </button>
                </LinkScroll>
                <LinkScroll
                  activeClass="active"
                  to={contactPageLink}
                 // onClick={() => contactButtonHandler()}
                 //onClick={() => contactButtonHandler()}
                 onClick={()=>contactHandler()}
                 
                >
                  <button type="button">
                    {t("pages.landing.contact.topLinkHeader")}
                  </button>
                </LinkScroll>
              </Col>
              <Col
                className="landing__menu-nav--button"
                xs="12"
                sm="12"
                md="12"
                lg="5"
                xl="5"
              >
                <Button
                  className="icon icon--right"
                  color="success"
                  onClick={() => loginButtonHandler()}

                >
                  {t("pages.landing.loginButton.topLinkHeader")}
                </Button>
                <Button
                  className="icon icon--right"
                  color="primary"
                  // onClick={() => signUpButtonHandler()}
                  onClick={() => registerButtonHandler()}
                >
                  {t("pages.landing.registerButton.topLinkHeader")}
                </Button>
                <LanguageDropdownButtonHandler />
              </Col>
            </nav>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
