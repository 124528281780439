import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Auth } from "aws-amplify";
import { useLocation } from 'react-router-dom';
import Loading from "shared/components/Loading";
import {
    cognitoErrorModalRenderHandler,
    cognitoPrimaryModalRenderHandler,
    resetModalHandler
} from "redux/slices/modalSlice";
import cognitoSignOut from "containers/Account/LogIn/AwsCognito/cognitoSignOut";
import { Button, Card, CardBody, Col } from 'reactstrap';
import { Field, Form } from 'react-final-form';
import * as PropTypes from 'prop-types';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
import CreditCardIcon from 'mdi-react/CreditCardIcon';
import renderRadioButtonField from 'shared/components/form/RadioButton';
import { renderMaskedField } from 'shared/components/form/FormField';
import renderCheckBoxField from 'shared/components/form/CheckBox';
import { useTranslation } from "react-i18next";
import MasterCard from 'assets/mc.svg'
import VisaCard from 'assets/visa.svg'
import PayPal from 'assets/paypal.svg'

export default function PaymentForm() {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();
    const [acceptUserAgreement,setAcceptUserAgreement] =useState(false)
    // States that control loading and error states
    const [isLoading, setIsLoading] = useState(true);
    const [isLoaded, setIsLoaded] = useState(false);
    const userData = useSelector((state) => state.user.userState);

    // Websocket states
    const [socketIyzicoPayment, setSocketIyzicoPayment] = useState(null);
    // This will be used to display the websocket connection status
    const [isIyzicoPaymentWebSocketConnected, setIsIyzicoPaymentWebSocketConnected] = useState(false);
    // State showing the current location of the user
    const [currentLocation, setCurrentLocation] = useState(null);

    const [code, setCodeHandler] = useState("");
    const [startErrorHandler, setStartErrorHandler] = useState(false);

    // Object that will be sent to the Iyzico service
    const [paymentRequestObject, setpaymentRequestObject] = useState({});

    const datePipe = createAutoCorrectedDatePipe('dd/mm/yyyy');
    const onSubmit = (data) => {
        // luhnAlgorithm(data.card_number) // this checking card number 
        const newData = {...data,userData}

        // sendMessageToIyzicoService(data)
        setpaymentRequestObject(newData);
    }
    const luhnAlgorithm = (creditCardNum) => {
        let cleanNum = creditCardNum.replace(/\D/g, '');
        if (cleanNum.length == 16) {
            var totalNumber = 0
            for (var i = 0; i < cleanNum.length - 1; i++) {
                if (i % 2 == 0) {
                    var multiplicationVar = parseInt(cleanNum[i]) * 2
                    if (multiplicationVar > 9) {
                        totalNumber = totalNumber + parseInt(multiplicationVar / 10) + parseInt(multiplicationVar % 10)
                    } else {
                        totalNumber = totalNumber + parseInt(multiplicationVar)
                    }
                } else {
                    totalNumber = totalNumber + parseInt(cleanNum[i])
                }
            }
            if ((totalNumber + parseInt(cleanNum[15])) % 10 == 0) {
                console.log("Kart uygun")
            } else {
                console.log("Kart uygun değil")
            }
        }
    }

    // Function to clean up the useEffect
    function cleanUpHandler(timerFunction) {
        setIsLoading(true);
        setIsLoaded(false);
        setStartErrorHandler(false);
        clearTimeout(timerFunction);
    }

    // Error handler for the modal
    // Convert the code into string before dispatch
    function errorHandler(errorFromService = "dashboard") {
        const errorState = JSON.stringify({
            code,
        });
        setIsLoading(false);
        setIsLoaded(true);
        dispatch(
            cognitoErrorModalRenderHandler({
                errorFromService,
                errorState,
            })
        );
    };

    const openModal = (title, message) => {
        dispatch(
            cognitoPrimaryModalRenderHandler({
                name: title,
                message: message,
            })
        );
    };

    // Function to send message to the IyziCo service websocket
    const sendMessageToIyzicoService = (requestObject) => {
        console.log("sendMessageToIyzicoService", requestObject);
        // Write the request object as message
        socketIyzicoPayment.send(JSON.stringify({
            // Write the name of the request action
            // Such as: payment, refund, etc.
            action: 'createPayment', //write action
            requestObject,
        }));
    };

    // Socket connection handler
    // This open a new socket when facilityId changed
    useEffect(() => {
        Auth.currentSession()
            .then((session) => session.getAccessToken().getJwtToken())
            .then((accessToken) => {
                const webSocketApi = new WebSocket(
                    `${process.env.REACT_APP_WEBSOCKET_IYZICO_PAYMENT_URL}?Authorization=${accessToken}`
                );
                setSocketIyzicoPayment(webSocketApi);
            })
            .catch((error) => {
                errorHandler();
            });
    }, []);

    // This snippet is showing a modal when the websocket is disconnected
    // It is setting the current location of the user as state
    // If the user is in the same location, but the websocket is disconnected,
    // it is showing the modal to inform the user about the disconnection
    // Then signout the user
    useEffect(() => {
        if (
            !isIyzicoPaymentWebSocketConnected
            && currentLocation
            && currentLocation === location.pathname
        ) {
            const modalMessage = {
                name: t("modal.modalMessage.onSuccess.name.webSocketDisconnected"),
                message: t(
                    "modal.modalMessage.onSuccess.message.webSocketDisconnected"
                ),
            };
            dispatch(cognitoPrimaryModalRenderHandler(modalMessage));
            cognitoSignOut();
        };
    }, [isIyzicoPaymentWebSocketConnected, currentLocation, location]);

    // Websocket data handler
    useEffect(() => {
        if (socketIyzicoPayment) {
            socketIyzicoPayment.onopen = () => {
                console.log("Iyzico Payment Websocket is connected");
                setIsIyzicoPaymentWebSocketConnected(true);
                setCurrentLocation(location.pathname);
                // Set the current location of the user
            };

            socketIyzicoPayment.onmessage = (event) => {
                console.log("Iyzico Payment Websocket is receiving data from the backend");
                // This is the data coming from the backend
                // This data will include the response of the Iyzico service
                const eventData = JSON.parse(event.data);
                console.log("Iyzico Payment Websocket eventData", eventData);
            };

            socketIyzicoPayment.onclose = () => {
                console.log("Iyzico Payment Websocket is closed");
                setIsIyzicoPaymentWebSocketConnected(false);
                // Set the Iyzico service object as empty
                setpaymentRequestObject({});
            };
        }
        return () => {
            if (socketIyzicoPayment) {
                socketIyzicoPayment.close();
                setCurrentLocation(null);
                resetModalHandler();
                setpaymentRequestObject({});
            }
        };
    }, [socketIyzicoPayment]);

    // In case of error or time out
    // This snippet is starting the error handler function
    useEffect(() => {
        if (!isLoaded && startErrorHandler) {
            errorHandler();
        }
    }, [isLoaded, startErrorHandler]);

    useEffect(() => {
        if (
            socketIyzicoPayment
            && Object.keys(paymentRequestObject).length > 0
        ) {
            // Send the request object to the Iyzico service
            // whenever the paymentRequestObject is updated
            sendMessageToIyzicoService(paymentRequestObject);
        }
    }, [socketIyzicoPayment, paymentRequestObject]);


    return (<Fragment>
        {!isLoaded && <Loading transparentBackground loading={isLoading} />}
        <Col style={{ margin: 10 }}>
            <Card>
                <CardBody>
                    <div className="card__title">
                        <h5 className="bold-text">Payment</h5>
                    </div>
                    <div className="payment">
                        <Form onSubmit={onSubmit} initialValues={{ card: 'mc' }}>
                            {({ handleSubmit }) => (
                                <form className="form payment__credit-cards" onSubmit={handleSubmit}>
                                    <div className="form__form-group">
                                        <span className="form__form-group-label">Choose payment method:</span>
                                        <div className="form__form-group-field">
                                            <div className="payment__credit-card">
                                                <Field
                                                    name="card"
                                                    component={renderRadioButtonField}
                                                    // eslint-disable-next-line
                                                    label={<div><img className="payment__credit-card_img" src={MasterCard} alt="mc" /><p className="payment__credit-name">MasterCard</p></div>}
                                                    radioValue="mc"
                                                />
                                            </div>
                                            <div className="payment__credit-card">
                                                <Field
                                                    name="card"
                                                    component={renderRadioButtonField}
                                                    // eslint-disable-next-line
                                                    label={<div><img className="payment__credit-card_img" src={VisaCard} alt="visa" /><p className="payment__credit-name">Visa</p></div>}
                                                    radioValue="visa"
                                                />
                                            </div>
                                            <div className="payment__credit-card">
                                                <Field
                                                    name="card"
                                                    component={renderRadioButtonField}
                                                    // eslint-disable-next-line
                                                    label={<div><img className="payment__credit-card_img" src={PayPal} alt="paypal" /><p className="payment__credit-name">PayPal</p></div>}
                                                    radioValue="paypal"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form__form-group">
                                        <span className="form__form-group-label">Card number</span>
                                        <div className="form__form-group-field">
                                            <div className="form__form-group-icon">
                                                <CreditCardIcon />
                                            </div>
                                            <Field
                                                name="card_number"
                                                component={renderMaskedField}
                                                type="text"
                                                mask={[/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                                placeholder="xxxx-xxxx-xxxx-xxxx"
                                            />
                                        </div>
                                    </div>
                                    <div className="form__form-group-date-cvc">
                                        <div className="form__form-group form__form-group-date">
                                            <span className="form__form-group-label">Expiration Date</span>
                                            <div className="form__form-group-field">
                                                <Field
                                                    name="date"
                                                    component={renderMaskedField}
                                                    type="text"
                                                    mask={[/\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                                    pipe={datePipe}
                                                    placeholder="MM/YY"
                                                    keepCharPositions
                                                />
                                            </div>
                                        </div>
                                        <div className="form__form-group form__form-group-cvc">
                                            <span className="form__form-group-label">CVC</span>
                                            <div className="form__form-group-field">
                                                <Field
                                                    name="cvc"
                                                    component={renderMaskedField}
                                                    type="text"
                                                    mask={[/\d/, /\d/, /\d/]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form__form-group">
                                        <span className="form__form-group-label">Cardholder name</span>
                                        <div className="form__form-group-field">
                                            <Field
                                                name="name"
                                                component="input"
                                                type="text"
                                                placeholder="Name and Surname"
                                            />
                                        </div>
                                    </div>
                                    <div className="form__form-group">
                                        <div className="form__form-group-field">
                                            <Field
                                                name="3Dsecure"
                                                type="checkbox"
                                                component={renderCheckBoxField}
                                                label={t("pages.formItems.3DSecure")}
                                            //disabled={item.disabled}
                                            //className={className}
                                            />
                                        </div>
                                    </div>

                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <input
                                            type="checkbox"
                                            checked={acceptUserAgreement}
                                            style={{ width: "12px", height: "12px", marginRight: "5px" }}
                                         onChange={() => setAcceptUserAgreement(!acceptUserAgreement)}
                                        />
                                        <label>
                                            <b>
                                                <span onClick={() => openModal(t("pages.landing.termsAndConditions.title"), t("pages.landing.termsAndConditions.message"))} style={{ cursor: "pointer" }}>
                                                    {t("auth.userAgreement")}
                                                </span>
                                            </b>
                                            {/* {t("auth.accept")} */}
                                        </label>
                                    </div>
                                    <h4 className="payment__total">Total Price: $348.00</h4>
                                    <div className="form__form-group">
                                        <div className="form__form-group-field">
                                            <div style={{ textAlign: 'end', flex: 1 }}>
                                                <Button disabled={!isIyzicoPaymentWebSocketConnected} color="primary" type="submit">Öde</Button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            )}
                        </Form>
                    </div>
                </CardBody>
            </Card>
        </Col>
    </Fragment>)
}
