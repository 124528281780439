import React from "react";
import { redirect } from "react-router-dom";

// Icon that will be used if user is authorized
const authorizedIcon = `${process.env.PUBLIC_URL}/img/burger.svg`;

// Icon that will be used if user is unauthorized
const unAuthorizedIcon = `${process.env.PUBLIC_URL}/img/unAuthorizedBurger.svg`;

export default function TopbarSidebarButton({
  changeMobileSidebarVisibility,
  changeSidebarVisibility,
  logInStatus,
}) {
  // If the user is unauthorized, the button will redirect to landing page
  function redirectToLandingPage() {
    // history.push("/");
    redirect("/");
  }

  return logInStatus === "authenticated" ? (
    <div>
      <button
        type="button"
        className="topbar__button topbar__button--desktop"
        onClick={() => changeSidebarVisibility()}
      >
        <img src={authorizedIcon} alt="" className="topbar__button-icon" />
      </button>
      <button
        type="button"
        className="topbar__button topbar__button--mobile"
        onClick={() => changeMobileSidebarVisibility()}
      >
        <img src={authorizedIcon} alt="" className="topbar__button-icon" />
      </button>
    </div>
  ) : (
    <div>
      <button
        type="button"
        className="topbar__button topbar__button--desktop"
        onClick={() => redirectToLandingPage()}
      >
        <img src={unAuthorizedIcon} alt="" className="topbar__button-icon" />
      </button>
    </div>
  );
}
