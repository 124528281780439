import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import SidebarCategory from "./SidebarCategory";
import { currentFacilityHandler } from "redux/slices/facilitySlice";

const SidebarContent = ({ changeToDark, changeToLight }) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const userData = useSelector((state) => state.user.userState);


  // Set state for the current facility
  const { facilities } = useSelector((state) => state.facility.facilityState);
  // Set state for the current user
  const { subId } = useSelector((state) => state.user.userState);

  // Set the list of dashboard links
  const [dashboardLinks, setDashboardLinks] = useState([]);
  /*  useEffect(() => {
    if (!isNotificationShown) {
      WelcomeNotification(theme, rtl, setIsNotificationShown, isNotificationShown);
    }
  }, [theme, rtl, isNotificationShown]);
*/
  useEffect(() => {
    if (facilities) {
      setDashboardLinks(() =>
        facilities.map(
          (facilityElement) => {
            return (
              <button
                key={facilityElement.SK}
                type="button"
                className="sidebar__link multi_line_link"
                onClick={() => {
                  navigate(`/dashboard/${subId}/${facilityElement.SK}`);
                  dispatch(currentFacilityHandler(facilityElement));
                }}
              >
                <p className="sidebar__link-title">{facilityElement.unitName}</p>
              </button>
            );
          }
        )
      );
    }
  }, [facilities]);

  return (
    <div className="sidebar__content">
      <ul className="sidebar__block">
        <SidebarCategory title={t("sidebar.layout.title")} icon="layers">
          <button
            type="button"
            className="sidebar__link"
            onClick={changeToLight}
          >
            <p className="sidebar__link-title">
              {t("sidebar.layout.theme.lightTheme")}
            </p>
          </button>
          <button
            type="button"
            className="sidebar__link"
            onClick={changeToDark}
          >
            <p className="sidebar__link-title">
              {t("sidebar.layout.theme.darkTheme")}
            </p>
          </button>
        </SidebarCategory>
      </ul>
      <ul className="sidebar__block">
        {/* Translation will be plural if length is different than 1 */}
        <SidebarCategory
          title={
            t("sidebar.dashboard.title",
              // count property is used for plural translation
              {
                count: facilities.length
              })}
          icon="chart-bars">
          {facilities ? dashboardLinks : t("sidebar.dashboard.noDashboardAvailable")}
        </SidebarCategory>
      </ul>
      {/*<ul className="sidebar__block"> //TODO new projet draft plan 
        <SidebarCategory title="Görev Proje" icon="layers">
          <SidebarCategory title="Görev" >
            <button
              type="button"
              className="sidebar__link"
              onClick={() => navigate("/myTaskEntries")}
            >
              <p className="sidebar__link-title">
                Görev Girdilerim
              </p>
            </button>
            <button
              type="button"
              className="sidebar__link"
              onClick={() => navigate("/myTasks")}
              >
              <p className="sidebar__link-title">
                Görevlerim
              </p>
            </button>
          </SidebarCategory>
          <button
            type="button"
            className="sidebar__link"
            onClick={() => navigate("/taskNotifications")}
          >
            <p className="sidebar__link-title">
              Bildirimler
            </p>
          </button>
          <button
            type="button"
            className="sidebar__link"
            onClick={() => navigate("/createNewTask")}
          >
            <p className="sidebar__link-title">
              Görev Oluştur
            </p>
          </button>
        </SidebarCategory>
            </ul>*/}

      <ul className="sidebar__block">
        <button
          type="button"
          className="sidebar__link"
          onClick={() => navigate("/tabsManualData")}
        >

          <span className={`sidebar__link-icon lnr lnr-${"upload"}`} />
          <p className="sidebar__link-title">
            {t("sidebar.addManualData.title")}
          </p>
        </button>
      </ul>

      {userData.userStatusInt > 5 &&
        <ul className="sidebar__block">
          <button
            type="button"
            className="sidebar__link"
            onClick={() => navigate("/settings")}
          >
            <span className={`sidebar__link-icon lnr lnr-${"cog"}`} />
            <p className="sidebar__link-title">
              {t("pages.settings.settings.title")}
            </p>
          </button>
        </ul>


      }

      {userData.id && userData.id.split('#')[0] === "BROKER" &&
        <ul className="sidebar__block">
          <button
            type="button"
            className="sidebar__link"
            onClick={() => navigate("/dealerSettings")}
          >
            <span className={`sidebar__link-icon lnr lnr-${"cog"}`} />
            <p className="sidebar__link-title">
              {t("pages.settings.settings.broker")}
            </p>
          </button>
        </ul>


      }

    </div>
  );
};

SidebarContent.propTypes = {
  changeToDark: PropTypes.func.isRequired,
  changeToLight: PropTypes.func.isRequired,
};

export default SidebarContent;
