import React from "react";

export default function IconHandler(modalColor) {
  switch (modalColor) {
    case "primary":
      return <span className="lnr lnr-pushpin modal__title-icon" />;
    case "success":
      return <span className="lnr lnr-thumbs-up modal__title-icon" />;
    case "warning":
      return <span className="lnr lnr-flag modal__title-icon" />;
    case "danger":
      return <span className="lnr lnr-cross-circle modal__title-icon" />;
    default:
      return <span className="lnr lnr-pushpin modal__title-icon" />;
  }
}
