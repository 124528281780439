// import React, { useContext } from "react";
import { Auth } from "aws-amplify";
import getGraphQlUserData from "./getGraphQlUserData";
import { userDataHandler } from "redux/slices/userSlice";
import { facilityInitialDataHandler } from "redux/slices/facilitySlice";
import { tenantDataHandler } from "redux/slices/tenantSlice";
import { loginStatusHandler } from "redux/slices/logInStatusSlice";

export default async function HandleStoreOnSignIn(dispatch) {
  // const { passwordFormTypeHandler } = useContext(PasswordContext);

  try {
    // Check if the user is already signed in
    const { attributes } = await Auth.currentAuthenticatedUser();

    // Get attributes from the database
    const graphqlData = await getGraphQlUserData(attributes);
    // console.log("graphqlData from appHandler", graphqlData);

    const { userData, facilityData, tenantData } = graphqlData;
    // console.log("facilityData from appHandler", facilityData);
    // console.log("tenantData from appHandler", tenantData);

    // Update the user data in Redux store
    dispatch(userDataHandler(userData));

    // Update the facility data in Redux store
    dispatch(facilityInitialDataHandler(facilityData));

    // Update the tenant data in Redux store
    dispatch(tenantDataHandler(tenantData));

    // Change the form state as authenticated
    dispatch(loginStatusHandler("authenticated"));

  } catch (err) {
    throw new Error(err.message);
  }
}
