import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  Card, CardBody, Col, Table
} from 'reactstrap';
export default function TaskNotifications() {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const notificationsTableHeaders = [
    { id: 0, title: "Bildirim mesajı" },
  ];
  const notificationsTableData = [
    { id: 0, message: "Şebnem Ferah '30 Takım ayak' görevini tamamladı." },
    { id: 1, message: "Mahmut Tuncer '20 Ahşap Sandalye' görevini durdurdu." },
    { id: 2, message: "Kaan Tangöz '5 CNC tabla' görevini iptal etti." },
    { id: 3, message: "Mahmut Tuncer '2 Takım pano' görevini başlattı." },
    { id: 3, message: "'11 Adet Yatak Başlığı' görevi zaman aşımına uğradı." },
  ];

  const myTasksItems = [
    { id: 0, title: "30 Takım ayak", responsible: "Şebnem Ferah", startDate: "12/05/1895", endDate: "12/09/1895", status: "Tamamlandı",statusColor: "teal"  },
    { id: 1, title: "30 adet kol", responsible: "Mahmut Tuncer", startDate: "12/05/1895", endDate: "12/09/1895", status: "Tamamlandı",statusColor: "teal"  },
    { id: 2, title: "30 adet tabla", responsible: "Tarkan Ferah", startDate: "12/05/1895", endDate: "12/09/1895", status: "Devam Ediyor",statusColor: "orange"},
    { id: 3, title: "30 Takım ayak", responsible: "Şebnem Ferah", startDate: "12/05/1895", endDate: "12/09/1895", status: "Tamamlandı",statusColor: "teal"},
    { id: 4, title: "30 Takım ayak", responsible: "Şebnem Ferah", startDate: "12/05/1895", endDate: "12/09/1895", status: "İptal Edildi",statusColor: "red"},


];
  return (
    <div style={{ marginLeft: 20, marginRight: 20 }}>
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">Bildirimler</h5>
            </div>

            <Table responsive hover style={{ tableLayout: 'fixed', width: '100%' }}>

              <tbody >
                {myTasksItems.map((item, index) => (

                  <tr key={item.id + "-" + index}
                    style={{ backgroundColor: index % 2 == 0 && 'ButtonShadow', display: 'flex' }}
                        /*onClick={() => console.log("ClickedList Item")}*/>
                    <td style={{ flex: 1 }}>
                      "{<b>{item.title}</b>}"  {<b>{item.responsible}</b>} tarafından {<b>{item.endDate}</b>} tarihinde {<b style={{color:item.statusColor}}>{item.status}</b>}
                      
                      </td>
                    <td style={{ color: 'teal', textAlign: 'right', paddingRight: '15px', paddingLeft: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center' }} /*onClick={() => console.log("from detail")}*/>
                      Detay
                    </td>
                  </tr>

                ))}
              </tbody>
            </Table>


          </CardBody>
        </Card>
      </Col>
    </div>
  )
}
