import { createSlice } from "@reduxjs/toolkit";
import {
  initialModalDetails,
  initialModalRender,
  errorModal,
  coloredSuccessModalDetails,
  primaryModalDetails,
  coloredWarningModalDetails,
} from "./modalSliceHelpers/initialStatesModalSlice";

const initialModalState = {
  // This state is used during localization
  errorFromService: "error",
  openTheModal: false,
  modalType: "error",
  modalDetails: {
    ...initialModalDetails,
  },
  modalRender: {
    ...initialModalRender,
  },
};

const modalSlice = createSlice({
  name: "modalStatus",
  initialState: initialModalState,
  reducers: {
    toggleModalHandler(state) {
      state.openTheModal = false;
    },

    resetModalHandler(state) {
      return {
        ...state,
        ...initialModalState,
      };
    },

    cognitoColoredSuccessModalRenderHandler(state, action) {
      const { name, message, acceptFunc, rejectFunc } = action.payload;
      state.openTheModal = true;
      state.modalType = "success";
      state.modalDetails = {
        ...coloredSuccessModalDetails,
      };
      state.modalRender = {
        ...initialModalRender,
        name,
        message,
        acceptFunc,
        rejectFunc
      };
    },
    cognitoColoredWarningModalRenderHandler(state, action) {
      const { name, message, acceptFunc, rejectFunc } = action.payload;
      state.openTheModal = true;
      state.modalType = "warning";
      state.modalDetails = {
        ...coloredWarningModalDetails,
      };
      state.modalRender = {
        ...initialModalRender,
        name,
        message,
        acceptFunc,
        rejectFunc
      };
    },

    cognitoPrimaryModalRenderHandler(state, action) {
      const { name, message, acceptFunc, rejectFunc } = action.payload;
      state.openTheModal = true;
      state.modalType = "primary";
      state.modalDetails = {
        ...primaryModalDetails,
      };
      state.modalRender = {
        ...initialModalRender,
        name,
        message,
        acceptFunc,
        rejectFunc
      };
    },

    cognitoErrorModalRenderHandler(state, action) {
      const { errorFromService, errorState } = action.payload;
      const errorStateAsObject = JSON.parse(errorState);

      state.openTheModal = true;
      state.errorFromService = errorFromService;
      state.modalDetails = {
        ...errorModal,
      };
      state.modalRender = {
        ...initialModalRender,
        ...errorStateAsObject,
      };
    },
  },
});

export const {
  toggleModalHandler,
  resetModalHandler,
  cognitoPrimaryModalRenderHandler,
  cognitoColoredSuccessModalRenderHandler,
  cognitoErrorModalRenderHandler,
  cognitoColoredWarningModalRenderHandler
} = modalSlice.actions;

export default modalSlice.reducer;
