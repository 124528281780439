import { API, Auth } from "aws-amplify";
export default async function userMutationHandler (
    mutationType,
    currentOperatorId,
    userInput
) {
    const apiName = process.env.REACT_APP_REST_API_USER_MUTATION_NAME;
    const path = "usermutation";
    const session = await Auth.currentSession();
    //console.log("session", session);
    const userMutationSchema = {
        headers: {
            Authorization: `Bearer ${session.idToken.jwtToken}`
        },
        body: {
            mutationType,
            currentOperatorId,
            userInput,
        },
    };
    try {
        const apiResponse = await API.post(apiName, path, userMutationSchema);
        //console.log("userMutation data api response", apiResponse);
        return apiResponse;
    } catch (userMutationError) {
        //console.log("Error from userMutation REST API: ", userMutationError);
    }
};