import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import ChevronDownIcon from "mdi-react/ChevronDownIcon";
import { languageHandler } from "redux/slices/languageSlice";

export default function LanguageDropdownButtonHandler() {
  const dispatch = useDispatch();
  const { lang, availableLanguages, localeLang } = useSelector(
    (state) => state.lang
  );
  const { t, i18n } = useTranslation();

  // i18n listener
  // Gets fired when i18n changeLanguage function got called
  i18n.on("languageChanged", (lng) => dispatch(languageHandler(lng)));

  async function languageChangeHandler(language) {
    await i18n.changeLanguage(language).catch((err) => err);
  }

  const [languageLinks, setLanguageLinks] = useState([]);

  const flagPath = (selectedLanguage) =>
    `${process.env.PUBLIC_URL}/img/language/${selectedLanguage}.png`;

  useEffect(() => {
    const linksForLanguages = availableLanguages
      .filter((languageElement) => languageElement !== lang)
      .map((alternativeLang, langIndex) => (
        <DropdownItem
          style={{
            width: "20%",
          }}
          key={localeLang} // This is the unique key of the element
          onClick={() => languageChangeHandler(alternativeLang)}
        >
          <img src={flagPath(alternativeLang)} alt={alternativeLang} />
        </DropdownItem>
      ));
    setLanguageLinks(linksForLanguages);
  }, [lang]);

  return (
    <UncontrolledDropdown>
      <DropdownToggle className="icon icon--right" color="link">
        <p>
          <span className="landing__language-btn-title-yt">
            <img
              style={{
                width: "30px",
                height: "auto",
              }}
              src={flagPath(lang)}
              alt={lang}
            />
          </span>
          <ChevronDownIcon />
        </p>
      </DropdownToggle>
      <DropdownMenu className="dropdown__menu">{languageLinks}</DropdownMenu>
    </UncontrolledDropdown>
  );
}
