import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Element } from "react-scroll";
import LandingMenu from "./components/LandingMenu";
import Header from "./components/Header";
import AboutUs from "./components/AboutUs";
import Register from "./components/Register";
import Benefits from "./components/Benefits";
import Footer from "./components/Footer";
import Cookies from "js-cookie";
import CookieConsent from "react-cookie-consent";

export default function Landing() {
  const { lang } = useSelector((state) => state.lang);

  // Link that will be used for contact page
  const [contactLink, setContactLink] = useState(
    "https://www.sanai.com.tr/en/contact"
  );

  // Set the contact page for different languages
  function contactLinkHandler() {
    const link =
      lang === "tr"
        ? "https://www.sanai.com.tr/iletisim"
        : "https://www.sanai.com.tr/en/contact";

    setContactLink(link);
  }

  useEffect(() => contactLinkHandler(), [lang]);

  return (
    <div className="landing">
      <LandingMenu contactPageLink={contactLink} />
      <Header />
      <Element name="benefits" />
      <Benefits />
      <Element name="register" />
      <Register contactPageLink={contactLink} />
      <Element name="aboutUs" />
      <AboutUs />
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        cookieName="TestCookie"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        onAccept={()=>Cookies.set('TestCookie','TestCookieValue')}
        expires={150}
        >
       We value your privacy<br/>We use cookies enchange your browsing experience, serve personalized ads or content, and anlyze our traffic<br/>By clicking 'Accept', you consent to our use of cookies.
      </CookieConsent>
     
      <Footer />
    </div>
  );
}
