import React from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import Topbar from "./topbar/Topbar";
import Sidebar from "./sidebar/Sidebar";
import { withRouter } from "../App/appHelpers/appWithRouter";

const Layout = () => {
  const sidebar = useSelector((state) => state.sidebar);
  const layoutClass = classNames({
    layout: true,
    "layout--collapse": sidebar.collapse,
  });

  return (
    <div className={layoutClass}>
      <Topbar />
      <Sidebar />
    </div>
  );
};

export default withRouter(Layout);
