import { createSlice } from '@reduxjs/toolkit';

const initialDeviceDataState = {devices: []};

const deviceDataSlice = createSlice({
    name: "deviceData",
    initialState: {
        deviceDataState: initialDeviceDataState,
    },
    reducers: {
        deviceFirstRenderDataHandler(state, action) {
            const deviceData = action.payload;
            // Reducers can only make copies of the original values, and then they can mutate the copies
            // This is safe, because we made a copy
            state.deviceDataState = {
                ...state.deviceDataState,
                devices: deviceData,
            };
        },

        deviceDataHandler(state, action) {
            const deviceData = action.payload;
            // Reducers can only make copies of the original values, and then they can mutate the copies
            // This is safe, because we made a copy
            state.deviceDataState = [
                ...state.deviceDataState,
                deviceData,
            ];
        },

        returnToInitialDeviceDataState(state) {
            state.deviceDataState = initialDeviceDataState;
        }
    }
});

export const {
    deviceFirstRenderDataHandler,
    deviceDataHandler,
    returnToInitialDeviceDataState
} = deviceDataSlice.actions;

export default deviceDataSlice.reducer;