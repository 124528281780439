import { API, Auth } from "aws-amplify";

export default async function getFacilityData(
    queryType,
    queryDescriptiveCode,
    facilityQueryStringParameter,
    endTimeForQuery,
    startTimeForQuery
  ) {
    const apiName = process.env.REACT_APP_REST_API_NAME;
    const path = "facilitydata";

    const session = await Auth.currentSession();
    const myInit = {
      headers: {
        Authorization: session.idToken.jwtToken,
      }, // OPTIONAL
      queryStringParameters: {
        queryType,
        queryDescriptiveCode,
        endTimeForQuery,
        startTimeForQuery,
        SK: facilityQueryStringParameter,
        // Default category code for general use
        industryCategory: "0000",
      },
    };

    try {
      const apiResponse = await API.get(apiName, path, myInit);
      // console.log("Facility data api response", apiResponse);
      return apiResponse;
    } catch (getFacilityDataError) {
     // console.log("Error from REST API: ", getFacilityDataError);
    }
};