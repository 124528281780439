import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PasswordContext from "context/PasswordContext";

export default function ForgotPasswordHandler() {
  const { t, i18n } = useTranslation();
  const { passwordFormTypeHandler } = useContext(PasswordContext);

  return (
    <div className="account__forgot-password">
      <Link to="/password" onClick={() => passwordFormTypeHandler("forgotPasswordBeforeCode")}
      >
        {t("auth.forgotPassword")}
      </Link>
    </div>
  );
}
