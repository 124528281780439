import React from "react";
import { useTranslation } from "react-i18next";
import Collapse from "shared/components/Collapse";

const DashboardPageBoxedCollapseFullWidth = ({
  toggleTitle,
  toggleContactDetails
  // toggleOriginalName,
  // toggleNote,
}) => {
  const { t } = useTranslation();
  const parseToggleContactDetails = JSON.parse(toggleContactDetails)
  return (
    <Collapse title={`${t("pages.dashboardPage.dashboardName")}: ${toggleTitle}`} className="with-shadow">
      <p>
        {t("pages.dashboardPage.contactDetails")}: 
      </p>
      {toggleContactDetails ?
        <div>

          <p>
            {t("pages.dashboardPage.country")}: {parseToggleContactDetails.country} /{t("pages.dashboardPage.city")}: {parseToggleContactDetails.city} / {t("pages.dashboardPage.postCode")}: {parseToggleContactDetails.postCode}           
          </p>
            {t("pages.dashboardPage.address")}: {parseToggleContactDetails.address}
        </div>

        : t("pages.dashboardPage.noContactDetails")
      }
    </Collapse>
  );
};

export default DashboardPageBoxedCollapseFullWidth;
