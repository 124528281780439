import React, { useEffect, Fragment, useState } from "react";
import { Collapse } from 'reactstrap';
import { useDispatch, useSelector } from "react-redux";
import { returnToInitialDashboardDataState } from "redux/slices/dashboardDataSlice";
import { Auth } from "aws-amplify";
import {
  cognitoErrorModalRenderHandler,
} from "redux/slices/modalSlice";
import NotificationsIcon from 'mdi-react/NotificationsIcon';
import { useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";


const TopbarNotification = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [socket, setSocket] = useState(null);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [code, setCodeHandler] = useState("");
  const [notifications, setNotifications] = useState([]);
  const [insideCollapse, setInsideCollapse] = useState(null)
  const [collapseKey, setCollapseKey] = useState(0)
  const [alarmIconColor,setAlarmIconColor] = useState(null)

  const { currentFacility } = useSelector(
    (state) => state.facility.facilityState
  );
  // Error handler for the modal
  // Convert the code into string before dispatch
  function errorHandler(errorFromService = "dashboard") {
    const errorState = JSON.stringify({
      code,
    });

    dispatch(
      cognitoErrorModalRenderHandler({
        errorFromService,
        errorState,
      })
    );
  }
  // facilityId is the id of the facility that is selected
  const facilityId = currentFacility.SK;
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const collapseNotification = () => {
    setAlarmIconColor(null)
    setIsCollapsed(!isCollapsed);
  };
  // Websocket data handler
  useEffect(() => {
    if (socket) {
      socket.onopen = () => {
        setCurrentLocation(location.pathname);
        // Set the current location of the user
      };

      socket.onmessage = (event) => {
        const eventData = JSON.parse(event.data)
        // This dispatch function is used for tracking the near real time data
        // dispatch(dashboardCurrentDataHandler(eventData));
        // TODO Show this alarm message on screen
        if (eventData.alarmId) {
          // Alarm message
          // T karakterinden böler ve saat/dakika kısmını alır
          const [tarihKismi, saatDakikaKismi] = eventData.timeIso.split('T');

          // Saat ve dakika kısmını almak için ilk 5 karakteri kullanır
          const notificationTime = saatDakikaKismi.slice(0, 5);

          const expData = {
            id: eventData.alarmId,
            dataName: eventData.dataName,
            equipmentName: eventData.equipmentName,
            message: `${t("pages.alarmSettings.alarmConditionOptionTitles." + eventData.alarmCondition)}:${eventData.alarmValue}`,
            date: notificationTime,
          }

          // Kontrol için bir işlem ekleyin
          const existingIndex = notifications.findIndex(item => item.id === expData.id);
          const updatedNotifications = notifications;

          if (existingIndex !== -1) {
            // Eğer aynı ID'ye sahip bir öğe varsa, güncelleyin
            updatedNotifications[existingIndex] = expData;
          } else {
            // Eğer aynı ID'ye sahip öğe yoksa, ekleyin
            updatedNotifications.push(expData)
          }
          setAlarmIconColor("orange")
          renderInsideColapse(updatedNotifications);
        }
      }

      socket.onclose = () => {
        // To clean the redux store and save some memory, the dispatch function to return to initial state is called
        // This will be triggered when the websocket is closed
        dispatch(returnToInitialDashboardDataState());
      };
    }
    return () => {
      if (socket) {
        socket.close();
        setCurrentLocation(null);
        dispatch(returnToInitialDashboardDataState());
      }
    };
  }, [socket, facilityId]);
  // Socket connection handler
  // This open a new socket when facilityId changed
  useEffect(() => {
    if (facilityId) {
      Auth.currentSession()
        .then((session) => session.getAccessToken().getJwtToken())
        .then((accessToken) => {
          const webSocketApi = new WebSocket(
            `${process.env.REACT_APP_WEBSOCKET_URL}?facilityId="${facilityId.split("#")[1]
            }"&Authorization=${accessToken}`
          );
          setSocket(webSocketApi);
        })
        .catch((error) => {
          errorHandler();
        });
    }
  }, [facilityId]);


  const renderInsideColapse = (renderNotificationList) => {
    setNotifications(renderNotificationList)
    setInsideCollapse(
      <div>
        {renderNotificationList.map(notification => (
          <div className="topbar__collapse-item" key={notification.id}>

            <p className="topbar__collapse-message">
              <span className="topbar__collapse-name">{notification.equipmentName} {notification.dataName && notification.dataName} </span>
              {notification.message}
            </p>
            <p className="topbar__collapse-date">{notification.date}</p>
          </div>
        ))}
      </div>
    )


  }



  return (

    <div className="topbar__collapse">
      <button className="topbar__btn" type="button" onClick={collapseNotification}>
        <span style={{
          display: 'flex',
          justifyContent: 'center',  
          alignItems: 'center'   ,

        }}>
          <span className={`sidebar__link-icon lnr lnr-${"alarm"}`} style={{fontSize:25,color:alarmIconColor}} />
        </span>
      </button>
      {isCollapsed && (
        <button
          className="topbar__back"
          aria-label="topbar__back"
          type="button"
          onClick={collapseNotification}
        />
      )}
      <Collapse
        isOpen={isCollapsed}
        className="topbar__collapse-content"
        style={{marginTop:25}}
      >
        <div className="topbar__collapse-title-wrap">
          <p className="topbar__collapse-title">{t("error.dashboard.title.NotificationNoData")}</p>
        </div>
        <div style={{ maxHeight: 312, overflowY: 'scroll' }}>
          {insideCollapse === null ? <div style={{ textAlign: 'center', marginTop: 10, marginBottom: 10 }}>{t("error.dashboard.message.NotificationNoData")}</div> : insideCollapse}

        </div>


      </Collapse>
    </div>

  );
};

export default TopbarNotification;
