import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const LogInFormSharedFields = (props) => {
  const { t, i18n } = useTranslation();
  const { name } = useSelector((state) => state.user.userState);
  const { children } = props;

  return (
    <div className="account">
      <div className="account__wrapper">
        <div className="account__card">
          <div className="account__head">
            <h3 className="account__title">
              {t("auth.signInWelcome")}
              {/* {name ? (
                <span className="account__title">
                  {" "}
                  <b>{name}</b>
                </span>
              ) : (
                <span className="account__logo">
                  {" "}
                  sanai
                  <span className="account__logo-accent">
                    info
                    <span className="account__logo">.com</span>
                  </span>
                </span>
              )} */}
            </h3>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};

export default LogInFormSharedFields;
