import React, { useEffect, useState } from 'react';
import { API, graphqlOperation } from "aws-amplify";
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { tenantDataHandler } from "redux/slices/tenantSlice";
import Select from 'react-select';
import renderCheckBoxField from 'shared/components/form/CheckBox';
import { Auth } from "aws-amplify";
import { getUsersByTenantId } from "graphql/queries";
// easydev imports
import { Field, Form } from 'react-final-form';
import {
    Card, CardBody, Col, Button,
} from 'reactstrap';
import { Data } from '@react-google-maps/api';
import { updateTenant } from "graphql/mutations";
import {
    cognitoErrorModalRenderHandler,
    cognitoColoredSuccessModalRenderHandler,
    resetModalHandler
} from "redux/slices/modalSlice";
export default function CreateNewTenant() {
    const { t, i18n } = useTranslation();
    const themeData = useSelector((state) => state.theme.className);

    const location = useLocation();
    const choosenTenantData = location.state?.choosenTenantData?.value;
const [choosenTenantTier,setChoosenTenantTier] = useState(null)
    const dispatch = useDispatch()
    const tenantDataDefaultTenant = useSelector((state) => state.tenant.tenantState);
    const [tenantData, setTenantData] = useState(choosenTenantData ? choosenTenantData : tenantDataDefaultTenant)
    const [tenantTierInitialState, setTenantTierInitialState] = useState(null);
    const [tenantNameInputError, setTenantNameInputError] = useState(false)
    const [ownerName, setOwnerName] = useState(null)
    const [userList, setUserList] = useState([]);
    const tenantTierOptions = [
        { value: 'basic', label: t("tenantTiers.basic") },
        { value: 'business', label: t("tenantTiers.business") },
        { value: 'platinum', label: t("tenantTiers.platinium") },
        { value: 'enterprise', label: t("tenantTiers.enterprise") },
    ];
    // Function to update the tenant data
    async function updateTenantHandler(tenantAttributes) {
        // Create the parameters for the update operation
        const updateOperationInput = {
            // Id should be fetched from the redux store
            ...tenantAttributes
        };
        // Id and SK are required for the database query
        updateOperationInput.id = tenantData.id;
        updateOperationInput.SK = tenantData.id;
        // We need only the value of tenant tier. Label should be removed
        if (tenantAttributes.tenantTier) {
            updateOperationInput.tenantTier = tenantAttributes.tenantTier.value;
        };
        // API call to update tenant data
        await API.graphql(
            graphqlOperation(
                updateTenant, {
                input: updateOperationInput
            })
        ).catch((err) => {
            throw new Error(err.errors[0]);
        });
        // Return the updated attributes for Redux store update
        return updateOperationInput;
    };

    async function queryTenantUsersHandler() {
        // For security reasons, the attributes are retrieved directly from Amplify
        const { attributes } = await Auth.currentAuthenticatedUser();

        // API call to query users data
        const graphQlDataAtQueryTenantUsers = await API.graphql(
            graphqlOperation(
                getUsersByTenantId, {
                tenantId: choosenTenantData?.value ? choosenTenantData.value : attributes["custom:tenantId"],
                subId: attributes.sub
            })
        ).catch((err) => {
            throw new Error(err.errors[0]);
        });
        // Return the list of users registered to the tenant
        return graphQlDataAtQueryTenantUsers.data.getUsersByTenantId;
    };
    const onSubmit = async (dataOnSubmit) => {
        try {
            await updateTenantHandler(dataOnSubmit)
                .then((updatedAttribures) => {
                    dispatch(tenantDataHandler(updatedAttribures));
                })
                .then(() => {
                    const successMessage = {
                        name: t(
                            "modal.modalMessage.onSuccess.name.attributeUpdateSuccess"
                        ),
                        message: t(
                            "modal.modalMessage.onSuccess.message.attributeUpdateSuccess"
                        ),
                    };
                    dispatch(cognitoColoredSuccessModalRenderHandler(successMessage));
                })
        } catch (err) {
            const errorState = {
                name: "updateError",
                code: "updateError"
            };
            // In case of an error, user is informed by an error message
            dispatch(
                cognitoErrorModalRenderHandler({
                    errorFromService: "apiOperation",
                    errorState: JSON.stringify(errorState)
                })
            );
        }
    };


    useEffect(() => {
        // Only certain level of users can fetch the list of users
        queryTenantUsersHandler()
            .then((arrayOfUsers) => {
                setUserList(arrayOfUsers);
                setOwnerName(arrayOfUsers.find(user => user.subId === tenantData.ownerId)?.givenName + " " + arrayOfUsers.find(user => user.subId === tenantData.ownerId)?.familyName)
            })
            .catch((errorQueryTenantUsers) => {
                // In case of an error, user is informed by an error message
                // For security reasons, the error message is displayed as "Something went wrong"
                dispatch(
                    cognitoErrorModalRenderHandler({
                        errorFromService: "apiOperation",
                        errorState: JSON.stringify(errorQueryTenantUsers)
                    })
                );
            });

        // Clean up function
        return () => {
            setUserList([]);
            dispatch(resetModalHandler());
        }
    }, [tenantData]);
    return (
        <div style={{ marginLeft: 20, marginRight: 20 }}>
            <Col md={12} lg={12}>
                <Card>
                    <CardBody>
                        <div className="card__title">
                            <h5 className="bold-text">{t("pages.settings.settings.createTenant")}</h5>
                        </div>
                        <Form onSubmit={onSubmit}
                            validate={(values) => {
                                const errors = {};
                                if (values.tenantName && values.tenantName.length > 19) {
                                    setTenantNameInputError(true)
                                } else {
                                    setTenantNameInputError(false)

                                }
                                return errors;
                            }}>
                            {({ handleSubmit, form }) => (
                                <form className="form form--horizontal" onSubmit={handleSubmit}>

                                    <div className="form__form-group">
                                        <span className="form__form-group-label">{t("pages.formItems.tenantName")}</span>
                                        <div className="form__form-group-field" style={{ display: 'block' }}>
                                            <Field
                                                name="tenantName"
                                                component="input"
                                                type="text"
                                                placeholder={t("pages.formItems.tenantName")}
                                                maxLength={20}
                                            />
                                            {tenantNameInputError && <span className="form__form-group-error">{t("pages.settings.errors.maxLengthError")}</span>}

                                        </div>

                                    </div>
                                    <div className="form__form-group">
                                        <span className="form__form-group-label">{t("pages.formItems.tenantTier")}</span>
                                        <div className="form__form-group-field">
                                            <Select
                                                name="tenantTier"
                                                value={choosenTenantTier}
                                                onChange={(data) => setChoosenTenantTier(data)}
                                                options={tenantTierOptions}
                                                clearable={false}
                                                className="react-select"
                                                placeholder={t("pages.formItems.tenantTier")}
                                                styles={{
                                                    control: (provided) => ({
                                                        ...provided,
                                                        backgroundColor: themeData === "theme-dark" && "#232329",

                                                    }),
                                                    singleValue: (provided) => ({
                                                        ...provided,
                                                        color: themeData === "theme-dark" && "#dddddd",
                                                    }),

                                                }}
                                            />

                                        </div>
                                    </div>
                                    {/* <div className="form__form-group">
                                        <span className="form__form-group-label">{t("pages.formItems.activeUserIds")}</span>
                                        <div className="form__form-group-field">
                                            <Field
                                                name="activeUserIds"
                                                component="input"
                                                type="text"
                                                placeholder={t("pages.formItems.activeUserIds")}

                                            />
                                        </div>
                                    </div>*/}
                                    <div className="form__form-group">
                                        <span className="form__form-group-label">{t("pages.formItems.allowedActiveUserNumber")}</span>
                                        <div className="form__form-group-field">
                                            <Field
                                                name="allowedActiveUserNumber"
                                                component="input"
                                                type="text"
                                                placeholder={t("pages.formItems.allowedActiveUserNumber")}
                                                disabled
                                            />
                                        </div>
                                    </div>


                            
                                    <div className="form__form-group">
                                        {/*<Field
                                            name="activateTenant"
                                            type="checkbox"
                                            component={renderCheckBoxField}
                                            label={t("pages.formItems.activateTenant")}
                                        //disabled={item.disabled}
                                        //className={className}
                                        />*/}
                                        <div className="form__form-group-field">
                                            <div style={{ textAlign: 'end', flex: 1 }}>
                                                <Button color="primary" type="submit">{t("pages.formItems.save")}</Button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            )}
                        </Form>
                    </CardBody>
                </Card>
            </Col>
        </div>
    )
}

