export const utilityDashboardSettings = {
  historicalData: {
    electricity: {
      // List is alphabetically ordered
      averageCos: {
        chartType: "bar",
        chartColors: {
          defaultConfig: {
            fill: "#76777c",
            stroke: "#76777c"
          }
        },
        // Input the value of the reference line
        referenceLineHorizontal: null,
        // Input the value of the reference line
        referenceLineVertical: null
      },
      averagePowerFactor: {
        chartType: "bar",
        chartColors: {
          defaultConfig: {
            fill: "#101a35",
            stroke: "#101a35"
          }
        },
        // Input the value of the reference line
        referenceLineHorizontal: null,
        // Input the value of the reference line
        referenceLineVertical: {
          referenceValue: 1,
          referenceLabel: "Max"
        },
      },
      averageVLL: {
        chartType: "bar",
        chartColors: {
          defaultConfig: {
            fill: "#00a488",
            stroke: "#00a488"
          }
        },
        // Input the value of the reference line
        referenceLineHorizontal: null,
        // Input the value of the reference line
        referenceLineVertical: {
          referenceValue: 380,
          referenceLabel: "Max"
        },
      },
      averageVLN: {
        chartType: "bar",
        chartColors: {
          defaultConfig: {
            fill: "#006471",
            stroke: "#006471"
          }
        },
        // Input the value of the reference line
        referenceLineHorizontal: null,
        // Input the value of the reference line
        referenceLineVertical: null
      },
      difference_tariff1ImportActiveIndex: {
        chartType: "bar",
        chartColors: {
          defaultConfig: {
            fill: "#003060",
            stroke: "#003060"
          }
        },
        // Input the value of the reference line
        referenceLineHorizontal: null,
        // Input the value of the reference line
        referenceLineVertical: null
      },
      // TODO For Yetkin Türken: This parameter is temporary. Delete it in the future versions
      differenceTariff1importactiveindex: {
        chartType: "bar",
        chartColors: {
          defaultConfig: {
            fill: "#1a1e34",
            stroke: "#1a1e34"
          }
        },
        // Input the value of the reference line
        referenceLineHorizontal: null,
        // Input the value of the reference line
        referenceLineVertical: null
      },
      // TODO For Yetkin Türken: This parameter is temporary. Delete it in the future versions
      powerPerMin: {
        chartType: "bar",
        chartColors: {
          defaultConfig: {
            fill: "#f8afed",
            stroke: "#f8afed"
          }
        },
        // Input the value of the reference line
        referenceLineHorizontal: null,
        // Input the value of the reference line
        referenceLineVertical: null
      },
      neutralCurrent: {
        chartType: "bar",
        chartColors: {
          defaultConfig: {
            fill: "#ac4484",
            stroke: "#ac4484"
          }
        },
        // Input the value of the reference line
        referenceLineHorizontal: null,
        // Input the value of the reference line
        referenceLineVertical: null
      },
      totalActivePower: {
        chartType: "bar",
        chartColors: {
          defaultConfig: {
            fill: "#ffb347",
            stroke: "#ffb347"
          }
        },
        // Input the value of the reference line
        referenceLineHorizontal: null,
        // Input the value of the reference line
        referenceLineVertical: null
      },
      totalApparentPower: {
        chartType: "bar",
        chartColors: {
          defaultConfig: {
            fill: "#6d366f",
            stroke: "#6d366f"
          }
        },
        // Input the value of the reference line
        referenceLineHorizontal: null,
        // Input the value of the reference line
        referenceLineVertical: null
      },
      totalCurrent: {
        chartType: "bar",
        chartColors: {
          defaultConfig: {
            fill: "#402d5e",
            stroke: "#402d5e"
          }
        },
        // Input the value of the reference line
        referenceLineHorizontal: null,
        // Input the value of the reference line
        referenceLineVertical: null
      },
      totalReactivePower: {
        chartType: "bar",
        chartColors: {
          defaultConfig: {
            fill: "#71e6e4",
            stroke: "#71e6e4"
          }
        },
        // Input the value of the reference line
        referenceLineHorizontal: null,
        // Input the value of the reference line
        referenceLineVertical: null
      },
      totalTHDI: {
        chartType: "bar",
        chartColors: {
          defaultConfig: {
            fill: "#008acd",
            stroke: "#008acd"
          }
        },
        // Input the value of the reference line
        referenceLineHorizontal: null,
        // Input the value of the reference line
        referenceLineVertical: null
      },
      totalTHDV: {
        chartType: "bar",
        chartColors: {
          defaultConfig: {
            fill: "#d35400",
            stroke: "#d35400"
          }
        },
        // Input the value of the reference line
        referenceLineHorizontal: null,
        // Input the value of the reference line
        referenceLineVertical: null
      },
      current: {
        chartType: "area",
        chartColors: {
          defaultConfig: {
            fill: "#4ce1b6",
            stroke: "#4ce1b6"
          },
          totalCurrent: {
            fill: "#ba55d3",
            stroke: "#ba55d3"
          },
          l1Current: {
            fill: "#4ce1b6",
            stroke: "#4ce1b6"
          },
          l2Current: {
            fill: "#70bbfd",
            stroke: "#70bbfd"
          },
          l3Current: {
            fill: "#ffb347",
            stroke: "#ffb347"
          }
        },
        // Input the value of the reference line
        referenceLineHorizontal: null,
        // Input the value of the reference line
        referenceLineVertical: null,
      },
      voltage: {
        chartType: "bar",
        chartColors: {
          defaultConfig: {
            fill: "#ba55d3",
            stroke: "#ba55d3"
          },
          l1Voltage: {
            fill: "#ba55d3",
            stroke: "#ba55d3"
          },
          l2Voltage: {
            fill: "#4ce1b6",
            stroke: "#4ce1b6"
          },
          l3Voltage: {
            fill: "#70bbfd",
            stroke: "#70bbfd"
          }
        },
        // Input the value of the reference line
        referenceLineHorizontal: null,
        // Input the value of the reference line
        referenceLineVertical: null,
      },
      activePower: {
        chartType: "bar",
        chartColors: {
          defaultConfig: {
            fill: "#ba55d3",
            stroke: "#ba55d3"
          },
          l1ActivePower: {
            fill: "#ba55d3",
            stroke: "#ba55d3"
          },
          l2ActivePower: {
            fill: "#4ce1b6",
            stroke: "#4ce1b6"
          },
          l3ActivePower: {
            fill: "#70bbfd",
            stroke: "#70bbfd"
          }
        },
        // Input the value of the reference line
        referenceLineHorizontal: null,
        // Input the value of the reference line
        referenceLineVertical: null,
      },
      apparentPower: {
        chartType: "bar",
        chartColors: {
          defaultConfig: {
            fill: "#ba55d3",
            stroke: "#ba55d3"
          },
          l1ApparentPower: {
            fill: "#ba55d3",
            stroke: "#ba55d3"
          },
          l2ApparentPower: {
            fill: "#4ce1b6",
            stroke: "#4ce1b6"
          },
          l3ApparentPower: {
            fill: "#70bbfd",
            stroke: "#70bbfd"
          }
        },
        // Input the value of the reference line
        referenceLineHorizontal: null,
        // Input the value of the reference line
        referenceLineVertical: null,
      },
      cos: {
        chartType: "bar",
        chartColors: {
          defaultConfig: {
            fill: "#ba55d3",
            stroke: "#ba55d3"
          },
          l1Cos: {
            fill: "#ba55d3",
            stroke: "#ba55d3"
          },
          l2Cos: {
            fill: "#4ce1b6",
            stroke: "#4ce1b6"
          },
          l3Cos: {
            fill: "#70bbfd",
            stroke: "#70bbfd"
          }
        },
        // Input the value of the reference line
        referenceLineHorizontal: null,
        // Input the value of the reference line
        referenceLineVertical: null,
      },
      electricityIndex: {
        chartType: "bar",
        chartColors: {
          defaultConfig: {
            fill: "#ba55d3",
            stroke: "#ba55d3"
          },
          dayTimeIndexValue: {
            fill: "#ba55d3",
            stroke: "#ba55d3"
          },
          nightTimeIndexValue: {
            fill: "#4ce1b6",
            stroke: "#4ce1b6"
          },
          peakTimeIndexValue: {
            fill: "#70bbfd",
            stroke: "#70bbfd"
          }
        },
      },
      frequency: {
        chartType: "bar",
        chartColors: {
          defaultConfig: {
            fill: "#ba55d3",
            stroke: "#ba55d3"
          },
          l1Frequency: {
            fill: "#ba55d3",
            stroke: "#ba55d3"
          },
          l2Frequency: {
            fill: "#4ce1b6",
            stroke: "#4ce1b6"
          },
          l3Frequency: {
            fill: "#70bbfd",
            stroke: "#70bbfd"
          }
        },
        // Input the value of the reference line
        referenceLineHorizontal: null,
        // Input the value of the reference line
        referenceLineVertical: null,
      },
      powerFactor: {
        chartType: "bar",
        chartColors: {
          defaultConfig: {
            fill: "#ba55d3",
            stroke: "#ba55d3"
          },
          l1PowerFactor: {
            fill: "#ba55d3",
            stroke: "#ba55d3"
          },
          l2PowerFactor: {
            fill: "#4ce1b6",
            stroke: "#4ce1b6"
          },
          l3PowerFactor: {
            fill: "#70bbfd",
            stroke: "#70bbfd"
          }
        },
        // Input the value of the reference line
        referenceLineHorizontal: null,
        // Input the value of the reference line
        referenceLineVertical: null,
      },
      reactivePower: {
        chartType: "bar",
        chartColors: {
          defaultConfig: {
            fill: "#ba55d3",
            stroke: "#ba55d3"
          },
          l1ReactivePower: {
            fill: "#ba55d3",
            stroke: "#ba55d3"
          },
          l2ReactivePower: {
            fill: "#4ce1b6",
            stroke: "#4ce1b6"
          },
          l3ReactivePower: {
            fill: "#70bbfd",
            stroke: "#70bbfd"
          }
        },
        // Input the value of the reference line
        referenceLineHorizontal: null,
        // Input the value of the reference line
        referenceLineVertical: null,
      },
      tHDI: {
        chartType: "bar",
        chartColors: {
          defaultConfig: {
            fill: "#ba55d3",
            stroke: "#ba55d3"
          },
          l1THDI: {
            fill: "#ba55d3",
            stroke: "#ba55d3"
          },
          l2THDI: {
            fill: "#4ce1b6",
            stroke: "#4ce1b6"
          },
          l3THDI: {
            fill: "#70bbfd",
            stroke: "#70bbfd"
          }
        },
        // Input the value of the reference line
        referenceLineHorizontal: null,
        // Input the value of the reference line
        referenceLineVertical: null,
      },
      tHDV: {
        chartType: "bar",
        chartColors: {
          defaultConfig: {
            fill: "#ba55d3",
            stroke: "#ba55d3"
          },
          l1THDV: {
            fill: "#ba55d3",
            stroke: "#ba55d3"
          },
          l2THDV: {
            fill: "#4ce1b6",
            stroke: "#4ce1b6"
          },
          l3THDV: {
            fill: "#70bbfd",
            stroke: "#70bbfd"
          }
        },
        // Input the value of the reference line
        referenceLineHorizontal: "max",
        // Input the value of the reference line
        referenceLineVertical: "max",
      },
      vLL: {
        chartType: "bar",
        // Input the value of the reference line
        referenceLineHorizontal: null,
        // Input the value of the reference line
        referenceLineVertical: null,
        chartColors: {
          defaultConfig: {
            fill: "#ba55d3",
            stroke: "#ba55d3"
          },
          l1VLL: {
            fill: "#ba55d3",
            stroke: "#ba55d3"
          },
          l2VLL: {
            fill: "#4ce1b6",
            stroke: "#4ce1b6"
          },
          l3VLL: {
            fill: "#70bbfd",
            stroke: "#70bbfd"
          }
        },
        // Input the value of the reference line
        referenceLineHorizontal: null,
        // Input the value of the reference line
        referenceLineVertical: null,
      }
    },

    facility: {
      equipmentState: {
        chartType: "bar",
        chartColors: {
          defaultConfig: {
            fill: "#ba55d3",
            stroke: "#ba55d3"
          },
          equipmentStateError: {
            fill: "#DB1600",
            stroke: "#DB1600"
          },
          equipmentStateRunning: {
            fill: "#01D507",
            stroke: "#01D507"
          },
          equipmentStateWarning: {
            fill: "#F58000",
            stroke: "#F58000"
          }
        },
        // Input the value of the reference line
        referenceLineHorizontal: null,
        // Input the value of the reference line
        referenceLineVertical: null,
      },
      analogDataGraph: {
        chartType: "bar",
        referenceLabel: "min",

        chartColors: {
          defaultConfig: {
            fill: "#ba55d3",
            stroke: "#ba55d3"
          },
          value: {
            fill: "#ba55d3",
            stroke: "#ba55d3"
          },
        },
        // Input the value of the reference line
        referenceLineHorizontal: null,
        // Input the value of the reference line
        referenceLineVertical: null,
      },

    },
  },

};