/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createTenant = /* GraphQL */ `
  mutation CreateTenant($input: CreateTenantInput!) {
    createTenant(input: $input) {
      id
      SK
      _TYPE
      organizationId
      brokerId
      authorizeBroker
      authorizeBrokerStatusDate
      tenantName
      tenantTier
      activeTenant
      allowedActiveUserNumber
      cookies
      ownerId
      invoice {
        cursor
        invoices {
          id
          SK
          _TYPE
          unitName
          contactDetails
          taxNo
          taxOffice
          nextPaymentDate
          lastPaymentDate
          createdAt
          createdBy
          updatedAt
          updatedBy
          dateToDelete
        }
      }
      facilities {
        cursor
        facilities {
          id
          SK
          _TYPE
          brokerId
          unitName
          industryCategory
          contactDetails
          userSubIds
          createdAt
          createdBy
          updatedAt
          updatedBy
          dateToDelete
        }
      }
      users {
        cursor
        users {
          id
          SK
          subId
          _TYPE
          userName
          givenName
          familyName
          email
          phoneNumber
          locale
          timezone
          userStatus
          firstTimeSignIn
          cookies
          userDefaultFacility
          tenantDetails {
            tenant {
              id
              SK
              _TYPE
              organizationId
              brokerId
              authorizeBroker
              authorizeBrokerStatusDate
              tenantName
              tenantTier
              activeTenant
              allowedActiveUserNumber
              cookies
              ownerId
              invoice {
                cursor
              }
              facilities {
                cursor
              }
              users {
                cursor
              }
              createdAt
              createdBy
              updatedAt
              updatedBy
              dateToDelete
            }
            nextToken
          }
          facilities {
            cursor
            facilities {
              id
              SK
              _TYPE
              brokerId
              unitName
              industryCategory
              contactDetails
              userSubIds
              createdAt
              createdBy
              updatedAt
              updatedBy
              dateToDelete
            }
          }
          lastActivityTime
          createdAt
          createdBy
          updatedAt
          updatedBy
          dateToDelete
        }
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      dateToDelete
    }
  }
`;
export const updateTenant = /* GraphQL */ `
  mutation UpdateTenant($input: UpdateTenantInput!) {
    updateTenant(input: $input) {
      id
      SK
      _TYPE
      organizationId
      brokerId
      authorizeBroker
      authorizeBrokerStatusDate
      tenantName
      tenantTier
      activeTenant
      allowedActiveUserNumber
      cookies
      ownerId
      invoice {
        cursor
        invoices {
          id
          SK
          _TYPE
          unitName
          contactDetails
          taxNo
          taxOffice
          nextPaymentDate
          lastPaymentDate
          createdAt
          createdBy
          updatedAt
          updatedBy
          dateToDelete
        }
      }
      facilities {
        cursor
        facilities {
          id
          SK
          _TYPE
          brokerId
          unitName
          industryCategory
          contactDetails
          userSubIds
          createdAt
          createdBy
          updatedAt
          updatedBy
          dateToDelete
        }
      }
      users {
        cursor
        users {
          id
          SK
          subId
          _TYPE
          userName
          givenName
          familyName
          email
          phoneNumber
          locale
          timezone
          userStatus
          firstTimeSignIn
          cookies
          userDefaultFacility
          tenantDetails {
            tenant {
              id
              SK
              _TYPE
              organizationId
              brokerId
              authorizeBroker
              authorizeBrokerStatusDate
              tenantName
              tenantTier
              activeTenant
              allowedActiveUserNumber
              cookies
              ownerId
              invoice {
                cursor
              }
              facilities {
                cursor
              }
              users {
                cursor
              }
              createdAt
              createdBy
              updatedAt
              updatedBy
              dateToDelete
            }
            nextToken
          }
          facilities {
            cursor
            facilities {
              id
              SK
              _TYPE
              brokerId
              unitName
              industryCategory
              contactDetails
              userSubIds
              createdAt
              createdBy
              updatedAt
              updatedBy
              dateToDelete
            }
          }
          lastActivityTime
          createdAt
          createdBy
          updatedAt
          updatedBy
          dateToDelete
        }
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      dateToDelete
    }
  }
`;
export const deleteTenant = /* GraphQL */ `
  mutation DeleteTenant($input: DeleteTenantInput!) {
    deleteTenant(input: $input) {
      id
      SK
      _TYPE
      organizationId
      brokerId
      authorizeBroker
      authorizeBrokerStatusDate
      tenantName
      tenantTier
      activeTenant
      allowedActiveUserNumber
      cookies
      ownerId
      invoice {
        cursor
        invoices {
          id
          SK
          _TYPE
          unitName
          contactDetails
          taxNo
          taxOffice
          nextPaymentDate
          lastPaymentDate
          createdAt
          createdBy
          updatedAt
          updatedBy
          dateToDelete
        }
      }
      facilities {
        cursor
        facilities {
          id
          SK
          _TYPE
          brokerId
          unitName
          industryCategory
          contactDetails
          userSubIds
          createdAt
          createdBy
          updatedAt
          updatedBy
          dateToDelete
        }
      }
      users {
        cursor
        users {
          id
          SK
          subId
          _TYPE
          userName
          givenName
          familyName
          email
          phoneNumber
          locale
          timezone
          userStatus
          firstTimeSignIn
          cookies
          userDefaultFacility
          tenantDetails {
            tenant {
              id
              SK
              _TYPE
              organizationId
              brokerId
              authorizeBroker
              authorizeBrokerStatusDate
              tenantName
              tenantTier
              activeTenant
              allowedActiveUserNumber
              cookies
              ownerId
              invoice {
                cursor
              }
              facilities {
                cursor
              }
              users {
                cursor
              }
              createdAt
              createdBy
              updatedAt
              updatedBy
              dateToDelete
            }
            nextToken
          }
          facilities {
            cursor
            facilities {
              id
              SK
              _TYPE
              brokerId
              unitName
              industryCategory
              contactDetails
              userSubIds
              createdAt
              createdBy
              updatedAt
              updatedBy
              dateToDelete
            }
          }
          lastActivityTime
          createdAt
          createdBy
          updatedAt
          updatedBy
          dateToDelete
        }
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      dateToDelete
    }
  }
`;
export const createFacility = /* GraphQL */ `
  mutation CreateFacility($input: CreateFacilityInput!) {
    createFacility(input: $input) {
      id
      SK
      _TYPE
      brokerId
      unitName
      industryCategory
      contactDetails
      userSubIds
      createdAt
      createdBy
      updatedAt
      updatedBy
      dateToDelete
    }
  }
`;
export const updateFacility = /* GraphQL */ `
  mutation UpdateFacility($input: UpdateFacilityInput!) {
    updateFacility(input: $input) {
      id
      SK
      _TYPE
      brokerId
      unitName
      industryCategory
      contactDetails
      userSubIds
      createdAt
      createdBy
      updatedAt
      updatedBy
      dateToDelete
    }
  }
`;
export const deleteFacility = /* GraphQL */ `
  mutation DeleteFacility($input: DeleteFacilityInput!) {
    deleteFacility(input: $input) {
      id
      SK
      _TYPE
      brokerId
      unitName
      industryCategory
      contactDetails
      userSubIds
      createdAt
      createdBy
      updatedAt
      updatedBy
      dateToDelete
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      id
      SK
      subId
      _TYPE
      userName
      givenName
      familyName
      email
      phoneNumber
      locale
      timezone
      userStatus
      firstTimeSignIn
      cookies
      userDefaultFacility
      tenantDetails {
        tenant {
          id
          SK
          _TYPE
          organizationId
          brokerId
          authorizeBroker
          authorizeBrokerStatusDate
          tenantName
          tenantTier
          activeTenant
          allowedActiveUserNumber
          cookies
          ownerId
          invoice {
            cursor
            invoices {
              id
              SK
              _TYPE
              unitName
              contactDetails
              taxNo
              taxOffice
              nextPaymentDate
              lastPaymentDate
              createdAt
              createdBy
              updatedAt
              updatedBy
              dateToDelete
            }
          }
          facilities {
            cursor
            facilities {
              id
              SK
              _TYPE
              brokerId
              unitName
              industryCategory
              contactDetails
              userSubIds
              createdAt
              createdBy
              updatedAt
              updatedBy
              dateToDelete
            }
          }
          users {
            cursor
            users {
              id
              SK
              subId
              _TYPE
              userName
              givenName
              familyName
              email
              phoneNumber
              locale
              timezone
              userStatus
              firstTimeSignIn
              cookies
              userDefaultFacility
              tenantDetails {
                nextToken
              }
              facilities {
                cursor
              }
              lastActivityTime
              createdAt
              createdBy
              updatedAt
              updatedBy
              dateToDelete
            }
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
          dateToDelete
        }
        nextToken
      }
      facilities {
        cursor
        facilities {
          id
          SK
          _TYPE
          brokerId
          unitName
          industryCategory
          contactDetails
          userSubIds
          createdAt
          createdBy
          updatedAt
          updatedBy
          dateToDelete
        }
      }
      lastActivityTime
      createdAt
      createdBy
      updatedAt
      updatedBy
      dateToDelete
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      SK
      subId
      _TYPE
      userName
      givenName
      familyName
      email
      phoneNumber
      locale
      timezone
      userStatus
      firstTimeSignIn
      cookies
      userDefaultFacility
      tenantDetails {
        tenant {
          id
          SK
          _TYPE
          organizationId
          brokerId
          authorizeBroker
          authorizeBrokerStatusDate
          tenantName
          tenantTier
          activeTenant
          allowedActiveUserNumber
          cookies
          ownerId
          invoice {
            cursor
            invoices {
              id
              SK
              _TYPE
              unitName
              contactDetails
              taxNo
              taxOffice
              nextPaymentDate
              lastPaymentDate
              createdAt
              createdBy
              updatedAt
              updatedBy
              dateToDelete
            }
          }
          facilities {
            cursor
            facilities {
              id
              SK
              _TYPE
              brokerId
              unitName
              industryCategory
              contactDetails
              userSubIds
              createdAt
              createdBy
              updatedAt
              updatedBy
              dateToDelete
            }
          }
          users {
            cursor
            users {
              id
              SK
              subId
              _TYPE
              userName
              givenName
              familyName
              email
              phoneNumber
              locale
              timezone
              userStatus
              firstTimeSignIn
              cookies
              userDefaultFacility
              tenantDetails {
                nextToken
              }
              facilities {
                cursor
              }
              lastActivityTime
              createdAt
              createdBy
              updatedAt
              updatedBy
              dateToDelete
            }
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
          dateToDelete
        }
        nextToken
      }
      facilities {
        cursor
        facilities {
          id
          SK
          _TYPE
          brokerId
          unitName
          industryCategory
          contactDetails
          userSubIds
          createdAt
          createdBy
          updatedAt
          updatedBy
          dateToDelete
        }
      }
      lastActivityTime
      createdAt
      createdBy
      updatedAt
      updatedBy
      dateToDelete
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser($input: DeleteUserInput!) {
    deleteUser(input: $input) {
      id
      SK
      subId
      _TYPE
      userName
      givenName
      familyName
      email
      phoneNumber
      locale
      timezone
      userStatus
      firstTimeSignIn
      cookies
      userDefaultFacility
      tenantDetails {
        tenant {
          id
          SK
          _TYPE
          organizationId
          brokerId
          authorizeBroker
          authorizeBrokerStatusDate
          tenantName
          tenantTier
          activeTenant
          allowedActiveUserNumber
          cookies
          ownerId
          invoice {
            cursor
            invoices {
              id
              SK
              _TYPE
              unitName
              contactDetails
              taxNo
              taxOffice
              nextPaymentDate
              lastPaymentDate
              createdAt
              createdBy
              updatedAt
              updatedBy
              dateToDelete
            }
          }
          facilities {
            cursor
            facilities {
              id
              SK
              _TYPE
              brokerId
              unitName
              industryCategory
              contactDetails
              userSubIds
              createdAt
              createdBy
              updatedAt
              updatedBy
              dateToDelete
            }
          }
          users {
            cursor
            users {
              id
              SK
              subId
              _TYPE
              userName
              givenName
              familyName
              email
              phoneNumber
              locale
              timezone
              userStatus
              firstTimeSignIn
              cookies
              userDefaultFacility
              tenantDetails {
                nextToken
              }
              facilities {
                cursor
              }
              lastActivityTime
              createdAt
              createdBy
              updatedAt
              updatedBy
              dateToDelete
            }
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
          dateToDelete
        }
        nextToken
      }
      facilities {
        cursor
        facilities {
          id
          SK
          _TYPE
          brokerId
          unitName
          industryCategory
          contactDetails
          userSubIds
          createdAt
          createdBy
          updatedAt
          updatedBy
          dateToDelete
        }
      }
      lastActivityTime
      createdAt
      createdBy
      updatedAt
      updatedBy
      dateToDelete
    }
  }
`;
export const createDevice = /* GraphQL */ `
  mutation CreateDevice($input: CreateDeviceInput!) {
    createDevice(input: $input) {
      descriptiveCode
      boxId
      _TYPE
      deviceBrand
      deviceModel
      brokerId
      tenantId
      facilityId
      dataUtilizationCode
      dataCategory
      equipmentName
      equipmentDescription
      equipmentCategory
      equipmentLocation
      dataUnit
      operatorId
      equipmentTimeZone
      industryCategory
      dataSendingDeviceDescriptiveCode
      dataSendingDeviceChannelName
      deviceFacilityValues
      alarmConditions
      deviceFacilitySettings
      createdAt
      createdBy
      updatedAt
      updatedBy
      dateToDelete
    }
  }
`;
export const getDeviceForManualDataOperations = /* GraphQL */ `
  mutation GetDeviceForManualDataOperations($input: CreateManualDeviceInput!) {
    getDeviceForManualDataOperations(input: $input) {
      descriptiveCode
      boxId
      _TYPE
      deviceBrand
      deviceModel
      brokerId
      tenantId
      facilityId
      dataUtilizationCode
      dataCategory
      equipmentName
      equipmentDescription
      equipmentCategory
      equipmentLocation
      dataUnit
      operatorId
      equipmentTimeZone
      industryCategory
      dataSendingDeviceDescriptiveCode
      dataSendingDeviceChannelName
      deviceFacilityValues
      alarmConditions
      deviceFacilitySettings
      createdAt
      createdBy
      updatedAt
      updatedBy
      dateToDelete
    }
  }
`;
export const getExecutiveFacilityData = /* GraphQL */ `
  mutation GetExecutiveFacilityData($input: CreateManualDeviceInput!) {
    getExecutiveFacilityData(input: $input) {
      descriptiveCode
      boxId
      _TYPE
      deviceBrand
      deviceModel
      brokerId
      tenantId
      facilityId
      dataUtilizationCode
      dataCategory
      equipmentName
      equipmentDescription
      equipmentCategory
      equipmentLocation
      dataUnit
      operatorId
      equipmentTimeZone
      industryCategory
      dataSendingDeviceDescriptiveCode
      dataSendingDeviceChannelName
      deviceFacilityValues
      alarmConditions
      deviceFacilitySettings
      createdAt
      createdBy
      updatedAt
      updatedBy
      dateToDelete
    }
  }
`;
export const createManualDevice = /* GraphQL */ `
  mutation CreateManualDevice($input: CreateManualDeviceInput!) {
    createManualDevice(input: $input) {
      descriptiveCode
      boxId
      _TYPE
      deviceBrand
      deviceModel
      brokerId
      tenantId
      facilityId
      dataUtilizationCode
      dataCategory
      equipmentName
      equipmentDescription
      equipmentCategory
      equipmentLocation
      dataUnit
      operatorId
      equipmentTimeZone
      industryCategory
      dataSendingDeviceDescriptiveCode
      dataSendingDeviceChannelName
      deviceFacilityValues
      alarmConditions
      deviceFacilitySettings
      createdAt
      createdBy
      updatedAt
      updatedBy
      dateToDelete
    }
  }
`;
export const updateDevice = /* GraphQL */ `
  mutation UpdateDevice($input: UpdateDeviceInput!) {
    updateDevice(input: $input) {
      descriptiveCode
      boxId
      _TYPE
      deviceBrand
      deviceModel
      brokerId
      tenantId
      facilityId
      dataUtilizationCode
      dataCategory
      equipmentName
      equipmentDescription
      equipmentCategory
      equipmentLocation
      dataUnit
      operatorId
      equipmentTimeZone
      industryCategory
      dataSendingDeviceDescriptiveCode
      dataSendingDeviceChannelName
      deviceFacilityValues
      alarmConditions
      deviceFacilitySettings
      createdAt
      createdBy
      updatedAt
      updatedBy
      dateToDelete
    }
  }
`;
export const deleteManualDevice = /* GraphQL */ `
  mutation DeleteManualDevice($input: DeleteDeviceInput!) {
    deleteManualDevice(input: $input) {
      descriptiveCode
      boxId
      _TYPE
      deviceBrand
      deviceModel
      brokerId
      tenantId
      facilityId
      dataUtilizationCode
      dataCategory
      equipmentName
      equipmentDescription
      equipmentCategory
      equipmentLocation
      dataUnit
      operatorId
      equipmentTimeZone
      industryCategory
      dataSendingDeviceDescriptiveCode
      dataSendingDeviceChannelName
      deviceFacilityValues
      alarmConditions
      deviceFacilitySettings
      createdAt
      createdBy
      updatedAt
      updatedBy
      dateToDelete
    }
  }
`;
export const createDeviceByBroker = /* GraphQL */ `
  mutation CreateDeviceByBroker($input: CreateManualDeviceInput!) {
    createDeviceByBroker(input: $input) {
      descriptiveCode
      boxId
      _TYPE
      deviceBrand
      deviceModel
      brokerId
      tenantId
      facilityId
      dataUtilizationCode
      dataCategory
      equipmentName
      equipmentDescription
      equipmentCategory
      equipmentLocation
      dataUnit
      operatorId
      equipmentTimeZone
      industryCategory
      dataSendingDeviceDescriptiveCode
      dataSendingDeviceChannelName
      deviceFacilityValues
      alarmConditions
      deviceFacilitySettings
      createdAt
      createdBy
      updatedAt
      updatedBy
      dateToDelete
    }
  }
`;
export const createInvoice = /* GraphQL */ `
  mutation CreateInvoice($input: CreateInvoiceInput!) {
    createInvoice(input: $input) {
      id
      SK
      _TYPE
      unitName
      contactDetails
      taxNo
      taxOffice
      nextPaymentDate
      lastPaymentDate
      createdAt
      createdBy
      updatedAt
      updatedBy
      dateToDelete
    }
  }
`;
export const updateInvoice = /* GraphQL */ `
  mutation UpdateInvoice($input: UpdateInvoiceInput!) {
    updateInvoice(input: $input) {
      id
      SK
      _TYPE
      unitName
      contactDetails
      taxNo
      taxOffice
      nextPaymentDate
      lastPaymentDate
      createdAt
      createdBy
      updatedAt
      updatedBy
      dateToDelete
    }
  }
`;
export const deleteInvoice = /* GraphQL */ `
  mutation DeleteInvoice($input: DeleteInvoiceInput!) {
    deleteInvoice(input: $input) {
      id
      SK
      _TYPE
      unitName
      contactDetails
      taxNo
      taxOffice
      nextPaymentDate
      lastPaymentDate
      createdAt
      createdBy
      updatedAt
      updatedBy
      dateToDelete
    }
  }
`;
export const createDataAlarm = /* GraphQL */ `
  mutation CreateDataAlarm($input: UpdateDeviceInput!) {
    createDataAlarm(input: $input) {
      descriptiveCode
      boxId
      _TYPE
      deviceBrand
      deviceModel
      brokerId
      tenantId
      facilityId
      dataUtilizationCode
      dataCategory
      equipmentName
      equipmentDescription
      equipmentCategory
      equipmentLocation
      dataUnit
      operatorId
      equipmentTimeZone
      industryCategory
      dataSendingDeviceDescriptiveCode
      dataSendingDeviceChannelName
      deviceFacilityValues
      alarmConditions
      deviceFacilitySettings
      createdAt
      createdBy
      updatedAt
      updatedBy
      dateToDelete
    }
  }
`;
export const updateDataAlarm = /* GraphQL */ `
  mutation UpdateDataAlarm($input: UpdateDeviceInput!) {
    updateDataAlarm(input: $input) {
      descriptiveCode
      boxId
      _TYPE
      deviceBrand
      deviceModel
      brokerId
      tenantId
      facilityId
      dataUtilizationCode
      dataCategory
      equipmentName
      equipmentDescription
      equipmentCategory
      equipmentLocation
      dataUnit
      operatorId
      equipmentTimeZone
      industryCategory
      dataSendingDeviceDescriptiveCode
      dataSendingDeviceChannelName
      deviceFacilityValues
      alarmConditions
      deviceFacilitySettings
      createdAt
      createdBy
      updatedAt
      updatedBy
      dateToDelete
    }
  }
`;
export const deleteDataAlarm = /* GraphQL */ `
  mutation DeleteDataAlarm($input: UpdateDeviceInput!) {
    deleteDataAlarm(input: $input) {
      descriptiveCode
      boxId
      _TYPE
      deviceBrand
      deviceModel
      brokerId
      tenantId
      facilityId
      dataUtilizationCode
      dataCategory
      equipmentName
      equipmentDescription
      equipmentCategory
      equipmentLocation
      dataUnit
      operatorId
      equipmentTimeZone
      industryCategory
      dataSendingDeviceDescriptiveCode
      dataSendingDeviceChannelName
      deviceFacilityValues
      alarmConditions
      deviceFacilitySettings
      createdAt
      createdBy
      updatedAt
      updatedBy
      dateToDelete
    }
  }
`;
export const resetEquipmentTimeTracker = /* GraphQL */ `
  mutation ResetEquipmentTimeTracker($input: UpdateDeviceInput!) {
    resetEquipmentTimeTracker(input: $input) {
      descriptiveCode
      boxId
      _TYPE
      deviceBrand
      deviceModel
      brokerId
      tenantId
      facilityId
      dataUtilizationCode
      dataCategory
      equipmentName
      equipmentDescription
      equipmentCategory
      equipmentLocation
      dataUnit
      operatorId
      equipmentTimeZone
      industryCategory
      dataSendingDeviceDescriptiveCode
      dataSendingDeviceChannelName
      deviceFacilityValues
      alarmConditions
      deviceFacilitySettings
      createdAt
      createdBy
      updatedAt
      updatedBy
      dateToDelete
    }
  }
`;
export const startExecution = /* GraphQL */ `
  mutation StartExecution($execution: StartExecutionInput!) {
    startExecution(execution: $execution) {
      name
      status
      input
      executionArn
      executionType
      startDate
      stopDate
      output
    }
  }
`;
export const getFacilityManualData = /* GraphQL */ `
  mutation GetFacilityManualData($input: UpdateDataInput!) {
    getFacilityManualData(input: $input) {
      descriptiveCode
      SK
      _TYPE
      brokerId
      tenantId
      facilityId
      operatorId
      dataObject
      createdAt
      createdBy
      updatedAt
      updatedBy
      dateToDelete
    }
  }
`;
