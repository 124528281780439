import React, { useContext } from "react";
import CheckIcon from "mdi-react/CheckIcon";
import PasswordContext from "context/PasswordContext";

export default function ConfirmationCodeFieldHandler({
  renderName,
  placeholder,
  showWarningMessage,
  showWarningMessageHandler,
  invalidFieldMessage,
}) {
  const { confirmationCode, confirmationCodeHandler } =
    useContext(PasswordContext);

  const warningMessage = !confirmationCode && showWarningMessage;

  function handleChange(event) {
    showWarningMessageHandler(false);
    confirmationCodeHandler(event.target.value);
  }

  return (
    <div className="form__form-group">
      <span className="form__form-group-label">{renderName}</span>
      {warningMessage && (
        <h4
          className="account__subhead subhead"
          style={{
            color: "red",
            fontWeight: "bold",
            marginBottom: "10px",
          }}
        >
          {invalidFieldMessage}
        </h4>
      )}
      <div className="form__form-group-field">
        <div className="form__form-group-icon">
          <CheckIcon />
        </div>
        <input
          name={renderName}
          type="text"
          value={confirmationCode}
          placeholder={placeholder}
          onChange={handleChange}
        />
      </div>
    </div>
  );
}
