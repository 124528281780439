import React, { useState, useEffect } from "react";
import Topbar from "../../Layout/topbar/Topbar";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const background = `${process.env.PUBLIC_URL}/img/landing/background/left_bg.png`;


export default function SignupInfoPage() {
    const { t, i18n } = useTranslation();
    const { lang } = useSelector((state) => state.lang);


    function contactHandler() {

        const link =
            lang === "tr"
                ? "https://www.sanai.com.tr/iletisim"
                : "https://www.sanai.com.tr/en/contact";
        window.open(link);
    }


    return (
        <section
            className="landing__header"
            style={{
                backgroundImage: `url(${background}`,
                height: '120vh',
            }}
        >

            <div className="landing" style={{ backgroundColor: 'rgba(0, 0, 0, 0)' }}>
                <Topbar />
                <h3 className="landing__section-title">
                    <b>{t("pages.signupInfoTexts.header1")}</b>
                </h3>
                <h5 className="landing__section-title">
                    {t("pages.signupInfoTexts.paragraph1")}
                </h5>
                <h5 className="landing__section-title">
                    {t("pages.signupInfoTexts.paragraph2")}
                </h5>
                <h5 className="landing__section-title">
                    {t("pages.signupInfoTexts.paragraph3")}
                </h5>

            </div>
            <div className="landing__center-btn">
                    <button
                        type="button"
                        onClick={() => contactHandler()}
                        className="landing__btn landing__btn--gradient"

                    >
                        {t("pages.landing.contact.topLinkHeader")}
                    </button>
                </div>
        </section>


    )
}


/*

   <div>
        <Topbar/>
        SignupInfoPage</div>

*/