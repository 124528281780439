import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {  Auth } from "aws-amplify";
import { useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { Card, CardBody, Col } from 'reactstrap';
import PaymentForm from './PaymentForm/PaymentForm';
import Loading from "shared/components/Loading";
import { 
  cognitoErrorModalRenderHandler, 
  cognitoPrimaryModalRenderHandler, 
  resetModalHandler 
} from "redux/slices/modalSlice";
import cognitoSignOut from "containers/Account/LogIn/AwsCognito/cognitoSignOut";

export default function BillingSettings() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();

  // States that control loading and error states
  const [isLoading, setIsLoading] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  
  // Websocket states
  const [ socketIyzicoPayment, setSocketIyzicoPayment ] = useState(null);
  // This will be used to display the websocket connection status
  const [isIyzicoPaymentWebSocketConnected, setIsIyzicoPaymentWebSocketConnected] = useState(false);
  // State showing the current location of the user
  const [currentLocation, setCurrentLocation] = useState(null);

  const [code, setCodeHandler] = useState("");
  const [startErrorHandler, setStartErrorHandler] = useState(false);
  
  // Function to clean up the useEffect
  function cleanUpHandler(timerFunction) {
    setIsLoading(true);
    setIsLoaded(false);
    setStartErrorHandler(false);
    clearTimeout(timerFunction);
  }

    // Error handler for the modal
  // Convert the code into string before dispatch
  function errorHandler(errorFromService = "dashboard") {
    const errorState = JSON.stringify({
      code,
    });
    setIsLoading(false);
    setIsLoaded(true);
    dispatch(
      cognitoErrorModalRenderHandler({
        errorFromService,
        errorState,
      })
    );
  };

  // Socket connection handler
  // This open a new socket when facilityId changed
  useEffect(() => {
    Auth.currentSession()
      .then((session) => session.getAccessToken().getJwtToken())
      .then((accessToken) => {
        const webSocketApi = new WebSocket(
          `${process.env.REACT_APP_WEBSOCKET_IYZICO_PAYMENT_URL}?Authorization=${accessToken}`
        );
        setSocketIyzicoPayment(webSocketApi);
      })
      .catch((error) => {
        errorHandler();
      });
  }, []);
  

  // This snippet is showing a modal when the websocket is disconnected
  // It is setting the current location of the user as state
  // If the user is in the same location, but the websocket is disconnected,
  // it is showing the modal to inform the user about the disconnection
  // Then signout the user
  useEffect(() => {
    if (
      !isIyzicoPaymentWebSocketConnected
      && currentLocation
      && currentLocation === location.pathname
    ) {
      const modalMessage = {
        name: t("modal.modalMessage.onSuccess.name.webSocketDisconnected"),
        message: t(
          "modal.modalMessage.onSuccess.message.webSocketDisconnected"
        ),
      };
      dispatch(cognitoPrimaryModalRenderHandler(modalMessage));
      cognitoSignOut();
    };
  }, [isIyzicoPaymentWebSocketConnected, currentLocation, location]);

  // Websocket data handler
  useEffect(() => {
    if (socketIyzicoPayment) {
      socketIyzicoPayment.onopen = () => {
        //console.log("Iyzico Payment Websocket is connected");
        setIsIyzicoPaymentWebSocketConnected(true);
        setCurrentLocation(location.pathname);
        // Set the current location of the user
      };

      socketIyzicoPayment.onmessage = (event) => {
      //  console.log("Iyzico Payment Websocket is receiving data");
        const eventData = JSON.parse(event.data);
       // console.log("Iyzico Payment Websocket eventData", eventData);
      };
      
      socketIyzicoPayment.onclose = () => {
      //  console.log("Iyzico Payment Websocket is closed");
        setIsIyzicoPaymentWebSocketConnected(false);
        // To clean the redux store and save some memory, the dispatch function to return to initial state is called
        // This will be triggered when the websocket is closed
      };
    }
    return () => {
      if (socketIyzicoPayment) {
        socketIyzicoPayment.close();
        setCurrentLocation(null);
        resetModalHandler();
      }
    };
  }, [socketIyzicoPayment]);

    // In case of error or time out
  // This snippet is starting the error handler function
  useEffect(() => {
    if (!isLoaded && startErrorHandler) {
      errorHandler();
    }
  }, [isLoaded, startErrorHandler]);

  return (
    <Fragment>
      {!isLoaded && <Loading transparentBackground loading={isLoading} />}
      <Col style={{margin:10}}>
        <Card>
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">Payment</h5>
            </div>
            <div className="payment">
             {/* <PaymentForm />*/}
             Use Payment Pagex
            </div>
          </CardBody>
        </Card>
      </Col>
    </Fragment>

  )
}