import { createSlice } from "@reduxjs/toolkit";

const languageSlice = createSlice({
  name: "languageStatus",
  initialState: {
    // List of react-i18next locales
    // https://github.com/ladjs/i18n-locales
    // Locale from react i18n
    localeLang: "en-US",
    // Locale to manipulate styles and images
    lang: "tr",
    availableLanguages: ["en", "tr"],
  },
  reducers: {
    languageHandler(state, action) {
      const languageState = action.payload;
      state.localeLang = languageState;
      state.lang = languageState.slice(0, 2);
    },
  },
});

export const { languageHandler } = languageSlice.actions;

export default languageSlice.reducer;
