import { Auth } from "aws-amplify";

async function signUp(
cognitoAttributes
) {
  try {
    const {
    username,
    password,
    email,
    phoneNumber,
    givenName,
    familyName,
    tenantId,
    locale,
    timezone,
  } = cognitoAttributes;

   // console.log("cognitoAttributes:", cognitoAttributes);

    await Auth.signUp({
      username,
      password,
      attributes: {
        email,
        phone_number: phoneNumber,
        given_name: givenName,
        family_name: familyName,
        "custom:tenantId": tenantId,
        "custom:locale": locale,
        "custom:timezone": timezone,
      },
    });
  } catch (error) {
    throw new Error(error);
  }
}

export default signUp;
