import React, { useState, useEffect, Fragment } from 'react';
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import PropTypes from 'prop-types';
import {
    ResponsiveContainer,
    Pie,
    PieChart,
    Legend
} from 'recharts';
import EmoticonIcon from 'mdi-react/EmoticonIcon';
import EmoticonConfusedIcon from 'mdi-react/EmoticonConfusedIcon';
import EmoticonNeutralIcon from 'mdi-react/EmoticonNeutralIcon';
import EmoticonSadIcon from 'mdi-react/EmoticonSadIcon';
import { borderWidth } from 'polished';

// It can be quite difficult to find, debug or change the code in the future
// Instead, use unique variable names such as statusDurationAsHours which is easy to track and understand
export default function EquipmentPanel({ equipmentKey, equipmentValue }) {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const { t, i18n } = useTranslation();
    const [equipmentStatusIndex, setEquipmentStatusIndex] = useState(0);
    const [graphPanel, setGraphPanel] = useState(null);
    const userLang = useSelector((state) => state.lang.lang);
    // Set the equipment status index according to the equipment status
    useEffect(() => {
        if (equipmentValue.currentEquipmentStatus.equipmentStateError) {
            setEquipmentStatusIndex(3)
        } else if (equipmentValue.currentEquipmentStatus.equipmentStateWarning) {
            setEquipmentStatusIndex(2)
        } else if (equipmentValue.currentEquipmentStatus.equipmentStateOff) {
            setEquipmentStatusIndex(1)
        } else {
            setEquipmentStatusIndex(0)
        };
        setGraphPanel(() => graphPanelFunc());
    }, [equipmentValue.currentEquipmentStatus, equipmentStatusIndex, userLang]);
    const dateFormatter = (toConvert) => {
        const date = new Date(toConvert); // Geçerli bir tarih ve saat nesnesi oluşturur
    
          const formattedDate = new Intl.DateTimeFormat(undefined, {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
          }).format(date);
    
          return formattedDate
      }

    const graphPanelFunc = () => {
        const filteredData = equipmentStatus.filter((item) => item.value !== 0); // to not display values ​​that are 0
        return (<>
            <div style={{ height: 100 }}>
                <div className="dashboard__carousel" >
                    <div className="dashboard__carousel-slide dashboard__carousel-slide-" style={{ backgroundColor: equipmentStatus[equipmentStatusIndex].fill }}>
                        {equipmentStatus[equipmentStatusIndex].icon}
                        <p className="dashboard__carousel-title" style={{ color: 'white' }}>{equipmentStatus[equipmentStatusIndex].name}</p>
                        <p style={{ color: '#e2e2e2' }}>{equipmentStatus[equipmentStatusIndex].description}</p>
                    </div>
                </div>
            </div>

            <p>{t("pages.equipmentPanel.equipmentStatus.subTitle")}  {equipmentValue.timeIso.slice(0, 10)}</p>
            <div className="dashboard__executive-equipment" style={{ marginTop: 20 }}>
                <div className="dashboard__executive-equipment-chart" >
                    <ResponsiveContainer className="dashboard__chart-pie ">
                        <PieChart className="dashboard__chart-pie-container">
                            <Pie
                                data={filteredData}
                                cx="50%"
                                cy="50%"
                                labelLine={false}
                                label={convertSeconds}
                                outerRadius={screenWidth < 420 ? 50 : 70}
                                dataKey="value"
                                isAnimationActive={false}
                            />
                            <Legend
                                layout="horizantal"
                                verticalAlign="bottom"
                                content={renderLegend}
                            />
                        </PieChart>
                    </ResponsiveContainer>
                </div>
            </div>
        </>)
    }


    const RADIAN = Math.PI / 180;

    const convertSeconds = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, value }) => {
        const seconds = value
        const statusDurationAsHours = Math.floor(seconds / 3600)
        const statusDurationAsMinutes = Math.floor((seconds % 3600) / 60)

       // x =( x > cx ? (x + (screenWidth < 420 ? 55 : 85)):(x - (screenWidth < 420 ? 55 : 85)))
        if (statusDurationAsHours > 0) {

            return `${t("pages.equipmentPanel.time.hour", { statusDurationAsHours })} ${t("pages.equipmentPanel.time.minute", { statusDurationAsMinutes })}`

     
        } else {
            return `${t("pages.equipmentPanel.time.minute", { statusDurationAsMinutes })}`;
        }
    };

    const equipmentStatus = [{
        id: 0, name: t("pages.equipmentPanel.equipmentStatus.working"), description: t("pages.equipmentPanel.equipmentStatus.workingDescription"),
        value: equipmentValue.equipmentStateDurations.equipmentStateRunningDuration, fill: '#01D507', icon: <EmoticonIcon />
    }, {
        id: 1, name: t("pages.equipmentPanel.equipmentStatus.stopped"), description: t("pages.equipmentPanel.equipmentStatus.stoppedDescription"),
        value: equipmentValue.equipmentStateDurations.equipmentStateOffDuration, fill: '#7F7F7F', icon: <EmoticonNeutralIcon />
    }, {
        id: 2, name: t("pages.equipmentPanel.equipmentStatus.warning"), description: t("pages.equipmentPanel.equipmentStatus.warningDescription"),
        value: equipmentValue.equipmentStateDurations.equipmentStateWarningDuration, fill: '#F58000', icon: <EmoticonConfusedIcon />
    }, {
        id: 3, name: t("pages.equipmentPanel.equipmentStatus.error"), description: t("pages.equipmentPanel.equipmentStatus.errorDescription"),
        value: equipmentValue.equipmentStateDurations.equipmentStateErrorDuration, fill: '#DB1600', icon: <EmoticonSadIcon />
    }];

    const renderLegend = ({ payload }) => (
        <ul className="dashboard__executive-equipment-chart-legend dashboard__chart-legend form form--horizontal" style={{ justifyContent: 'center' }}>
            {payload.map(entry => (
                <li key={entry.payload.id} style={{ marginRight: 10, marginTop: 20 }}>
                    <span style={{ backgroundColor: entry.color }} />
                    <p>{entry.value}</p>
                </li>
            ))}
        </ul>
    );
    renderLegend.propTypes = {
        payload: PropTypes.arrayOf(PropTypes.shape({
            color: PropTypes.string,
            value: PropTypes.string,
        })).isRequired,
    };

    return (
        <div>
            {graphPanel}
        </div>
    )
}

