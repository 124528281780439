import { createSlice } from "@reduxjs/toolkit";

const initialDashboardDataState = {
    dashboardData: {
        descriptiveCodeOfTheData: "",
        responseData: {
            historicalData: {
            utility: {
                electricity: {
                },
                water: {
                },
                naturalGas: {
                },
            },
            facility: {
            },
            },
            aggregateData: {
                utility: {
                    electricity: {
                    },
                    water: {
                    },
                    naturalGas: {
                    },
                },
                facility: {
                },
            }
        }
    }
}

const electricParametersToDisplayOnLanding = [
    // "averageVLN",
    // "totalCurrent",
    "totalApparentPower",
    "totalActivePower",
    // "totalReactivePower",
    "difference_tariff1ImportActiveIndex",
    "averagePowerFactor"
];

const parametersToIgnore = [
    "timeIso",
    "epochIso",
    "timeUtc",
    "epochUtc",
    "dataCategory",
    "dataUtilizationCode",
    "descriptiveCode",
    "deviceFacilityValues",
    // Machine channels
    "analogCh1",
    "analogCh2",
    "digitalCh1",
    "digitalCh2",
    "digitalCh3",
    "digitalCh4",
    "digitalCh5",
    "digitalCh6",
    "equipmentStateOffDuration",
    "equipmentStateRunningDuration",
    "equipmentStateWarningDuration",
    "equipmentStateErrorDuration",
    "equipmentStateError",
    "equipmentStateWarning",
    "equipmentStateOff",
    "equipmentStateRunning",
    "SK",
    "id",
    "industryCategory"
];

const dashboardDataSlice = createSlice({
    name: "dashboardData",
    initialState: {
        dashboardDataState: initialDashboardDataState,
    },
    reducers: {
        dashboardFirstRenderDataHandler(state, action) {
            let dashboardData = action.payload;

            // Reducers can only make copies of the original values, and then they can mutate the copies
            // This is safe, because we made a copy
            state.dashboardDataState = {
                ...state.dashboardDataState,
                dashboardData,
            };
        },

        dashboardCurrentDataHandler(state, action) {
            let dataFromSocket = action.payload;

            // Check the dataFromSocket. If the keys are in the electricParametersToDisplayOnLanding list
            // then add the values to the dashboardDataState
            // Do not manipulate the state directly. Copy it first and then mutate the copy
            let dataDescriptiveCode = {...state.dashboardDataState.descriptiveCodeOfTheData};
            let dataToAdd = {...state.dashboardDataState};
            const dataArray = dataToAdd.dashboardData.responseData.historicalData[dataFromSocket.dataCategory][dataFromSocket.dataUtilizationCode];
            
            // let objectOfL1L2L3Records = {};
            // let keyForMutation = "";
            // for (let key in dataFromSocket) {
            //     console.log("key", key);
            //     if (key.startsWith("l1") || key.startsWith("l2") || key.startsWith("l3")) {
            //         keyForMutation = key.slice(2); // Remove the first two characters
            //         keyForMutation = keyForMutation.charAt(0).toLowerCase() + keyForMutation.slice(1); // Convert first character to lowercase
            //     } else {
            //         keyForMutation = key;
            //     };
                    
            //     if (
            //         !parametersToIgnore.includes(key)
            //         && Object.keys(dataArray).length > 0
            //         // Check if the key is in the object
            //         && dataArray[dataDescriptiveCode][keyForMutation]?.data.length > 0
            //     ) {
            //         console.log("keyformutation", keyForMutation);

            //         if ( keyForMutation === key ) {
            //             dataArray[dataDescriptiveCode][keyForMutation].data.push(
            //                 {
            //                     time: dataFromSocket.timeIso,
            //                     value: dataFromSocket[key]
            //                 }
            //             );
            //         } else {
            //         }
            //     }
            // }

            state.dashboardDataState = {
                ...state.dashboardDataState,
                ...dataToAdd
            };
        },

        returnToInitialDashboardDataState(state) {
            state.dashboardDataState = initialDashboardDataState;
        },
    },
});

export const {
    dashboardFirstRenderDataHandler,
    dashboardCurrentDataHandler,
    returnToInitialDashboardDataState,
} = dashboardDataSlice.actions;

export default dashboardDataSlice.reducer;