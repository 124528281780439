import { createSlice } from "@reduxjs/toolkit";

export const rtlSlice = createSlice({
  name: "rtl",
  initialState: {
    direction: "ltr",
  },
  reducers: {
    changeDirectionToLTR(state) {
      state.direction = "ltr";
    },
    changeDirectionToRTL(state) {
      state.direction = "rtl";
    },
  },
});

export const { changeDirectionToLTR, changeDirectionToRTL } = rtlSlice.actions;

export default rtlSlice.reducer;
