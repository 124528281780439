import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import MainWrapper from "./MainWrapper";
import WrappedRoutes from "./WrappedRoutes/WrappedRoutes";
import RouteErrorBoundary from "./RouteErrorBoundary";
import SignIn from "../Account/LogIn/SignIn";
import SignUp from "../Account/LogIn/SignUp";
import SignUpNew from "containers/Pages/SignUpNew/SignUpNew";
import PasswordForm from "../Account/LogIn/PasswordForm";
import ConfirmationCodeForm from "../Account/LogIn/ConfirmationCodeForm";
import PrivateRoute from "./ProtectedRoutes/PrivateRoute";
import SignInRouter from "./ProtectedRoutes/SignInRouter";
import NotFound404 from "../NotFound/NotFound404";
import Landing from "../Landing/LandingPage";
import HubPage from "./WrappedRoutes/Hub/HubPage";
import Dashboard from "./WrappedRoutes/Dashboard/FacilityDashboard/DashboardLandingPage";
import RegistrationWizardForm from "./WrappedRoutes/Form/WizardForm/RegistrationWizardForm";
import cognitoSignOut from "containers/Account/LogIn/AwsCognito/cognitoSignOut";
import EquipmentDetails from "containers/Pages/DashboardPages/EquipmentDetails/EquipmentDetails";
import AlarmSettings from "containers/Pages/AlarmSettings/AlarmSettings";
//Settings
import SettingsIndex from "containers/Pages/Settings/SettingsIndex";
import TenantSettings from "containers/Pages/Settings/TenantSettings/TenantSettings";
import FacilitySettings from "containers/Pages/Settings/FacilitySettings/FacilitySettings";
import UserSettings from "containers/Pages/Settings/UserSettings/UserSettings";
import EquipmentSettings from "containers/Pages/Settings/EquipmentSettings/EquipmentSettings";
import BillingSettings from "containers/Pages/Settings/Billing/BillingSettings";
import PaymentForm from "containers/Pages/Settings/Billing/PaymentForm/PaymentForm";
import TabsManualData from "containers/Pages/ManualDataOperations/TabsManualData";
import CreateNewTenant from "containers/Pages/Settings/TenantSettings/CreateNewTenant";
//DealerSettings
import DealerSettings from "containers/Pages/DealerSettings/BrokerSettings";
//TMS (Task Management System)
import CreateNewTask from "containers/Pages/TMS/Task/CreateNewTask";
import TaskNotifications from "containers/Pages/TMS/Notifications/TaskNotifications";
import MyTasks from "containers/Pages/TMS/Task/MyTasks";
import MyTaskEntries from "containers/Pages/TMS/Task/MyTaskEntries";

import SignupInfoPage from "containers/Pages/SignupInfoPage/SignupInfoPage";
import ContactPage from "containers/Pages/ContactPage/ContactPage";
const Router = () => {
  const { SK: facilityIdOfCurrentDashboard, initialDashboardView } = useSelector((state) => state.facility.facilityState.currentFacility);
  const { logIn } = useSelector((state) => state.login);
  const location = useLocation();
  return (
    <MainWrapper>
      <Routes location={location}>
        <Route
          index
          element={<Landing />}
          errorElement={<RouteErrorBoundary />}
        />
        {/* This path is useful as a short cut to sign out */}
        <Route
          path="sign_out"
          // performing this mutation when data is submitted to it
          action={async () => await cognitoSignOut()}
          element={<Landing />}
          errorElement={<RouteErrorBoundary />}
        />
        <Route
          path="404"
          element={<NotFound404 />}
          errorElement={<RouteErrorBoundary />}
        />
        <Route
          path="password"
          element={<PasswordForm />}
          errorElement={<RouteErrorBoundary />}
        />
        <Route
          path="confirmation_code"
          element={<ConfirmationCodeForm />}
          errorElement={<RouteErrorBoundary />}
        />
        <Route
          path="sign_in"
          element={
            <SignInRouter>
              <SignIn />
            </SignInRouter>
          }
          errorElement={<RouteErrorBoundary />}
        />
        <Route
          path="contact_page"
          element={
            <SignInRouter>
              <ContactPage />
            </SignInRouter>
          }
          errorElement={<RouteErrorBoundary />}
        />
        <Route
          path="hub"
          element={
            <WrappedRoutes>
              <HubPage />
            </WrappedRoutes>
          }
          errorElement={<RouteErrorBoundary />}
        />
        <Route
          path="createNewTask"
          element={
            <WrappedRoutes>
              <CreateNewTask />
            </WrappedRoutes>
          }
          errorElement={<RouteErrorBoundary />}
        />
        <Route
          path="taskNotifications"
          element={
            <WrappedRoutes>
              <TaskNotifications />
            </WrappedRoutes>
          }
          errorElement={<RouteErrorBoundary />}
        />
        <Route
          path="myTasks"
          element={
            <WrappedRoutes>
              <MyTasks />
            </WrappedRoutes>
          }
          errorElement={<RouteErrorBoundary />}
        />
        <Route
          path="myTaskEntries"
          element={
            <WrappedRoutes>
              <MyTaskEntries />
            </WrappedRoutes>
          }
          errorElement={<RouteErrorBoundary />}
        />
        <Route
          path="tabsManualData"
          element={
            <WrappedRoutes>
              <TabsManualData />
            </WrappedRoutes>
          }
          errorElement={<RouteErrorBoundary />}
        />
        <Route
          path="alarmSettings"
          element={
            <WrappedRoutes>
              <AlarmSettings />
            </WrappedRoutes>
          }
          errorElement={<RouteErrorBoundary />}
        />
        <Route
          path="equipmentDetails"
          element={
            <WrappedRoutes>
              <EquipmentDetails />
            </WrappedRoutes>
          }
          errorElement={<RouteErrorBoundary />}
        />
        <Route
          path="settings"
          element={
            <WrappedRoutes>
              <SettingsIndex />
            </WrappedRoutes>
          }
          errorElement={<RouteErrorBoundary />}
        />
         <Route
          path="dealerSettings"
          element={
            <WrappedRoutes>
              <DealerSettings />
            </WrappedRoutes>
          }
          errorElement={<RouteErrorBoundary />}
        />
        <Route
          path="tenant_settings"
          element={
            <WrappedRoutes>
              <TenantSettings />
            </WrappedRoutes>
          }
          errorElement={<RouteErrorBoundary />}
        />
          <Route
          path="create_tenant"
          element={
            <WrappedRoutes>
              <CreateNewTenant />
            </WrappedRoutes>
          }
          errorElement={<RouteErrorBoundary />}
        />
        <Route
          path="facility_settings"
          element={
            <WrappedRoutes>
              <FacilitySettings />
            </WrappedRoutes>
          }
          errorElement={<RouteErrorBoundary />}
        />
        <Route
          path="user_settings"
          element={
            <WrappedRoutes>
              <UserSettings />
            </WrappedRoutes>
          }
          errorElement={<RouteErrorBoundary />}
        />
        <Route
          path="device_settings"
          element={
            <WrappedRoutes>
              <EquipmentSettings />
            </WrappedRoutes>
          }
          errorElement={<RouteErrorBoundary />}
        />
        <Route
          path="billing_settings"
          element={
            <WrappedRoutes>
              <BillingSettings />
            </WrappedRoutes>
          }
          errorElement={<RouteErrorBoundary />}
        />
        <Route
          path="payment_form"
          element={
            <WrappedRoutes>
              <PaymentForm />
            </WrappedRoutes>
          }
          errorElement={<RouteErrorBoundary />}
        />

        <Route
          path="register"
          element={
            <WrappedRoutes>
              <RegistrationWizardForm />
            </WrappedRoutes>
          }
          errorElement={<RouteErrorBoundary />}
        />
        <Route
          path="sign_up"
          element={
            // <SignUp />
            <SignUpNew />
          }
          errorElement={<RouteErrorBoundary />}
        />
        <Route
          path="signupInfoPage"
          element={
            <SignupInfoPage />
          }
          errorElement={<RouteErrorBoundary />}
        />
        <Route
          path="dashboard/:userParameter/:facilityIdParameter"
          element={
            <PrivateRoute
              isAllowed={
                logIn === "authenticated" &&
                // It takes some time to get response from API call and update the store
                // Due to that reason we wait for the initial dashboard view state
                !initialDashboardView
              }
            >
              <WrappedRoutes>
                {facilityIdOfCurrentDashboard ? <Dashboard /> : <HubPage />}
              </WrappedRoutes>
            </PrivateRoute>
          }
          errorElement={<RouteErrorBoundary />}
        />
        <Route
          path="*"
          element={<NotFound404 />}
          errorElement={<RouteErrorBoundary />}
        />
      </Routes>
    </MainWrapper>
  );
};

export default Router;
