import React from "react";
import { Col, Row, Container } from "reactstrap";
import { Trans, useTranslation } from "react-i18next";

export default function Register({ contactPageLink }) {
  const { t, i18n } = useTranslation();
  return (
    <section className="landing__section">
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="landing__section-title">
              <b>{t("pages.landing.register.content.header")}</b>
            </h3>
            <h3 className="landing__section-title">
              {t("pages.landing.register.content.paragraph1")}
            </h3>
            <div className="landing__center-btn">
              <a
                className="landing__btn landing__btn--gradient"
                target="_blank"
                rel="noopener noreferrer"
                href={contactPageLink}
              >
                {t("pages.landing.register.content.button")}
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
