import { createSlice } from "@reduxjs/toolkit";
const currentDate = new Date();
const minutesToHours = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const formattedHours = hours.toString().padStart(2, "0");
    const remainingMinutes = minutes % 60;
    const formattedMinutes = remainingMinutes.toString().padStart(2, "0");
    const minesOrPlus = minutes < 0 ? "-" : "+"
    return `${minesOrPlus}${formattedHours}:${formattedMinutes}`;
};

const initialTimeState = {
    storeStartTime: `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}T${((currentDate.getHours() - 1)).toString().padStart(2, '0')}:${currentDate.getMinutes().toString().padStart(2, '0')}:${(currentDate.getSeconds()).toString().padStart(2, '0')}${minutesToHours(-1 * currentDate.getTimezoneOffset())}`,
    storeEndTime: `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}T${(currentDate.getHours()).toString().padStart(2, '0')}:${(currentDate.getMinutes()).toString().padStart(2, '0')}:${(currentDate.getSeconds()).toString().padStart(2, '0')}${minutesToHours(-1 * currentDate.getTimezoneOffset())}`,
    storeTimeStatus: 'now',
};

// const initialTimeState = {
//     storeStartTime: "",
//     storeEndTime: "",
//     storeTimeStatus: "",
// };

const timeSlice = createSlice({
    name: "timeData",
    initialState: {
        timeState: initialTimeState,
    },
    reducers: {
        timeFirstRenderDataHandler(state, action) {
            let timeData = action.payload;

            state.timeState = {
                ...state.timeState,
                timeData,
            };
        },

        timePickerDataHandler(state, action) {
            let timeDataFromPayload = action.payload;
            // If time value has no integer component, such as "today",
            // then it will be false. Otherwise, it will be true
            const timeValue = timeDataFromPayload.split("#")[0];
            // If time value is not an integer, such as "today",
            // then it will be NaN. Otherwise, it will be an integer
            const timeValueIsInt = parseInt(timeValue);
            // These variables will be used for the time range selection
            let storeEndTime, storeStartTime, storeTimeStatus;
            const currentDate = new Date();
            // If time value has no integer component, such as "today",
            // then it will be NaN and false. Otherwise, it will be true
            switch (timeValue) {
                case "yesterday":
                    const yesterdayDate = new Date(currentDate.getTime() - 24 * 60 * 60 * 1000);
                    storeStartTime = `${yesterdayDate.getFullYear()}-${(yesterdayDate.getMonth() + 1).toString().padStart(2, '0')}-${yesterdayDate.getDate().toString().padStart(2, '0')}T00:00:00${minutesToHours(-1 * yesterdayDate.getTimezoneOffset())}`
                    storeEndTime = `${yesterdayDate.getFullYear()}-${(yesterdayDate.getMonth() + 1).toString().padStart(2, '0')}-${yesterdayDate.getDate().toString().padStart(2, '0')}T23:59:59${minutesToHours(-1 * yesterdayDate.getTimezoneOffset())}`
                    storeTimeStatus = "yesterday"
                    break;
                case "last15Minutes":
                    const fifteenMinutesAgo = new Date(currentDate.getTime() - 15 * 60 * 1000);
                    storeStartTime = `${fifteenMinutesAgo.getFullYear()}-${(fifteenMinutesAgo.getMonth() + 1).toString().padStart(2, '0')}-${fifteenMinutesAgo.getDate().toString().padStart(2, '0')}T${(fifteenMinutesAgo.getHours()).toString().padStart(2, '0')}:${fifteenMinutesAgo.getMinutes().toString().padStart(2, '0')}:${(fifteenMinutesAgo.getSeconds()).toString().padStart(2, '0')}${minutesToHours(-1 * fifteenMinutesAgo.getTimezoneOffset())}`
                    storeEndTime = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}T${(currentDate.getHours()).toString().padStart(2, '0')}:${(currentDate.getMinutes()).toString().padStart(2, '0')}:${(currentDate.getSeconds()).toString().padStart(2, '0')}${minutesToHours(-1 * currentDate.getTimezoneOffset())}`
                    storeTimeStatus = "last15Minutes"
                    break;
                case "last1Hour":
                    const last1Hour = new Date(currentDate.getTime() - 60 * 60 * 1000);
                    storeStartTime = `${last1Hour.getFullYear()}-${(last1Hour.getMonth() + 1).toString().padStart(2, '0')}-${last1Hour.getDate().toString().padStart(2, '0')}T${(last1Hour.getHours()).toString().padStart(2, '0')}:${last1Hour.getMinutes().toString().padStart(2, '0')}:${(last1Hour.getSeconds()).toString().padStart(2, '0')}${minutesToHours(-1 * last1Hour.getTimezoneOffset())}`
                    storeEndTime = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}T${(currentDate.getHours()).toString().padStart(2, '0')}:${(currentDate.getMinutes()).toString().padStart(2, '0')}:${(currentDate.getSeconds()).toString().padStart(2, '0')}${minutesToHours(-1 * currentDate.getTimezoneOffset())}`
                    storeTimeStatus = "last1Hour"
                    break;

                    case "today":
                        storeStartTime = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}T00:00:00${minutesToHours(-1 * currentDate.getTimezoneOffset())}`
                        storeEndTime = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}T${(currentDate.getHours()).toString().padStart(2, '0')}:${(currentDate.getMinutes()).toString().padStart(2, '0')}:${(currentDate.getSeconds()).toString().padStart(2, '0')}${minutesToHours(-1 * currentDate.getTimezoneOffset())}`
                        storeTimeStatus = "today"
                        break;
                    
                default:
                    // When the time value is "today" or something else
                    storeStartTime = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}T00:00:00${minutesToHours(-1 * currentDate.getTimezoneOffset())}`
                    storeEndTime = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}T${(currentDate.getHours()).toString().padStart(2, '0')}:${(currentDate.getMinutes()).toString().padStart(2, '0')}:${(currentDate.getSeconds()).toString().padStart(2, '0')}${minutesToHours(-1 * currentDate.getTimezoneOffset())}`
                    storeTimeStatus = "now"
                    break;
            }
            state.timeState = {
                ...state.timeState,
                storeEndTime,
                storeStartTime,
                storeTimeStatus,
            };
        },

        timeCustomDateHandler(state, action) {
            let { startTimeToDispatch, endTimeToDispatch } = action.payload;
            const storeStartTimeDate = new Date(startTimeToDispatch);
            const storeEndTimeDate = new Date(endTimeToDispatch);
            const storeTimeStatus = 'custom'
            const storeStartTime = `${storeStartTimeDate.getFullYear()}-${(storeStartTimeDate.getMonth() + 1).toString().padStart(2, '0')}-${storeStartTimeDate.getDate().toString().padStart(2, '0')}T${storeStartTimeDate.getHours().toString().padStart(2, '0')}:${storeStartTimeDate.getMinutes().toString().padStart(2, '0')}:${storeStartTimeDate.getSeconds().toString().padStart(2, '0')}${minutesToHours(-1 * storeStartTimeDate.getTimezoneOffset())}`
            const storeEndTime = `${storeEndTimeDate.getFullYear()}-${(storeEndTimeDate.getMonth() + 1).toString().padStart(2, '0')}-${storeEndTimeDate.getDate().toString().padStart(2, '0')}T${storeEndTimeDate.getHours().toString().padStart(2, '0')}:${storeEndTimeDate.getMinutes().toString().padStart(2, '0')}:${storeEndTimeDate.getSeconds().toString().padStart(2, '0')}${minutesToHours(-1 * storeEndTimeDate.getTimezoneOffset())}`
            state.timeState = {
                ...state.timeState,
                storeEndTime,
                storeStartTime,
                storeTimeStatus
            };
        },
        returnToInitialTimeState(state) {
            state.timeState = initialTimeState;
        },
        returnToSignOutState(state) {
            state.timeState = {
                storeStartTime: "",
                storeEndTime: "",
                storeTimeStatus: "",
            };
        }
    },
});
export const {
    timeFirstRenderDataHandler,
    timePickerDataHandler,
    timeCustomDateHandler,
    returnToInitialTimeState,
    returnToSignOutState
} = timeSlice.actions;
export default timeSlice.reducer;