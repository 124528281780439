import { createSlice } from "@reduxjs/toolkit";

const initialFacilityState = {
    userDefaultFacility: {},
    currentFacility: {},
    initialDashboardView: true,
    facilities: []
};

const facilitySlice = createSlice({
    name: "facility",
    initialState: {
        facilityState: initialFacilityState,
    },
    reducers: {
        facilityInitialDataHandler(state, action) {

            let facilityData = action.payload;

            // Reducers can only make copies of the original values, and then they can mutate the copies
            // This is safe, because we made a copy
            state.facilityState = {
                ...state.facilityState,
                userDefaultFacility: facilityData.userDefaultFacility,
                currentFacility: facilityData.userDefaultFacility,
                initialDashboardView: false,
                facilities: facilityData.facilities
            };
        },

        currentFacilityHandler(state, action) {
            let facilityData = action.payload;

            // Reducers can only make copies of the original values, and then they can mutate the copies
            // This is safe, because we made a copy
            state.facilityState = {
                ...state.facilityState,
                currentFacility: facilityData,
            };
        },

        returnToInitialFacilityState(state) {
            state.facilityState = initialFacilityState;
        },
    },
});

export const { 
    facilityInitialDataHandler, 
    currentFacilityHandler, 
    returnToInitialFacilityState 
} = facilitySlice.actions;

export default facilitySlice.reducer;