import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cognitoSignOut from "../../Account/LogIn/AwsCognito/cognitoSignOut";

export default function LogoutButton() {
  const { t, i18n } = useTranslation();

  return (
    <Link
      className="topbar__link"
      to="/"
      replace
      onClick={() => {
        cognitoSignOut();
      }}
    >
      <span className="topbar__link-icon lnr lnr-exit" />
      <p className="topbar__link-title">{t("logOut")}</p>
    </Link>
  );
}
