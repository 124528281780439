import React, { useState } from "react";
import DownIcon from "mdi-react/ChevronDownIcon";
import { useTranslation } from "react-i18next";
import { Collapse } from "reactstrap";
import { useSelector } from "react-redux";
import TopbarMenuLink from "./TopbarMenuLink";
import LogoutButton from "./LogoutButton";

// ava.png is the custom picture for the profile
// It is set as transparent square
const Ava = `${process.env.PUBLIC_URL}/img/ava.png`;

// Picture of the user feature will be added in the next versions
//const Ava = `https://www.sanai.com.tr/images/logo.png`;

export default function TopbarProfile() {
  const { givenName, familyName } = useSelector((state) => state.user.userState);
  const { t, i18n } = useTranslation();

  const [isCollapsed, setIsCollapsed] = useState(false);
  const handleToggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className="topbar__profile">
      <button
        type="button"
        className="topbar__avatar"
        onClick={() => handleToggleCollapse()}
      >
        <img className="topbar__avatar-img" src={Ava} alt="avatar" />
        <p className="topbar__avatar-name">
          {givenName} {familyName}
        </p>
        <DownIcon className="topbar__icon" />
      </button>
      {isCollapsed && (
        <button
          type="button"
          aria-label="button collapse"
          className="topbar__back"
          onClick={() => handleToggleCollapse()}
        />
      )}
      <Collapse isOpen={isCollapsed} className="topbar__menu-wrap">
        <div className="topbar__menu">
          <TopbarMenuLink
            title={t("topbar.changePasswordLink")}
            icon="lock"
            path="/password"
            onClick={() => handleToggleCollapse()}
          />
           <TopbarMenuLink
            title={t("pages.settings.settings.profileSettings")}
            icon="user"
            path="/user_settings"
            state={{profileOrSettings:"profileSettings"}}
            onClick={() => handleToggleCollapse()}
          />
          {/* <TopbarMenuLink title="Page two" icon="inbox" path="/pages/two" onClick /> */}
          <div className="topbar__menu-divider" />
          <LogoutButton />
        </div>
      </Collapse>
    </div>
  );
}
