import React, { useEffect, Suspense, useState } from 'react';
import FacilityForm from './FacilityForm';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';

//Tabs
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import MuiStyleHandler from 'containers/Router/WrappedRoutes/Dashboard/dashboardHelpers/MuiStyleHandler';
import AddNewFacility from './AddNewFacility';
export default function FacilitySettings(props) {
    const location = useLocation();
    // location.state?.choosenTenantData?.value.facilities
    const facilitiesFromLocationState = location.state?.choosenTenantData?.value?.facilities;
    const facilityFromReduxState = useSelector((state) => state.facility.facilityState);

    const facilityState = facilitiesFromLocationState || facilityFromReduxState;
    const { children, index, ...other } = props;
    const maxFacilityLength = 3;
    // const [value, setValue] = useState(facilityState.facilities  ? 1 : 0);
    const [value, setValue] = useState(0);

    const { t, i18n } = useTranslation();


    useEffect(() => {

    }, [])

    function a11yProps(index) {
        return {
            id: `dashboard-tab-${index}`,
            'aria-controls': `dashboard-tabpanel-${index}`,
        };
    }
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    function TabPanel(props) {
        const { children, value, index, ...other } = props;
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`dashboard-tabpanel-${index}`}
                aria-labelledby={`dashboard-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <div>{children}</div>
                    </Box>
                )}
            </div>
        );
    }
    return (
        <MuiStyleHandler>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="sanaiinfo panel">
                        {/*facilityState.facilities.length < maxFacilityLength && <Tab label={t("pages.formItems.addNew")} {...a11yProps(0)} />*/}
                        {facilityState.facilities && facilityState.facilities.map((facilityItem, facilityIndex) =>
                            <Tab key={facilityState.facilities.length < maxFacilityLength ? facilityIndex : facilityIndex} label={facilityItem.unitName}
                                {...a11yProps(facilityState.facilities.length < maxFacilityLength ? facilityIndex + 1 : facilityIndex)} />
                        )
                        }
                    </Tabs>
                </Box>
                {/*facilityState.facilities.length < maxFacilityLength && <TabPanel value={value} index={0}>
                    <Suspense fallback={<div>Loading...</div>}>
                        <AddNewFacility />
                    </Suspense>
                    </TabPanel>*/}
                {facilityState.facilities && facilityState.facilities.map((facilityItem, facilityIndex) =>
                    <TabPanel key={facilityState.facilities.length < maxFacilityLength ? facilityIndex : facilityIndex} value={value} index={facilityState.facilities.length < maxFacilityLength ? facilityIndex : facilityIndex}>
                        <Suspense fallback={<div>Loading...</div>}>
                            <FacilityForm facilityItem={facilityItem} facilityIndex={facilityIndex} />
                        </Suspense>
                    </TabPanel>
                )}
            </Box>
        </MuiStyleHandler>
    )
}

