import { API, Auth } from "aws-amplify";
export default async function manualDataOperationHandler(manualEquipmentData
  /* { tenantId,
    facilityId,
    descriptiveCode,  // descriptive code of the device
    dataSendingDeviceChannelName,
    dataCategory,
    dataUtilizationCode,
    equipmentName,
    dataUnit,
    mutationType,
    operatorId,
    arrayOfDataObject,
    industryCategory,
    equipmentTimeZone}*/
) {
    const apiName = process.env.REACT_APP_REST_API_MANUAL_DATA_OPERATIONS_NAME;
    const path = "manualDataOperation";
    const session = await Auth.currentSession();
    //console.log("session", session);
    const manualDataOperationSchema = {
        headers: {
            Authorization: `Bearer ${session.idToken.jwtToken}`
        },
        body:manualEquipmentData /*{ 
            // TODO add these to the schema
            tenantId: tenantId,
            facilityId: facilityId,
            descriptiveCode: descriptiveCode,  // descriptive code of the device
            dataSendingDeviceChannelName: dataSendingDeviceChannelName,
            dataCategory: dataCategory,
            dataUtilizationCode: dataUtilizationCode,
            equipmentName: equipmentName,
            dataUnit: dataUnit,
            mutationType: mutationType,
            operatorId: operatorId,
            arrayOfDataObject: arrayOfDataObject,
            industryCategory:industryCategory,
            equipmentTimeZone:equipmentTimeZone,
        }*/,
    };
   try {
        const apiResponse = await API.post(apiName, path, manualDataOperationSchema);
        return apiResponse;
    } catch (manualDataOperationError) {
    }
};