import React, { Suspense, Fragment, useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import i18n from "i18next";
import { useDispatch } from "react-redux";
import "bootstrap/dist/css/bootstrap.css";
import "scss/app.scss";
import Modal from "shared/components/Modal/Modal";
import Router from "../Router/Router";
import ScrollToTop from "./ScrollToTop";
import Loading from "shared/components/Loading";
import setAuthListener from "../Account/LogIn/AwsCognito/AuthListener";
import { languageHandler } from "redux/slices/languageSlice";
import handleStoreOnSignIn from "./appHelpers/appHandlerHelpers";

export default function App() {
  const dispatch = useDispatch();
  let location = useLocation();

  // Gets fired after initialization
  // Set the language in Redux store
  i18n.on("initialized", () => {
    dispatch(languageHandler(i18n.language));
  });

  const [isLoading, setIsLoading] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);

  const loadingStateCleanUpHandler = () => {
    setIsLoading(true);
    setIsLoaded(false);
  };

  useEffect(() => {
    window.addEventListener("load", () => {
      setIsLoading(false);
      setTimeout(() => setIsLoaded(true), 500);
    });

    // useEffect cleanup
    return () => loadingStateCleanUpHandler();
  }, []);

  async function handleStoreValues() {
    try {
      // Handle the Redux store data
      await handleStoreOnSignIn(dispatch);
    } catch (error) {
     // console.log("Proceed to signin:", error);
    }
  }

  useEffect(() => {
    handleStoreValues();

    // Listener for user and form state handling
    setAuthListener(dispatch).catch((err) => console.log(err));

    // Stop Loading spinner execution
    setIsLoading(false);
    setIsLoaded(true);

    // useEffect cleanup
    return () => loadingStateCleanUpHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Modal button handler functions
  function acceptButtonFunction() {
    //console.log("accepted");
  }
  function rejectButtonFunction() {
    //console.log("rejected");
  }

  return (
    <div>
      <ScrollToTop>
        <Router />
        {!isLoaded && <Loading loading={isLoading} />}
        <Routes>
          <Route
            path={location.pathname}
            element={
              <Modal
                onAcceptHandler={() => acceptButtonFunction()}
                onRejectHandler={() => rejectButtonFunction()}
              />
            }
          />
        </Routes>
      </ScrollToTop>
    </div>
  );
}
