import React from "react";
import { useSelector, useDispatch } from "react-redux";
import AlternateEmailIcon from "mdi-react/AlternateEmailIcon";
import { userDataHandler } from "redux/slices/userSlice";

const EmailHandler = ({
  renderName,
  placeholder,
  showWarningMessage,
  showWarningMessageHandler,
  invalidFieldMessage,
}) => {
  const { email } = useSelector((state) => state.user.userState);
  const dispatch = useDispatch();

  // trim prevents whitespaces
  const warningMessage =
    (email.trim() === "" || !email.includes("@")) && showWarningMessage;

  function handleChange(event) {
    showWarningMessageHandler(false);
    const emailState = {
      username: event.target.value,
      email: event.target.value,
    };

    dispatch(userDataHandler(emailState));
  }

  return (
    <div className="form__form-group">
      <span className="form__form-group-label">{renderName}</span>
      {warningMessage && (
        <h4
          className="account__subhead subhead"
          style={{
            color: "red",
            fontWeight: "bold",
            marginBottom: "10px",
          }}
        >
          {invalidFieldMessage}
        </h4>
      )}
      <div className="form__form-group-field">
        <div className="form__form-group-icon">
          <AlternateEmailIcon />
        </div>
        <input
          name="email"
          type="email"
          value={email}
          placeholder={placeholder}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

export default EmailHandler;
