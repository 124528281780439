import React, { useState, useEffect } from "react";
import Topbar from "../../Layout/topbar/Topbar";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import MapComponent from "../MapComponent/MapComponent";

const background = `${process.env.PUBLIC_URL}/img/landing/background/left_bg.png`;


export default function ContactPage() {
    const { t, i18n } = useTranslation();
    const { lang } = useSelector((state) => state.lang);

    return (
        <section
            className="landing__header"
            style={{
                backgroundImage: `url(${background}`,
                height: '120vh',
            }}
        >


            <div className="landing" style={{ backgroundColor: 'rgba(0, 0, 0, 0)' }}>
                <Topbar />
                <div style={{display:'flex',margin:50}}>
                    <div style={{width:'50%',marginRight:10}}>
                    <MapComponent lat={41.0082} lng={28.9784} />

                    </div>

                    <div style={{width:'50%',marginLeft:10}}>
                        <h3 className="landing__section-title">
                            <b>{t("pages.contactPageTexts.header1")}</b>
                        </h3>
                        <h5 className="landing__section-title">
                        <b>{t("pages.contactPageTexts.title1")}</b>:{t("pages.contactPageTexts.adress")}
                        </h5>
                        <h5 className="landing__section-title">
                        <b>{t("pages.contactPageTexts.title2")}</b>:{t("pages.contactPageTexts.telNo")}
                        </h5>
                        <h5 className="landing__section-title">
                        <b>{t("pages.contactPageTexts.title3")}</b>: {t("pages.contactPageTexts.email")}
                        </h5>
                        <h5 className="landing__section-title">
                        <b>{t("pages.contactPageTexts.title4")}</b>:  {t("pages.contactPageTexts.mersis")}
                        </h5>

                    </div>
                </div>

            </div>

        </section>


    )
}


/*

   <div>
        <Topbar/>
        ContactPage</div>

*/