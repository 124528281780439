import React from "react";
import { Col, Row, Container } from "reactstrap";
import { useTranslation } from "react-i18next";
import FacebookLogo from 'assets/facebookLogo.png'
import TwitterLogo from 'assets/twitterLogo.png'
import InstagramLogo from 'assets/instagramLogo.png'
import LinkedLogo from 'assets/linkedLogo.png'
export default function AboutUs() {
  const { t, i18n } = useTranslation();
  return (
    <section className="landing__section">
      <Container>
        <Row>
          <Col md={2} />
          <Col md={8}>
            <h3 className="landing__section-title">
              <b>{t("pages.landing.aboutUs.content.header1")}</b>
            </h3>
            <p className="landing__section-subhead">
              {t("pages.landing.aboutUs.content.paragraph1")}
            </p>
            <p className="landing__section-subhead">
              {t("pages.landing.aboutUs.content.paragraph2")}
            </p>
            <br />
            <h3 className="landing__section-title">
              <b>{t("pages.landing.aboutUs.content.header2")}</b>
            </h3>
            <p className="landing__section-subhead">
              {t("pages.landing.aboutUs.content.paragraph3")}
            </p>
            <p className="landing__section-subhead">
              {t("pages.landing.aboutUs.content.paragraph4")}
            </p>
            <p className="landing__section-subhead">
              {t("pages.landing.aboutUs.content.paragraph5")}
            </p>
            <p className="landing__section-subhead">
              {t("pages.landing.aboutUs.content.paragraph6")}
            </p>
          </Col>
          <Col md={2} />
          <Row >
            <Col md={12}>
              <div className="landing__section-title">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/people/Sanai-Bili%C5%9Fim/100091928621946/"
                >
                  <img className="payment__credit-card_img" src={FacebookLogo} alt="mc" />
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.twitter.com/sanaibilisim"
                >
                  <img className="payment__credit-card_img" src={TwitterLogo} alt="mc" />
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.instagram.com/sanaibilisim"
                >
                  <img className="payment__credit-card_img" src={InstagramLogo} alt="mc" />
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.linkedin.com/company/sanai-bilisim/"
                >
                  <img className="payment__credit-card_img" src={LinkedLogo} alt="mc" />
                </a>
              </div>
            </Col>
          </Row>
        </Row>
      </Container>
    </section>
  );
}
