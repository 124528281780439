export const initialModalDetails = {
  colored: false,
  header: false,
  inputField: false,
  // showCancelButton: false,
  // modalColor options: primary, success, warning, danger
  modalColor: "primary",
};

export const initialModalRender = {
  code: "",
  name: "",
  message: "",
};

export const errorModal = {
  colored: true,
  header: false,
  inputField: false,
  // showCancelButton: false,
  // modalColor options: primary, success, warning, danger
  modalColor: "danger",
};

export const coloredSuccessModalDetails = {
  colored: true,
  header: false,
  inputField: false,
  // showCancelButton: false,
  // modalColor options: primary, success, warning, danger
  modalColor: "success",
};

export const coloredWarningModalDetails = {
  colored: true,
  header: false,
  inputField: false,
  // showCancelButton: false,
  // modalColor options: primary, success, warning, danger
  modalColor: "warning",
};

export const primaryModalDetails = {
  colored: true,
  header: false,
  inputField: false,
  // showCancelButton: false,
  // modalColor options: primary, success, warning, danger
  modalColor: "primary",
};
