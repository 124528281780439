import React from 'react';
import { useTranslation } from 'react-i18next';
import { PieChart, Pie, ResponsiveContainer } from 'recharts';
import { Col } from 'react-bootstrap';
import FlashIcon from 'mdi-react/FlashIcon';
import {
   CardTitleWrap, CardTitle,
} from 'shared/components/Card';
import {
  DashboardExecutiveChartCard,
  DashboardExecutiveChartInfo,
  DashboardExecutiveChartNumber,
  DashboardExecutiveChartWrap,
  DashboardExecutiveGoal,
} from './DashboardExecutiveChartElements';
export default function ExecutiveReportDashboard({
  valueToRender,
  equipmentName,
}) {
  const { t, i18n } = useTranslation();
  // Ideally power factor should be 1
  let fillColor;
  if (valueToRender < 0.7 ) {
    fillColor = '#ff4861';
  } else if (valueToRender < 0.9) {
    fillColor = '#f6da6e';
  } else {
    fillColor = '#4ce1b6';
  };
  const data = [{ value: valueToRender*100, fill: fillColor },
    { value: (1-valueToRender)*100, fill: '#eeeeee' }];
  return (
        <DashboardExecutiveChartCard>
          <CardTitleWrap>
            <CardTitle>{t("pages.dashboardPage.charts.executiveDashboardElectricity.title.averagePowerFactor", {equipmentName})}</CardTitle>
          </CardTitleWrap>
          <DashboardExecutiveChartWrap>
            <ResponsiveContainer height={180}>
              <PieChart>
                <Pie 
                data={data} 
                dataKey="value" 
                cy={85} 
                innerRadius={80} 
                outerRadius={90} 
                isAnimationActive={true}
              />
              </PieChart>
            </ResponsiveContainer>
            <DashboardExecutiveChartInfo>
              <FlashIcon style={{ fill: fillColor }} />
              <DashboardExecutiveChartNumber>{valueToRender}</DashboardExecutiveChartNumber>
            </DashboardExecutiveChartInfo>
          </DashboardExecutiveChartWrap>
          <DashboardExecutiveGoal>{t("pages.dashboardPage.charts.executiveDashboardElectricity.subTitle.powerFactorReference")}</DashboardExecutiveGoal>
        </DashboardExecutiveChartCard>

  );
};
