import { configureStore } from "@reduxjs/toolkit";
import rtlReducer from "../slices/rtlSlice";
import sidebarReducer from "../slices/sidebarSlice";
import themeReducer from "../slices/themeSlice";
import languageReducer from "../slices/languageSlice";
import userReducer from "../slices/userSlice";
import facilityReducer from "../slices/facilitySlice";
import tenantReducer from "../slices/tenantSlice";
import logInReducer from "../slices/logInStatusSlice";
import modalReducer from "../slices/modalSlice";
import dashboardDataReducer from "../slices/dashboardDataSlice";
import initialLoadReducer from "../slices/initialLoadSlice";
import dashboardConfigurationReducer from "redux/slices/dashboardConfigurationSlice";
import deviceDataReducer from "redux/slices/deviceDataSlice";
import executiveDashboardDataReducer from "redux/slices/executiveDashboardSlice";
import timeReducer from "redux/slices/timeSlice";

const store = configureStore({
  reducer: {
    rtl: rtlReducer,
    sidebar: sidebarReducer,
    theme: themeReducer,
    lang: languageReducer,
    user: userReducer,
    facility: facilityReducer,
    tenant: tenantReducer,
    login: logInReducer,
    modal: modalReducer,
    dashboardData: dashboardDataReducer,
    deviceData: deviceDataReducer,
    executiveDashboardData: executiveDashboardDataReducer,
    firstTimeLoading: initialLoadReducer,
    dashboardConfiguration: dashboardConfigurationReducer,
    timeData: timeReducer
  },
});

export default store;
