import { createSlice } from "@reduxjs/toolkit";

/*
States:
formSignIn
formSignUp
formConfirmSignUp
formChangePassword
authenticated
*/
const logInStatusSlice = createSlice({
  name: "logInStatus",
  initialState: {
    logIn: "formSignIn",
  },
  reducers: {
    loginStatusHandler(state, action) {
      const loginState = action.payload;
      state.logIn = loginState;
    },
  },
});

export const { loginStatusHandler } = logInStatusSlice.actions;

export default logInStatusSlice.reducer;
