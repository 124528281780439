import React from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

const MainWrapper = ({ children }) => {
  const location = useLocation();

  const directionState = useSelector((state) => state.rtl.direction);
  const themeState = useSelector((state) => state.theme.className);
  const direction = location.pathname === "/" ? "ltr" : directionState;

  return (
    <div className={`${themeState} ${direction}-support`} dir={direction}>
      <div className="wrapper">
        <main>{children}</main>
      </div>
    </div>
  );
};

MainWrapper.propTypes = {
  children: PropTypes.element.isRequired,
  // location: PropTypes.shape().isRequired,
};

export default MainWrapper;