import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import {
    Card, CardBody, Col, Table
} from 'reactstrap';
export default function MyTaskEntries() {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const maxTextSize = 20

    const myTasksItems = [
        { id: 0, title: "30 Takım ayak", responsible: "Şebnem Ferah", startDate: "12/05/1895", endDate: "12/09/1895", status: "Tamamlandı",statusColor: "teal"  },
        { id: 1, title: "30 adet kol", responsible: "Mahmut Tuncer", startDate: "12/05/1895", endDate: "12/09/1895", status: "Tamamlandı",statusColor: "teal"  },
        { id: 2, title: "30 adet tabla", responsible: "Tarkan Ferah", startDate: "12/05/1895", endDate: "12/09/1895", status: "Devam Ediyor",statusColor: "orange"},
        { id: 3, title: "30 Takım ayak", responsible: "Şebnem Ferah", startDate: "12/05/1895", endDate: "12/09/1895", status: "Tamamlandı",statusColor: "teal"},
        { id: 4, title: "30 Takım ayak", responsible: "Şebnem Ferah", startDate: "12/05/1895", endDate: "12/09/1895", status: "İptal Edildi",statusColor: "red"},


    ];

    const myTasksHeaders = [
        { id: 1, title: "Status" },
        { id: 2, title: "Başlık" },
        { id: 3, title: "Sorumlu" },
        { id: 4, title: "Planlanan Başlangıç" },
        { id: 5, title: "Planlanan bitiş" },

    ];
    return (
        <div style={{ marginLeft: 20, marginRight: 20 }}>
            <Col md={12} lg={12}>
                <Card>
                    <CardBody>
                        <div className="card__title">
                            <h5 className="bold-text">Görev Girdilerim</h5>
                        </div>

                        <Table responsive hover  >
                            <thead>
                                <tr>
                                    {myTasksHeaders.map(item => (
                                        <th key={item.id} style={{ minWidth: 150, padding: '0 20px',verticalAlign: 'middle' }}>{item.title}</th>
                                        ))}
                                </tr>
                            </thead>
                            <tbody >
                                {myTasksItems.map((item, index) => (

                                    <tr
                                    style={{ backgroundColor: index % 2 == 0 && 'ButtonShadow', height:50}}
                                    >
                                           <td style={{ verticalAlign: 'middle' }}>
                                            <div style={{ width: "100px", textAlign: 'center', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', backgroundColor: item.statusColor, borderRadius: 10, color: 'white', }}>
                                                {item.status}
                                            </div>
                                        </td>
                                        <td style={{ padding: '0 20px', verticalAlign: 'middle' }}>
                                        {item.title.length > maxTextSize ? item.title.substring(0, maxTextSize) + "..." : item.title}
                                        </td>
                                        <td style={{ padding: '0 20px', verticalAlign: 'middle' }}>
                                            {item.responsible.length > maxTextSize ? item.responsible.substring(0, maxTextSize) + "..." : item.responsible}
                                        </td>
                                        <td style={{ padding: '0 20px', verticalAlign: 'middle' }}>
                                            {item.startDate}
                                        </td>
                                        <td style={{ padding: '0 20px', verticalAlign: 'middle' }}>
                                            {item.endDate}
                                        </td>

                                     

                                        <td  style={{ padding: '0 20px',verticalAlign: 'middle', color:'teal' }} /*onClick={() => console.log("from detail")}*/>
                                            Detay
                                        </td>
                                    </tr>

                                ))}
                            </tbody>
                        </Table>


                    </CardBody>
                </Card>
            </Col>
        </div>
    )
}
