import React from "react";
import { useSelector, useDispatch } from "react-redux";

import classNames from "classnames";
import SidebarContent from "./SidebarContent";
import {
  changeThemeToDark,
  changeThemeToLight,
} from "redux/slices/themeSlice";
import { changeMobileSidebarVisibility } from "redux/slices/sidebarSlice";

const Sidebar = () => {
  const sidebar = useSelector((state) => state.sidebar);
  const dispatch = useDispatch();

  const changeToDark = () => dispatch(changeThemeToDark());
  const changeToLight = () => dispatch(changeThemeToLight());
  const changeMobileSidebar = () => dispatch(changeMobileSidebarVisibility());

  const sidebarClass = classNames({
    sidebar: true,
    "sidebar--show": sidebar.show,
    "sidebar--collapse": sidebar.collapse,
  });

  return (
    <div className={sidebarClass}>
      <button
        type="button"
        aria-label="sidebar visibility"
        className="sidebar__back"
        onClick={changeMobileSidebar}
      />

      <div className="sidebar__wrapper sidebar__wrapper--desktop">
        <SidebarContent
          onClick={() => {}}
          changeToDark={changeToDark}
          changeToLight={changeToLight}
          sidebarCollapse={sidebar.collapse}
        />
      </div>
      <div className="sidebar__wrapper sidebar__wrapper--mobile">
        <SidebarContent
          onClick={changeMobileSidebar}
          changeToDark={changeToDark}
          changeToLight={changeToLight}
        />
      </div>
    </div>
  );
};

export default Sidebar;
