import React, { useState, useEffect, Fragment } from 'react';
import MenuItem from './components/MenuItems/MenuItem';
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Card } from 'reactstrap';
import { API, graphqlOperation } from 'aws-amplify';
import { getTenantsByBrokerId } from '../../../graphql/queries';

export default function SettingsIndex() {
    const { t, i18n } = useTranslation();
    const userData = useSelector((state) => state.user.userState);
    const { lang } = useSelector((state) => state.lang);
    // State that holds the settings menu
    const [settingMenuMapping, setSettingMenuMapping] = useState(null);



    useEffect(() => {
        const settingsCategories = [
            {
                mainTitle: (t("pages.settings.settings.title")),
                mainbAuthorityLevel: 0,
                subTitleArray: [
                    { subTitle: t("pages.settings.settings.tenant"), description: t("pages.settings.settings.tenantDescription"), subAuthorityLevel: 0, navigateName: "tenant_settings" },
                    { subTitle: t("pages.settings.settings.facility"), description: t("pages.settings.settings.facilityDescription"), subAuthorityLevel: 0, navigateName: "facility_settings" },
                    { subTitle: t("pages.settings.settings.user"), description: t("pages.settings.settings.userDescription"), subAuthorityLevel: 0, navigateName: "user_settings" },
                    { subTitle: t("pages.settings.settings.equipment"), description: t("pages.settings.settings.equipmentDescription"), subAuthorityLevel: 0, navigateName: "device_settings" },
                    { subTitle: t("pages.settings.settings.alarm"), description: t("pages.settings.settings.alarmDescription"), subAuthorityLevel: 0, navigateName: "alarmSettings" }

                ]
            },
           /* {
                mainTitle: (t("pages.settings.billing.title")),
                mainbAuthorityLevel: 0,
                subTitleArray: [
                    { subTitle: t("pages.settings.billing.billing"), description: t("pages.settings.billing.billingDescription"), subAuthorityLevel: 0, navigateName: "billing_settings" },
                    { subTitle: t("pages.settings.billing.payment"), description: t("pages.settings.billing.payment"), subAuthorityLevel: 0, navigateName: "payment_form" },

                ]
            }*/
        ];
        setSettingMenuMapping(() => <div>{
            settingsCategories.map((item, index) => <div key={item.mainTitle}> {
                item.mainbAuthorityLevel <= userData.userStatusInt &&
                <div className='main-category-cont'>
                    <p style={{ fontSize: 30 }}>{item.mainTitle}</p>
                    <div className='row-category-cont'>
                        {item.subTitleArray.map((subItem, subIndex) => <div key={subItem.navigateName}> {
                            subItem.subAuthorityLevel <= userData.userStatusInt &&
                            <div>
                                <MenuItem item={subItem} />
                            </div>
                        }</div>
                        )}
                    </div>
                   
                </div>
            }</div>)
        }</div>)
    }, [lang, userData.userStatus]);
    return (
        <Fragment>
            <div className={'settings-main-cont'} >
                <Card>
                    {settingMenuMapping}
                </Card>
            </div>
        </Fragment>
    )
}
