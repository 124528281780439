import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Collapse from "shared/components/Collapse";
import getDeviceData from "containers/Router/WrappedRoutes/Dashboard/dashboardHelpers/getDeviceData";
import { deviceFirstRenderDataHandler } from "redux/slices/deviceDataSlice";
import {
  executiveDashboardFirstRenderDataHandler,
} from "redux/slices/executiveDashboardSlice";
import DashboardAreaChartHandler from "../../dashboardGraphHandler";
import { utilityDashboardSettings } from "../../settingsDashboardUtility";
import Loading from "shared/components/Loading";
import getFacilityData from "containers/Router/WrappedRoutes/Dashboard/dashboardHelpers/getFacilityData";
import { dashboardFirstRenderDataHandler } from "redux/slices/dashboardDataSlice";
import { formatDate } from 'functions/FormatDate';
import { useTranslation } from "react-i18next";

export default function ElectricityDataDashboard1({ dir }) {
  const dispatch = useDispatch();
  const [electricityRender, setElectricityRender] = useState(null)
  const [electricityDashboardRender, setElectricityDashboardRender] = useState(null)
  const [insideCollapse, setInsideCollapse] = useState(null)
  const { responseData: { historicalData } } = useSelector(
    (state) => state.dashboardData.dashboardDataState.dashboardData
  );
  const { t, i18n } = useTranslation();

  const { devices } = useSelector((state) => state.deviceData.deviceDataState);
  const { storeStartTime, storeEndTime, storeTimeStatus } = useSelector((state) => state.timeData.timeState);
  const { currentFacility } = useSelector(
    (state) => state.facility.facilityState
  );
  // tenantId is the id of the tenant that is selected
  const tenantId = currentFacility.id;
  // facilityId is the id of the facility that is selected
  const facilityId = currentFacility.SK;
  const [isLoading, setIsLoading] = useState(true);
  const [codeHandler, setCodeHandler] = useState("");
  const [startErrorHandler, setStartErrorHandler] = useState(false);
  const [openTabDescriptiveCode, setOpenTabDescriptiveCode] = useState(null);
  // These attributes will be ignored during rendering
  const listOfAttributesToIgnore = [
    "descriptiveCode",
    "dataUtilizationCode",
    "measure_name",
    "epochIso",
    "epochUtc",
    "time"
  ]
  const electricityDashboardItemRenderFunc = (descriptiveCode) => {//It is used to create the electrical graphs to be displayed within the collapse.
    if (descriptiveCode) {
      return (historicalData.utility.electricity[descriptiveCode] ?
        setInsideCollapse(Object.entries(historicalData.utility.electricity[descriptiveCode]).map(([key, value]) => {
          if (
            !listOfAttributesToIgnore.includes(key)
            && value.data.length > 0
          ) {
            return (
              <DashboardAreaChartHandler
                key={key}
                dir={dir}
                objectKey={key}
                dataSet={value.data}
                chartSettings={utilityDashboardSettings.historicalData.electricity[key]}
              />
            )
          }
        })) : null)
    }

  }
  const getFacilityGraphData = (descriptiveCode) => {//"It retrieves the electrical detail graph data required for generating the graphs.
    if (descriptiveCode) {
      setOpenTabDescriptiveCode(descriptiveCode)

      setInsideCollapse(null)
      const nowTime = new Date()
      const endTimeValue = storeTimeStatus === "now" ? formatDate(nowTime) : storeEndTime;
      const startTimeValue = storeTimeStatus === "now" ? formatDate(new Date(nowTime.getTime() - 60 * 60 * 1000)) : storeStartTime;
      let timeoutId;
      const timeoutDuration = 10000; // time out second 10 seconds



      // Fetch işlemi
      const fetchData = getFacilityData(
        "historicalElectricityData",
        descriptiveCode,
        facilityId,
        endTimeValue,
        startTimeValue
      );

      // Zaman aşımı işlemi
      const timeout = new Promise((_, reject) => {
        setTimeout(() => {
          reject(new Error("Zaman aşımı"));
        }, timeoutDuration);
      });

      // Fetch işlemi veya zaman aşımı işlemi hangisi önce tamamlanırsa o sonucu alırız
      Promise.race([fetchData, timeout])
        .then((responseData) => {
          dispatch(
            dashboardFirstRenderDataHandler({
              descriptiveCodeOfTheData: descriptiveCode,
              responseData,
            })
          );
          if (responseData.noDataAvailableForTheGivenPeriod) {
            setInsideCollapse(
              <div style={{ textAlign: "center", backgroundColor: "Highlight", color: "black" }}>
                {t("error.dashboard.message.DashboardNoData")}
              </div>
            );
          
          } else {
            // responseData ile başarılı işlem sonucunu kullanabilirsiniz
            electricityDashboardItemRenderFunc(descriptiveCode);

          }
        })
        .catch((err) => {
          // Eğer fetch işlemi zaman aşımına uğradıysa veya başka bir hata olduysa bu blok çalışır
          if (err.message === "Zaman aşımı") {
          } else {
            // Diğer hata durumları için gerekli işlemleri yapabilirsiniz
            setCodeHandler("NoDataError");
            setStartErrorHandler(true);
          }
        });

    }
  }

  const dashboardChartHandler = () => {//It creates collapses with the titles of electrical data.
    if (historicalData.utility.electricity) {
      return (
        Object.entries(devices).map(([key, value]) => {
          // Find the device with the same descriptiveCode as the electricityElement
          //  const currentDevice = devices.filter(obj => obj.descriptiveCode === key);

          //if (currentDevice.length > 0) 
          if (value.dataUtilizationCode === "electricity") {
            //  const { equipmentName } = currentDevice[0];
            return (
              <Collapse
                key={key}
                title={value.equipmentName}
                className="with-shadow"
                onEnteringFunc={() => getFacilityGraphData(value.descriptiveCode)}
                isOpen={openTabDescriptiveCode === value.descriptiveCode ? true : false}
              >
                {codeHandler != "timeOut" ?
                  <div>
                    {insideCollapse != null ?
                      insideCollapse : <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100px' }}><Loading transparentBackground loading={isLoading} /></div>
                    }
                  </div> :
                  <div style={{ textAlign: 'center', backgroundColor: 'Highlight', color: 'black', width: '100%' }}>{t("error.dashboard.message.timeOut")}</div>
                }

              </Collapse >
            );
          }
        })
      )
    }
  };
  const getDeviceDataFunc = () => {//It is used to retrieve device data.
    getDeviceData(tenantId, facilityId)
      .then((response) => {
        // Dispatch the API data to the redux store
        dispatch(deviceFirstRenderDataHandler(response.deviceList));
        if (response?.deviceFacilityValues && Object.keys(response.deviceFacilityValues).length > 0)
          dispatch(executiveDashboardFirstRenderDataHandler(response.deviceFacilityValues));

        if (!response) throw Error("No data for devices");
      })
      .catch((err) => {
        setCodeHandler("NoDataError");
        setStartErrorHandler(true);
      });
  }
  const electricityRenderFunc = () => {
    //It is a rendering process used as a shell. It is designed to facilitate the rendering of the overall page structure, as nested multiple renderings are used.
    return (
      <div>
        {devices ? electricityDashboardRender : <p>No dashboard data yet</p>}
      </div>
    )

  }
  useEffect(() => {
    setElectricityRender(electricityRenderFunc)//first return
  }, [electricityDashboardRender])

  useEffect(() => {
    if (devices.length === 0) {
      getDeviceDataFunc()
    } else {
      setElectricityDashboardRender(dashboardChartHandler)//main titles
    }

  }, [insideCollapse, devices]) //to just run enter
  useEffect(() => {
    electricityDashboardItemRenderFunc(openTabDescriptiveCode)
  }, [openTabDescriptiveCode, historicalData])
  useEffect(() => {
    getFacilityGraphData(openTabDescriptiveCode)
  }, [storeEndTime, storeStartTime, storeTimeStatus, currentFacility])
  return (
    <div>{electricityRender}</div>
  )
}
