import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { API, graphqlOperation } from "aws-amplify";
import { deleteDataAlarm, updateDataAlarm, getDeviceForManualDataOperations } from "graphql/mutations";
import Collapse from "shared/components/Collapse";
import { statusColored } from 'functions/UserStatusColored';
import { Auth } from "aws-amplify";
import { getUsersByTenantId, getUsersByTenantNBrokerId } from "graphql/queries";
import { useLocation } from 'react-router-dom';


import {
    cognitoErrorModalRenderHandler,
    cognitoColoredWarningModalRenderHandler,
    cognitoColoredSuccessModalRenderHandler,
    resetModalHandler
} from "redux/slices/modalSlice";



import {
    Card, CardBody, Col, Button, Table
} from 'reactstrap';
export default function UppdateAlarm({ choosenTenantData }) {
    const location = useLocation();

    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const themeData = useSelector((state) => state.theme.className);
    const [choosenFacilityData, setChoosenFacilityData] = useState(null);
    const facilitiesFromLocationState = location.state?.choosenTenantData?.value?.facilities;
    const facilityFromReduxState = useSelector((state) => state.facility.facilityState);

    const facilityState = facilitiesFromLocationState || facilityFromReduxState; const [equipmentStatusOptions, setEquipmentStatusOptions] = useState();
    const [choosenEquipmentData, setChoosenEquipmentData] = useState(null);
    const [sendOnlyInWeekdays, setSendOnlyInWeekdays] = useState(false);
    const [sendOnlyInWorkingHours, setSendOnlyInWorkingHours] = useState(true);
    const [sendEmail, setSendEmail] = useState(true);
    const [sendSMS, setSendSMS] = useState(false);
    const [alarmListItems, setAlarmListItems] = useState(null)
    const [initialUpdateData, setInitialUpdateData] = useState(null)
    const userData = useSelector((state) => state.user.userState);
    const { currentFacility } = useSelector((state) => state.facility.facilityState);
    const [choosenAlarmCondition, setChoosenAlarmCondition] = useState(null)
    const [showUpdateForm, setShowUpdateForm] = useState(false)
    const [userList, setUserList] = useState([]);
    const tenantDataDefaultTenant = useSelector((state) => state.tenant.tenantState);
    const [tenantData, setTenantData] = useState(choosenTenantData ? choosenTenantData : tenantDataDefaultTenant)
    const [choosenUserList, setChoosenUserList] = useState([])
    const alarmConditionOptions = [// biggerThan, smallerThan, equalTo
        { value: 'biggerThan', label: t("pages.alarmSettings.alarmConditionOptionTitles.biggerThan") },
        { value: 'smallerThan', label: t("pages.alarmSettings.alarmConditionOptionTitles.smallerThan") },
        { value: 'equalTo', label: t("pages.alarmSettings.alarmConditionOptionTitles.equalTo") },
    ];
    const [choosenAlarmFrequencyItem, setChoosenAlarmFrequencyItem] = useState(null)
    const alarmFrequencyOptions = [
        { value: '0m', label: t("pages.alarmSettings.alarmFrequencyOptionTitles.0m") },
        { value: '15m', label: t("pages.alarmSettings.alarmFrequencyOptionTitles.15m") },
        { value: '30m', label: t("pages.alarmSettings.alarmFrequencyOptionTitles.30m") },
        { value: '60m', label: t("pages.alarmSettings.alarmFrequencyOptionTitles.60m") },
        { value: '180m', label: t("pages.alarmSettings.alarmFrequencyOptionTitles.180m") },
        { value: '360m', label: t("pages.alarmSettings.alarmFrequencyOptionTitles.360m") },
    ];
    const header = [
        { id: 1, title: t("alarmListHeaders.alarmName") },
        { id: 2, title: t("alarmListHeaders.alarmValue") },
        { id: 3, title: t("alarmListHeaders.alarmCondition") },
        { id: 5, title: t("pages.formItems.delete") },

    ];
    const [choosenElectricityAttirbuteItem, setChoosenElectricityAttirbuteItem] = useState(null)
    const electricityAttirbuteOptions = [
        { value: "difference_tariff1ImportActiveIndex", label: t("electricityAttirbuteItems.difference_tariff1ImportActiveIndex") },
        { value: "tariff1ImportActiveIndex", label: t("electricityAttirbuteItems.tariff1ImportActiveIndex") },
        { value: "singleTimeIndex", label: t("electricityAttirbuteItems.singleTimeIndex") },
        { value: "dayTimeIndexValue", label: t("electricityAttirbuteItems.dayTimeIndexValue") },
        { value: "nightTimeIndexValue", label: t("electricityAttirbuteItems.nightTimeIndexValue") },
        { value: "peakTimeIndexValue", label: t("electricityAttirbuteItems.peakTimeIndexValue") },
        { value: "averageVLN", label: t("electricityAttirbuteItems.averageVLN") },
        { value: "totalCurrent", label: t("electricityAttirbuteItems.totalCurrent") },
        { value: "totalApparentPower", label: t("electricityAttirbuteItems.totalApparentPower") },
        { value: "totalReactivePower", label: t("electricityAttirbuteItems.totalReactivePower") },
        { value: "totalActivePower", label: t("electricityAttirbuteItems.totalActivePower") },
        { value: "averagePowerFactor", label: t("electricityAttirbuteItems.averagePowerFactor") },
        { value: "averageCos", label: t("electricityAttirbuteItems.averageCos") },
        { value: "averageVLL", label: t("electricityAttirbuteItems.averageVLL") },
        { value: "neutralCurrent", label: t("electricityAttirbuteItems.neutralCurrent") },
        { value: "totalTHDV", label: t("electricityAttirbuteItems.totalTHDV") },
        { value: "totalTHDI", label: t("electricityAttirbuteItems.totalTHDI") },
        { value: "l1Voltage", label: t("electricityAttirbuteItems.l1Voltage") },
        { value: "l2Voltage", label: t("electricityAttirbuteItems.l2Voltage") },
        { value: "l3Voltage", label: t("electricityAttirbuteItems.l3Voltage") },
        { value: "l1Current", label: t("electricityAttirbuteItems.l1Current") },
        { value: "l2Current", label: t("electricityAttirbuteItems.l2Current") },
        { value: "l3Current", label: t("electricityAttirbuteItems.l3Current") },
        { value: "l1ActivePower", label: t("electricityAttirbuteItems.l1ActivePower") },
        { value: "l2ActivePower", label: t("electricityAttirbuteItems.l2ActivePower") },
        { value: "l3ActivePower", label: t("electricityAttirbuteItems.l3ActivePower") },
        { value: "l1ApparentPower", label: t("electricityAttirbuteItems.l1ApparentPower") },
        { value: "l2ApparentPower", label: t("electricityAttirbuteItems.l2ApparentPower") },
        { value: "l3ApparentPower", label: t("electricityAttirbuteItems.l3ApparentPower") },
        { value: "l1ReactivePower", label: t("electricityAttirbuteItems.l1ReactivePower") },
        { value: "l2ReactivePower", label: t("electricityAttirbuteItems.l2ReactivePower") },
        { value: "l3ReactivePower", label: t("electricityAttirbuteItems.l3ReactivePower") },
        { value: "l1PowerFactor", label: t("electricityAttirbuteItems.l1PowerFactor") },
        { value: "l2PowerFactor", label: t("electricityAttirbuteItems.l2PowerFactor") },
        { value: "l3PowerFactor", label: t("electricityAttirbuteItems.l3PowerFactor") },
        { value: "l1Cos", label: t("electricityAttirbuteItems.l1Cos") },
        { value: "l2Cos", label: t("electricityAttirbuteItems.l2Cos") },
        { value: "l3Cos", label: t("electricityAttirbuteItems.l3Cos") },
        { value: "l1Frequency", label: t("electricityAttirbuteItems.l1Frequency") },
        { value: "l2Frequency", label: t("electricityAttirbuteItems.l2Frequency") },
        { value: "l3Frequency", label: t("electricityAttirbuteItems.l3Frequency") },
        { value: "l1THDV", label: t("electricityAttirbuteItems.l1THDV") },
        { value: "l2THDV", label: t("electricityAttirbuteItems.l2THDV") },
        { value: "l3THDV", label: t("electricityAttirbuteItems.l3THDV") },
        { value: "l1THDI", label: t("electricityAttirbuteItems.l1THDI") },
        { value: "l2THDI", label: t("electricityAttirbuteItems.l2THDI") },
        { value: "l3THDI", label: t("electricityAttirbuteItems.l3THDI") }]

    const userHeaders = [
        { id: 1, title: t("userListHeaders.activate") },
        { id: 2, title: t("userListHeaders.givenName") },
        { id: 3, title: t("userListHeaders.familyName") },
        { id: 4, title: t("userListHeaders.userName") },
        { id: 5, title: t("userListHeaders.status") },
    ];
    const [formItemsErrors, setFormItemsErrors] = useState({
        equipmentName: null,
        facilityName: null,
        alarmName: null,
        alarmDescription: null,
        alarmValue: null,
        sendEmail: null,
        sendSMS: null,
        sendOnlyInWorkingHours: null,
        sendOnlyInWeekdays: null,
        alarmCondition: null,
        alarmFrequency: null,
    })

    const tenantId = currentFacility.id;
    const facilityId = currentFacility.SK;
    const editChoosenUserList = (checkboxData, userCheckedData) => {
        const existingUser = choosenUserList.find(user => user.subId === userCheckedData.subId);
        // console.log("checkboxData", checkboxData)
        if (existingUser) {
            if (choosenUserList.length > 1) {
                // console.log("userCheckedData.subId", userCheckedData.subId)
                setChoosenUserList(prevUserList => prevUserList.filter(user => user.subId !== userCheckedData.subId));
            } else {
                const successMessage = {
                    name: t("modal.modalMessage.onSuccess.name.cannotDeleteOwner"),
                    message: t("modal.modalMessage.onSuccess.message.cannotDeleteOwner"),



                };
                dispatch(cognitoColoredWarningModalRenderHandler(successMessage));
            }
        } else {
            const newUser = {
                "tenantId": userCheckedData.id,
                "subId": userCheckedData.subId,
            }
            setChoosenUserList(prevUserList => [...prevUserList, newUser]);
        }
    }




    const selectDataToUpdate = (selectedData) => {
        setInitialUpdateData(selectedData)
        setChoosenUserList([...selectedData.notifyUsers])
    }
    const facilityStatusOptions = facilityState.facilities.map((facilityItem) => {
        return { value: facilityItem, label: facilityItem.unitName }
    })
    useEffect(() => {
        if (facilityStatusOptions && facilityStatusOptions.length === 1) {
            setChoosenFacilityData(facilityStatusOptions[0])
        }

    }, [facilityStatusOptions])
    useEffect(() => {
        // Only certain level of users can fetch the list of users
        queryTenantUsersHandler()
            .then((arrayOfUsers) => {
                setUserList(arrayOfUsers);
            })
            .catch((errorQueryTenantUsers) => {
                // In case of an error, user is informed by an error message
                // For security reasons, the error message is displayed as "Something went wrong"
                dispatch(
                    cognitoErrorModalRenderHandler({
                        errorFromService: "apiOperation",
                        errorState: JSON.stringify(errorQueryTenantUsers)
                    })
                );
            });

        // Clean up function
        return () => {
            setUserList([]);
            dispatch(resetModalHandler());
        }
    }, [userData.userStatusInt]);

    async function queryTenantUsersHandler() {
        // For security reasons, the attributes are retrieved directly from Amplify
        const { attributes } = await Auth.currentAuthenticatedUser();
        // API call to query users data
        const graphQlDataAtQueryTenantUsers = await API.graphql(
            graphqlOperation(
                getUsersByTenantNBrokerId, {
                tenantId: tenantData.id,
                brokerId: tenantData.brokerId
            })
        )
            .catch((err) => {
                throw new Error(err.errors[0]);
            });
        // Return the list of users registered to the tenant
        return graphQlDataAtQueryTenantUsers.data.getUsersByTenantNBrokerId;
    };

    useEffect(() => {
        if (choosenFacilityData?.value) {
            getDevicesOptions()

        }
    }, [choosenFacilityData?.value]);

    useEffect(() => {
        if (choosenEquipmentData?.value) {
            setShowUpdateForm(false)
            const parsedAlarmList = choosenEquipmentData.value.alarmConditions.map((data) => {
                const parsedVeri = JSON.parse(data);
                return parsedVeri;
            });
            setAlarmListItems(parsedAlarmList)
        }

    }, [choosenEquipmentData?.value]);

    useEffect(() => {
        if (initialUpdateData) {
            setSendOnlyInWeekdays(initialUpdateData.sendOnlyInWeekdays)
            setSendOnlyInWorkingHours(initialUpdateData.sendOnlyInWorkingHours)
            setChoosenAlarmCondition(alarmConditionOptions.filter((item) => item.value === initialUpdateData.alarmCondition))
            setChoosenAlarmFrequencyItem(alarmFrequencyOptions.filter((item) => item.value === initialUpdateData.alarmFrequency))
            setChoosenElectricityAttirbuteItem(electricityAttirbuteOptions.filter((item) => item.value === initialUpdateData.alarmAttribute))

            setShowUpdateForm(true)
        }

    }, [initialUpdateData])

    async function getDevicesOptions() {
        setChoosenEquipmentData(null)
        //machineStatusOptions
        //setEquipmentStatusOptions
        const getDevicesData = {

            tenantId: tenantId,
            facilityId: choosenFacilityData?.value.SK,
        };
        // API call to create manual device data
        await API.graphql(
            graphqlOperation(
                getDeviceForManualDataOperations, {
                input: getDevicesData
            })
        ).then((response) => {
            const filteredDevices = Object.entries(response.data.getDeviceForManualDataOperations)
                .filter(([key, value]) => value.alarmConditions.length > 0)
                .map(([key, value]) => ({ value: value, label: value.equipmentName }));

            if (filteredDevices.length === 1) {
                setChoosenEquipmentData(filteredDevices[0])
            }

            setEquipmentStatusOptions(filteredDevices)

            //equipmentCategory: "manualDataEntry"

        }).catch((err) => {
            throw new Error(err.errors[0]);
        });

        return getDevicesData;
    };


    const onSubmit = async (dataOnSubmit) => {

        setFormItemsErrors({
            equipmentName: null,
            facilityName: null,
            alarmName: null,
            alarmDescription: null,
            alarmValue: null,
            sendEmail: null,
            sendSMS: null,
            sendOnlyInWorkingHours: null,
            sendOnlyInWeekdays: null,
            alarmCondition: null,
            alarmFrequency: null,
        });

        var newErorItem = {}

        if (!dataOnSubmit.alarmName || dataOnSubmit.alarmName === "") {
            newErorItem.alarmName = t("pages.formItems.emptyFieldError");
        }
        if (!dataOnSubmit.alarmDescription || dataOnSubmit.alarmDescription === "") {
            newErorItem.alarmDescription = t("pages.formItems.emptyFieldError");
        }
        if (!dataOnSubmit.alarmValue || dataOnSubmit.alarmValue === "") {
            newErorItem.alarmValue = t("pages.formItems.emptyFieldError");
        }
        if (choosenAlarmCondition === null) {
            newErorItem.alarmCondition = t("pages.formItems.emptyFieldError");
        }
        if (choosenAlarmFrequencyItem === null) {
            newErorItem.alarmFrequency = t("pages.formItems.emptyFieldError");
        }
        if (Object.keys(newErorItem).length > 0) {
            setFormItemsErrors((prevErrors) => ({
                ...prevErrors,
                ...newErorItem,
            }));

            return
        }
        const notificationItem = []

        if (sendEmail) {
            notificationItem.push("email");
        }

        if (sendSMS) {
            notificationItem.push("sms");
        }
        const updatedAlarmItem = {
            tenantId: tenantData.id,
            facilityId: choosenEquipmentData.value.facilityId,
            descriptiveCode: choosenEquipmentData.value.descriptiveCode,
            alarmConditions: JSON.stringify({
                "alarmId": initialUpdateData.alarmId,
                "alarmName": dataOnSubmit.alarmName,
                "alarmDescription": dataOnSubmit.alarmDescription,
                "alarmValue": dataOnSubmit.alarmValue,  // Value to be compared. If alarmCondition is biggerThan, alarm will be triggered if the value is bigger than this value.
                // "alarmAttribute": choosenEquipmentData.value.dataSendingDeviceChannelName,
                "alarmAttribute": choosenElectricityAttirbuteItem?.value ? choosenElectricityAttirbuteItem?.value : choosenEquipmentData.value.dataSendingDeviceChannelName,
                "alarmFrequency": choosenAlarmFrequencyItem?.value,  // Unit is minute. Set as 0m if you want to send alarm every time.
                "alarmCondition": choosenAlarmCondition?.value,  // biggerThan, smallerThan, equalTo
                "sendOnlyInWeekdays": sendOnlyInWeekdays,  // If true, alarm will be sent only in weekdays. Will not send on the weekends.
                "sendOnlyInWorkingHours": sendOnlyInWorkingHours,  // If true, alarm will be sent only between 09:00 and 18:00
                "notificationMethod": notificationItem,
                "notifyUsers": choosenUserList
            }
            )

        }
        //console.log("choosenEquipmentData", choosenEquipmentData)


        updateDataAlarmHandler(updatedAlarmItem)

    }
    async function updateDataAlarmHandler(updatedAlarmItem) {
        /*const inputUpdateDataAlarm = {
            tenantId: "TENANT#10009",
            facilityId: "FACILITY#100091001",
            descriptiveCode: "demo1000910015",
            alarmConditions: JSON.stringify(
                {   
                    // Required
                    "alarmId": "ALARM#deneme-1",
                    // Optional
                    "alarmName": "Deneme Alarmı V13",
                    "alarmDescription": "Test amaçlı alarm V13",
                    // "alarmFrequency": "15m",  // Unit is minute
                    // "alarmCondition": "biggerThan",  // biggerThan, smallerThan, equalTo
                    // "alarmAttribute": "analogCh1",
                    // "alarmValue": "1",  // Value to be compared. If alarmCondition is biggerThan, alarm will be triggered if the value is bigger than this value.
                    // "sendOnlyInWeekdays": true,  // If true, alarm will be sent only in weekdays. Will not send on the weekends.
                    // "sendOnlyInWorkingHours": true,  // If true, alarm will be sent only between 09:00 and 18:00
                    // "notificationMethod": ["email", "sms"],
                    // "notifyUsers": [
                    //     {
                    //         "tenantId": "TENANT#10009",
                    //         "subId": "4ee168cd-c1dc-448e-9a7c-e280e75c5a22",
                    //     }
                    // ]
                }
            )
        }*/

        // API call to update data alarm
        await API.graphql(
            graphqlOperation(
                updateDataAlarm, {
                input: updatedAlarmItem
            })
        ).then((response) => {

            const successMessage = {
                name: t(
                    "modal.modalMessage.onSuccess.name.attributeUpdateSuccess"
                ),
                message: t(
                    "modal.modalMessage.onSuccess.message.attributeUpdateSuccess"
                ),
                acceptFunc: () => resetItems(),
                rejectFunc: () => resetItems()
            };
            dispatch(cognitoColoredSuccessModalRenderHandler(successMessage))

            const arrayOfAlarmConditionsAfterUpdate = response.data.updateDataAlarm.alarmConditions;

        }).catch((err) => {
            JSON.stringify(err, null, 2);
            throw new Error(err);
        });
    };
    const resetItems = () => {
        setChoosenFacilityData(null)
        setShowUpdateForm(false)
        setAlarmListItems(null)

    }


    const askToDelete = (choosenDeleteData) => {//It is used to obtain confirmation before deletion.
        const successMessage = {
            name: t(
                "modal.modalMessage.onSuccess.name.areYouSureToDelete"
            ),
            message: t(
                "modal.modalMessage.onSuccess.message.areYouSureToDelete"
            ),
            acceptFunc: () => deleteDataAlarmHandler(choosenDeleteData),
        };
        dispatch(cognitoColoredWarningModalRenderHandler(successMessage));

    }
    async function deleteDataAlarmHandler(choosenDeleteData) {

        const inputDeleteDataAlarm = {
            tenantId: choosenEquipmentData.value.tenantId,
            facilityId: choosenEquipmentData.value.facilityId,
            descriptiveCode: choosenEquipmentData.value.descriptiveCode,
            alarmConditions: JSON.stringify(
                {
                    "alarmId": choosenDeleteData.alarmId,
                }
            )
        }

        // API call to delete data alarm
        await API.graphql(
            graphqlOperation(
                deleteDataAlarm, {
                input: inputDeleteDataAlarm
            })
        ).then((response) => {
            const arrayOfAlarmConditionsAfterDelete = response.data.deleteDataAlarm.alarmConditions;
            const successMessage = {
                name: t(
                    "modal.modalMessage.onSuccess.name.attributeDeleteSuccess"
                ),
                message: t(
                    "modal.modalMessage.onSuccess.message.attributeDeleteSuccess"
                ),
                acceptFunc: () => resetItems(),
                rejectFunc: () => resetItems()
            };
            dispatch(cognitoColoredSuccessModalRenderHandler(successMessage));
        }).catch((err) => {
            JSON.stringify(err, null, 2);
            throw new Error(err);
        });
    };




    return (
        <div style={{ marginLeft: 20, marginRight: 20 }}>
            <Col md={12} lg={12}>
                <Card>
                    <CardBody>
                        <div className="card__title">
                            <h5 className="bold-text">{t("sidebar.alarmSettings.uppdateAlarm")}</h5>
                        </div>
                        <Form onSubmit={onSubmit}
                            validate={(values) => {
                                /* for (const key in values) { //TODO This loop is an example for multiple character checks. Please do not delete it
                                     if (values.hasOwnProperty(key) && values[key].length > 19) {
                                         formItemsErrors[key] = t("pages.settings.errors.maxLengthError")
                                     }
                                     else {
                                         formItemsErrors[key] = null
                                     }
                                 }*/
                                if (values.alarmName?.length > 19) {
                                    formItemsErrors.alarmName = t("pages.settings.errors.maxLengthError")
                                    setFormItemsErrors(formItemsErrors)
                                    return formItemsErrors;


                                } else {
                                    if (formItemsErrors.alarmName == t("pages.settings.errors.maxLengthError")) {
                                        formItemsErrors.alarmName = null
                                        setFormItemsErrors(formItemsErrors)
                                    }


                                }
                                setFormItemsErrors(formItemsErrors);

                            }}>
                            {({ handleSubmit, form }) => (
                                <form className="form form--horizontal" onSubmit={handleSubmit}>

                                    {//ocation.state == null && userData.userStatusInt >= 5 &&
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">{t("pages.formItems.facilityName")}</span>
                                            <div className="form__form-group-field" style={{ display: 'block' }}>
                                                <Select

                                                    isDisabled={facilityStatusOptions.length === 1 ? true : false}
                                                    name="facilityName"
                                                    value={choosenFacilityData}
                                                    onChange={setChoosenFacilityData}
                                                    options={facilityStatusOptions}
                                                    clearable={false}
                                                    className="react-select"
                                                    placeholder={t("pages.formItems.facilityName")}
                                                    styles={{
                                                        control: (provided) => ({
                                                            ...provided,
                                                            backgroundColor: themeData === "theme-dark" && "#232329",

                                                        }),
                                                        singleValue: (provided) => ({
                                                            ...provided,
                                                            color: themeData === "theme-dark" && "#dddddd",
                                                        }),

                                                    }}
                                                />

                                            </div>
                                        </div>}
                                    {equipmentStatusOptions && choosenFacilityData != null &&
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">{t("pages.formItems.equipmentName")}</span>
                                            <div className="form__form-group-field" style={{ display: 'block' }}>
                                                <Select
                                                    isDisabled={equipmentStatusOptions.length === 1 ? true : false}

                                                    name="equipmentName"
                                                    value={choosenEquipmentData}
                                                    onChange={setChoosenEquipmentData}
                                                    options={equipmentStatusOptions}
                                                    clearable={false}
                                                    className="react-select"
                                                    placeholder={t("pages.formItems.equipmentName")}
                                                    styles={{
                                                        control: (provided) => ({
                                                            ...provided,
                                                            backgroundColor: themeData === "theme-dark" && "#232329",

                                                        }),
                                                        singleValue: (provided) => ({
                                                            ...provided,
                                                            color: themeData === "theme-dark" && "#dddddd",
                                                        }),
                                                    }}
                                                />
                                            </div>
                                        </div>}

                                    {showUpdateForm &&
                                        <div style={{ width: '100%' }}>
                                            {choosenEquipmentData.value?.dataUtilizationCode == "electricity" &&
                                                <div className="form__form-group">
                                                    <span className="form__form-group-label">{t("pages.alarmSettings.alarmAttirbute")}</span>
                                                    <div className="form__form-group-field" style={{ display: 'block' }}>
                                                        <Select
                                                            name="alarmAttirbute"
                                                            value={choosenElectricityAttirbuteItem}
                                                            onChange={setChoosenElectricityAttirbuteItem}
                                                            options={electricityAttirbuteOptions}
                                                            clearable={false}
                                                            className="react-select"
                                                            placeholder={t("pages.alarmSettings.alarmAttirbute")}
                                                            styles={{
                                                                control: (provided) => ({
                                                                    ...provided,
                                                                    backgroundColor: themeData === "theme-dark" && "#232329",

                                                                }),
                                                                singleValue: (provided) => ({
                                                                    ...provided,
                                                                    color: themeData === "theme-dark" && "#dddddd",
                                                                }),

                                                            }}
                                                        //classNamePrefix={!disabled ? "react-select" : "disabled"}
                                                        //ref={ref}
                                                        //isDisabled={disabled}
                                                        />
                                                        {formItemsErrors?.alarmAttirbute && <span className="form__form-group-error">{formItemsErrors.alarmAttirbute}</span>}

                                                    </div>
                                                </div>
                                            }
                                            <div className="form__form-group">
                                                <span className="form__form-group-label">{t("pages.alarmSettings.alarmName")}</span>
                                                <div className="form__form-group-field" style={{ display: 'block' }}>
                                                    <Field
                                                        name="alarmName"
                                                        component="input"
                                                        type="text"
                                                        placeholder={t("pages.alarmSettings.alarmName")}
                                                        maxLength={20}
                                                        initialValue={initialUpdateData?.alarmName}

                                                    />
                                                    <span className="form__form-group-error">{formItemsErrors.alarmName}</span>

                                                </div>
                                            </div>
                                            <div className="form__form-group">
                                                <span className="form__form-group-label">{t("pages.alarmSettings.alarmDescription")}</span>
                                                <div className="form__form-group-field" style={{ display: 'block' }}>
                                                    <Field
                                                        name="alarmDescription"
                                                        component="input"
                                                        type="text"
                                                        placeholder={t("pages.alarmSettings.alarmDescription")}
                                                        initialValue={initialUpdateData?.alarmDescription}

                                                    />
                                                    <span className="form__form-group-error">{formItemsErrors.alarmDescription}</span>

                                                </div>
                                            </div>
                                            <div className="form__form-group">
                                                <span className="form__form-group-label">{t("pages.alarmSettings.alarmValue")}</span>
                                                <div className="form__form-group-field" style={{ display: 'block' }}>
                                                    <Field
                                                        name="alarmValue"
                                                        component="input"
                                                        type="number"
                                                        placeholder={t("pages.alarmSettings.alarmValue")}
                                                        initialValue={initialUpdateData?.alarmValue}

                                                    />
                                                    <span className="form__form-group-error">{formItemsErrors.alarmValue}</span>

                                                </div>
                                            </div>



                                            <div className="form__form-group" style={{ alignItems: 'center' }}>
                                                <div className="form__form-group-field" style={{ display: 'block' }}>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>

                                                        <div >

                                                            <input
                                                                type="checkbox"
                                                                checked={sendOnlyInWorkingHours}
                                                                onChange={() => setSendOnlyInWorkingHours(!sendOnlyInWorkingHours)}
                                                            />
                                                        </div>
                                                        <div style={{ alignItems: 'center' }}>
                                                            <span className="form__form-group-label" style={{ marginLeft: 20, width: '100%' }}>{t("pages.alarmSettings.sendOnlyInWorkingHours")}</span>

                                                        </div>


                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form__form-group" style={{ alignItems: 'center' }}>
                                                <div className="form__form-group-field" style={{ display: 'block' }}>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>

                                                        <div >

                                                            <input
                                                                type="checkbox"
                                                                checked={sendOnlyInWeekdays}
                                                                onChange={() => setSendOnlyInWeekdays(!sendOnlyInWeekdays)}
                                                            />
                                                        </div>
                                                        <div style={{ alignItems: 'center' }}>
                                                            <span className="form__form-group-label" style={{ marginLeft: 20, width: '100%' }}>{t("pages.alarmSettings.sendOnlyInWeekdays")}</span>

                                                        </div>
                                                    </div>



                                                </div>
                                            </div>




                                            {/*       <div className="form__form-group" style={{ alignItems: 'center' }}>

                                                <div className="form__form-group-field" style={{ display: 'block' }}>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>

                                                        <div >

                                                            <input
                                                                type="checkbox"
                                                                checked={sendEmail}
                                                                onChange={() => setSendEmail(!sendEmail)}
                                                            />
                                                        </div>
                                                        <div style={{ alignItems: 'center' }}>
                                                            <span className="form__form-group-label" style={{ marginLeft: 20, alignItems: 'center' }}>email</span>

                                                        </div>
                                                        <div >

                                                            <input
                                                                type="checkbox"
                                                                checked={sendSMS}
                                                                onChange={() => setSendSMS(!sendSMS)}
                                                            />
                                                        </div>
                                                        <div style={{ alignItems: 'center' }}>
                                                            <span className="form__form-group-label" style={{ marginLeft: 20, alignItems: 'center' }}>sms</span>

                                                        </div>

                                                    </div>
                                                </div>
                                    </div>*/}
                                            <div className="form__form-group">
                                                <span className="form__form-group-label">{t("pages.alarmSettings.alarmCondition")}</span>
                                                <div className="form__form-group-field" style={{ display: 'block' }}>
                                                    <Select
                                                        name="alarmCondition"
                                                        value={choosenAlarmCondition}
                                                        onChange={setChoosenAlarmCondition}
                                                        options={alarmConditionOptions}
                                                        clearable={false}
                                                        className="react-select"
                                                        placeholder={t("pages.alarmSettings.alarmCondition")}
                                                        styles={{
                                                            control: (provided) => ({
                                                                ...provided,
                                                                backgroundColor: themeData === "theme-dark" && "#232329",

                                                            }),
                                                            singleValue: (provided) => ({
                                                                ...provided,
                                                                color: themeData === "theme-dark" && "#dddddd",
                                                            }),

                                                        }}
                                                    //classNamePrefix={!disabled ? "react-select" : "disabled"}
                                                    //ref={ref}
                                                    //isDisabled={disabled}
                                                    />
                                                    {formItemsErrors?.userTier && <span className="form__form-group-error">{formItemsErrors.userTier}</span>}

                                                </div>
                                            </div>
                                            <div className="form__form-group">
                                                <span className="form__form-group-label">{t("pages.alarmSettings.alarmFrequency")}</span>
                                                <div className="form__form-group-field" style={{ display: 'block' }}>
                                                    <Select
                                                        name="alarmFrequency"
                                                        onChange={setChoosenAlarmFrequencyItem}
                                                        value={choosenAlarmFrequencyItem}
                                                        options={alarmFrequencyOptions}
                                                        clearable={false}
                                                        className="react-select"
                                                        placeholder={t("pages.alarmSettings.alarmFrequency")}
                                                        styles={{
                                                            control: (provided) => ({
                                                                ...provided,
                                                                backgroundColor: themeData === "theme-dark" && "#232329",

                                                            }),
                                                            singleValue: (provided) => ({
                                                                ...provided,
                                                                color: themeData === "theme-dark" && "#dddddd",
                                                            }),

                                                        }}
                                                    //classNamePrefix={!disabled ? "react-select" : "disabled"}
                                                    //ref={ref}
                                                    //isDisabled={disabled}
                                                    />
                                                    {formItemsErrors?.userTier && <span className="form__form-group-error">{formItemsErrors.userTier}</span>}

                                                </div>
                                            </div>
                                            <div className="form__form-group">
                                                <div className="form__form-group-field">
                                                    <div style={{ textAlign: 'end', flex: 1 }}>
                                                        <Button color="primary" type="submit">{t("pages.formItems.save")}</Button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div >
                                                <Collapse title={t("pages.formItems.activeUsersList")} className="with-shadow" isOpen={true}>
                                                    <Table responsive hover>
                                                        <thead>
                                                            <tr>
                                                                {userHeaders.map(item => (
                                                                    <th key={item.id} style={{ padding: '0 20px', verticalAlign: 'middle' }}>{item.title}</th>
                                                                ))}
                                                            </tr>
                                                        </thead>
                                                        <tbody >
                                                            {userList.map((item, index) => {
                                                                const isChecked = choosenUserList.some(user => user.subId === item.subId)

                                                                return (<tr
                                                                    style={{ backgroundColor: item.id === tenantId ? (index % 2 == 0 && 'ButtonShadow') : (index % 2 == 0 ? '#FF6666' : "#FCAEAE"), height: 50 }}

                                                                    key={item.id + "-" + index} /*onClick={() => console.log("ClickedList Item")}*/  >
                                                                    <td style={{ padding: '0 20px', verticalAlign: 'middle' }}>
                                                                        <input
                                                                            type="checkbox"
                                                                            key={item.subId}
                                                                            checked={isChecked}
                                                                            //defaultChecked={choosenUserList.find(user => user.subId === item.subId)}
                                                                            onChange={(checkboxData) => editChoosenUserList(checkboxData, item)}
                                                                            style={{ width: 15 }}
                                                                        />
                                                                    </td>
                                                                    <td style={{ padding: '0 20px', verticalAlign: 'middle' }}>
                                                                        {item.givenName}</td>
                                                                    <td style={{ padding: '0 20px', verticalAlign: 'middle' }}>
                                                                        {item.familyName}</td>
                                                                    <td style={{ padding: '0 20px', verticalAlign: 'middle' }}>
                                                                        {item.email}</td>
                                                                    <td style={{ padding: '0 20px', verticalAlign: 'middle' }}>

                                                                        <div style={{ width: "100px", textAlign: 'center', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', backgroundColor: statusColored(item.userStatus), borderRadius: 10, color: 'white' }}>
                                                                            {item.userStatus}
                                                                        </div>
                                                                    </td>
                                                                </tr>)
                                                            }
                                                            )}
                                                        </tbody>
                                                    </Table>
                                                </Collapse>
                                            </div>

                                        </div>

                                    }

                                    {alarmListItems !== null &&
                                        <div style={{ flex: 1 }}>
                                            <Collapse title={t("pages.formItems.activeAlarmsList")} className="with-shadow" isOpen={true}>
                                                <Table responsive hover>
                                                    <thead>
                                                        <tr>
                                                            {header.map(item => (
                                                                <th key={item.id}>{item.title}</th>
                                                            ))}
                                                        </tr>
                                                    </thead>
                                                    <tbody >
                                                        {Object.values(alarmListItems).map((item, userListIndex) => (
                                                            <tr
                                                                style={{ backgroundColor: userListIndex % 2 == 0 && 'ButtonShadow', height: 50 }}

                                                                key={item.id + " - " + userListIndex} onClick={() => selectDataToUpdate(item)}>
                                                                <td style={{ padding: '0 20px', verticalAlign: 'middle' }}>
                                                                    {item.alarmName}</td>
                                                                <td style={{ padding: '0 20px', verticalAlign: 'middle' }}>
                                                                    {item.alarmValue}</td>
                                                                <td style={{ padding: '0 20px', verticalAlign: 'middle' }}>
                                                                    {item.alarmCondition}</td>

                                                                <td onClick={() => askToDelete(item)} style={{ color: 'red' }}>
                                                                    {t("pages.formItems.delete")}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                            </Collapse>
                                        </div>}

                                </form>
                            )}
                        </Form>

                    </CardBody>
                </Card>
            </Col>
        </div>
    )
}
