import React from "react";
import PropTypes from "prop-types";

// Sanai colors are #192747 & #2293bc
const Loading = ({ loading, transparentBackground }) => (
  <div
    className={`load${transparentBackground ? "__transparent_background" : ""}${
      loading ? "" : " loaded"
    }`}
  >
    <div className="load__icon-wrap">
      <svg className="load__icon">
        <path fill="#2293bc" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
      </svg>
    </div>
  </div>
);

Loading.propTypes = {
  loading: PropTypes.bool,
  transparentBackground: PropTypes.bool,
};

Loading.defaultProps = {
  loading: false,
  transparentBackground: false,
};

export default Loading;
