import React, { useEffect, Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {  Auth } from "aws-amplify";
import { useTranslation } from "react-i18next";
import {  Container } from "react-bootstrap";
import { useLocation } from 'react-router-dom';
import Loading from "shared/components/Loading";
import { 
  cognitoErrorModalRenderHandler, 
  cognitoPrimaryModalRenderHandler, 
  resetModalHandler 
} from "redux/slices/modalSlice";
import {  returnToInitialDashboardDataState } from "redux/slices/dashboardDataSlice";
import { deviceFirstRenderDataHandler } from "redux/slices/deviceDataSlice";
import { 
  executiveDashboardFirstRenderDataHandler, 
  executiveElectricityDashboardDataHandler,
  executiveMachineDashboardDataHandler 
} from "redux/slices/executiveDashboardSlice";
import DashboardPageBoxedCollapseFullWidth from "../dashboardHelpers/DashboardPageBoxedCollapseFullWidth";
import DashboardHeader from "../dashboardHelpers/DashboardHeader";
import DashboardTabPanel from "../dashboardHelpers/DashboardTabPanel";
import getDeviceData from "../dashboardHelpers/getDeviceData";
import cognitoSignOut from "../../../../Account/LogIn/AwsCognito/cognitoSignOut";

export default function DashboardPage() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();

  const { currentFacility } = useSelector(
    (state) => state.facility.facilityState
  );
  const rtl = useSelector((state) => state.rtl);
  const { responseData: { historicalData } } = useSelector(
    (state) => state.dashboardData.dashboardDataState.dashboardData
  );

  // States that control the time range for rest api calls
  const { storeStartTime, storeEndTime } = useSelector((state) => state.timeData.timeState);

  // tenantId is the id of the tenant that is selected
  const tenantId = currentFacility.id;
  // facilityId is the id of the facility that is selected
  const facilityId = currentFacility.SK;

  // States that control loading and error states
  const [isLoading, setIsLoading] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);

  // Websocket states
  const [socket, setSocket] = useState(null);

  // This will be used to display the websocket connection status
  const [isWebSocketConnected, setIsWebSocketConnected] = useState(false);
  // State showing the current location of the user
  const [currentLocation, setCurrentLocation] = useState(null);

  // Code for error state modal
  // It is different than title and description
  const [code, setCodeHandler] = useState("");
  const [startErrorHandler, setStartErrorHandler] = useState(false);

  // Function to clean up the useEffect
  function cleanUpHandler(timerFunction) {
    setIsLoading(true);
    setIsLoaded(false);
    setStartErrorHandler(false);
    setCurrentLocation(null);
    clearTimeout(timerFunction);
  }

  // Error handler for the modal
  // Convert the code into string before dispatch
  function errorHandler(errorFromService = "dashboard") {
    const errorState = JSON.stringify({
      code,
    });
    setIsLoading(false);
    setIsLoaded(true);
    dispatch(
      cognitoErrorModalRenderHandler({
        errorFromService,
        errorState,
      })
    );
  }

  // Socket connection handler
  // This open a new socket when facilityId changed
  useEffect(() => {
    Auth.currentSession()
      .then((session) => session.getAccessToken().getJwtToken())
      .then((accessToken) => {
        const webSocketApi = new WebSocket(
          `${process.env.REACT_APP_WEBSOCKET_URL}?facilityId="${
            facilityId.split("#")[1]
          }"&Authorization=${accessToken}`
        );
        setSocket(webSocketApi);
      })
      .catch((error) => {
        errorHandler();
      });
  }, [facilityId]);

  // This snippet is showing a modal when the websocket is disconnected
  // It is setting the current location of the user as state
  // If the user is in the same location, but the websocket is disconnected,
  // it is showing the modal to inform the user about the disconnection
  // Then signout the user
  useEffect(() => {
    if (
      !isWebSocketConnected
      && currentLocation
      && currentLocation === location.pathname
    ) {
      const modalMessage = {
        name: t("modal.modalMessage.onSuccess.name.webSocketDisconnected"),
        message: t(
          "modal.modalMessage.onSuccess.message.webSocketDisconnected"
        ),
      };
      dispatch(cognitoPrimaryModalRenderHandler(modalMessage));
      // cognitoSignOut();
    };
  }, [isWebSocketConnected, currentLocation, location]);

  // Websocket data handler
  useEffect(() => {
    if (socket) {
      socket.onopen = () => {
        setIsWebSocketConnected(true);
        setCurrentLocation(location.pathname);
        // Set the current location of the user
      };

      socket.onmessage = (event) => {
        const eventData = JSON.parse(event.data);
        // This dispatch function is used for tracking the near real time data
        // dispatch(dashboardCurrentDataHandler(eventData));
        // TODO Show this alarm message on screen
        if (eventData.alarmId) {
          // TODO Semih
          // Alarm message
        } else {
          if (eventData["dataUtilizationCode"] === "electricity") {
            dispatch(executiveElectricityDashboardDataHandler(
              {
                descriptiveCode: eventData.descriptiveCode,
                dataUtilizationCode: eventData.dataUtilizationCode,
                timeIso: eventData.timeIso,
                ...(eventData.tariff1ImportActiveIndex ? { tariff1ImportActiveIndex: eventData.tariff1ImportActiveIndex } : {}),
                ...(eventData.averagePowerFactor ? { averagePowerFactor: eventData.averagePowerFactor } : {}),
              }
            ));
          } else if (eventData["dataUtilizationCode"] === "machine") {
              dispatch(executiveMachineDashboardDataHandler(eventData));
          } else {};
        }
      };
      
      socket.onclose = () => {
       // console.log("Websocket connection closed at DashboardLandingPage");
        setIsWebSocketConnected(false);
        // To clean the redux store and save some memory, the dispatch function to return to initial state is called
        // This will be triggered when the websocket is closed
        dispatch(returnToInitialDashboardDataState());
      };
    }
    return () => {
      if (socket) {
        socket.close();
        setCurrentLocation(null);
        resetModalHandler();
        dispatch(returnToInitialDashboardDataState());
      }
    };
  }, [socket, facilityId]);

  // Timeout handler for the modal
  function timeOutHandler() {
    setCodeHandler("DashboardTimeout");
    setStartErrorHandler(true);
  }

  // In case of error or time out
  // This snippet is starting the error handler function
  useEffect(() => {
    if (!isLoaded && startErrorHandler) {
      errorHandler();
    }
  }, [isLoaded, startErrorHandler]);
  // Fetch device data for the given facility
  useEffect(() => {
    // This snippet is stopping the code after expiry time
    const timer = setTimeout(() => timeOutHandler(), 30000);
    getDeviceData(tenantId, facilityId)
      .then((response) => {
        // Dispatch the API data to the redux store
        dispatch(deviceFirstRenderDataHandler(response.deviceList));
        if (response?.deviceFacilityValues && Object.keys(response.deviceFacilityValues).length > 0)
          dispatch(executiveDashboardFirstRenderDataHandler(response.deviceFacilityValues));
        if (!response) throw Error("No data for devices");
        // Set states to stop the loading spinner
        setIsLoading(false);
        setIsLoaded(true);
      })
      .catch((err) => {
        setCodeHandler("NoDataError");
        setStartErrorHandler(true);
      });
    
    return () => cleanUpHandler(timer);
  }, [
    tenantId, 
    facilityId,
  ]);

  // In case of error or time out
  // This snippet is starting the error handler function
  useEffect(() => {
    if (!isLoaded && startErrorHandler) {
      errorHandler();
    }
  }, [isLoaded, startErrorHandler]);

  return (
    <Fragment>
      {!isLoaded && <Loading transparentBackground loading={isLoading} />}
      <DashboardHeader />
      <Container className="dashboard">
        <DashboardPageBoxedCollapseFullWidth
          toggleTitle={currentFacility.unitName}
          toggleId={currentFacility.SK}
          toggleContactDetails={currentFacility.contactDetails}
  />
        <DashboardTabPanel dir={rtl.direction} dataSet={historicalData.utility.electricity}/>
      </Container>
    </Fragment>
  );
}
