import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Col, Row, Container } from "reactstrap";
const background = `${process.env.PUBLIC_URL}/img/landing/background/left_bg.png`;
export default function Header() {
  const { t, i18n } = useTranslation();
  const { lang } = useSelector((state) => state.lang);
  const img = `${process.env.PUBLIC_URL}/img/landing/sanaiinfo_dashboard_${lang}.png`;
  return (
    <section
      className="landing__header"
      style={{
        backgroundImage: `url(${background}`,
      }}
    >
      <Container>
        <Row className="landing__header-row-wrap">
          <Col lg={5}>
            <h2 className="landing__header-title">
              <b>{t("pages.landing.header.content.paragraph1")}</b>
            </h2>
            <h2 className="landing__header-title">
              <b>{t("pages.landing.header.content.paragraph2")}</b>
            </h2>
            <h2 className="landing__header-title">
              <b>{t("pages.landing.header.content.paragraph3")}</b>
            </h2>
            <h2 className="landing__header-title">
              <b>{t("pages.landing.header.content.paragraph4")}</b>
            </h2>
          </Col>
          <Col lg={7}>
            <div className="landing__demo-img-wrap">
              <img src={img} alt="sanaiinfo dashboard" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
