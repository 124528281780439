import { createSlice } from "@reduxjs/toolkit";

const initialLoadSlice = createSlice({
  name: "initialLoadStatus",
  initialState: {
    initialLoad: true,
  },
  reducers: {
    initialLoadHandler(state) {
      state.initialLoad = false;
    },
  },
});

export const { initialLoadHandler } = initialLoadSlice.actions;

export default initialLoadSlice.reducer;
