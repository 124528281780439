import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ReferenceLine
} from 'recharts';
// import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ReferenceLine } from 'recharts';
import Panel from 'shared/components/Panel';
import getTooltipStyles from 'shared/helpers';
import { DashboardAreaChartContainer } from '../BasicDashboardComponents';
import { FitScreen } from '@mui/icons-material';

// A React component should always have pure rendering logic. 
// This means that it must return the same output if its props, state, and context haven’t changed. 
// By using memo, you are telling React that your component complies with this requirement, 
// so React doesn’t need to re-render as long as its props haven’t changed
// TODO Check why memo is used in here. May be it is not necessary or better to use as useMemo
const DashboardAreaChartHandler = memo(function DashboardAreaChartHandler(
  {
    dir,
    objectKey,
    objectTitle,
    dataSet,
    chartSettings,
    subhead,
  }
) {
  const { t, i18n } = useTranslation();
  const themeName = useSelector(state => state.theme.className);

  return (
    <div dir="ltr">
      <Panel
        // xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
        title={objectTitle ? objectTitle : t(`technicalTerms.${objectKey}.extension`)}
        subhead={subhead ? subhead : t(`technicalTerms.${objectKey}.unit`)}
      >
        <div dir="ltr">
          <DashboardAreaChartContainer height={250}>

            <AreaChart data={dataSet} height={FitScreen} margin={{ top: 20, left: -15, bottom: 20 }}>

              <XAxis dataKey="time" tickLine={false} reversed={dir === 'rtl'} scale="auto" />
              <YAxis orientation={dir === 'rtl' ? 'right' : 'left'} scale='auto' interval={0} domain={['auto', 'auto']} />
              <Tooltip {...getTooltipStyles(themeName, 'defaultItems')} />
              <Legend />
              {chartSettings?.referenceLineHorizontal && <ReferenceLine x="Page C" stroke="red" label="Max PV PAGE" />}
              {chartSettings?.referenceLineVertical && <ReferenceLine y={chartSettings.referenceLineVertical.referenceValue} label={chartSettings.referenceLineVertical.referenceLabel} stroke="red" />}
              <CartesianGrid />
              {Object.keys(dataSet[0]).map((key) => {
                // We will ignore time attribute
                if (key !== "time") {
                  // We will not use the value as key
                  const chartKey = key === "value" ? objectKey : key;
                  const colorKey = key === "value" ? "defaultConfig" : key;

                  return <Area
                    key={key}
                    name={objectKey === "analog" ? objectTitle : t(`technicalTerms.${chartKey}.name`)}
                    type="monotone"
                    dataKey={key}
                    fill={chartSettings.chartColors[colorKey].fill}
                    stroke={chartSettings.chartColors[colorKey].stroke}
                    fillOpacity={0.2}
                    stackId={key}
                  />
                }
              })}
            </AreaChart>
          </DashboardAreaChartContainer>
        </div>
      </Panel>
    </div>
  );
});

export default DashboardAreaChartHandler;

DashboardAreaChartHandler.propTypes = {
  dir: PropTypes.string.isRequired,
  objectKey: PropTypes.string.isRequired,
  dataSet: PropTypes.array.isRequired, //dataSet: PropTypes.object.isRequired,
  /*
  Invalid prop `dataSet` of type `array` supplied to `DashboardAreaChartHandler`, expected `object`.
   */
  chartSettings: PropTypes.object.isRequired
}