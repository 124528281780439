import { Hub, Logger } from "aws-amplify";
import handleStoreOnSignIn from "../../../App/appHelpers/appHandlerHelpers";
import { returnToInitialUserState } from "redux/slices/userSlice";
import { loginStatusHandler } from "redux/slices/logInStatusSlice";
import { returnToInitialTenantState } from "redux/slices/tenantSlice";
import { returnToInitialFacilityState } from "redux/slices/facilitySlice";
import { returnToInitialDashboardDataState } from "redux/slices/dashboardDataSlice";
import { returnToInitialDeviceDataState } from "redux/slices/deviceDataSlice";
import { changeThemeToLight } from "redux/slices/themeSlice";
import { returnToInitialExecutiveDashboardDataState } from "redux/slices/executiveDashboardSlice";
import { returnToInitialDashboardConfigurationState } from "redux/slices/dashboardConfigurationSlice";
import { returnToSignOutState } from "redux/slices/timeSlice";


// AWS Amplify writes console logs through Logger
const logger = new Logger("sanaiinfoLogger");

export default async function SetAuthListener(dispatch) {
  try {
    // Amplify has a local eventing system called Hub.
    // It is a lightweight implementation of Publisher-Subscriber pattern,
    // and is used to share data between modules and components in your app
    Hub.listen("auth", (data) => {
      switch (data.payload.event) {
        case "signIn":
          // logger.info("user signed in");
          // Sometimes logger data is not appearing in the console
          // So we are using console.log as well
          // console.log("Listener sign in");
          try {
            handleStoreOnSignIn(dispatch);
          } catch (err) {
            // console.log(err);
          }
          break;
        case "signUp":
          // logger.info("user signed up");
          // console.log("user signed up");
          break;
        case "signOut":
          // Sometimes logger data is not appearing in the console
          // So we are using console.log as well
          // console.log("Listener Sign out");
          // logger.info("user signed out");
          // After logout, we clear the store and
          // return to initial user state
          dispatch(returnToInitialUserState());
          dispatch(returnToInitialTenantState());
          dispatch(returnToInitialFacilityState());
          dispatch(returnToInitialDashboardDataState());
          dispatch(returnToInitialDeviceDataState());
          dispatch(returnToInitialExecutiveDashboardDataState());
          dispatch(returnToInitialDashboardConfigurationState());
          dispatch(returnToSignOutState());
          dispatch(loginStatusHandler("formSignIn"));
          dispatch(changeThemeToLight());
          break;
        case "signIn_failure":
          logger.error("user sign in failed");
          break;
        case "tokenRefresh":
          logger.info("token refresh succeeded");
          break;
        case "tokenRefresh_failure":
          logger.error("token refresh failed");
          break;
        case "configured":
          logger.info("the Auth module is configured");
          break;
        default:
          logger.info("default state at auth listener");
          break;
      }
    });
  } catch (err) {
    throw new Error(err);
  }
}
