const minutesToHours = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const formattedHours = hours.toString().padStart(2, "0");
    const remainingMinutes = minutes % 60;
    const formattedMinutes = remainingMinutes.toString().padStart(2, "0");
    const minesOrPlus = minutes < 0 ? "-" : "+"
    return `${minesOrPlus}${formattedHours}:${formattedMinutes}`;
};
export const formatDate = (date) => {

    const formatedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}T${(date.getHours()).toString().padStart(2, '0')}:${(date.getMinutes()).toString().padStart(2, '0')}:${(date.getSeconds()).toString().padStart(2, '0')}${minutesToHours(-1 * date.getTimezoneOffset())}`

    return formatedDate

}