import React, { useEffect, Suspense, useState } from 'react';
import { API, graphqlOperation } from "aws-amplify";
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { updateDevice, deleteManualDevice, createManualDevice } from "graphql/mutations";

//Tabs
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import MuiStyleHandler from 'containers/Router/WrappedRoutes/Dashboard/dashboardHelpers/MuiStyleHandler';
import AddManualData from 'containers/Pages/ManualDataOperations/AddManualData';
import DeleteManualData from 'containers/Pages/ManualDataOperations/DeleteManualData';
export default function TabsManualData(props) {
  const { facilityState } = useSelector((state) => state.facility);
  const { children, index, ...other } = props;
  const [value, setValue] = useState(0); //TODO to default tab
  const { t, i18n } = useTranslation();
  const [choosenFacilityItem, setChoosenFacilityItem] = useState(null)



  function a11yProps(index) {
    return {
      id: `dashboard-tab-${index}`,
      'aria-controls': `dashboard-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`dashboard-tabpanel-${index}`}
        aria-labelledby={`dashboard-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
  }
  return (
    <MuiStyleHandler>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="sanaiinfo panel">
            <Tab label={t("pages.formItems.addNew")} {...a11yProps(0)} />
            <Tab label={t("pages.formItems.delete")} {...a11yProps(1)} />

          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Suspense fallback={<div>Loading...</div>}>
            <AddManualData />
          </Suspense>
         
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Suspense fallback={<div>Loading...</div>}>
            <DeleteManualData />
          </Suspense>
         
        </TabPanel>
        

      </Box>
    </MuiStyleHandler>
  )
}

