    import { createSlice } from '@reduxjs/toolkit';

const initialDashboardConfigurationState = {
    dashboardConfiguration: {
        historicalData: {
            utility: {
                electricity: {
                },
                water: {
                },
                naturalGas: {
                },
            },
            facility: {
            },
        },
        aggregateData: {
            utility: {
                electricity: {
                },
                water: {
                },
                naturalGas: {
                },
            },
            facility: {
            },
        }
    }
}

const dashboardConfigurationSlice = createSlice({
    name: "dashboardConfiguration",
    initialState: {
        dashboardConfigurationState: initialDashboardConfigurationState,
    },
    reducers: {
        dashboardConfigurationHandler(state, action) {
            let dashboardConfiguration = action.payload;

            // Reducers can only make copies of the original values, and then they can mutate the copies
            // This is safe, because we made a copy
            state.dashboardConfigurationState = {
                ...state.dashboardConfigurationState,
                dashboardConfiguration,
            };
        },

        returnToInitialDashboardConfigurationState(state) {
            state.dashboardConfigurationState = initialDashboardConfigurationState;
        }
    },
});

export const {
    dashboardConfigurationHandler,
    returnToInitialDashboardConfigurationState,
} = dashboardConfigurationSlice.actions;

export default dashboardConfigurationSlice.reducer;