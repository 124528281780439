import React, { useState, useEffect, Suspense } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ResponsiveContainer, PieChart, Pie, Text, Cell } from 'recharts';

import Panel from "shared/components/Panel";
import { Col, Row } from "react-bootstrap";
import { colorAdditional, colorRed } from "utils/palette";
import {
  DashboardStatChartWrap,
  DashboardStatLabel,
  DashboardStatWrap,
} from "../../BasicStatisticComponents";
import ExecutiveReportDashboard from '../ExecutiveReportDashboard/ExecutiveReportDashboard';
const ExecutiveDashboardElectricityDataHandler = () => {
  const { t, i18n } = useTranslation();
  const { devices } = useSelector((state) => state.deviceData.deviceDataState);
  const { electricity } = useSelector((state) => state.executiveDashboardData.executiveDashboardState);
  const { lang } = useSelector((state) => state.lang);
  const [panelList, setPanelList] = useState([]);
  useEffect(() => {
    if (Object.keys(electricity).length > 0) {
      setPanelList(() =>
        Object.keys(electricity).map(
          (electricityElement) => {
            // Find the device with the same descriptiveCode as the electricityElement
            const currentDevice = devices.filter(obj => obj.descriptiveCode === electricityElement);
            if (currentDevice.length > 0) {
              const {
                equipmentName,
              } = currentDevice[0];
              const {
                averagePowerFactor,
                dailyConsumptionElectricity,
                weeklyConsumptionElectricity,
                monthlyConsumptionElectricity
              } = electricity[electricityElement];
              return (
                <Panel
                  key={`${electricityElement}-panel`}
                  md={12}
                  lg={12}
                  xl={6}
                  sm={12} xs={12}
                  title={t("pages.dashboardPage.charts.executiveDashboardElectricity.title.consumption", { equipmentName })}
                >
                  <Row style={{ alignItems: "center", justifyContent: 'center' }}>
                    <Col >
                      <DashboardBudgetStatWrap >
                        <DashboardStatMain>
                          {currentDevice[0].equipmentCategory === "manualDataEntry" &&
                            <div style={{ textAlign: 'center', justifyContent: 'center', backgroundColor: 'Highlight' }}>
                              {t("pages.dashboardPage.charts.executiveDashboardElectricity.subTitle.manualDataEntry")}
                            </div>}
                          <DashboardStatMnTitle>{t("pages.dashboardPage.charts.executiveDashboardElectricity.subTitle.monthlyConsumption")}</DashboardStatMnTitle>
                          {currentDevice[0].equipmentCategory !== "manualDataEntry" ?

                            <div style={{marginTop:15}}>
                              <DashboardStatMainNumber>{monthlyConsumptionElectricity.toFixed(2)} </DashboardStatMainNumber>
                              <hr />
                            </div>
                            :
                            <div style={{ position: 'relative', width: '100%', height: 160, justifyContent: 'center' }}>
                              <ResponsiveContainer>
                                <PieChart>
                                  <Pie
                                    data={[{ name: 'Veri 1', value: 30 }]}
                                    dataKey="value"
                                    innerRadius="130%" // Yarım daire için
                                    outerRadius="150%"
                                    cy={110}
                                    startAngle={180} // 180 derece
                                    endAngle={0} // 180 derece
                                    paddingAngle={0}
                                    fill='orange'
                                  >

                                  </Pie>
                                </PieChart>
                              </ResponsiveContainer>
          
                              <div style={{ textAlign: 'center', justifyContent: 'center', marginTop: 20, marginTop: -80 }}>
                                {/*<DashboardStatMainNumber>{monthlyConsumptionElectricity.toFixed(2)}</DashboardStatMainNumber>*/}
                                <p style={{ fontSize: monthlyConsumptionElectricity.toFixed(2).length < 10 ? 30 : 24 }}>{monthlyConsumptionElectricity.toFixed(2)}</p>

                              </div>
                            </div>

                          }
                        </DashboardStatMain>

                        <DashboardStatData>
                          <div>
                            <DashboardStatDataNumber>{dailyConsumptionElectricity.toFixed(2)}</DashboardStatDataNumber>
                            <span style={{ color: "#64677b" }}>{t("pages.dashboardPage.charts.executiveDashboardElectricity.subTitle.dailyConsumption")}</span>
                          </div>
                          <div>
                            <DashboardStatDataNumber>{weeklyConsumptionElectricity.toFixed(2)}</DashboardStatDataNumber>
                            <span style={{ color: "#4ce1b6" }}>{t("pages.dashboardPage.charts.executiveDashboardElectricity.subTitle.weeklyConsumption")}</span>
                          </div>
                        </DashboardStatData>
                      </DashboardBudgetStatWrap>
                    </Col>
                    {currentDevice[0].equipmentCategory !== "manualDataEntry" ?

                      <Col md={7} >
                        <ExecutiveReportDashboard
                          // key={`${deviceElement.descriptiveCode}-powerFactor}`}
                          // valueToRender={parseFloat(averagePowerFactor).toFixed(2)}
                          valueToRender={averagePowerFactor}
                          equipmentName={equipmentName}
                        />
                      </Col> :
                      <div style={{ textAlign: 'center', justifyContent: 'center', marginTop: 20 }}>
                        {t("pages.dashboardPage.charts.executiveDashboardElectricity.date.lastUpdateDate")} : {new Date(electricity[electricityElement].timeIso).toLocaleString([], { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: false })}
                      </div>}
                  </Row>
                </Panel>
              );
            }
          }
        )
      );
    }
  }, [lang, electricity, devices]);
  return <>{panelList}</>;
};
export default ExecutiveDashboardElectricityDataHandler;
// region STYLES
const DashboardBudgetStatWrap = styled(DashboardStatWrap)`
  text-align: center;
  flex-wrap: wrap;

  ${DashboardStatChartWrap} {
    margin: auto;
  }

  ${DashboardStatLabel} {
    font-size: 36px;
  }
`;

const DashboardStatMain = styled.div`
  width: 100%;

  hr {
    margin-bottom: 30px;
    margin-top: 40px;
  }
`;

const DashboardStatMnTitle = styled.p`
  color: ${colorAdditional};
`;

const DashboardStatMainNumber = styled.p`
  color: ${colorRed};
  font-size: 48px;
  line-height: 34px;
  margin-top: 15px;
`;

const DashboardStatData = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-top: 10px;

  p {
    margin: 0;
  }
`;

const DashboardStatDataNumber = styled.p`
  font-size: 18px;
  line-height: 34px;
  font-weight: 500;
`;

