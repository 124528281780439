import { createSlice } from "@reduxjs/toolkit";

const initialTenantState = {
    id: null,
    organizationId: null,
    brokerId: null,
    tenantName: null,
    tenantTier: null,
    activeTenant: false,
    activeUserIds: [],
    allowedActiveUserNumber: null,
    cookies: {},
    ownerId: null,
    invoice: {},
    facilities: {},
    users: {}
};

const tenantSlice = createSlice({
    name: "tenant",
    initialState: {
        tenantState: initialTenantState,
    },
    reducers: {
        tenantDataHandler(state, action) {
            let tenantData = action.payload;

            state.tenantState = {
                ...state.tenantState,
                ...tenantData,
            };
        },

        returnToInitialTenantState(state) {
            state.tenantState = initialTenantState;
        },
    },
});

export const { tenantDataHandler, returnToInitialTenantState } = tenantSlice.actions;

export default tenantSlice.reducer;