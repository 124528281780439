import React, { Fragment, useState, useContext, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import Loading from "shared/components/Loading";
import Topbar from "../../Layout/topbar/Topbar";
import EmailHandler from "./components/inputFields/EmailFieldHandler";
import PasswordFieldHandler from "./components/inputFields/PasswordField";
import ForgotPasswordHandler from "./components/ForgotPasswordHandler";
// import RememberMeCheckBox from "./components/RememberMeField";
import CreateUserButton from "./components/inputFields/CreateUserButtonField";
import LogInFormSharedFields from "./components/SharedFieldsLogin";
import CognitoSignIn from "./AwsCognito/CognitoSignIn";
import { loginStatusHandler } from "redux/slices/logInStatusSlice";
import PasswordContext from "context/PasswordContext";
import { cognitoErrorModalRenderHandler } from "redux/slices/modalSlice";
import { timePickerDataHandler } from "redux/slices/timeSlice";

export default function SignIn() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    currentPasswordValue,
    passwordFormTypeHandler,
    passwordIsValid,
    resetContext,
  } = useContext(PasswordContext);
  const { email } = useSelector((state) => state.user.userState);
  const { logIn } = useSelector((state) => state.login);
  // Async fonksiyonlar dursun
  const [isLoading, setIsLoading] = useState(false);
  // Show warning message for invalid password field
  const [showPasswordWarningMessage, setShowPasswordWarningMessageHandler] =
    useState(false);
  // Show warning message for invalid email field
  const [showEmailWarningMessage, setShowEmailWarningMessageHandler] =
    useState(false);
  // Check validity of email and password
  // trim is used to get rid of whitespaces
  // To be valid, email should include "@" and not be empty
  const emailIsInvalid = email.trim() === "" || !email.includes("@");
  const currentPasswordIsValid = passwordIsValid(currentPasswordValue);
  // This variable will be used for the localization of modal messages
  const errorFromService = "cognito";
  function formStateDispatchHandler(formStateName) {
    dispatch(loginStatusHandler(formStateName));
  }
  async function handleSubmit() {
    if (emailIsInvalid) setShowEmailWarningMessageHandler(true);
    // Warning message is shown only after the click
    if (!currentPasswordIsValid) setShowPasswordWarningMessageHandler(true);
    // Set the password that will be passed to AWS Cognito
    const cognitoPassword = currentPasswordIsValid && currentPasswordValue;
    // Sign in starts only after email and passwords are valid
    if (cognitoPassword && !emailIsInvalid) {
      setIsLoading(true);
      await CognitoSignIn(
        email,
        cognitoPassword,
        formStateDispatchHandler,
        passwordFormTypeHandler,
        resetContext
      )
        .catch((err) => {
          dispatch(
            cognitoErrorModalRenderHandler({
              errorFromService,
              errorState: err.message,
            })
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setShowPasswordWarningMessageHandler(true);
    }
  }
  function handleEnterKeyDown(keyEvent) {
    // Modal also has enter key down feature
    // If modal is open, it will have priority
    if (keyEvent.key === "Enter") {
      handleSubmit();
    }
  }
  useEffect(() => { if (logIn === "formChangePassword") navigate("/password") }, [logIn]);
  useEffect(() => {
    dispatch(timePickerDataHandler("last1Hour"));
    // If you want to add an event callback but have it run only once
    // Use the once option in the method's options object
    window.addEventListener("keydown", (event) => handleEnterKeyDown(event), {
      once: true,
    });
    // cleanup this component
    return () => {
      window.removeEventListener("keydown", (event) =>
        handleEnterKeyDown(event)
      );
    };
  }, [email, currentPasswordValue]);
  return (
    <Fragment>
      {isLoading && <Loading loading={isLoading} />}
      <Topbar />
      <LogInFormSharedFields>
        <form className="form">
          <EmailHandler
            renderName={t("auth.emailFieldRenderName")}
            placeholder={t("auth.emailFieldPlaceholder")}
            showWarningMessage={showEmailWarningMessage}
            showWarningMessageHandler={setShowEmailWarningMessageHandler}
            invalidFieldMessage={t("email.emailFieldEmptyWarningMessage")}
          />
          <PasswordFieldHandler
            renderName={t("password.password")}
            placeholder={t("password.password")}
            showWarningMessage={showPasswordWarningMessage}
            showWarningMessageHandler={setShowPasswordWarningMessageHandler}
          >
            <ForgotPasswordHandler />
          </PasswordFieldHandler>
          {/* If RememberMeCheckBox is uncommented, 
                  remove the following div field */}
          <div className="form__form-group" />
          {/* <RememberMeCheckBox /> */}
          <Button
            className="btn btn-primary account__btn account__btn--small"
            color="primary"
            onClick={() => handleSubmit()}
          >
            {t("auth.signInButton")}
          </Button>
        </form>
      </LogInFormSharedFields>
    </Fragment>
  );
}
