import React, { useState, useEffect } from 'react';
import { Auth } from "aws-amplify";
import { API, graphqlOperation } from "aws-amplify";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import DownIcon from "mdi-react/ChevronDownIcon";

import {
    cognitoErrorModalRenderHandler,
    cognitoColoredWarningModalRenderHandler,
    cognitoColoredSuccessModalRenderHandler,
    resetModalHandler
} from "redux/slices/modalSlice";
import {
    userDataHandler
} from 'redux/slices/userSlice';
import renderSelectField from "shared/components/form/Select"
import { useLocation } from 'react-router-dom'
import Collapse from "shared/components/Collapse";
import { getUsersByTenantId } from "graphql/queries";
// easydev imports
import { Field, Form } from 'react-final-form';
import {
    Card, CardBody, Col, Button, Table
} from 'reactstrap';
import userMutationHandler from '../settingsHelpers/userMutationHandler';
import Loading from "shared/components/Loading";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { statusColored } from 'functions/UserStatusColored';
export default function UserSettings() {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();
    const { lang } = useSelector((state) => state.lang);
    const userData = useSelector((state) => state.user.userState);
    const themeData = useSelector((state) => state.theme.className);
    const tenantData = useSelector((state) => state.tenant.tenantState);
    const { localeLang } = useSelector((state) => state.lang);
    const [userTierInitialState, setUserTierInitialState] = useState(null);
    const [usingUserData, setUsingUserData] = useState(null)
    const [complateFunc, setComplateFunc] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [profileOrSettingsPath, setProfileOrSettingsPath] = useState()
    const [userList, setUserList] = useState([]);
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [formItemsErrors, setFormItemsErrors] = useState({})
    const [givenNameInputError, setGivenNameInputError] = useState(false)
    const [familyNameInputError, setFamilyNameInputError] = useState(false)
    const [brokerCanAdd, setBrokerCanAdd] = useState(false)
    const languageButtonContent = (lang) => {
        const flagPath = `${process.env.PUBLIC_URL}/img/language/${lang}.png`;
        const i18nTranslationKey = `language.${lang}`;
        return (
            <span className="topbar__language-btn-title">
                <img src={flagPath} alt={lang} />
                <span>{t(i18nTranslationKey)}</span>
            </span>
        );
    };
    const userLang = useSelector((state) => state.lang.lang);
    const [choosenLang, setChoosenLang] = useState(userLang === "tr" ?
        { value: 'tr', label: languageButtonContent("tr") } :
        { value: 'en', label: languageButtonContent("en") }
    )

    const [isCollapsed, setIsCollapsed] = useState(false);
    const userStatusOptions = [
        { value: 'owner', label: t("userStatus.owner") },
        { value: 'admin', label: t("userStatus.admin") },
        { value: 'manager', label: t("userStatus.manager") },
        { value: 'user', label: t("userStatus.user") },
        { value: 'viewer', label: t("userStatus.viewer") },
        { value: 'operator', label: t("userStatus.operator") },
    ];


    const header = [
        { id: 1, title: t("userListHeaders.givenName") },
        { id: 2, title: t("userListHeaders.familyName") },
        { id: 3, title: t("userListHeaders.eMail") },
        { id: 4, title: t("userListHeaders.status") },
        { id: 5, title: t("pages.formItems.delete") },

    ];


    const languageOptions = [
        { value: 'tr', label: languageButtonContent("tr") },
        { value: 'en', label: languageButtonContent("en") },

    ];
    useEffect(() => {
        console.log("location", location.state)
    }, [])
    // Function to query the users list for the tenant
    async function queryTenantUsersHandler() {
        // For security reasons, the attributes are retrieved directly from Amplify
        const { attributes } = await Auth.currentAuthenticatedUser();
        // API call to query users data
        const graphQlDataAtQueryTenantUsers = await API.graphql(
            graphqlOperation(
                getUsersByTenantId, {
                tenantId: location.state?.choosenTenantData?.value?.id ? location.state?.choosenTenantData?.value?.id : attributes["custom:tenantId"],
                subId: attributes.sub
            })
        ).catch((err) => {
            throw new Error(err.errors[0]);
        });

        // Return the list of users registered to the tenant
        return graphQlDataAtQueryTenantUsers.data.getUsersByTenantId;
    };

    // We left it as it is because we can use in the future
    async function updateUserByAdminHandler(userAttributes) {/*
    // Create the parameters for the update operation
    const updateOperationInput = {
        // Id should be fetched from the redux store
        ...userAttributes
    };

    // Id and SK are required for the database query
    updateOperationInput.id = tenantData.id;
    updateOperationInput.SK = tenantData.id;

    // We need only the value of tenant tier. Label should be removed
    if (userAttributes.userTier) {
        // updateOperationInput.userTier = userAttributes.userTier.value;
    };

    // API call to update tenant data
    await API.graphql(
        graphqlOperation(
        updateTenant, {
        input: updateOperationInput
        })
    ).catch((err) => {
        throw new Error(err.errors[0]);
    });

    // Return the updated attributes for Redux store update
    return updateOperationInput;
    */};
    const onSubmit = async (dataOnSubmit) => {
        const errors = {}

        if (!dataOnSubmit.eMail) {
            errors.eMail = t("pages.formItems.emptyFieldError");
        }
        if (!dataOnSubmit.givenName) {
            errors.givenName = t("pages.formItems.emptyFieldError");
        }
        if (!dataOnSubmit.familyName) {
            errors.familyName = t("pages.formItems.emptyFieldError");
        }
        if (!phoneNumber) {
            errors.phoneNumber = t("pages.formItems.emptyFieldError");
        }
        //console.log("phoneNumber --", phoneNumber)
        if (!userTierInitialState && location.state?.profileOrSettings == null) {
            errors.userTier = t("pages.formItems.emptyFieldError");
        }
        if (validateEmail(dataOnSubmit.eMail) == false) {
            const warningMessage = {
                name: t(
                    "modal.modalMessage.onSuccess.name.failedToCreateEquipment"
                ),
                message: t("pages.settings.errors.emailFormatError"),
            };
            dispatch(cognitoColoredWarningModalRenderHandler(warningMessage));
            return null
        }
        setFormItemsErrors(errors)
        if (Object.keys(errors).length === 0) {
            // console.log("From error list empty")
        } else {
            // console.log("From error list not empty")
            return
        }
        setIsLoading(true)
        // Dummy data for testing
        // console.log(dataOnSubmit)
        // If you want to create a user, use this payload
        /* */
        const userCreatedBy = userData.subId;
        if (usingUserData?.SK || location.state?.profileOrSettings != null) { //Update user
            // If you want to update a user, use this payload

            const dataUpdateUserByAdmin = {
                // Required attributes
                "id": usingUserData?.id ? usingUserData.id : userData.id,
                "SK": usingUserData?.SK ? usingUserData.SK : userData.SK,
                "userName": userData.userName,
                "givenName": dataOnSubmit.givenName,
                "familyName": dataOnSubmit.familyName,
                "locale": choosenLang.value === "tr" ? "tr-TR" : "en-En",
                phoneNumber: phoneNumber,
                userStatus: userTierInitialState?.value ? userTierInitialState.value : userData.userStatus
            };
            updateUserData(userCreatedBy, dataUpdateUserByAdmin)
        } else {//Create new user
            const dataCreateUser = {
                email: dataOnSubmit.eMail,
                "familyName": dataOnSubmit.familyName,
                "givenName": dataOnSubmit.givenName,
                // "phoneNumber": dataOnSubmit.phoneNumber,
                phoneNumber: "+" + phoneNumber,
                //"locale": "tr-TR",  // FIXME This data should be fetched automatically from the state

                // FIXME locale data should be fetched automatically from the state
                // State should be changed by a useEffect function
                "locale": choosenLang.value === "tr" ? "tr-TR" : "en-En",
                // "timezone": "Europe/Istanbul",  
                "timezone": Intl.DateTimeFormat().resolvedOptions().timeZone,
                "id": tenantData.id,
                userStatus: userTierInitialState.value
            };
            createNewUser(userCreatedBy, dataCreateUser)
        }
        /*       try {
                   // Şifre: Sanai123
                   // Available mutation types can be "createUser", "updateUserByAdmin" or "deleteUser"
                   // If you want to create a user, use this function
                   // userMutationHandler("createUser", userCreatedBy, dataCreateUser)  // Create user
                   // If you want to delete a user, use this function
                   //userMutationHandler("deleteUser", userCreatedBy, dataDeleteUser)  // Delete user
                   // If you want to update a user, use this function
                   userMutationHandler("updateUserByAdmin", userCreatedBy, dataUpdateUserByAdmin)  // Update user
                       .then((updatedAttribures) => {
                           if (location.state != null && userData.userStatusInt >= 5) {
                               const { id, SK, userName, ...rest } = dataUpdateUserByAdmin;
                               console.log("rest object at userSettings: ", rest);
                               dispatch(userDataHandler(rest));
       
       
                           }
                           // const updatedUserData = [...userData,givenName:dataUpdateUserByAdmin]
       
                           // dispatch(userDataHandler(updatedAttribures));
       
       
                           setUsingUserData(null)
       
                       })
                       .then(() => {
                           setIsLoading(false)
                           // dispatch(userDataHandler(emailState));
       
                           const successMessage = {
                               name: t(
                                   "modal.modalMessage.onSuccess.name.attributeUpdateSuccess"
                               ),
                               message: t(
                                   "modal.modalMessage.onSuccess.message.attributeUpdateSuccess"
                               ),
                               acceptFunc: () => setComplateFunc(!complateFunc),
                               rejectFunc: () => setComplateFunc(!complateFunc)
                           };
                           dispatch(cognitoColoredSuccessModalRenderHandler(successMessage))
                           setPhoneNumber(null)
       
                       })
               } catch (err) {
                   setIsLoading(false)
       
                   const errorState = {
                       name: "updateError",
                       code: "updateError"
                   };
       
                   // In case of an error, user is informed by an error message
                   dispatch(
                       cognitoErrorModalRenderHandler({
                           errorFromService: "apiOperation",
                           errorState: JSON.stringify(errorState)
                       })
                   );
               }*/

    };

    const validateEmail = (input) => {
        // to validate email format
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
        return emailPattern.test(input);
    };

    const askToDelete = (choosenUserData) => {//It is used to obtain confirmation before deletion.

        const ownerCount = userList.filter(userListItem => userListItem.userStatus === "owner").length;

        if (choosenUserData.userStatus === "owner" && ownerCount < 2) {
            const successMessage = {
                name: t(
                    "modal.modalMessage.onSuccess.name.cannotDeleteOwner"
                ),
                message: t(
                    "modal.modalMessage.onSuccess.message.cannotDeleteOwner"
                ),
            };
            dispatch(cognitoColoredWarningModalRenderHandler(successMessage));

        } else {
            const successMessage = {
                name: t(
                    "modal.modalMessage.onSuccess.name.areYouSureToDelete"
                ),
                message: t(
                    "modal.modalMessage.onSuccess.message.areYouSureToDelete"
                ),
                acceptFunc: () => deleteUser(choosenUserData),
            };
            dispatch(cognitoColoredWarningModalRenderHandler(successMessage));
        }
    }

    const deleteUser = (choosenUserData) => {
        setIsLoading(true)
        // If you want to delete a user, use this payload
        const dataDeleteUser = {
            "userName": choosenUserData.userName,
            "id": choosenUserData.id,
            "SK": choosenUserData.SK
        };
        const userCreatedBy = userData.subId;
        try {
            // Available mutation types can be "createUser", "updateUserByAdmin" or "deleteUser"
            // If you want to delete a user, use this function
            userMutationHandler("deleteUser", userCreatedBy, dataDeleteUser)  // Delete user
                .then((updatedAttribures) => {
                    setUsingUserData(null)
                    //dispatch(userDataHandler(updatedAttribures));
                })
                .then(() => {
                    setIsLoading(false)
                    const successMessage = {
                        name: t(
                            "modal.modalMessage.onSuccess.name.attributeDeleteSuccess"
                        ),
                        message: t(
                            "modal.modalMessage.onSuccess.message.attributeDeleteSuccess"
                        ),
                        acceptFunc: () => setComplateFunc(!complateFunc),
                        rejectFunc: () => setComplateFunc(!complateFunc)
                    };
                    dispatch(cognitoColoredSuccessModalRenderHandler(successMessage));
                })
        } catch (err) {
            setIsLoading(false)
            const errorState = {
                name: "updateError",
                code: "updateError"
            };
            // In case of an error, user is informed by an error message
            dispatch(
                cognitoErrorModalRenderHandler({
                    errorFromService: "apiOperation",
                    errorState: JSON.stringify(errorState)
                })
            );
        }
    }
    const createNewUser = (userCreatedBy, dataCreateUser) => {// Create new user
        try {
            userMutationHandler("createUser", userCreatedBy, dataCreateUser)
                .then((updatedAttribures) => {
                    setUsingUserData(null)
                })
                .then((res) => {
                    setIsLoading(false)
                    const successMessage = {
                        name: t(
                            "modal.modalMessage.onSuccess.name.attributeUpdateSuccess"
                        ),
                        message: t(
                            "modal.modalMessage.onSuccess.message.attributeUpdateSuccess"
                        ),
                        acceptFunc: () => setComplateFunc(!complateFunc),
                        rejectFunc: () => setComplateFunc(!complateFunc)
                    };
                    dispatch(cognitoColoredSuccessModalRenderHandler(successMessage))
                    setPhoneNumber(null)


                })
        } catch (err) {
            setIsLoading(false)

            const errorState = {
                name: "updateError",
                code: "updateError"
            };

            // In case of an error, user is informed by an error message
            dispatch(
                cognitoErrorModalRenderHandler({
                    errorFromService: "apiOperation",
                    errorState: JSON.stringify(errorState)
                })
            );
        }

    }
    const updateUserData = async (userCreatedBy, dataUpdateUserByAdmin) => {
        try {
            userMutationHandler("updateUserByAdmin", userCreatedBy, dataUpdateUserByAdmin)  // Update user
                .then((updatedAttribures) => {
                    if (location.state?.profileOrSettings != null) {
                        const { id, SK, userName, ...rest } = dataUpdateUserByAdmin;
                        dispatch(userDataHandler(rest));
                    }
                    setUsingUserData(null)
                    setUserTierInitialState(null)
                })
                .then(() => {
                    setIsLoading(false)
                    const successMessage = {
                        name: t(
                            "modal.modalMessage.onSuccess.name.attributeUpdateSuccess"
                        ),
                        message: t(
                            "modal.modalMessage.onSuccess.message.attributeUpdateSuccess"
                        ),
                        acceptFunc: () => setComplateFunc(!complateFunc),
                        rejectFunc: () => setComplateFunc(!complateFunc)
                    };
                    dispatch(cognitoColoredSuccessModalRenderHandler(successMessage))
                    setPhoneNumber(null)
                })
        } catch (err) {
            setIsLoading(false);
            const errorState = {
                name: "updateError",
                code: "updateError"
            };

            // In case of an error, user is informed by an error message
            dispatch(
                cognitoErrorModalRenderHandler({
                    errorFromService: "apiOperation",
                    errorState: JSON.stringify(errorState)
                })
            );
        }
    };

    const selectDataToUpdate = (selectedData) => {
        switch (selectedData.userStatus) {
            case 'owner':
                setUserTierInitialState(userStatusOptions[0])
                break;
            case 'admin':
                setUserTierInitialState(userStatusOptions[1])
                break;

            case 'manager':
                setUserTierInitialState(userStatusOptions[2])
                break;

            case 'user':
                setUserTierInitialState(userStatusOptions[3])
                break;
            case 'viewer':
                setUserTierInitialState(userStatusOptions[4])
                break;
            case 'operator':
                setUserTierInitialState(userStatusOptions[5])
                break;
            default:
                break;
        }
        setUsingUserData(selectedData)
        setChoosenLang(selectedData.locale == "tr-TR" ?
            { value: 'tr', label: languageButtonContent("tr") } :
            { value: 'en', label: languageButtonContent("en") })
    }
    // Fetch the full list of users for the tenant
    useEffect(() => {
        // Only certain level of users can fetch the list of users
        if (location.state?.profileOrSettings !== null && userData.userStatusInt > 1)  {
            setUsingUserData(userData)
            setBrokerCanAdd(true)

        }
        if (userData.userStatusInt >= 5) {
            queryTenantUsersHandler()
                .then((arrayOfUsers) => {
                    setUserList(arrayOfUsers);
                    if (location.state?.choosenTenantData?.value) {
                        setUserTierInitialState(userStatusOptions[0])
                        if (arrayOfUsers.length === 0) {
                            setBrokerCanAdd(true)
                        } else {
                            setBrokerCanAdd(false)
            
                        }
                    } else {
                        setBrokerCanAdd(true)
                    }
                })
                .catch((errorQueryTenantUsers) => {
                    // In case of an error, user is informed by an error message
                    // For security reasons, the error message is displayed as "Something went wrong"
                    dispatch(
                        cognitoErrorModalRenderHandler({
                            errorFromService: "apiOperation",
                            errorState: JSON.stringify(errorQueryTenantUsers)
                        })
                    );
                });
        };
        // Clean up function
        return () => {
            setUserList([]);
            dispatch(resetModalHandler());
            setIsLoading(false)

            setPhoneNumber(null)
        }
    }, [userData.userStatusInt, complateFunc]);
    useEffect(() => {
        if (location.state?.profileOrSettings != null) {
            setProfileOrSettingsPath(`pages.settings.settings.${location.state.profileOrSettings}`); //profileSettings
            setUsingUserData(userData)
            switch (usingUserData?.userStatus) {
                case 'owner':
                    setUserTierInitialState(userStatusOptions[0])
                    break;
                case 'admin':
                    setUserTierInitialState(userStatusOptions[1])
                    break;

                case 'manager':
                    setUserTierInitialState(userStatusOptions[2])
                    break;

                case 'user':
                    setUserTierInitialState(userStatusOptions[3])
                    break;
                case 'viewer':
                    setUserTierInitialState(userStatusOptions[4])
                    break;
                case 'operator':
                    setUserTierInitialState(userStatusOptions[5])
                    break;
                default:
                    break;
            }
        } else {
            setProfileOrSettingsPath("pages.settings.settings.user") //userSettings
        }
    }, [location.state, localeLang]);
    useEffect(() => {
        if (usingUserData?.phoneNumber) {
            setPhoneNumber(usingUserData?.phoneNumber)

        } else {
            setPhoneNumber(null)
        }
    }, [usingUserData?.phoneNumber])
    useEffect(() => {//It is used to determine the user's role.
        switch (userTierInitialState?.value) {
            case 'owner':
                setUserTierInitialState(userStatusOptions[0])
                break;
            case 'admin':
                setUserTierInitialState(userStatusOptions[1])
                break;

            case 'manager':
                setUserTierInitialState(userStatusOptions[2])
                break;

            case 'user':
                setUserTierInitialState(userStatusOptions[3])
                break;
            case 'viewer':
                setUserTierInitialState(userStatusOptions[4])
                break;
            case 'operator':
                setUserTierInitialState(userStatusOptions[5])
                break;
            default:
                break;
        }

    }, [localeLang])


    return (
        <div style={{ marginLeft: 20, marginRight: 20 }}>
            {isLoading ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100px' }}><Loading transparentBackground loading={isLoading} /></div> :
                <Col md={12} lg={12}>
                    <Card>
                        <CardBody>
                            <div className="card__title">
                                <h5 className="bold-text">{t("pages.settings.settings.user")}</h5>
                            </div>
                            {brokerCanAdd ?
                                <div>
                                    <div>
                                        {userList.length < tenantData.allowedActiveUserNumber || usingUserData ?
                                            <Form onSubmit={onSubmit}
                                                validate={(values) => {
                                                    const errors = formItemsErrors;

                                                    if (values?.givenName && values.givenName?.length > 19) {
                                                        errors.givenName = t("pages.settings.errors.maxLengthError")

                                                    } else {
                                                        errors.givenName = null
                                                    }
                                                    if (values?.familyName && values?.familyName.length > 19) {
                                                        errors.familyName = t("pages.settings.errors.maxLengthError")
                                                    } else {
                                                        errors.familyName = null
                                                    }
                                                    if (formItemsErrors !== errors) {
                                                        setFormItemsErrors({ ...formItemsErrors, errors })
                                                        return errors

                                                    }

                                                }}>
                                                {({ handleSubmit, form }) => (
                                                    <form className="form form--horizontal" onSubmit={handleSubmit}>
                                                        {location.state?.profileOrSettings && <div className="form__form-group">
                                                            <span className="form__form-group-label">ID</span>
                                                            <div className="form__form-group-field">
                                                                <Field
                                                                    name="id"
                                                                    component="input"
                                                                    type="text"
                                                                    disabled={location.state?.profileOrSettings == null ? false : true}
                                                                    initialValue={usingUserData?.subId}
                                                                />
                                                            </div>
                                                        </div>}
                                                        <div className="form__form-group">
                                                            <span className="form__form-group-label">{t("pages.formItems.eMail")}</span>
                                                            <div className="form__form-group-field" style={{ display: 'block' }}>
                                                                <Field
                                                                    name="eMail"
                                                                    component="input"
                                                                    type="text"
                                                                    placeholder={t("pages.formItems.eMail")}
                                                                    //disabled={location.state == null ? false : true}
                                                                    disabled={usingUserData?.email == null ? false : true}
                                                                    initialValue={usingUserData?.email}
                                                                />
                                                                {formItemsErrors?.eMail && <span className="form__form-group-error">{formItemsErrors.eMail}</span>}


                                                            </div>
                                                        </div>

                                                        <div className="form__form-group">
                                                            <span className="form__form-group-label">{t("pages.formItems.name")}</span>
                                                            <div className="form__form-group-field" style={{ display: 'block' }}>
                                                                <Field
                                                                    name="givenName"
                                                                    component="input"
                                                                    type="text"
                                                                    placeholder={t("pages.formItems.name")}
                                                                    initialValue={usingUserData?.givenName}
                                                                    maxLength={20}

                                                                />
                                                                {formItemsErrors?.givenName && <span className="form__form-group-error">{formItemsErrors.givenName}</span>}

                                                            </div>
                                                        </div>
                                                        <div className="form__form-group">
                                                            <span className="form__form-group-label">{t("pages.formItems.familyName")}</span>
                                                            <div className="form__form-group-field" style={{ display: 'block' }}>
                                                                <Field
                                                                    name="familyName"
                                                                    component="input"
                                                                    type="text"
                                                                    placeholder={t("pages.formItems.familyName")}
                                                                    initialValue={usingUserData?.familyName}
                                                                    maxLength={20}

                                                                />
                                                                {formItemsErrors?.familyName && <span className="form__form-group-error">{formItemsErrors.familyName}</span>}
                                                                {familyNameInputError && <span className="form__form-group-error">{t("pages.settings.errors.maxLengthError")}</span>}

                                                            </div>
                                                        </div>
                                                        <div className="form__form-group">
                                                            <span className="form__form-group-label">{t("pages.formItems.phoneNumber")}</span>
                                                            <div className="form__form-group-field" style={{ display: 'block' }}>
                                                                {/* <Field
                                                        name="phoneNumber"
                                                        component={renderMaskedField}
                                                        type="text"
                                                        mask={['0', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                                        className="phone-input"
                                                        initialValue={usingUserData?.phoneNumber}
                                    />*/}
                                                                <PhoneInput
                                                                    name="phoneNumber"
                                                                    // country={lang == 'tr' && 'tr'}
                                                                    value={phoneNumber}
                                                                    onChange={setPhoneNumber}
                                                                    enableSearch={true}
                                                                    disableSearchIcon={true}
                                                                    inputStyle={{ display: 'flex', width: '100%', backgroundColor: themeData === "theme-dark" && "#232329", color: themeData === "theme-dark" && "#dddddd" }}
                                                                    buttonStyle={themeData === "theme-dark" ? { backgroundColor: "#232329", color: "#dddddd" } : null}
                                                                />
                                                                {formItemsErrors?.phoneNumber && <span className="form__form-group-error">{formItemsErrors.phoneNumber}</span>}
                                                            </div>
                                                        </div>
                                                        {location.state?.profileOrSettings == null && userData.userStatusInt >= 5 &&
                                                            <div className="form__form-group">
                                                                <span className="form__form-group-label">{t("pages.formItems.userTier")}</span>
                                                                <div className="form__form-group-field" style={{ display: 'block' }}>
                                                                    {/*    <Field
                                                                name="userTier"
                                                                component={renderSelectField}
                                                                options={userStatusOptions}
                                                                initialValue={userTierInitialState}
                                                                placeholder={t("pages.formItems.userTier")}
                                                                />*/}
                                                                    <Select
                                                                        name="userTier"
                                                                        value={userTierInitialState}
                                                                        onChange={setUserTierInitialState}
                                                                        options={userStatusOptions}
                                                                        clearable={false}
                                                                        isDisabled={!location.state?.choosenTenantData?.value ? false : true}
                                                                        className="react-select"
                                                                        placeholder={t("pages.formItems.userTier")}
                                                                        styles={{
                                                                            control: (provided) => ({
                                                                                ...provided,
                                                                                backgroundColor: themeData === "theme-dark" && "#232329",

                                                                            }),
                                                                            singleValue: (provided) => ({
                                                                                ...provided,
                                                                                color: themeData === "theme-dark" && "#dddddd",
                                                                            }),
                                                                            /*option: (provided) => ({
                                                                                ...provided,
                                                                                color: "white",
                                                                              }),
                                                                              menu: (provided) => ({
                                                                                ...provided,
                                                                                backgroundColor: "black",
                                                                              }),*/
                                                                        }}
                                                                    //classNamePrefix={!disabled ? "react-select" : "disabled"}
                                                                    //ref={ref}
                                                                    //isDisabled={disabled}
                                                                    />
                                                                    {formItemsErrors?.userTier && <span className="form__form-group-error">{formItemsErrors.userTier}</span>}

                                                                </div>
                                                            </div>



                                                        }
                                                        {location.state?.profileOrSettings == null && userData.userStatusInt >= 5 &&

                                                            <div className="form__form-group">
                                                                <span className="form__form-group-label">{t("pages.formItems.language")}</span>
                                                                <div className="form__form-group-field" style={{ display: 'block' }}>
                                                                    <Select
                                                                        name="userTier"
                                                                        value={choosenLang}
                                                                        onChange={setChoosenLang}
                                                                        options={languageOptions}
                                                                        clearable={false}
                                                                        className="react-select"
                                                                        placeholder={t("pages.formItems.language")}
                                                                        styles={{
                                                                            control: (provided) => ({
                                                                                ...provided,
                                                                                backgroundColor: themeData === "theme-dark" && "#232329",

                                                                            }),
                                                                            singleValue: (provided) => ({
                                                                                ...provided,
                                                                                color: themeData === "theme-dark" && "#dddddd",
                                                                            }),
                                                                            /*option: (provided) => ({
                                                                                ...provided,
                                                                                color: "white",
                                                                              }),
                                                                              menu: (provided) => ({
                                                                                ...provided,
                                                                                backgroundColor: "black",
                                                                              }),*/
                                                                        }}
                                                                    //classNamePrefix={!disabled ? "react-select" : "disabled"}
                                                                    //ref={ref}
                                                                    //isDisabled={disabled}
                                                                    />

                                                                </div>



                                                            </div>}
                                                        <div className="form__form-group">
                                                            <div className="form__form-group-field">
                                                                {location.state?.profileOrSettings == null ? <div style={{ textAlign: 'end', flex: 1, marginRight: 20 }}>
                                                                    <Button style={{ backgroundColor: 'orange', color: 'white' }} onClick={() => {

                                                                        setUsingUserData({});
                                                                        setUserTierInitialState(null);
                                                                        setFormItemsErrors({})
                                                                        setChoosenLang(userLang === "tr" ?
                                                                            { value: 'tr', label: languageButtonContent("tr") } :
                                                                            { value: 'en', label: languageButtonContent("en") })
                                                                        form.restart(null)
                                                                    }}>{t("pages.formItems.clear")}</Button>
                                                                </div> : <div style={{ flex: 1 }}></div>}
                                                                <div style={{ textAlign: 'end' }}>
                                                                    <Button color="primary" type="submit">{t("pages.formItems.save")}</Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                )}
                                            </Form> : <div style={{ textAlign: 'left', margin: 20 }}>
                                                {t("pages.settings.errors.maximumUsersExceeded")}
                                            </div>}
                                    </div>
                                    <div>

                                        {!location.state?.choosenTenantData?.value && !location.state?.profileOrSettings &&
                                            <div style={{ flex: 1 }}>
                                                <Collapse title={t("pages.formItems.activeUsersList")} className="with-shadow" isOpen={true}>
                                                    <Table responsive hover>
                                                        <thead>
                                                            <tr>
                                                                {header.map(item => (
                                                                    <th key={item.id}>{item.title}</th>
                                                                ))}
                                                            </tr>
                                                        </thead>
                                                        <tbody >
                                                            {userList.map((item, userListIndex) => (
                                                                <tr
                                                                    style={{ backgroundColor: userListIndex % 2 == 0 && 'ButtonShadow', height: 50 }}

                                                                    key={item.id + " - " + userListIndex} onClick={() => selectDataToUpdate(item)}>
                                                                    <td style={{ padding: '0 20px', verticalAlign: 'middle' }}>
                                                                        {item.givenName}</td>
                                                                    <td style={{ padding: '0 20px', verticalAlign: 'middle' }}>
                                                                        {item.familyName}</td>
                                                                    <td style={{ padding: '0 20px', verticalAlign: 'middle' }}>
                                                                        {item.email}</td>
                                                                    <td style={{ padding: '0 20px', verticalAlign: 'middle' }}>

                                                                        <div style={{ width: "100px", textAlign: 'center', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', backgroundColor: statusColored(item.userStatus), borderRadius: 10, color: 'white' }}>
                                                                            {item.userStatus}
                                                                        </div>
                                                                    </td>

                                                                    <td onClick={() => askToDelete(item)} style={{ color: 'red' }}>
                                                                        {t("pages.formItems.delete")}
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                </Collapse>
                                            </div>
                                        }
                                    </div>
                                </div> :   <div style={{textAlign:'center'}} dangerouslySetInnerHTML={{ __html: t("pages.settings.errors.brokerCanNotCreateUser") }} /> }
                        </CardBody>
                    </Card>
                </Col>}
        </div>
    )
}