import { useRouteError } from "react-router-dom";
import { useTranslation } from "react-i18next";


export default function RouteErrorBoundary() {
  let error = useRouteError();
  const { t, i18n } = useTranslation();

  return (
    <div>
      <h1>{t("pages.notificationPages.notificationMessages.somethingWrong")} 😩</h1>
      <pre>{error.message || JSON.stringify(error)}</pre>
      <button onClick={() => (window.location.href = "/")}>
        {t("pages.notificationPages.notificationMessages.clickToReload")}
      </button>
    </div>
  );
}
