// Function that will be executed on click accept or OK
export function OnAccept(acceptHandlerFunction, modalHandler) {
  try {
    if (acceptHandlerFunction) acceptHandlerFunction();
  } catch {
    console.error("Error in OnAccept component");
  } finally {
    modalHandler();
  }
}

// Function that will be executed on click reject or cancel
export async function OnReject(rejectHandlerFunction, modalHandler) {
  try {
    if (rejectHandlerFunction) rejectHandlerFunction();
  } catch {
    console.error("Error in OnReject component");
  } finally {
    modalHandler();
  }
}
