import { useState, useEffect } from 'react';

export default function Clock () {
  const [date, setDate] = useState(new Date());
  
  function refreshClock() {
    setDate(new Date());
  }
  useEffect(() => {
    const timerId = setInterval(refreshClock, 1000);
    return function cleanup() {
      clearInterval(timerId);
    };
  }, []);
  return (
    <time>
    {/* 
      TODO Write the locale as state from redux
      Fetch the locale automatically. Do not hardcode it.
    */}
      {date.toLocaleTimeString("tr-TR")}
    </time>
  );
};