import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { API, graphqlOperation } from "aws-amplify";
import { createDevice } from 'graphql/mutations';
import { createManualDevice } from "graphql/mutations";
import { useLocation } from 'react-router-dom';

import {
    cognitoColoredSuccessModalRenderHandler,
    cognitoErrorModalRenderHandler,
    cognitoColoredWarningModalRenderHandler
} from "redux/slices/modalSlice";
// easydev imports
import { Field, Form } from 'react-final-form';
import {
    Card, CardBody, Col, Button, Table, Badge
} from 'reactstrap';
export default function AddNewEquipment() {
    const location = useLocation();

    const { t, i18n } = useTranslation();
    const facilitiesFromLocationState = location.state?.choosenTenantData?.value?.facilities;
    const facilityFromReduxState = useSelector((state) => state.facility.facilityState);

    const facilityState = facilitiesFromLocationState || facilityFromReduxState;
    const [choosenFacility, setChoosenFacilityData] = useState(null);
    const themeData = useSelector((state) => state.theme.className);
    const [formItemsErrors, setFormItemsErrors] = useState({})
    const [complateFunc, setComplateFunc] = useState(false);
    const [choosenDataUtilizationCode, setChoosenDataUtilizationCode] = useState(null)
    const [equipmentNameInputError, setEquipmentNameInputError] = useState(false)

    const dataUtilizationCodeOptions = [
        { value: { name: "electricity", unit: "kWh" }, label: t("dataUtilizationCodeOptions.electricity") },
        { value: { name: "water", unit: "m³" }, label: t("dataUtilizationCodeOptions.water") },
        { value: { name: "naturalGas", unit: "m³" }, label: t("dataUtilizationCodeOptions.naturalGas") },
        { value: { name: "machine", unit: null }, label: t("dataUtilizationCodeOptions.machine") }
    ]
    const facilityStatusOptions = facilityState.facilities.map((facilityItem) => {
        return { value: facilityItem, label: facilityItem.unitName }
    })

    const dispatch = useDispatch()

    const onSubmit = async (dataOnSubmit) => {
        const addNewData = {

            /*tenantId: choosenFacility.value.id,
            facilityId: choosenFacility.value.SK,
            dataUtilizationCode: "naturalGas", // "electricity", "water", "naturalGas", "machine"
            equipmentName: dataOnSubmit.equipmentName, // Equipment name for utility data
            equipmentDescription: dataOnSubmit.equipmentDescription,
            equipmentLocation: dataOnSubmit.equipmentLocation,
            equipmentIOChannelKey: null,
            dataCategory: "utility", // or facility
            dataUnit: "m3",
            alarmConditions: []*/
            /*  tenantId: choosenFacility.value.id,
              facilityId: choosenFacility.value.SK,
              dataUtilizationCode: "naturalGas", // "electricity", "water", "naturalGas", "machine"
              equipmentName: dataOnSubmit.equipmentName, // Equipment name for utility data
              equipmentDescription: dataOnSubmit.equipmentDescription,
              equipmentLocation: dataOnSubmit.equipmentLocation,*/
            tenantId: choosenFacility.value.id,
            facilityId: choosenFacility.value.SK,
            dataUtilizationCode: choosenDataUtilizationCode.value.name, // "electricity", "water", "naturalGas", "machine"
            equipmentName: dataOnSubmit.equipmentName,  // Equipment name for utility data
            equipmentDescription: dataOnSubmit.equipmentDescription,
            equipmentLocation: dataOnSubmit.equipmentLocation,
            deviceFacilitySettings: null,
            //equipmentIOChannelKey: null, //TODO sorun çıkartıyor null gönderince
            dataCategory: "utility", // or facility
            dataUnit: choosenDataUtilizationCode?.value?.unit == null ? dataOnSubmit.dataUnit : choosenDataUtilizationCode.value.unit,
            alarmConditions: []

        }
        createManualDeviceHandler(dataOnSubmit)
    };
    useEffect(() => {
        if (facilityStatusOptions && facilityStatusOptions.length === 1) {
            setChoosenFacilityData(facilityStatusOptions[0])
        }

    }, [facilityStatusOptions])
    async function createManualDeviceHandler(dataOnSubmit) {
        const createManualDeviceInput = {

            tenantId: choosenFacility.value.id,
            facilityId: choosenFacility.value.SK,
            dataUtilizationCode: choosenDataUtilizationCode.value.name, // "electricity", "water", "naturalGas", "machine"
            equipmentName: dataOnSubmit.equipmentName,  // Equipment name for utility data
            equipmentDescription: dataOnSubmit.equipmentDescription,
            equipmentLocation: dataOnSubmit.equipmentLocation,
            //deviceFacilitySettings: null,
            //equipmentIOChannelKey: null, //TODO sorun çıkartıyor null gönderince
            dataCategory: choosenDataUtilizationCode?.value?.dataCategory, // utily or facility, // choosenDataUtilizationCode?.value?.dataCategory, // utily or facility
            dataUnit: choosenDataUtilizationCode?.value?.unit == null ? dataOnSubmit.dataUnit : choosenDataUtilizationCode.value.unit,
            alarmConditions: []

            /* tenantId: choosenFacility.value.id,
             facilityId: choosenFacility.value.SK,
             dataUtilizationCode: choosenDataUtilizationCode.value.name, // "electricity", "water", "naturalGas", "machine"
             equipmentName: dataOnSubmit.equipmentName,  // Equipment name for utility data
             equipmentDescription: dataOnSubmit.equipmentDescription,
             equipmentLocation: dataOnSubmit.equipmentLocation,
             dataUnit: choosenDataUtilizationCode?.value?.unit == null ? dataOnSubmit.dataUnit : choosenDataUtilizationCode.value.unit,*/
        };
        // API call to create manual device data
        await API.graphql(
            graphqlOperation(
                createManualDevice, {
                input: createManualDeviceInput
            })
        ).then((response) => {
            //setIsLoading(false)
            const successMessage = {
                name: t(
                    "modal.modalMessage.onSuccess.name.attributeCreateSuccess"
                ),
                message: t(
                    "modal.modalMessage.onSuccess.message.attributeCreateSuccess"
                ),
                acceptFunc: () => setComplateFunc(!complateFunc),
                rejectFunc: () => setComplateFunc(!complateFunc)
            };
            dispatch(cognitoColoredSuccessModalRenderHandler(successMessage));
        }).catch((err) => {
            const errorMessage = {
                name: t(
                    "modal.modalMessage.onSuccess.name.failedToCreateEquipment"
                ),
                message: t(
                    "modal.modalMessage.onSuccess.message.failedToCreateEquipment"
                ),
                acceptFunc: () => setComplateFunc(!complateFunc),
                rejectFunc: () => setComplateFunc(!complateFunc)
            };
            dispatch(cognitoColoredWarningModalRenderHandler(errorMessage));
            //throw new Error(err.errors[0]);
        });

        return createManualDeviceInput;
    };

    useEffect(() => {
        setChoosenFacilityData(null)
        setChoosenDataUtilizationCode(null)

    }, [complateFunc])

    return (
        <div style={{ marginLeft: 20, marginRight: 20 }}>
            <Col md={12} lg={12}>
                <Card>
                    <CardBody>
                        <div className="card__title">
                            <h5 className="bold-text">{t("pages.settings.settings.equipment")}</h5>
                        </div>
                        <Form onSubmit={onSubmit}
                            validate={(values) => {
                                if (values.equipmentName && values.equipmentName.length > 19) {
                                    setEquipmentNameInputError(true)
                                    return null
                                } else {
                                    setEquipmentNameInputError(false)
                                }
                            }}>

                            {({ handleSubmit, form }) => (
                                <form className="form form--horizontal" onSubmit={handleSubmit}>
                                    {facilityState.facilities &&
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">{t("pages.formItems.facilityName")}</span>
                                            <div className="form__form-group-field" style={{ display: 'block' }}>

                                                <Select
                                                    isDisabled={facilityStatusOptions.length === 1 ? true : false}

                                                    name="facilityName"
                                                    value={choosenFacility}
                                                    onChange={setChoosenFacilityData}
                                                    options={facilityStatusOptions}
                                                    clearable={false}
                                                    className="react-select"
                                                    placeholder={t("pages.formItems.facilityName")}
                                                    styles={{
                                                        control: (provided) => ({
                                                            ...provided,
                                                            backgroundColor: themeData === "theme-dark" && "#232329",

                                                        }),
                                                        singleValue: (provided) => ({
                                                            ...provided,
                                                            color: themeData === "theme-dark" && "#dddddd",
                                                        }),
                                                        /*option: (provided) => ({
                                                            ...provided,
                                                            color: "white",
                                                          }),
                                                          menu: (provided) => ({
                                                            ...provided,
                                                            backgroundColor: "black",
                                                          }),*/
                                                    }}
                                                //classNamePrefix={!disabled ? "react-select" : "disabled"}
                                                //ref={ref}
                                                //isDisabled={disabled}
                                                />
                                                {formItemsErrors?.userTier && <span className="form__form-group-error">{formItemsErrors.userTier}</span>}

                                            </div>
                                        </div>}
                                    {choosenFacility &&
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">{t("pages.formItems.dataType")}</span>
                                            <div className="form__form-group-field" style={{ display: 'block' }}>
                                                <Select
                                                    name="dataType"
                                                    value={choosenDataUtilizationCode}
                                                    onChange={setChoosenDataUtilizationCode}
                                                    options={dataUtilizationCodeOptions}
                                                    clearable={false}
                                                    className="react-select"
                                                    placeholder={t("pages.formItems.dataType")}
                                                    styles={{
                                                        control: (provided) => ({
                                                            ...provided,
                                                            backgroundColor: themeData === "theme-dark" && "#232329",

                                                        }),
                                                        singleValue: (provided) => ({
                                                            ...provided,
                                                            color: themeData === "theme-dark" && "#dddddd",
                                                        }),
                                                        /*option: (provided) => ({
                                                            ...provided,
                                                            color: "white",
                                                          }),
                                                          menu: (provided) => ({
                                                            ...provided,
                                                            backgroundColor: "black",
                                                          }),*/
                                                    }}
                                                //classNamePrefix={!disabled ? "react-select" : "disabled"}
                                                //ref={ref}
                                                //isDisabled={disabled}
                                                />
                                                {formItemsErrors?.userTier && <span className="form__form-group-error">{formItemsErrors.userTier}</span>}

                                            </div>
                                        </div>}

                                    {choosenDataUtilizationCode &&

                                        <div style={{ width: "100%" }}>


                                            <div className="form__form-group">
                                                <span className="form__form-group-label">{t("pages.formItems.equipmentName")}</span>
                                                <div className="form__form-group-field" style={{ display: 'block' }}>
                                                    <Field
                                                        name="equipmentName"
                                                        component="input"
                                                        type="text"
                                                        placeholder={t("pages.formItems.equipmentName")}
                                                        maxLength={20}
                                                    />
                                                    {equipmentNameInputError && <span className="form__form-group-error">{t("pages.settings.errors.maxLengthError")}</span>}

                                                </div>

                                            </div>
                                            {choosenDataUtilizationCode?.value?.unit === null && <div className="form__form-group">
                                                <span className="form__form-group-label">{t("pages.updateEquipment.dataUnit")}</span>
                                                <div className="form__form-group-field">
                                                    <Field
                                                        name="dataUnit"
                                                        component="input"
                                                        type="text"
                                                        placeholder={t("pages.updateEquipment.dataUnit")}
                                                    />
                                                </div>
                                            </div>}

                                            {choosenDataUtilizationCode?.value?.name != "machine" &&
                                                <div>
                                                    <div className="form__form-group">
                                                        <span className="form__form-group-label">{t("pages.formItems.equipmentLocation")}</span>
                                                        <div className="form__form-group-field">
                                                            <Field
                                                                name="equipmentLocation"
                                                                component="input"
                                                                type="text"
                                                                placeholder={t("pages.formItems.equipmentLocation")}

                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="form__form-group">
                                                        <span className="form__form-group-label">{t("pages.formItems.equipmentDescription")}</span>
                                                        <div className="form__form-group-field">
                                                            <Field
                                                                name="equipmentDescription"
                                                                placeholder={t("pages.formItems.equipmentDescription")}
                                                                component="textarea"
                                                                type="text"
                                                                maxLength={600}
                                                                style={{ height: '50px' }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            <div className="form__form-group">
                                                <div className="form__form-group-field">
                                                    <div style={{ textAlign: 'end', flex: 1 }}>
                                                        <Button color="primary" type="submit">{t("pages.formItems.save")}</Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {/* <div className="form__form-group">
                                        <span className="form__form-group-label">{t("pages.formItems.boxId")}</span>
                                        <div className="form__form-group-field">
                                            <Field
                                                name="boxId"
                                                component="input"
                                                type="text"
                                                placeholder={t("pages.formItems.boxId")}
                                            />
                                        </div>
                                                </div>
                                      <div className="form__form-group">
                                        <span className="form__form-group-label">{t("pages.formItems.equipmentBrand")}</span>
                                        <div className="form__form-group-field">
                                            <Field
                                                name="equipmentBrand"
                                                component="input"
                                                type="text"
                                                placeholder={t("pages.formItems.equipmentBrand")}
                                            />
                                        </div>
                                    </div>
                                    <div className="form__form-group">
                                        <span className="form__form-group-label">{t("pages.formItems.equipmentModel")}</span>
                                        <div className="form__form-group-field">
                                            <Field
                                                name="equipmentModel"
                                                component="input"
                                                type="text"
                                                placeholder={t("pages.formItems.equipmentModel")}
                                            />
                                        </div>
                                            </div>
                                    <div className="form__form-group">
                                        <span className="form__form-group-label">{t("pages.formItems.equipmentCategory")}</span>
                                        <div className="form__form-group-field">
                                            <Field
                                                name="equipmentCategory"
                                                component="input"
                                                type="text"
                                                placeholder={t("pages.formItems.equipmentCategory")}
                                            />
                                        </div>
                                        </div>
                                    
                                    <div className="form__form-group">
                                        <span className="form__form-group-label">{t("pages.formItems.equipmentTimeZone")}</span>
                                        <div className="form__form-group-field">
                                            <Field
                                                name="equipmentTimeZone"
                                                component="input"
                                                type="text"
                                                placeholder={t("pages.formItems.equipmentTimeZone")}
                                            />
                                        </div>
                                    </div>*/}

                                </form>
                            )}
                        </Form>
                    </CardBody>
                </Card>
            </Col>
        </div>
    )
}