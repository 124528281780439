import React, { useState, useEffect } from "react";
import moment from "moment";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import "react-datepicker/dist/react-datepicker.css";
import tr from "date-fns/locale/tr";
import Clock from "./Clock";
import { timePickerDataHandler, timeCustomDateHandler } from "redux/slices/timeSlice";
import MuiStyleHandler from "./MuiStyleHandler";

export default function DashboardHeader() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { lang } = useSelector((state) => state.lang);
  const { storeStartTime } = useSelector((state) => state.timeData.timeState);
  const [today, setToday] = useState(new Date(Date.now()))
  // const [oneYearAgo, setOneYearAgo] = useState(new Date((today.getFullYear() - 1), today.getMonth(), today.getDay(), today.getHours(), today.getMinutes(), 0))
  // If we use the today state variable in a separate state variable, it will not change the state when the today state variable changes
  const [oneYearAgo, setOneYearAgo] = useState(new Date());

  useEffect(() => {
    const oneYearAgoDate = new Date(
      today.getFullYear() - 1,
      today.getMonth(),
      today.getDate(),
      today.getHours(),
      today.getMinutes(),
      0
    );
    setOneYearAgo(oneYearAgoDate);
  }, [today]);

  // This will be used for the localization of date picket
  let selectedLanguage;
  switch (lang) {
    case "tr":
      selectedLanguage = tr;
      break;
    default:
      selectedLanguage = tr;
      break;
  }

  // Time range for drop down time picker
  const [timeRange, setTimeRange] = useState("");
  // Custom date picker open state
  const [openCustomRangeSelector, setOpenCustomRangeSelector] = useState(false);
  // Custom date picker start time
  const [startTime, setStartTime] = useState(null);
  // Custom date picker end time
  const [endTime, setEndTime] = useState(null);
  // Alert message
  const [alertMessage, setAlertMessage] = useState(null);

  // This function will be used for the time range drop down
  const handleChange = (event) => {
    if (event.target.value === "custom") {
      setOpenCustomRangeSelector(true);
      setTimeRange(event.target.value);
    } else {
      setTimeRange(event.target.value);
      setOpenCustomRangeSelector(false);
      setAlertMessage(null);
      dispatch(timePickerDataHandler(event.target.value));
    }
  };

  const minDateFormat = (dateStartTime, dateEndTime) => {
    if (dateEndTime == null) {
      return null
    } else if (dateEndTime.toDateString() === today.toDateString()) {
      return new Date((today.getFullYear() - 1), today.getMonth(), today.getDate())
    } else if (dateEndTime.getMonth() === dateStartTime.getMonth() && dateEndTime.getDay() === dateStartTime.getDay() ) {     
      return (new Date((today.getFullYear() - 1), today.getMonth(), today.getDate(), today.getHours(), today.getMinutes(), 0))

    }
  }

  const maxDateFormat = (dateStartTime,dateEndTime) => {
    if (dateEndTime == null) {
      return null
    } else if (dateEndTime.toDateString() === today.toDateString()) {

      return (new Date((today.getFullYear() - 1), today.getMonth(), today.getDate(), today.getHours(), today.getMinutes(), 0))


    } else if (dateEndTime.getMonth() === dateStartTime.getMonth() && dateEndTime.getDay() === dateStartTime.getDay() ) {
      return (new Date((today.getFullYear() - 1), today.getMonth(), today.getDate(), 23, 59, 0))
    }


  }

  useEffect(() => {
    if (startTime) {
      const date = new Date();

    }

  }, [startTime]);

  return (
    <Container className="dashboard">
      {alertMessage
        && <Stack sx={{ width: '100%' }} spacing={2}>
          <Alert severity="warning" onClose={() => { setAlertMessage(null) }}>{alertMessage}</Alert>
        </Stack>
      }
      <Row>
        <Col md={4}>
          <h3 className="page-title">
            <Clock />
          </h3>
        </Col>
        {storeStartTime && <Col className="d-flex align-items-center justify-content-center" md={4}>
          <p>{`${t("pages.dashboardPage.timePage.dashboardStartTime")} : ${moment(storeStartTime).format(t("pages.dashboardPage.time.dateFormatForMomentLibrary"))}`}</p>
        </Col>}
        <Col className="justify-content-center" md={2}>
          {openCustomRangeSelector && (
            <div className={"time-range-selection"}>
              <div>
                <Col className="text-center">
                  <div>
                    {t("pages.dashboardPage.timePage.customTimeRangeTitle")}
                  </div>
                  <div>
           
                    <DatePicker
                      minDate={new Date((today.getFullYear() - 1), today.getMonth(), today.getDate())}
                      maxDate={today}
                      minTime={minDateFormat(oneYearAgo,startTime)}
                      maxTime={maxDateFormat(oneYearAgo,startTime)}
                      selected={startTime}
                      onChange={(time) => {
                        setStartTime(time)
                        setEndTime(time)
                      }}
                      showTimeSelect

                      timeFormat={t("pages.dashboardPage.time.timeFormat")}
                      timeIntervals={15}
                      dateFormat={t("pages.dashboardPage.time.dateFormat")}
                      timeCaption={t(
                        "pages.dashboardPage.timePage.timeCaption"
                      )}
                      locale={selectedLanguage}
                      placeholderText={t(
                        "pages.dashboardPage.timePage.startTime"
                      )}
                    />
                  </div>
                  <div>
                    {startTime != null &&
                      <DatePicker
                        minDate={startTime}
                        maxDate={today}
                        minTime={minDateFormat(startTime,endTime)}
                        maxTime={maxDateFormat(startTime,endTime)}
                        onChange={(time) => setEndTime(time)}
                        selected={endTime < new Date(startTime.getFullYear(), startTime.getMonth(), startTime.getDate(), startTime.getHours(), (startTime.getMinutes()+15), 0) ? 
                          new Date(startTime.getFullYear(), startTime.getMonth(), startTime.getDate(), startTime.getHours(), (startTime.getMinutes()+15), 0) : endTime}
                        showTimeSelect
                        timeFormat={t("pages.dashboardPage.time.timeFormat")}
                        timeIntervals={15}
                        dateFormat={t("pages.dashboardPage.time.dateFormat")}
                        timeCaption={t(
                          "pages.dashboardPage.timePage.timeCaption"
                        )}
                        locale={selectedLanguage}
                        placeholderText={t(
                          "pages.dashboardPage.timePage.endTime"
                        )}
                        />}
                  </div>
                  <div>
                    <Button
                      size="small"
                      variant="contained"
                      color="success"
                      // On click, we will dispatch the start and end time to the redux store
                      onClick={() => {
                        setOpenCustomRangeSelector(false);
                        setTimeRange("");
                        setAlertMessage(null);
                        if (startTime && endTime) {
                          const startTimeToDispatch = moment(startTime).format();
                          const endTimeToDispatch = moment(endTime).format();
                          dispatch(timeCustomDateHandler({ startTimeToDispatch, endTimeToDispatch }));
                        }
                      }}
                    >
                      {t("pages.dashboardPage.timePage.setTimeButton")}
                    </Button>
                    <Button
                      size="small"
                      variant="outlined"
                      color="error"
                      sx={{ ml: 1 }}
                      onClick={() => {
                        setStartTime(null);
                        setEndTime(null);
                        setOpenCustomRangeSelector(false);
                        setTimeRange("");
                        setAlertMessage(null);
                      }}
                    >
                      {t("pages.dashboardPage.timePage.cancelTimeButton")}
                    </Button>
                  </div>
                </Col>
              </div>
              <div></div>
            </div>
          )}
        </Col>
        <Col className="d-flex align-items-center justify-content-center" md={2}>
          {/* <Col className="d-flex align-items-center justify-content-center"> */}
          {/* <b>{t("pages.dashboardPage.timePage.title")}</b> */}
          <MuiStyleHandler>
            <FormControl sx={{ m: 1, minWidth: 160 }} size="small">
              <InputLabel id="small-time-range-selector">
                {t("pages.dashboardPage.timePage.timeRangePicker.inputLabel")}
              </InputLabel>
              <Select
                labelId="small-time-range-selector"
                id="small-time-selector"
                value={timeRange}
                label={t("pages.dashboardPage.timePage.timeRangePicker.inputLabel")}
                onChange={handleChange}
              >
                <MenuItem value=""><em>{t("pages.dashboardPage.timePage.timeRangePicker.noneValue")}</em></MenuItem>
                <MenuItem value={"last15Minutes"}>{t("pages.dashboardPage.timePage.timeRangePicker.last15Minutes")}</MenuItem>
                <MenuItem value={"last1Hour"}>{t("pages.dashboardPage.timePage.timeRangePicker.last1Hour")}</MenuItem>
                <MenuItem value={"today"}>{t("pages.dashboardPage.timePage.timeRangePicker.today")}</MenuItem>
                <MenuItem value={"yesterday"}>{t("pages.dashboardPage.timePage.timeRangePicker.yesterday")}</MenuItem>
                <MenuItem value={"custom"}>
                  {t("pages.dashboardPage.timePage.timeRangePicker.customTimeRange")}
                </MenuItem>
              </Select>
            </FormControl>
          </MuiStyleHandler>
        </Col>
      </Row>
    </Container>
  );
}
