import React, { Suspense, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Row } from 'react-bootstrap';
import ElectricityDataDashboard from "./UtilityDashboard/ElectricityDataDashboard/ElectricityDataDashboard";
import MuiStyleHandler from './MuiStyleHandler';
import ExecutiveDashboardElectricityDataHandler from './ExecutiveReportDashboard/ExecutiveDashboardElectricityDataHandler';
import EquipmentPanelPage from 'containers/Pages/DashboardPages/EquipmentPanelPage/EquipmentPanelPage';
import EquipmentDetails from 'containers/Pages/DashboardPages/EquipmentDetails/EquipmentDetails';
import EquipmentSummary from 'containers/Pages/DashboardPages/EquipmentSummary/EquipmentSummary';
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`dashboard-tabpanel-${index}`}
      aria-labelledby={`dashboard-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `dashboard-tab-${index}`,
    'aria-controls': `dashboard-tabpanel-${index}`,
  };
}

export default function DashboardTabPanel({ dir, dataSet }) {
  const { t, i18n } = useTranslation();
  const [choosenTab, setChoosenTab] = useState(0);
  const [renderTabPanel, setRenderTabPanel] = useState(null)
  const { electricity } = useSelector((state) => state.executiveDashboardData.executiveDashboardState);
  const { devices } = useSelector((state) => state.deviceData.deviceDataState);

  const { machine } = useSelector((state) => state.executiveDashboardData.executiveDashboardState);


  const [machineStateCondition, setMachineStateCondition] = useState(false)
  const [electricityStateCondition, setElectricityStateCondition] = useState(false)
  const [machineDetailsStateCondition, setMachineDetailsStateCondition] = useState(false)
  const [tabPanelsIndexList, setTabPanelsIndexList] = useState({
    EquipmentPanelPage: 0,
    ExecutiveDashboardElectricityDataHandler: 1,
    ElectricityDataDashboard: 2,
    EquipmentSummary: 3,
    EquipmentDetails: 4,

  })


  const { responseData: { historicalData } } = useSelector(
    (state) => state.dashboardData.dashboardDataState.dashboardData
  );

  const handleChange = (event, newValue) => {
    setChoosenTab(newValue);
  }
  // Electricity may not be available for all users. So, if electricity is not available, the default tab should be the machine tab.
  useEffect(() => {

    const machineStateConditionList = Object.entries(machine).map(([equipmentKey, equipmentValue]) =>
    (
      equipmentKey
      // Check if the equipment has equipmentStateDurations
      // Especially during the very first initialization, the equipment may not have equipmentStateDurations
      // In that case the app will throw an error on the console
      && equipmentValue.equipmentStateDurations
      && Object.keys(equipmentValue.equipmentStateDurations).length > 0

      && equipmentKey
    )
    )
    if (machineStateConditionList.length > 1) {
      console.log("machineStateConditionList", machineStateConditionList)
      // setChoosenTab(0)
      setMachineStateCondition(true)
    } else {
      setMachineStateCondition(false)
    }
  }, [machine])

  useEffect(() => {
    const electricityStateConditionList = Object.keys(electricity).map(
      (electricityElement) => {
        // Find the device with the same descriptiveCode as the electricityElement
        const currentDevice = devices.filter(obj => obj.descriptiveCode === electricityElement)
        if (currentDevice.length > 0) { return currentDevice }
      })

    if (electricity) {
      //   setChoosenTab(choosenTab > 1 && 1)
      setElectricityStateCondition(true)
    } else {
      setElectricityStateCondition(false)
    }
  }, [electricity])
  useEffect(() => {

    const machineDetailsStateConditionList = Object.entries(devices).map(([key, devicesValue]) => {
      if (devicesValue.dataUtilizationCode === "machine") {
        return devicesValue
      }
    })
    if (machineDetailsStateConditionList) {
      // setChoosenTab(choosenTab > 4 && 4)
      setMachineDetailsStateCondition(true)
    } else {
      setMachineDetailsStateCondition(false)
    }
  }, [devices])

  useEffect(() => {
    // const electricityStateConditionList = Object.entries(devices).map(([key, value]) => { value.dataUtilizationCode === "electricity" })
  }, [devices])
  useEffect(() => {

    if (machineStateCondition) {
      console.log("if", electricityStateCondition, machineDetailsStateCondition)

      setTabPanelsIndexList({
        EquipmentPanelPage: 0,
        ExecutiveDashboardElectricityDataHandler: 1,
        ElectricityDataDashboard: 2,
        EquipmentSummary: 3,
        EquipmentDetails: 4,

      })

    }
    else if (electricityStateCondition) {
      console.log("electricityStateCondition")
      setTabPanelsIndexList({
        EquipmentPanelPage: 4,
        ExecutiveDashboardElectricityDataHandler: 0,
        ElectricityDataDashboard: 1,
        EquipmentSummary: 2,
        EquipmentDetails: 3,

      })
    } else if (machineStateCondition) {
      console.log("machineStateCondition")

      setTabPanelsIndexList({
        EquipmentPanelPage: 3,
        ExecutiveDashboardElectricityDataHandler: 4,
        ElectricityDataDashboard: 0,
        EquipmentSummary: 1,
        EquipmentDetails: 2,

      })

    }
    else {
      console.log("else")

      setTabPanelsIndexList({
        EquipmentPanelPage: 2,
        ExecutiveDashboardElectricityDataHandler: 3,
        ElectricityDataDashboard: 4,
        EquipmentSummary: 0,
        EquipmentDetails: 1,

      })
    }
  }, [machineStateCondition, electricityStateCondition]);

  return (
    <MuiStyleHandler>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={choosenTab}
            onChange={handleChange}
            aria-label="sanaiinfo panel"
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile>
            {machineStateCondition && <Tab label={t(
              "pages.dashboardPage.tabs.status"
            )} />}
            {electricityStateCondition && <Tab label={t(
              "pages.dashboardPage.tabs.electricity"
            )} />}
            {electricityStateCondition && <Tab label={t(
              "pages.dashboardPage.tabs.electricityDetail"
            )} />}
            {machineDetailsStateCondition && <Tab label={t(
              "pages.dashboardPage.tabs.machineStatus"
            )} />}
            {machineDetailsStateCondition && <Tab label={t(
              "pages.dashboardPage.tabs.machineDetail"
            )} />}
            {/* <Tab label="Item Two" {...a11yProps(1)} />
            <Tab label="Item Three" {...a11yProps(2)} /> */}
          </Tabs>
        </Box>
        <TabPanel value={choosenTab} index={tabPanelsIndexList.EquipmentPanelPage}>
          <Suspense fallback={<div>Loading...</div>}>
            <EquipmentPanelPage />
          </Suspense>
        </TabPanel>
        <TabPanel value={choosenTab} index={tabPanelsIndexList.ExecutiveDashboardElectricityDataHandler}>
          <Row >
            <ExecutiveDashboardElectricityDataHandler />
          </Row>
        </TabPanel>

        <TabPanel value={choosenTab} index={tabPanelsIndexList.ElectricityDataDashboard}>
          <Suspense fallback={<div>Loading...</div>}>
            <ElectricityDataDashboard
              dir={dir}
              title={t(
                "pages.dashboardPage.historicalData.utility.electricity.subtitles"
              )}
            />
          </Suspense>
        </TabPanel>

        <TabPanel value={choosenTab} index={tabPanelsIndexList.EquipmentSummary}>
          <Row >
            <EquipmentSummary />
          </Row>
        </TabPanel>

        <TabPanel value={choosenTab} index={tabPanelsIndexList.EquipmentDetails}>
          <Suspense fallback={<div>Loading...</div>}>
            <EquipmentDetails
              dir={dir}
              title={t(
                "pages.dashboardPage.historicalData.utility.electricity.subtitles"
              )}
            />
          </Suspense>
        </TabPanel>


      </Box>
    </MuiStyleHandler>
  );
}
