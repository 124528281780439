import React from 'react';
import { Link } from "react-router-dom";
export default function MenuItem({ item,choosenTenantData }) {
    return (
        <Link to={`/${item.navigateName}`} state={{choosenTenantData: choosenTenantData}} style={{ textDecoration: 'none' }}>
            <div className='card-cont' style={{maxWidth:200}}>
                <h4>{item.subTitle}</h4>
                <p style={{ color: "#75787e" }}>{item.description}</p>
            </div>
        </Link>

    )
}



