import { Auth } from "aws-amplify";

export default async function CognitoSignIn(
  username,
  password,
  dispatchHandler,
  passwordFormStateHandler,
  resetContext
) {
  try {
    await Auth.signIn(username, password).then((user) => {
     // console.log("CognitoSignIn: ", user);
      
      // NEW_PASSWORD_REQUIRED is returned after user creation by admin
      // and during the first sign in attempt of the user
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        // Change the state in Redux store
        // so that the user will be directed to
        // change password page
        // Other states will be handled by AuthListener Hub
        dispatchHandler("formChangePassword");
        // Change the state of password form
        // Password context will be kept.
        // These data will be used in change temporary password
        passwordFormStateHandler("changeTemporaryPassword");
      } else {
        dispatchHandler("authenticated");
        // Reset the password context
        // For security reasons, the password data is not stored
        // It is not done at finally stage because we want to keep the
        // temporary password for "changeTemporaryPassword" state
        resetContext();
      }
    });
  } catch (error) {
    if (error.code && error.code === "UserNotConfirmedException") {
      dispatchHandler("formConfirmSignUp");
      // Reset the password context
      // For security reasons, the password data is not stored
      // Password is not needed at "formConfirmSignUp" state
      resetContext();
    } else {
      throw new Error(JSON.stringify(error));
    }
  }
}
