import React from "react";
import { Outlet } from "react-router-dom";
import Layout from "../../Layout/index";

export default function WrappedRoutes({ children }) {
  return (
    <div>
      <Layout />
      <div className="container__wrap">{children ? children : <Outlet />}</div>
    </div>
  );
}
