import React, { useState, useEffect, Fragment } from 'react';
import MenuItem from '../Settings/components/MenuItems/MenuItem';
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Card } from 'reactstrap';
import Select from 'react-select';
import { getTenantsByBrokerId, getTenant } from 'graphql/queries';
import { API, graphqlOperation } from "aws-amplify";


export default function DealerSettings() {
    const { t, i18n } = useTranslation();
    const userData = useSelector((state) => state.user.userState);
    const { lang } = useSelector((state) => state.lang);
    // State that holds the settings menu
    const [settingMenuMapping, setSettingMenuMapping] = useState(null);
    const [choosenTenantData, setChoosenTenantData] = useState(null);
    const [tenantStatusOptions, setTenantStatusOptions] = useState([]);


    const themeData = useSelector((state) => state.theme.className);

    /*   async function getUserData (){
            // Get user attributes
          const graphQlData = await API.graphql(
            graphqlOperation(getUser, {
              subId: tenan.sub,
            })
          ).catch((err) => {
            //console.log("Error from getUser query at graphQlData", err.errors[0]);
            throw new Error(err.errors[0]);
          });
      }*/




    useEffect(() => {
        const settingsCategories = [
            {
                mainTitle: (t("pages.settings.settings.title")),
                mainbAuthorityLevel: 0,
                subTitleArray: [
                    { subTitle: t("pages.settings.settings.tenant"), description: t("pages.settings.settings.tenantDescription"), subAuthorityLevel: 0, navigateName: "tenant_settings" },
                    { subTitle: t("pages.settings.settings.facility"), description: t("pages.settings.settings.facilityDescription"), subAuthorityLevel: 0, navigateName: "facility_settings" },
                    { subTitle: t("pages.settings.settings.user"), description: t("pages.settings.settings.userDescription"), subAuthorityLevel: 0, navigateName: "user_settings" },
                    { subTitle: t("pages.settings.settings.equipment"), description: t("pages.settings.settings.equipmentDescription"), subAuthorityLevel: 0, navigateName: "device_settings" },
                    { subTitle: t("pages.settings.settings.alarm"), description: t("pages.settings.settings.alarmDescription"), subAuthorityLevel: 0, navigateName: "alarmSettings" },
                   // { subTitle: t("pages.settings.settings.createTenant"), description: t("pages.settings.settings.createTenantDescription"), subAuthorityLevel: 0, navigateName: "create_tenant" },

                ]
            },
            /* {
                 mainTitle: (t("pages.settings.billing.title")),
                 mainbAuthorityLevel: 0,
                 subTitleArray: [
                     { subTitle: t("pages.settings.billing.billing"), description: t("pages.settings.billing.billingDescription"), subAuthorityLevel: 0, navigateName: "billing_settings" },
                     { subTitle: t("pages.settings.billing.payment"), description: t("pages.settings.billing.payment"), subAuthorityLevel: 0, navigateName: "payment_form" },
 
                 ]
             }*/
        ];
        setSettingMenuMapping(() => <div>{
            settingsCategories.map((item, index) => <div key={item.mainTitle}> {
                item.mainbAuthorityLevel <= userData.userStatusInt &&
                <div className='main-category-cont' style={{ marginTop: 10 }}>
                    <p style={{ fontSize: 30 }}>{item.mainTitle}</p>
                    <div className='row-category-cont'>
                        {item.subTitleArray.map((subItem, subIndex) => <div key={subItem.navigateName}> {
                            subItem.subAuthorityLevel <= userData.userStatusInt &&
                            <div>
                                <MenuItem item={subItem} choosenTenantData={choosenTenantData} />
                            </div>
                        }</div>
                        )}
                    </div>

                </div>
            }</div>)
        }</div>)
    }, [lang, userData.userStatus, choosenTenantData]);

    async function getTenantsList() {
        // API call to update tenant data
        // For security reasons, the attributes are retrieved directly from Amplify
        const graphQlResponse = await API.graphql(
            graphqlOperation(
                getTenantsByBrokerId, {
                brokerId: "BROKER#26619da1-12e2-4bca-9fc6-aacfedaecd5f"
            })
        )

        setTenantStatusOptions(graphQlResponse.data.getTenantsByBrokerId.map((item) => ({
            value: item,
            label: item.tenantName,
        })))

    }
    async function getTenantData(tenantItem) {
        // API call to update tenant data
        // For security reasons, the attributes are retrieved directly from Amplify
        const graphQlResponse = await API.graphql(
            graphqlOperation(
                getTenant, {
                id: tenantItem.value.SK

            })
        )

        setChoosenTenantData({
            value: graphQlResponse.data.getTenant,
            label: graphQlResponse.data.getTenant.tenantName,
        })

        /* setTenantStatusOptions(  graphQlResponse.data.getTenantsByBrokerId.map((item) => ({
             value: item,
             label: item.tenantName,
           })))*/

    }

    useEffect(() => {
        // console.log("userData", userData)
        getTenantsList()
    }, [])

    useEffect(() => {
        // console.log("userData", choosenTenantData)
        if (choosenTenantData) { getTenantData() }
    }, [choosenTenantData])
    return (
        <Fragment>
            <div className={'settings-main-cont'} >
                <Card>
                    <div className="form__form-group">
                        <span className="form__form-group-label">{t("pages.formItems.facilityName")}</span>
                        <div className="form__form-group-field" style={{ display: 'block' }}>
                            <Select
                                isDisabled={tenantStatusOptions.length === 1 ? true : false}
                                name="facilityName"
                                value={choosenTenantData}
                                onChange={(data) => getTenantData(data)}
                                options={tenantStatusOptions}
                                clearable={false}
                                className="react-select"
                                placeholder={t("pages.formItems.facilityName")}
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        backgroundColor: themeData === "theme-dark" && "#232329",

                                    }),
                                    singleValue: (provided) => ({
                                        ...provided,
                                        color: themeData === "theme-dark" && "#dddddd",
                                    }),

                                }}
                            />

                        </div>
                    </div>
                    {choosenTenantData && settingMenuMapping}
                </Card>
            </div>
        </Fragment>
    )
}
