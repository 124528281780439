import React from "react";

const InputFieldHandler = (props) => {
  const {
    renderName,
    placeholder,
    eventHandler,
    warningMessage,
    showWarningMessage,
    showWarningMessageHandler,
    required,
  } = props;

  function handleChange(event) {
    eventHandler(event.target.value);
    showWarningMessageHandler(false);
  }

  return (
    <div className="form__form-group">
      <span className="form__form-group-label">{renderName}</span>
      {required && showWarningMessage && (
        <h4
          className="account__subhead subhead"
          style={{
            color: "red",
            fontWeight: "bold",
            marginBottom: "10px",
          }}
        >
          {warningMessage}
        </h4>
      )}
      <div className="form__form-group-field">
        <input
          name={renderName}
          type="text"
          placeholder={placeholder}
          onChange={(event) => handleChange(event)}
        />
      </div>
    </div>
  );
};

export default InputFieldHandler;
