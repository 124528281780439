export const statusColored = (userStatus) => {
    switch (userStatus) {
        case "owner":
            return "#056677"
        case "admin":
            return "#00a5a8"
        case "manager":
            return "#f8cf39"
        case "user":
            return "#70818C"
        case "viewer":
            return "#4B8FB9"
        case "operator":
            return "#948E84"
        default:
            return "#41EAD4"
    }
}