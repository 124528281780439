import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Field, Form } from 'react-final-form';
import Select from 'react-select';
import getDeviceData from "containers/Router/WrappedRoutes/Dashboard/dashboardHelpers/getDeviceData";
import { deviceFirstRenderDataHandler } from "redux/slices/deviceDataSlice";
import Collapse from "shared/components/Collapse";
import Loading from "shared/components/Loading";
import { useDispatch, useSelector } from 'react-redux';
import { API, graphqlOperation } from "aws-amplify";
import { updateDevice, deleteManualDevice, getDeviceForManualDataOperations } from "graphql/mutations";
import {
    cognitoColoredWarningModalRenderHandler,
    cognitoColoredSuccessModalRenderHandler,
} from "redux/slices/modalSlice";

import {
    Card, CardBody, Col, Button,
} from 'reactstrap';
export default function UpdateEquipment({ facilityItem }) {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const [initialEquipmentValue, setInitialEquipmentValue] = useState(null)
    const [openTabKeys, setOpenTabKeys] = useState(null)
    const themeData = useSelector((state) => state.theme.className);
    const [choosenMachineData, setChoosenMachineData] = useState(null);
    const [machineStatusOptions, setMachineStatusOptions] = useState()
    const [complateFunc, setComplateFunc] = useState(false);
    const [insideCollapse, setInsideCollapse] = useState(null)
    const { devices } = useSelector((state) => state.deviceData.deviceDataState);
    const [equipmentNameInputError, setEquipmentNameInputError] = useState(null)

    const setEquipmentDataFunc = (choosenEquipmentItem) => {//After selecting the equipment, an object for the values to be filled into the form is created.
        const initialStateData = {
            equipmentName: choosenEquipmentItem.value.equipmentName,
            equipmentLocation: choosenEquipmentItem.value.equipmentLocation,
            equipmentUnit: choosenEquipmentItem.value.dataUnit,
            equipmentDescription: choosenEquipmentItem.value.equipmentDescription,
            equipmentIOChannelKeys: JSON.parse(choosenEquipmentItem.value.deviceFacilitySettings)?.equipmentIOChannelKeys
            //dataunit
        }
        setInitialEquipmentValue(initialStateData)
        setChoosenMachineData(choosenEquipmentItem)
    }
    const askToDelete = () => {//It is used to obtain confirmation before deletion.
        const warningMessage = {
            name: t(
                "modal.modalMessage.onSuccess.name.areYouSureToDelete"
            ),
            message: t(
                "modal.modalMessage.onSuccess.message.areYouSureToDelete"
            ),
            acceptFunc: () => deleteEquipment(),
        };
        dispatch(cognitoColoredWarningModalRenderHandler(warningMessage));

    }

    useEffect(() => {//It is used to populate the data of Machine Select.
        setChoosenMachineData(null)
        getDevicesOptions()

    }, [complateFunc]);

    async function getDevicesOptions() {
        setChoosenMachineData(null)
        //machineStatusOptions
        //setMachineStatusOptions
        const getDevicesData = {

            tenantId: facilityItem.id,
            facilityId: facilityItem.SK,
        };
        // API call to create manual device data
        await API.graphql(
            graphqlOperation(
                getDeviceForManualDataOperations, {
                input: getDevicesData
            })
        ).then((response) => {
            const filteredDevices = Object.entries(response.data.getDeviceForManualDataOperations).map(([key, value]) => ({ value: value, label: value.equipmentName }));
            setMachineStatusOptions(filteredDevices)

        }).catch((err) => {
            throw new Error(err.errors[0]);
        });

        return getDevicesData;
    };
    const onSubmit = async (dataOnSubmit) => {


        const updatedData = {
            descriptiveCode: choosenMachineData.value.descriptiveCode,
            tenantId: choosenMachineData.value.tenantId,
            facilityId: choosenMachineData.value.facilityId,
            equipmentName: dataOnSubmit.equipmentName,
            equipmentDescription: dataOnSubmit.equipmentDescription,
            equipmentLocation: dataOnSubmit.equipmentLocation,
        }

        updateDeviceHandler(dataOnSubmit)
    }


    const deleteEquipment = async () => {

        var deleteManualDeviceInput = {
            descriptiveCode: choosenMachineData.value.descriptiveCode,
            tenantId: choosenMachineData.value.tenantId,
            facilityId: choosenMachineData.value.facilityId,
            dataSendingDeviceChannelName: choosenMachineData.value.dataSendingDeviceChannelName,
        }

        // API call to update tenant data
        await API.graphql(
            graphqlOperation(
                deleteManualDevice, {
                input: deleteManualDeviceInput
            })
        ).then((res) => {
            //setIsLoading(false)
            const successMessage = {
                name: t("modal.modalMessage.onSuccess.name.attributeDeleteSuccess"),
                message: t("modal.modalMessage.onSuccess.message.attributeDeleteSuccess"),
                acceptFunc: () => setComplateFunc(!complateFunc),
                rejectFunc: () => setComplateFunc(!complateFunc)
            };
            dispatch(cognitoColoredSuccessModalRenderHandler(successMessage));
        }).catch((err) => {

            throw new Error(err.errors[0]);
        });
        // Return the updated attributes for Redux store update
        return deleteManualDeviceInput;

    }
    useEffect(() => {
        if (machineStatusOptions && machineStatusOptions.length === 1) {
            setEquipmentDataFunc(machineStatusOptions[0])
        }

    }, [machineStatusOptions])

    async function updateDeviceHandler(dataOnSubmit) {

        const updateOperationInput = {
            tenantId: choosenMachineData.value.tenantId,
            facilityId: choosenMachineData.value.facilityId,
            descriptiveCode: choosenMachineData.value.descriptiveCode,
            dataSendingDeviceChannelName: choosenMachineData.value.dataSendingDeviceChannelName,
            dataCategory: choosenMachineData.value.dataCategory,
            dataUtilizationCode: choosenMachineData.value.dataUtilizationCode,

            equipmentName: dataOnSubmit.equipmentName,
            equipmentDescription: dataOnSubmit.equipmentDescription,
            equipmentLocation: dataOnSubmit.equipmentLocation,
            dataUnit: dataOnSubmit.equipmentUnit ? dataOnSubmit.equipmentUnit : choosenMachineData.value.dataUnit,
        };

        // API call to update tenant data
        await API.graphql(
            graphqlOperation(
                updateDevice, {
                input: updateOperationInput
            })
        ).then(() => {
            //setIsLoading(false)

            const successMessage = {
                name: t("modal.modalMessage.onSuccess.name.attributeUpdateSuccess"),
                message: t("modal.modalMessage.onSuccess.message.attributeUpdateSuccess"),
                acceptFunc: () => { setComplateFunc(!complateFunc) },
                rejectFunc: () => { setComplateFunc(!complateFunc) }
            };
            dispatch(cognitoColoredSuccessModalRenderHandler(successMessage));
        })
            .catch((err) => {
                throw new Error(err.errors[0]);
            });
        // Return the updated attributes for Redux store update
        return updateOperationInput;
    };


    const equipmentIOChannelKeysItemRenderFunc = (key, insideValue) => {//It creates the form within the collapse to modify equipment specifications.
        setOpenTabKeys(key)
        setInsideCollapse(
            <div>
                <div className="form__form-group">
                    <span className="form__form-group-label">{t("pages.updateEquipment.dataUnit")}</span>
                    <div className="form__form-group-field">
                        <Field
                            name="dataUnit"
                            component="input"
                            type="text"
                            placeholder={t("pages.updateEquipment.dataUnit")}
                            initialValue={insideValue.dataUnit}
                        />
                    </div>
                </div>
                <div className="form__form-group">
                    <span className="form__form-group-label">{t("pages.updateEquipment.description")}</span>
                    <div className="form__form-group-field">
                        <Field
                            name="description"
                            component="input"
                            type="text"
                            placeholder={t("pages.updateEquipment.description")}
                            initialValue={insideValue.equipmentChannelKey}

                        />
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div style={{ marginLeft: 20, marginRight: 20 }}>
            <Col md={12} lg={12}>
                <Card>
                    <CardBody>
                        <div className="card__title">
                            <h5 className="bold-text">{facilityItem.unitName}</h5>
                        </div>
                        <Form onSubmit={onSubmit}
                            validate={(values) => {
                                const errors = {};
                                if (values.equipmentName && values.equipmentName.length > 19) {
                                    setEquipmentNameInputError(true)
                                } else {
                                    setEquipmentNameInputError(false)

                                }
                                return errors;
                            }} initialValues={initialEquipmentValue}>
                            {({ handleSubmit, form }) => (
                                <form className="form form--horizontal" onSubmit={handleSubmit}>

                                    {machineStatusOptions &&
                                        <div style={{ width: '100%' }}>
                                            <div className="form__form-group">
                                                <span className="form__form-group-label">{t("pages.formItems.equipmentName")}</span>
                                                <div className="form__form-group-field" style={{ display: 'block' }}>
                                                    <Select
                                                        isDisabled={machineStatusOptions.length === 1 ? true : false}

                                                        name="equipmentName"
                                                        value={choosenMachineData}
                                                        onChange={(item) => setEquipmentDataFunc(item)}
                                                        options={machineStatusOptions}
                                                        clearable={false}
                                                        className="react-select"
                                                        placeholder={t("pages.formItems.equipmentName")}
                                                        styles={{
                                                            control: (provided) => ({
                                                                ...provided,
                                                                backgroundColor: themeData === "theme-dark" && "#232329",

                                                            }),
                                                            singleValue: (provided) => ({
                                                                ...provided,
                                                                color: themeData === "theme-dark" && "#dddddd",
                                                            }),
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            {choosenMachineData &&
                                                <div>
                                                    <div className="form__form-group">
                                                        <span className="form__form-group-label">{t("pages.formItems.equipmentName")}</span>
                                                        <div className="form__form-group-field" style={{ display: 'block' }}>
                                                            <Field
                                                                name="equipmentName"
                                                                component="input"
                                                                type="text"
                                                                placeholder={t("pages.formItems.equipmentName")}
                                                                maxLength={20}
                                                            />
                                                            {equipmentNameInputError && <span className="form__form-group-error">{t("pages.settings.errors.maxLengthError")}</span>}

                                                        </div>
                                                    </div>
                                                    {choosenMachineData.value.dataUtilizationCode !== "machine" &&
                                                        <div className="form__form-group">
                                                            <span className="form__form-group-label">{t("pages.formItems.equipmentLocation")}</span>
                                                            <div className="form__form-group-field">
                                                                <Field
                                                                    name="equipmentLocation"
                                                                    component="input"
                                                                    type="text"
                                                                    placeholder={t("pages.formItems.equipmentLocation")}

                                                                />
                                                            </div>
                                                        </div>}
                                                    {choosenMachineData.value.dataUtilizationCode === "machine" &&
                                                        <div className="form__form-group">
                                                            <span className="form__form-group-label">{t("pages.formItems.equipmentUnit")}</span>
                                                            <div className="form__form-group-field">
                                                                <Field
                                                                    name="equipmentUnit"
                                                                    component="input"
                                                                    type="text"
                                                                    placeholder={t("pages.formItems.equipmentUnit")}

                                                                />
                                                            </div>
                                                        </div>}
                                                    {choosenMachineData.value.dataUtilizationCode !== "machine" &&
                                                        <div className="form__form-group">
                                                            <span className="form__form-group-label">{t("pages.formItems.equipmentDescription")}</span>
                                                            <div className="form__form-group-field">
                                                                <Field
                                                                    name="equipmentDescription"
                                                                    placeholder={t("pages.formItems.equipmentDescription")}
                                                                    component="textarea"
                                                                    type="text"
                                                                    maxLength={600}
                                                                    style={{ height: '50px' }}
                                                                />
                                                            </div>
                                                        </div>}


                                                    {initialEquipmentValue.equipmentIOChannelKeys && choosenMachineData.value.equipmentCategory === "manualDataEntry" && (

                                                        < div >
                                                            {
                                                                Object.entries(initialEquipmentValue?.equipmentIOChannelKeys).map(([key, insideValue]) => {
                                                                    return (<Collapse
                                                                        key={key}
                                                                        title={insideValue.equipmentChannelKey}
                                                                        className="with-shadow"
                                                                        onEnteringFunc={() => equipmentIOChannelKeysItemRenderFunc(key, insideValue)}
                                                                        isOpen={openTabKeys === key ? true : false}
                                                                    >
                                                                        {insideCollapse != null ?
                                                                            insideCollapse : <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100px' }}><Loading transparentBackground loading={true} /></div>
                                                                        }

                                                                    </Collapse >)
                                                                })
                                                            }
                                                        </div>)}
                                                    <div className="form__form-group">
                                                        <div className="form__form-group-field">
                                                            {choosenMachineData.value.equipmentCategory != "manualDataEntry" && //TODO equipmentCategory comes up null.  change condition when fix it
                                                                <div style={{ textAlign: 'end', flex: 1 }}>

                                                                    <Button color="primary" type="button" onClick={() => askToDelete()}>{t("pages.formItems.delete")}</Button>
                                                                </div>}
                                                            <div style={{ textAlign: 'end', marginLeft: 20 }}>
                                                                <Button color="primary" type="submit">{t("pages.formItems.save")}</Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>}

                                        </div>
                                    }

                                </form>
                            )}
                        </Form>

                    </CardBody>
                </Card>
            </Col>
        </div >
    )
}
