import { API, graphqlOperation } from "aws-amplify";
import { getUser } from "graphql/queries";

  /*
   * This function returns the facility id to be rendered as the default facility
   * @param {string} defaultFacilityId: data from the database. 
   * The id of the facility selected by the user as the default facility
   * @param {Array<{key:string}>} facilityList: List of facility attributes object that the user has access to
   * @returns {{key:string}} Facility object to be rendered as the default facility
   */
  function facilityIdHandler(defaultFacilityId = null, facilityList = []) {
    // Check if the user default facility id is in the facility list
    const filterResult = facilityList.filter((facilityObject) => facilityObject.SK === defaultFacilityId)[0];

    // If the user has a default facility and the default facility is in his list
    if (defaultFacilityId && filterResult) {
      return filterResult;
    // If the user has a default facility but the default facility is not his list
    } else if (defaultFacilityId && !filterResult && facilityList.length > 0) {
      return facilityList[0];
    // If the user does not have a default facility, but has at least one facility in his list
    } else if (defaultFacilityId && !filterResult && facilityList.length === 0) {
      return {};
    } else if (!defaultFacilityId && facilityList.length > 0) {
      return facilityList[0];
    // If the user does not have a default facility and does not have any facility in his list
    } else {
      return {};
    }
  }

export default async function getGraphQlUserData(userAttributes) {
  // Get user attributes
  const graphQlData = await API.graphql(
    graphqlOperation(getUser, {
      subId: userAttributes.sub,
    })
  ).catch((err) => {
    //console.log("Error from getUser query at graphQlData", err.errors[0]);
    throw new Error(err.errors[0]);
  });

  const getUserData = graphQlData?.data?.getUser;
  // console.log("getUserData from getGraphQlUserData", getUserData);

  // const { dashboards } = getUserData;
  // eslint-disable-next-line no-unused-vars
  // const { dashboards } = [];

  const { tenant } = getUserData.tenantDetails;
  const { facilities } = getUserData.facilities;

  // Set tenant data
  const tenantData = tenant
    ? {
      ...(getUserData.id) && { id: getUserData.id },
      ...tenant
    }
    : {};

  // Set facility data
  const facilityData = facilities
  ? {
    userDefaultFacility: facilityIdHandler(getUserData.userDefaultFacility, facilities),
    ...(facilities) && { facilities: facilities }
  }
  : {};

  // Set user data
  const userData = getUserData
    ? {
        ...(getUserData.subId) && { subId: getUserData.subId },
        ...(getUserData.userName) && { userName: getUserData.userName },
        ...(getUserData.SK) && { SK: getUserData.SK },
        ...(getUserData.id) && { id: getUserData.id },
        ...(getUserData.givenName) && { givenName: getUserData.givenName },
        ...(getUserData.familyName) && { familyName: getUserData.familyName },
        ...(getUserData.email) && { email: getUserData.email },
        ...(getUserData.phoneNumber) && { phoneNumber: getUserData.phoneNumber },
        ...(getUserData.userStatus) && { userStatus: getUserData.userStatus },
        ...(getUserData.cookies) && { cookies: getUserData.cookies }
    }
    : {};

  // TODO the following snippet is from the previous dashboard
  // function leanDashboardData(dashboardItem) {
  //   const dashboardItemData = dashboardItem
  //     ? {
  //         createdAt: dashboardItem.createdAt,
  //         dashboardId: dashboardItem.dashboardId,
  //         themeDarkDashboardId: dashboardItem.themeDarkDashboardId,
  //         dashboardOriginalName: dashboardItem.dashboardOriginalName,
  //         userDashboardName: dashboardItem.userDashboardName,
  //         userDashboardNote: dashboardItem.userDashboardNote,
  //         userDefaultDashboard: dashboardItem.userDefaultDashboard,
  //       }
  //     : {};
  //   return dashboardItemData;
  // }

  // const dashboardData = dashboards.items
  //   ? dashboards.items.map(leanDashboardData)
  //   : [];

  // const defaultDashboard = dashboardData.find(
  //   dashboardItem => dashboardItem.userDefaultDashboard === true
  // );

  return {
    userData,
    tenantData,
    facilityData
  };
}
