import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { getExecutiveFacilityData } from "graphql/mutations";
import { API, graphqlOperation } from "aws-amplify";

import { ResponsiveContainer, PieChart, Pie, Text, Cell } from 'recharts';
import Panel from "shared/components/Panel";
import { Col, Row } from "react-bootstrap";
import { colorRed } from "utils/palette";

export default function EquipmentSummary() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { currentFacility } = useSelector((state) => state.facility.facilityState);
  const { machine } = useSelector((state) => state.executiveDashboardData.executiveDashboardState);

  // tenantId is the id of the tenant that is selected
  const tenantId = currentFacility.id;
  // facilityId is the id of the facility that is selected
  const facilityId = currentFacility.SK;

  // States that control loading and error states
  const [isLoading, setIsLoading] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [summaryPanelsRender, setSummaryPanelsRender] = useState(null)

  const [equipmentChannelValues, setEquipmentChannelValues] = useState(null)

  useEffect(() => {
  }, [machine])
  // Code for error state modal
  // It is different than title and description
  const [code, setCodeHandler] = useState("");

  async function getFacilityDeviceHandler() {
    const facilityDeviceInput = {

      tenantId: tenantId,
      facilityId: facilityId,
    };
    // API call to create manual device data
    await API.graphql(
      graphqlOperation(
        getExecutiveFacilityData, {
        input: facilityDeviceInput
      })
    ).then((response) => {
      setEquipmentChannelValues(response.data.getExecutiveFacilityData)
      setIsLoading(false);

    }).catch((err) => {
      setIsLoading(false);
    });

    return facilityDeviceInput;
  };

  useEffect(() => { //to get analog or digital data
    getFacilityDeviceHandler()
  }, [facilityId, tenantId])

  useEffect(() => { //to main graph screen
    if (equipmentChannelValues != null) {
      setSummaryPanelsRender(() =>
        // Object.entries(equipmentChannelValues).map(([equipmentKey, equipmentValue]) => {
        Object.entries(machine).map(([equipmentKey, equipmentValue]) => {
          if (
            Object.keys(equipmentValue).length > 0
            && Object.keys(equipmentValue.equipmentChannelValues).length > 0
          ) {
            return (Object.entries(equipmentValue.equipmentChannelValues).map(([equipmentKey, equipmentValue]) => {
              return (
                <Panel
                  key={`${equipmentKey}-panel`}
                  md={12} xl={6} lg={12} sm={12} xs={12}
                  // title={JSON.parse(equipmentValue.deviceFacilityValues).equipmentName}
                  title={equipmentValue.dataName}
                  padding={screenWidth > 500 ? 10 : 0}
                >
                  <Row style={{ alignItems: "center", justifyContent: 'center' }}>
                    {equipmentValue.equipmentCategory === "manualDataEntry" &&
                      <div style={{ textAlign: 'center', justifyContent: 'center', backgroundColor: 'Highlight', marginBottom: 10 }}>
                        {t("pages.dashboardPage.charts.executiveDashboardElectricity.subTitle.manualDataEntry")}
                      </div>}
                    <div style={{ position: 'relative', width: '100%', height: 160, justifyContent: 'center' }}>

                      <ResponsiveContainer>
                        <PieChart>
                          <Pie
                            data={[{ name: 'Veri 1', value: 30 }]}
                            dataKey="value"
                            innerRadius="130%"
                            outerRadius="150%"
                            cy={110}
                            startAngle={180}
                            endAngle={0}
                            paddingAngle={0}
                            fill='orange'
                          >
                          </Pie>
                        </PieChart>
                      </ResponsiveContainer>
                      <div style={{ textAlign: 'center', justifyContent: 'center', marginTop: -80 }}>
                        {/*<DashboardStatMainNumber>{JSON.parse(equipmentValue.deviceFacilityValues).dataValue.toFixed(2)}</DashboardStatMainNumber>*/}
                        <p style={{fontSize: 40}}>{equipmentValue.dataValue.toFixed(2)}</p>



                      </div>
                      {/* <p style={{ fontSize: 20, textAlign: 'center', }}>{JSON.parse(equipmentValue.deviceFacilityValues).dataUnit}</p>*/}

                      <p style={{ fontSize: 20, textAlign: 'center', marginTop: -10 }}>{equipmentValue.dataUnit}</p>
                    </div>
                    <div style={{ textAlign: 'center', justifyContent: 'center' }}>
                      {t("pages.dashboardPage.charts.executiveDashboardElectricity.date.lastUpdateDate")} : {new Date(equipmentValue.timeIso).toLocaleString([], { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: false })}
                    </div>
                  </Row>
                </Panel>
              );
            }))
          } else {
            return;
          }
        }))
    }
  }, [equipmentChannelValues, machine])
  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }} >
      {equipmentChannelValues ? summaryPanelsRender : <div style={{ textAlign: 'center', backgroundColor: 'Highlight', color: 'black', width: '100%' }}>{t("error.dashboard.message.DashboardNoData")}</div>}
    </div>
  )
}
