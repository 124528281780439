import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Field, Form } from 'react-final-form';
import Select from 'react-select';
import { useLocation } from 'react-router-dom'
import { currentFacilityHandler } from "redux/slices/facilitySlice";
import getDeviceData from "containers/Router/WrappedRoutes/Dashboard/dashboardHelpers/getDeviceData";
import { deviceFirstRenderDataHandler } from "redux/slices/deviceDataSlice";
import {
    executiveDashboardFirstRenderDataHandler,
    executiveElectricityDashboardDataHandler,
    executiveMachineDashboardDataHandler
} from "redux/slices/executiveDashboardSlice";
import { useDispatch, useSelector } from 'react-redux';

import {
    Card, CardBody, Col, Button,
} from 'reactstrap';

export default function CreateNewTask() {
    const dispatch = useDispatch();

    const { t, i18n } = useTranslation();
    const userData = useSelector((state) => state.user.userState);
    const location = useLocation();
    const [choosenFacilityData, setChoosenFacilityData] = useState(null);
    const [choosenMachineData, setChoosenMachineData] = useState(null);

    const themeData = useSelector((state) => state.theme.className);
    const { facilities } = useSelector((state) => state.facility.facilityState);
    const [machineList, setMachineList] = useState(null)
    const { machine } = useSelector((state) => state.executiveDashboardData.executiveDashboardState);
    const { devices } = useSelector((state) => state.deviceData.deviceDataState);
    const findEquipmentName = (equipmentKey) => devices.filter(device => device.descriptiveCode === equipmentKey)[0].equipmentName;


    const facilityStatusOptions = facilities.map((facilityItem) => {
        return { value: facilityItem, label: facilityItem.unitName }
    })




    const { currentFacility } = useSelector(
        (state) => state.facility.facilityState
    );
    const tenantId = currentFacility.id;


    const onSubmit = async (dataOnSubmit) => {
        // dispatch(currentFacilityHandler(facilityElement));

    }

    useEffect(() => {
        // This snippet is stopping the code after expiry time
        //const timer = setTimeout(() => timeOutHandler(), 30000);
        getDeviceData(tenantId, choosenFacilityData?.value.SK)
            .then((response) => {
                // Dispatch the API data to the redux store
                dispatch(deviceFirstRenderDataHandler(response.deviceList));
                if (response?.deviceFacilityValues && Object.keys(response.deviceFacilityValues).length > 0)
                    dispatch(executiveDashboardFirstRenderDataHandler(response.deviceFacilityValues))
                if (!response) throw Error("No data for devices");



                // Set states to stop the loading spinner
                //setIsLoading(false);
                //setIsLoaded(true);
            })
            .catch((err) => {
                // setCodeHandler("NoDataError");
                // setStartErrorHandler(true);
            });

        // return () => cleanUpHandler(timer);
    }, [
        choosenFacilityData?.value
    ]);



// FIXME There are hardcoded words in this component. They should be moved to translation file
    return (
        <div style={{ marginLeft: 20, marginRight: 20 }}>
            <Col md={12} lg={12}>
                <Card>
                    <CardBody>
                        <div className="card__title">
                            <h5 className="bold-text">Yeni Görev Oluştur</h5>
                        </div>
                        <Form onSubmit={onSubmit}>
                            {({ handleSubmit, form }) => (
                                <form className="form form--horizontal" onSubmit={handleSubmit}>
                                    {//ocation.state == null && userData.userStatusInt >= 5 &&
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">Görev Sorumlusu</span>
                                            <div className="form__form-group-field" style={{ display: 'block' }}>
                                                <Select
                                                    name="facilityName"
                                                    value={choosenFacilityData}
                                                    onChange={setChoosenFacilityData}
                                                    options={facilityStatusOptions}
                                                    clearable={false}
                                                    className="react-select"
                                                    placeholder="Görev Sorumlusu"
                                                    styles={{
                                                        control: (provided) => ({
                                                            ...provided,
                                                            backgroundColor: themeData === "theme-dark" && "#232329",

                                                        }),
                                                        singleValue: (provided) => ({
                                                            ...provided,
                                                            color: themeData === "theme-dark" && "#dddddd",
                                                        }),
                                                    }}
                                                />
                                            </div>
                                        </div>}

                                    <div className="form__form-group">
                                        <span className="form__form-group-label">Başlık</span>
                                        <div className="form__form-group-field" style={{ display: 'block' }}>
                                            <Field
                                                name="electricityConsumption"
                                                component="input"
                                                type="text"
                                                placeholder="Başlık"
                                                maxLength={20}
                                            />
                                        </div>
                                    </div>

                                    <div className="form__form-group">
                                        <span className="form__form-group-label">Planlanan Başlangıç Tar.</span>
                                        <div className="form__form-group-field" style={{ display: 'block' }}>
                                            <Field
                                                name="waterConsumption"
                                                component="input"
                                                type="text"
                                                placeholder="Planlanan Başlangıç Tar."
                                                maxLength={20}
                                            />
                                        </div>
                                    </div>
                                    <div className="form__form-group">
                                        <span className="form__form-group-label">Planlanan Bitiş Tar.</span>
                                        <div className="form__form-group-field" style={{ display: 'block' }}>
                                            <Field
                                                name="naturalGasConsumption"
                                                component="input"
                                                type="text"
                                                placeholder="Planlanan Bitiş Tar."
                                                maxLength={20}

                                            />
                                        </div>
                                    </div>

                                    <div className="form__form-group">
                                        <span className="form__form-group-label">Görev Tanımı</span>
                                        <div className="form__form-group-field" style={{ display: 'block' }}>
                                            <Field
                                                name="naturalGasConsumption"
                                                component="textarea"
                                                type="text"
                                                placeholder="Görev Tanımı"
                                                maxLength={600}
                                                style={{ height: '50px' }}
                                            />

                                        </div>
                                    </div>

                                    <div className="form__form-group">
                                        <div className="form__form-group-field">
                                            <div style={{ textAlign: 'end', flex: 1 }}>
                                                <Button color="primary" type="submit">{t("pages.formItems.save")}</Button>
                                            </div>
                                        </div>
                                    </div>

                                </form>
                            )}
                        </Form>

                    </CardBody>
                </Card>
            </Col>
        </div>
    )
}
