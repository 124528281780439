import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { Collapse } from "reactstrap";
import DownIcon from "mdi-react/ChevronDownIcon";
import { languageHandler } from "redux/slices/languageSlice";

const TopbarLanguage = () => {
  const userLang = useSelector((state) => state.lang.lang);
  const dispatch = useDispatch();

  const { t, i18n } = useTranslation();
  // i18n listener
  // Gets fired when i18n changeLanguage function got called
  i18n.on("languageChanged", (lng) => dispatch(languageHandler(lng)));

  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleLanguage = () => {
    setIsCollapsed(!isCollapsed);
  };

  const mainButtonContent = (lang) => {
    const flagPath = `${process.env.PUBLIC_URL}/img/language/${lang}.png`;
    const i18nTranslationKey = `language.${lang}`;
    return (
      <span className="topbar__language-btn-title">
        <img src={flagPath} alt={lang} />
        <span>{t(i18nTranslationKey)}</span>
      </span>
    );
  };

  async function changeLanguage(lng) {
    await i18n
      .changeLanguage(lng)
      .then(() => toggleLanguage())
      .catch((err) => err);
  }

  return (
    <div className="topbar__collapse topbar__collapse--language">
      <button
        className="topbar__btn"
        type="button"
        onClick={() => toggleLanguage()}
      >
        {mainButtonContent(userLang)}
        <DownIcon className="topbar__icon" />
      </button>
      {isCollapsed && (
        <button
          className="topbar__back"
          type="button"
          aria-label="language button"
          onClick={() => toggleLanguage()}
        />
      )}
      <Collapse
        isOpen={isCollapsed}
        className="topbar__collapse-content topbar__collapse-content--language"
      >
        <button
          className="topbar__language-btn"
          type="button"
          onClick={() => changeLanguage("en")}
        >
          {mainButtonContent("en")}
        </button>
        <button
          className="topbar__language-btn"
          type="button"
          onClick={() => changeLanguage("tr")}
        >
          {mainButtonContent("tr")}
        </button>
        {/* <button
          className="topbar__language-btn"
          type="button"
          onClick={() => changeLanguage("de")}
        >
          {mainButtonContent("de")}
        </button> */}
      </Collapse>
    </div>
  );
};

export default TopbarLanguage;
