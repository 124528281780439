import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

export default function SignInRouter({ children }) {
  const { logIn } = useSelector((state) => state.login);

  // Set state for the current facility
  const { currentFacility, initialDashboardView } = useSelector((state) => state.facility.facilityState);
  const currentDashboardId = currentFacility?.SK;

  // Set state for the current user
  const { subId } = useSelector((state) => state.user.userState);

  const location = useLocation();

  if (
    logIn === "authenticated" &&
    // It takes some time to get response from API call and update the store
    // Due to that reason we wait for the initial dashboard view state
    !initialDashboardView
  ) {
    return currentDashboardId ? (
      <Navigate
        // To be able to use the facility id as parameter, we have to split from # character
        to={location?.state?.from?.pathname || `/dashboard/${subId}/${currentDashboardId.split("#")[1]}`}
        replace={true}
      />
    ) : (
      <Navigate to={location?.state?.from?.pathname || "/hub"} replace={true} />
    );
  }

  return children ? children : <Outlet />;
}
