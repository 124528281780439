import React, { useEffect, Suspense, useState } from 'react';
import { API, graphqlOperation } from "aws-amplify";
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { updateDevice, deleteManualDevice, createManualDevice } from "graphql/mutations";
import { useLocation } from 'react-router-dom';

//Tabs
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import MuiStyleHandler from 'containers/Router/WrappedRoutes/Dashboard/dashboardHelpers/MuiStyleHandler';
import AddNewEquipment from './AddNewEquipment';
import UpdateEquipment from './UpdateEquipment';
export default function EquipmentSettings(props) {
    const location = useLocation();

    const facilitiesFromLocationState = location.state?.choosenTenantData?.value?.facilities;
    const facilityFromReduxState = useSelector((state) => state.facility.facilityState);

    const facilityState = facilitiesFromLocationState || facilityFromReduxState;    
    const { children, index, ...other } = props;
    const [value, setValue] = useState(0);
    const { t, i18n } = useTranslation();
    const [choosenFacilityItem, setChoosenFacilityItem] = useState(null)



    function a11yProps(index) {
        return {
            id: `dashboard-tab-${index}`,
            'aria-controls': `dashboard-tabpanel-${index}`,
        };
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    function TabPanel(props) {
        const { children, value, index, ...other } = props;
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`dashboard-tabpanel-${index}`}
                aria-labelledby={`dashboard-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <div>{children}</div>
                    </Box>
                )}
            </div>
        );
    }
    return (
        <MuiStyleHandler>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="sanaiinfo panel">
                        <Tab label={t("pages.formItems.addNew")} {...a11yProps(0)} />
                        {facilityState.facilities.map((facilityItem, facilityIndex) =>
                            <Tab key={facilityIndex + 1}
                                label={facilityItem.unitName}
                                onClick={() => setChoosenFacilityItem(facilityItem)}
                                {...a11yProps(facilityIndex + 1)} />
                        )
                        }
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <Suspense fallback={<div>Loading...</div>}>
                        <AddNewEquipment />
                    </Suspense>
                </TabPanel>
                {facilityState.facilities.map((facilityItem, facilityIndex) =>
                    <TabPanel key={facilityIndex + 1} value={value} index={facilityIndex + 1}>
                        <Suspense fallback={<div>Loading...</div>}>
                            <UpdateEquipment facilityItem={choosenFacilityItem} />
                        </Suspense>
                    </TabPanel>
                )}
            </Box>
        </MuiStyleHandler>
    )
}

