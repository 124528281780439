import React, { Fragment, useState, useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";
import Loading from "shared/components/Loading";
import Topbar from "../../Layout/topbar/Topbar";
import EmailHandler from "./components/inputFields/EmailFieldHandler";
import LogInFormSharedFields from "./components/SharedFieldsLogin";
import PasswordField from "./components/inputFields/PasswordField";
import InputFieldHandler from "./components/inputFields/InputFieldHandler";
import completeNewPassword from "./AwsCognito/completeNewPassword";
import changePassword from "./AwsCognito/changePassword";
import forgotPassword from "./AwsCognito/forgotPassword";
import { loginStatusHandler } from "redux/slices/logInStatusSlice";
import PasswordContext from "context/PasswordContext";
import passwordFormFields from "./components/passwordFormFields";
import {
  cognitoColoredSuccessModalRenderHandler,
  cognitoErrorModalRenderHandler,
  cognitoPrimaryModalRenderHandler,
} from "redux/slices/modalSlice";
export default function PasswordForm() {

  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // Set the password values from React Context
  const {
    currentPasswordValue,
    newPasswordValue,
    confirmNewPasswordValue,
    passwordFormType,
    passwordIsValid,
    passwordFormTypeHandler,
    resetContext,
    confirmationCode,
    confirmationCodeHandler,
  } = useContext(PasswordContext);
  // Set states from React Redux
  const { logIn } = useSelector((state) => state.login);
  const { email } = useSelector((state) => state.user.userState);
  const [userAgreementAccept, setUserAgreementAccept] = useState(false)
  // Header and button text fields for password form
  const { headerText, buttonText } = passwordFormFields(passwordFormType);
  // Async fonksiyonlar dursun
  const [isLoading, setIsLoading] = useState(false);
  // Show warning message for current password
  const [
    showCurrentPasswordWarningMessage,
    setShowCurrentPasswordWarningMessageHandler,
  ] = useState(false);
  // Show warning message for new password
  const [
    showNewPasswordWarningMessage,
    setShowNewPasswordWarningMessageHandler,
  ] = useState(false);
  // Show warning message for confirmed password
  const [
    showConfirmedPasswordWarningMessage,
    setShowConfirmedPasswordWarningMessageHandler,
  ] = useState(false);
  // Show warning message for email
  const [showEmailWarningMessage, setShowEmailWarningMessageHandler] =
    useState(false);
  // Show warning message for confirmation code
  const [
    showConfirmationCodeWarningMessage,
    setShowConfirmationCodeWarningMessageHandler,
  ] = useState(false);
  // Check the validities of the fields
  // trim is used to get rid of whitespaces
  // Check if email field has a value without whitespaces
  const emailIsValid = email.trim();
  const confirmationCodeIsValid = confirmationCode.trim();
  const currentPasswordIsValid = passwordIsValid(currentPasswordValue);
  const newPasswordIsValid = passwordIsValid(newPasswordValue);
  // Confirmation password should be longer than given character lower limit and
  // should be equal to new password value
  const confirmNewPasswordIsValid =
    passwordIsValid(confirmNewPasswordValue) &&
    newPasswordValue === confirmNewPasswordValue;
  // This variable will be used for the localization of modal messages
  const errorFromService = "cognito";
  const openModal = (title, message) => {
    dispatch(
      cognitoPrimaryModalRenderHandler({
        name: title,
        message: message,
      })
    );
  }
  async function handleSubmit() {
    // Warning messages are shown only after the click
    // If code has been sent to the email address and fields are not valid,
    // warning message states set as true
    if (!emailIsValid) setShowEmailWarningMessageHandler(true);

    if (passwordFormType === "forgotPasswordAfterCode") {
      if (!confirmationCodeIsValid)
        setShowConfirmationCodeWarningMessageHandler(true);
      if (!currentPasswordIsValid)
        setShowCurrentPasswordWarningMessageHandler(true);
      if (!newPasswordIsValid) setShowNewPasswordWarningMessageHandler(true);
      if (!confirmNewPasswordIsValid)
        setShowConfirmedPasswordWarningMessageHandler(true);
      if (!confirmNewPasswordIsValid)
        setShowConfirmedPasswordWarningMessageHandler(true);
    }
    // If email field is valid and confirmed password value matches with new password
    // Set the value of the password that will be passed to AWS Cognito as confirmed password
    const cognitoPassword =
      emailIsValid &&
      confirmationCodeIsValid &&
      confirmNewPasswordIsValid &&
      confirmNewPasswordValue;

    const cognitoPasswordForTemporaryPassword =
      emailIsValid &&
      confirmNewPasswordIsValid &&
      confirmNewPasswordValue;

    setIsLoading(true);

    try {
      // In the first sign in attempt, user changes the temporary password
      if (cognitoPasswordForTemporaryPassword && passwordFormType === "changeTemporaryPassword") {
        await completeNewPassword(email, currentPasswordValue, cognitoPasswordForTemporaryPassword)
          // Open modal on Success
          .then(() => {
            const successMessage = {
              name: t(
                "modal.modalMessage.onSuccess.name.temporaryPasswordChangeSuccess"
              ),
              message: t(
                "modal.modalMessage.onSuccess.message.temporaryPasswordChangeSuccess"
              ),
            };
            dispatch(cognitoColoredSuccessModalRenderHandler(successMessage));
          })
          .then(() => dispatch(loginStatusHandler("authenticated")))
          // If password has changed successfully, go to the main page
          .then(() => navigate("/sign_in", { replace: true }))
          // Password context is reset after promise fulfilled
          .then(() => resetContext());
        // User can change the password if he/she is already signed in
      }
      // else if (cognitoPassword && passwordFormType === "changePassword") {
      //   await changePassword(currentPasswordValue, cognitoPassword)
      //   // A modal is opened to inform the user about password change
      //   .then(() => {
      //     const successMessage = {
      //       name: t("modal.modalMessage.onSuccess.name.passwordChangeSuccess"),
      //       message: t("modal.modalMessage.onSuccess.message.passwordChangeSuccess"),
      //     };
      //     dispatch(cognitoColoredSuccessModalRenderHandler(successMessage));
      //   })
      //   // If password has changed successfully, go to the main page
      //   .then(() => navigate("/sign_in", {replace: true}))
      //   // Reset all the values in the password context
      //   .then(() => resetContext());
      // }
      else if (
        emailIsValid &&
        passwordFormType === "forgotPasswordBeforeCode"
      ) {
        await forgotPassword(passwordFormType, email)
          // Password form state in context is changed
          .then(() => passwordFormTypeHandler("forgotPasswordAfterCode"))
          // A modal is opened to inform user about sent code
          .then(() => {
            const modalMessage = {
              name: t(
                "modal.modalMessage.onSuccess.name.forgotPasswordBeforeCode"
              ),
              message: t(
                "modal.modalMessage.onSuccess.message.forgotPasswordBeforeCode"
              ),
            };
            dispatch(cognitoPrimaryModalRenderHandler(modalMessage));
          });
      } else if (
        cognitoPassword &&
        passwordFormType === "forgotPasswordAfterCode"
      ) {
        await forgotPassword(
          passwordFormType,
          email,
          confirmationCode,
          cognitoPassword
        )
          // A modal is opened to inform the user about password change
          .then(() => {
            const successMessage = {
              name: t(
                "modal.modalMessage.onSuccess.name.passwordChangeSuccess"
              ),
              message: t(
                "modal.modalMessage.onSuccess.message.passwordChangeSuccess"
              ),
            };
            dispatch(cognitoColoredSuccessModalRenderHandler(successMessage));
          })
          // If password has changed successfully, go to the main page
          .then(() => navigate("/sign_in", { replace: true }))
          // Reset all the values in the password context
          .then(() => resetContext());
      } else {
        // console.log("Nothing happened at PasswordForm");
      }
    } catch (err) {
      // In case of an error, user is informed by an error message
      dispatch(
        cognitoErrorModalRenderHandler({
          errorFromService,
          errorState: err.message,
        })
      );
    } finally {
      setIsLoading(false);
      // Reset all the values in the password context
      // resetContext();
    }
  }

  return (
    <Fragment>
      {isLoading && <Loading loading={isLoading} />}
      <Topbar />
      <LogInFormSharedFields>
        <div>
          <h3
            className="account__subhead subhead"
            style={{
              color: "#2293bc",
              fontWeight: "bold",
              marginBottom: "20px",
            }}
          >
            {t(headerText)}
          </h3>
        </div>
        <form className="form reset-password-form">
          <EmailHandler
            renderName={t("auth.emailFieldRenderName")}
            placeholder={t("auth.emailFieldPlaceholder")}
            showWarningMessage={showEmailWarningMessage}
            showWarningMessageHandler={setShowEmailWarningMessageHandler}
            invalidFieldMessage={t("email.emailFieldEmptyWarningMessage")}
          />
          {passwordFormType === "changePassword" && (
            <PasswordField
              renderName={t("password.currentPassword")}
              placeholder={t("password.currentPassword")}
              showWarningMessage={showCurrentPasswordWarningMessage}
              showWarningMessageHandler={
                setShowCurrentPasswordWarningMessageHandler
              }
            />
          )}
          {/* Forgot password confirmation code field */}
          {passwordFormType === "forgotPasswordAfterCode" && (
            <InputFieldHandler
              renderName={t("password.verificationCodeName")}
              placeholder={t("password.verificationCodePlaceHolder")}
              eventHandler={confirmationCodeHandler}
              warningMessage={t("password.verificationCodeWarningMessage")}
              showWarningMessage={showConfirmationCodeWarningMessage}
              showWarningMessageHandler={
                setShowConfirmationCodeWarningMessageHandler
              }
              required
            />
          )}
          {passwordFormType !== "forgotPasswordBeforeCode" && (
            <PasswordField
              renderName={t("password.newPassword")}
              placeholder={t("password.newPassword")}
              showWarningMessage={showNewPasswordWarningMessage}
              showWarningMessageHandler={
                setShowNewPasswordWarningMessageHandler
              }
            />
          )}
          {passwordFormType !== "forgotPasswordBeforeCode" && (

            <div style={{ width: '100%' }}>
              <PasswordField
                renderName={t("password.confirmPassword")}
                placeholder={t("password.confirmPassword")}
                showWarningMessage={showConfirmedPasswordWarningMessage}
                showWarningMessageHandler={
                  setShowConfirmedPasswordWarningMessageHandler
                }

              />
              
              <div style={{ display: "flex", alignItems: "center" }}>
                <input
                  type="checkbox"
                  checked={userAgreementAccept}
                  style={{ width: "12px", height: "12px", marginRight: "5px" }}
                  onChange={() => setUserAgreementAccept(!userAgreementAccept)}
                />
                <label>
                  <b>
                    <span onClick={() => openModal(t("pages.landing.termsAndConditions.title"), t("pages.landing.termsAndConditions.message"))} style={{ cursor: "pointer" }}>
                      {t("auth.userAgreement")}
                    </span>
                  </b>
                </label>
              </div>

            </div>
          )}

          {
              <Button
                className="account__btn"
                color="primary"
                onClick={() => handleSubmit()}
                disabled={ passwordFormType === "changeTemporaryPassword"  ? !userAgreementAccept : false} //If they are coming to change the password for the first time upon login, they should have accepted the terms and conditions
              >
                {t(buttonText)}
              </Button>
          }
        </form>
        {logIn !== "formChangePassword" && (
          <div className="account__have-account">
            <p>
              <NavLink to="/sign_in">
                {t("password.rememberYourPassword")}
              </NavLink>
            </p>
          </div>
        )}
      </LogInFormSharedFields>
    </Fragment>
  );
}
