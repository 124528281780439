import { Auth } from "aws-amplify";

export default async function forgotPassword(
  passwordFormType,
  username,
  code = "",
  newPassword = ""
) {
  try {
    if (passwordFormType === "forgotPasswordBeforeCode") {
      // If state is "forgotPasswordBeforeCode"
      // Send confirmation code to user's email
      await Auth.forgotPassword(username);
    } else if (passwordFormType === "forgotPasswordAfterCode" && code.trim()) {
      // If state is "forgotPasswordAfterCode"
      // and confirmation code field is empty
      // throw an error
      // else collect confirmation code and new password
      await Auth.forgotPasswordSubmit(username, code, newPassword);
    } else {
     // console.log("Something went wrong at Auth.forgotPassword");
    }
  } catch (error) {
    throw new Error(JSON.stringify(error));
  }
}
