import React, { useState, useEffect } from 'react';
import { API, graphqlOperation } from "aws-amplify";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { facilityInitialDataHandler } from 'redux/slices/facilitySlice';
import { Auth } from "aws-amplify";
import { updateFacility } from "graphql/mutations";
import Collapse from "shared/components/Collapse";
import { useLocation } from 'react-router-dom'
import { getUsersByTenantId } from "graphql/queries";
import {
    cognitoErrorModalRenderHandler,
    cognitoColoredSuccessModalRenderHandler,
    resetModalHandler
} from "redux/slices/modalSlice";
// easydev imports
import { Field, Form } from 'react-final-form';
import {
    Card, CardBody, Col, Button, Table
} from 'reactstrap';
import { statusColored } from 'functions/UserStatusColored';
export default function FacilityForm({ facilityItem, facilityIndex }) {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const { facilityState } = useSelector((state) => state.facility);
    const [unitNameInputError, setUnitNameInputError] = useState(false)
    const [userList, setUserList] = useState([]);
    const userData = useSelector((state) => state.user.userState);
    const location = useLocation();
    // Function to update the facility data
    async function updateFacilityHandler(facilityAttributes) {
        // Create the parameters for the update operation
        const updateOperationInput = {
            // Id should be fetched from the redux store
            ...facilityAttributes
        };
        updateOperationInput.id = facilityItem.id;
        updateOperationInput.SK = facilityItem.SK;
        updateOperationInput.unitName = facilityAttributes.unitName
        await API.graphql(
            graphqlOperation(
                updateFacility, {
                input: updateOperationInput
            })
        ).catch((err) => {
            throw new Error(err.errors[0]);
        });
        // Return the updated attributes for Redux store update
        return updateOperationInput;
    };
    const onSubmit = async (dataOnSubmit) => {
        try {
            await updateFacilityHandler(dataOnSubmit)
                .then((updatedAttribures) => {
                    const updateFacilityInput = {
                        ...facilityState,
                        facilities: facilityState.facilities.map((item, index) => {
                            if (index === facilityIndex) {
                                return { ...item, unitName: updatedAttribures.unitName }
                            }
                            return item
                        })
                    };
                    dispatch(facilityInitialDataHandler(updateFacilityInput));
                })
                .then(() => {
                    const successMessage = {
                        name: t(
                            "modal.modalMessage.onSuccess.name.attributeUpdateSuccess"
                        ),
                        message: t(
                            "modal.modalMessage.onSuccess.message.attributeUpdateSuccess"
                        ),
                    };
                    dispatch(cognitoColoredSuccessModalRenderHandler(successMessage));
                })
        } catch (err) {
            const errorState = {
                name: "updateError",
                code: "updateError"
            };
            // In case of an error, user is informed by an error message
            dispatch(
                cognitoErrorModalRenderHandler({
                    errorFromService: "apiOperation",
                    errorState: JSON.stringify(errorState)
                })
            );
        }
    };
    const header = [
        { id: 1, title: t("userListHeaders.activate") },
        { id: 2, title: t("userListHeaders.givenName") },
        { id: 3, title: t("userListHeaders.familyName") },
        { id: 4, title: t("userListHeaders.userName") },
        { id: 5, title: t("userListHeaders.status") },
    ];
    async function queryTenantUsersHandler() {
        // For security reasons, the attributes are retrieved directly from Amplify
        const { attributes } = await Auth.currentAuthenticatedUser();
        // API call to query users data
        const graphQlDataAtQueryTenantUsers = await API.graphql(
            graphqlOperation(
                getUsersByTenantId, {
                tenantId: attributes["custom:tenantId"],
                subId: attributes.sub
            })
        ).catch((err) => {
            throw new Error(err.errors[0]);
        });
        // Return the list of users registered to the tenant
        return graphQlDataAtQueryTenantUsers.data.getUsersByTenantId;
    };
    async function updateFacilityHandlerSubs(facilityAttributes) {
        // Create the parameters for the update operation
        const updateOperationInput = {
            // Id should be fetched from the redux store
            ...facilityAttributes
        };
        updateOperationInput.id = facilityItem.id;
        updateOperationInput.SK = facilityItem.SK;
        //updateOperationInput.unitName = facilityAttributes.unitName
        // API call to update tenant data
        await API.graphql(
            graphqlOperation(
                updateFacility, {
                input: updateOperationInput
            })
        ).catch((err) => {
            throw new Error(err.errors[0]);
        });
        // Return the updated attributes for Redux store update
        return updateOperationInput;
    };
    const changeActiveStatus = async (userSubID) => {
        const updatedData = facilityItem.userSubIds.includes(userSubID) ? { userSubIds: facilityItem.userSubIds.filter(subId => subId !== userSubID) } : { userSubIds: [...facilityItem.userSubIds, userSubID] }
        try {
            await updateFacilityHandlerSubs(updatedData)
                .then((updatedAttribures) => {
                    const updateFacilityInput = {
                        ...facilityState,
                        facilities: facilityState.facilities.map((item, index) => {
                            if (index == facilityIndex) {
                                return { ...item, userSubIds: updatedAttribures.userSubIds }
                            }
                            return item
                        })
                    };
                    dispatch(facilityInitialDataHandler(updateFacilityInput));
                })
                .then(() => {
                    const successMessage = {
                        name: t(
                            "modal.modalMessage.onSuccess.name.attributeUpdateSuccess"
                        ),
                        message: t(
                            "modal.modalMessage.onSuccess.message.attributeUpdateSuccess"
                        ),
                    };
                    dispatch(cognitoColoredSuccessModalRenderHandler(successMessage));
                })
        } catch (err) {
            const errorState = {
                name: "updateError",
                code: "updateError"
            };
            // In case of an error, user is informed by an error message
            dispatch(
                cognitoErrorModalRenderHandler({
                    errorFromService: "apiOperation",
                    errorState: JSON.stringify(errorState)
                })
            );
        }

    }
    useEffect(() => {
        // Only certain level of users can fetch the list of users
        queryTenantUsersHandler()
            .then((arrayOfUsers) => {
                setUserList(arrayOfUsers);
            })
            .catch((errorQueryTenantUsers) => {
                // In case of an error, user is informed by an error message
                // For security reasons, the error message is displayed as "Something went wrong"
                dispatch(
                    cognitoErrorModalRenderHandler({
                        errorFromService: "apiOperation",
                        errorState: JSON.stringify(errorQueryTenantUsers)
                    })
                );
            });

        // Clean up function
        return () => {
            setUserList([]);
            dispatch(resetModalHandler());
        }
    }, [userData.userStatusInt]);
    return (
        <div style={{ marginLeft: 20, marginRight: 20 }}>
            <Col md={12} lg={12}>
                <Card>
                    <CardBody>
                        <div className="card__title">
                            <h5 className="bold-text">{t("pages.settings.settings.facility")} </h5>
                        </div>
                        <div>
                            <Form onSubmit={onSubmit}
                                validate={(values) => {
                                    const errors = {};
                                    if (values.unitName && values.unitName.length > 19) {
                                        setUnitNameInputError(true)
                                    } else {
                                        setUnitNameInputError(false)
                                    }
                                    return errors;
                                }}>
                                {({ handleSubmit, form }) => (
                                    <form className="form form--horizontal" onSubmit={handleSubmit}>
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">{t("pages.formItems.facilityName")}</span>
                                            <div className="form__form-group-field" style={{ display: 'block' }}>
                                                <Field
                                                    name="unitName"
                                                    component="input"
                                                    type="text"
                                                    placeholder={t("pages.formItems.facilityName")}
                                                    initialValue={facilityItem.unitName}
                                                    maxLength={20}

                                                />
                                                {unitNameInputError && <span className="form__form-group-error">{t("pages.settings.errors.maxLengthError")}</span>}

                                            </div>
                                        </div>
                                        {/*   <div className="form__form-group">
                                        <span className="form__form-group-label">{t("pages.formItems.industryCategory")}</span>
                                        <div className="form__form-group-field">
                                            <Field
                                                name="industryCategory"
                                                component="input"
                                                type="text"
                                                placeholder={t("pages.formItems.industryCategory")}
                                                initialValue={facilityItem.industryCategory}


                                            />
                                        </div>
                                    </div>
                                    <div className="form__form-group">
                                        <span className="form__form-group-label">{t("pages.formItems.facilityAdress")}</span>
                                        <div className="form__form-group-field">
                                            <Field
                                                name="facilityAdress"
                                                component="input"
                                                type="text"
                                                placeholder={t("pages.formItems.facilityAdress")}
                                                

                                            />
                                        </div>
                                    </div>
                                    <div className="form__form-group">
                                        <span className="form__form-group-label">{t("pages.formItems.facilityTelephoneNumber")}</span>
                                        <div className="form__form-group-field">
                                            <Field
                                                name="facilityTelephoneNumber"
                                                component="input"
                                                type="text"
                                                placeholder={t("pages.formItems.facilityTelephoneNumber")}

                                            />
                                        </div>
                                    </div>
                                    
                                    <div className="form__form-group">
                                        <span className="form__form-group-label">{t("pages.formItems.facilityZipCode")}</span>
                                        <div className="form__form-group-field">
                                            <Field
                                                name="facilityZipCode"
                                                component="input"
                                                type="text"
                                                placeholder={t("pages.formItems.facilityZipCode")}

                                            />
                                        </div>
                                    </div>*/}
                                        <div className="form__form-group">
                                            <div className="form__form-group-field">
                                                <div style={{ textAlign: 'end', flex: 1 }}>
                                                    <Button color="primary" type="submit">{t("pages.formItems.save")}</Button>
                                                </div>
                                            </div>
                                        </div>

                                    </form>
                                )}
                            </Form>
                        </div>
                        <div>
                            <div style={{ flex: 1 }}>
                                <Collapse title={t("pages.formItems.activeUsersList")} className="with-shadow" isOpen={true}>
                                    <Table responsive hover>
                                        <thead>
                                            <tr>
                                                {header.map(item => (
                                                    <th key={item.id} style={{ padding: '0 20px', verticalAlign: 'middle' }}>{item.title}</th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody >
                                            {userList.map((item, index) => (
                                                <tr
                                                    style={{ backgroundColor: index % 2 == 0 && 'ButtonShadow', height: 50 }}

                                                    key={item.id + "-" + index} /*onClick={() => console.log("ClickedList Item")}*/>
                                                    <td style={{ padding: '0 20px', verticalAlign: 'middle' }}>
                                                        <input
                                                            type="checkbox"
                                                            checked={item.userStatus === "owner" || item.userStatus === "admin" ? true : facilityItem.userSubIds.includes(item.subId)}
                                                            onChange={() => changeActiveStatus(item.subId)}
                                                            disabled={item.userStatus === "owner" || item.userStatus === "admin" ? true : false}
                                                        />
                                                    </td>
                                                    <td style={{ padding: '0 20px', verticalAlign: 'middle' }}>
                                                        {item.givenName}</td>
                                                    <td style={{ padding: '0 20px', verticalAlign: 'middle' }}>
                                                        {item.familyName}</td>
                                                    <td style={{ padding: '0 20px', verticalAlign: 'middle' }}>
                                                        {item.email}</td>
                                                    <td style={{ padding: '0 20px', verticalAlign: 'middle' }}>

                                                        <div style={{ width: "100px", textAlign: 'center', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', backgroundColor: statusColored(item.userStatus), borderRadius: 10, color: 'white' }}>
                                                            {item.userStatus}
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </Collapse>
                            </div>
                            
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </div>
    )
}

