import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Collapse from "shared/components/Collapse";
import getDeviceData from "containers/Router/WrappedRoutes/Dashboard/dashboardHelpers/getDeviceData";
import { deviceFirstRenderDataHandler } from "redux/slices/deviceDataSlice";
import {
  executiveDashboardFirstRenderDataHandler,
} from "redux/slices/executiveDashboardSlice";
import DashboardAreaChartHandler from 'containers/Router/WrappedRoutes/Dashboard/dashboardHelpers/dashboardGraphHandler';
import { utilityDashboardSettings } from 'containers/Router/WrappedRoutes/Dashboard/dashboardHelpers/settingsDashboardUtility';
import Loading from "shared/components/Loading";
import getFacilityData from "containers/Router/WrappedRoutes/Dashboard/dashboardHelpers/getFacilityData";
import { dashboardFirstRenderDataHandler } from "redux/slices/dashboardDataSlice";
import { formatDate } from 'functions/FormatDate';
import { useTranslation } from "react-i18next";

export default function EquipmentDetails({ dir }) {
  const dispatch = useDispatch();
  const [machineRender, setMachineRender] = useState(null)
  const [machineDashboardRender, setMachineDashboardRender] = useState(null)
  const [insideCollapse, setInsideCollapse] = useState(null)
  const { t, i18n } = useTranslation();

  const { responseData: { historicalData } } = useSelector(
    (state) => state.dashboardData.dashboardDataState.dashboardData
  );
  const { machine } = useSelector((state) => state.executiveDashboardData.executiveDashboardState);
  const { devices } = useSelector((state) => state.deviceData.deviceDataState);
  const { storeStartTime, storeEndTime, storeTimeStatus } = useSelector((state) => state.timeData.timeState);
  const { currentFacility } = useSelector(
    (state) => state.facility.facilityState
  );
  // tenantId is the id of the tenant that is selected
  const tenantId = currentFacility.id;
  // facilityId is the id of the facility that is selected
  const facilityId = currentFacility.SK;
  const [isLoading, setIsLoading] = useState(true);
  const [codeHandler, setCodeHandler] = useState("");
  const [startErrorHandler, setStartErrorHandler] = useState(false);
  const [openTabDescriptiveCode, setOpenTabDescriptiveCode] = useState(null);
  // These attributes will be ignored during rendering
  const listOfAttributesToIgnore = [
    "descriptiveCode",
    "dataUtilizationCode",
    "measure_name",
    "epochIso",
    "epochUtc",
    "time"
  ]
  const machineDashboardItemRenderFunc = (descriptiveCode) => {


    if (descriptiveCode && historicalData.facility.machine) {
      return (historicalData.facility.machine[descriptiveCode] ?
        setInsideCollapse(Object.entries(historicalData.facility.machine[descriptiveCode]).map(([key, value]) => {
          if (key != "descriptiveCode") {
            // console.log("key - ", key, "value - ", value, "historicalData", historicalData)

            if (
              !listOfAttributesToIgnore.includes(key.dataName)
              && value.data.length > 0
            ) {

              return (
                <DashboardAreaChartHandler
                  key={key}
                  dir={dir}
                  objectKey={key === "equipmentState" ? key : "analog"}
                  dataSet={value.data}
                  objectTitle={value.dataName}
                  subhead={value.dataUnit}
                  chartSettings={key === "equipmentState" ? utilityDashboardSettings.historicalData.facility.equipmentState : utilityDashboardSettings.historicalData.facility.analogDataGraph}
                />
              )
            }
          }
        })) : null)
    }
  }
  const getFacilityGraphData = (descriptiveCode) => {
    setOpenTabDescriptiveCode(descriptiveCode)

    setInsideCollapse(null)
    const nowTime = new Date()
    const endTimeValue = storeTimeStatus === "now" ? formatDate(nowTime) : storeEndTime;
    const startTimeValue = storeTimeStatus === "now" ? formatDate(new Date(nowTime.getTime() - 60 * 60 * 1000)) : storeStartTime;
    // Fetch data for the given facility for the given time range
    let timeoutId;
    const timeoutDuration = 10000; // time out second 10 seconds

    const fetchData = getFacilityData(
      "historicalFacilityData",
      descriptiveCode,
      facilityId,
      endTimeValue,
      startTimeValue
    )
       // Zaman aşımı işlemi
       const timeout = new Promise((_, reject) => {
        setTimeout(() => {
          reject(new Error("Zaman aşımı"));
        }, timeoutDuration);
      });

      Promise.race([fetchData, timeout])
      .then((responseData) => {

        // Dispatch the API data to the redux store
        dispatch(dashboardFirstRenderDataHandler({
          descriptiveCodeOfTheData: descriptiveCode,
          responseData
        }));
        if (responseData.noDataAvailableForTheGivenPeriod) {


          setInsideCollapse(<div style={{ textAlign: 'center', backgroundColor: 'Highlight', color: 'black' }}>{t("error.dashboard.message.DashboardNoData")}</div>)

        }
        machineDashboardItemRenderFunc(descriptiveCode)

      })
      .catch((err) => {
        // Eğer fetch işlemi zaman aşımına uğradıysa veya başka bir hata olduysa bu blok çalışır
        if (err.message === "Zaman aşımı") {
          //console.log("Zaman aşımı");
        } else {
          // Diğer hata durumları için gerekli işlemleri yapabilirsiniz
          setCodeHandler("NoDataError");
          setStartErrorHandler(true);
        }
      });

   
  }
  const dashboardChartHandler = () => {
    if (historicalData.utility.electricity) {
      return (
        Object.entries(devices).map(([key, value]) => {
          // Find the device with the same descriptiveCode as the electricityElement
          //  const currentDevice = devices.filter(obj => obj.descriptiveCode === key);

          //if (currentDevice.length > 0) 
          if (value.dataUtilizationCode === "machine") {
            //  const { equipmentName } = currentDevice[0];

            return (
              <Collapse
                key={key}
                title={value.equipmentName}
                className="with-shadow"
                onEnteringFunc={() => getFacilityGraphData(value.descriptiveCode)}
                isOpen={openTabDescriptiveCode === value.descriptiveCode ? true : false}
              >
              

                {codeHandler != "timeOut" ?
                  <div>
                    {insideCollapse != null ?
                      insideCollapse : <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100px' }}><Loading transparentBackground loading={isLoading} /></div>
                    }
                  </div> : 
                  <div style={{ textAlign: 'center', backgroundColor: 'Highlight', color: 'black', width: '100%' }}>{t("error.dashboard.message.timeOut")}</div>
                }
              </Collapse >
            );
          }
        })
      )
    }
  };
  const getDeviceDataFunc = () => {
    getDeviceData(tenantId, facilityId)
      .then((response) => {
        // Dispatch the API data to the redux store
        dispatch(deviceFirstRenderDataHandler(response.deviceList));
        if (response?.deviceFacilityValues && Object.keys(response.deviceFacilityValues).length > 0)
          dispatch(executiveDashboardFirstRenderDataHandler(response.deviceFacilityValues));

        if (!response) throw Error("No data for devices");
      })
      .catch((err) => {
        setCodeHandler("NoDataError");
        setStartErrorHandler(true);
      });
  }
  const machineRenderFunc = () => {
    return (
      <div>
        {machine ? machineDashboardRender : <p>No dashboard data yet</p>}
      </div>
    )

  }

  useEffect(() => {

    setMachineRender(machineRenderFunc)//first return
  }, [machineDashboardRender])

  useEffect(() => {
    if (devices.length === 0) {
      getDeviceDataFunc()
    } else {
      setMachineDashboardRender(dashboardChartHandler)//main titles
    }

  }, [insideCollapse, devices]) //to just run enter
  useEffect(() => {
    machineDashboardItemRenderFunc(openTabDescriptiveCode)

  }, [openTabDescriptiveCode, historicalData,])


  useEffect(() => {
    if (openTabDescriptiveCode != null) {
      getFacilityGraphData(openTabDescriptiveCode)
    }

  }, [storeEndTime, storeStartTime, storeTimeStatus, currentFacility])


  return (
    <div>{machineRender}</div>
  )
}


