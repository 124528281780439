import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import EyeIcon from "mdi-react/EyeIcon";
import KeyVariantIcon from "mdi-react/KeyVariantIcon";
import PasswordContext from "context/PasswordContext";

export default function PasswordFieldHandler({
  renderName,
  placeholder,
  showWarningMessage,
  showWarningMessageHandler,
  children,
}) {
  const { t, i18n } = useTranslation();
  const {
    currentPasswordValue,
    currentPasswordValueHandler,
    newPasswordValue,
    newPasswordValueHandler,
    confirmNewPasswordValue,
    confirmNewPasswordValueHandler,
  } = useContext(PasswordContext);

  const [isPasswordShown, setIsPasswordShown] = useState(false);
  // const [isToggleCheckboxEnabled, setIsToggleCheckboxEnabled] = useState(false);

  const showPassword = () => {
    setIsPasswordShown(!isPasswordShown);
  };

  let passwordValue = currentPasswordValue;
  let passwordValueHandler = currentPasswordValueHandler;
  let warningMessage = t("password.passwordWarningMessage");

  switch (renderName) {
    case t("password.confirmPassword"):
      passwordValue = confirmNewPasswordValue;
      passwordValueHandler = confirmNewPasswordValueHandler;
      warningMessage = t("password.confirmPasswordWarningMessage");
      break;
    case t("password.newPassword"):
      passwordValue = newPasswordValue;
      passwordValueHandler = newPasswordValueHandler;
      break;
    default:
      passwordValue = currentPasswordValue;
      break;
  }

  function handleChange(event) {
    passwordValueHandler(event.target.value);
    showWarningMessageHandler(false);
  }

  return (
    <div className="form__form-group">
      <span className="form__form-group-label">{renderName}</span>
      {showWarningMessage && (
        <h4
          className="account__subhead subhead"
          style={{
            color: "red",
            fontWeight: "bold",
            marginBottom: "10px",
          }}
        >
          {warningMessage}
        </h4>
      )}
      <div className="form__form-group-field">
        <div className="form__form-group-icon">
          <KeyVariantIcon />
        </div>
        <input
          name="password"
          type={isPasswordShown ? "text" : "password"}
          placeholder={placeholder}
          value={passwordValue}
          onChange={(event) => handleChange(event)}
        />
        <button
          className={`form__form-group-button${
            isPasswordShown ? " active" : ""
          }`}
          onClick={() => showPassword()}
          type="button"
        >
          <EyeIcon />
        </button>
      </div>
      {children}
    </div>
  );
}
