import React from "react";
import { Col, Row, Container } from "reactstrap";
import { useTranslation } from "react-i18next";

const competitive = `${process.env.PUBLIC_URL}/img/landing/benefits/competitive.png`;
const security = `${process.env.PUBLIC_URL}/img/landing/benefits/security.png`;
const visualize = `${process.env.PUBLIC_URL}/img/landing/benefits/visualize.png`;
const efficiency = `${process.env.PUBLIC_URL}/img/landing/benefits/efficiency.png`;
// const economic = `${process.env.PUBLIC_URL}/img/landing/benefits/economic.png`;
const industy4 = `${process.env.PUBLIC_URL}/img/landing/benefits/industry4.png`;
const easy = `${process.env.PUBLIC_URL}/img/landing/benefits/easy.png`;
const cost = `${process.env.PUBLIC_URL}/img/landing/benefits/cost.png`;
const innovative = `${process.env.PUBLIC_URL}/img/landing/benefits/innovative.png`;

export default function Benefits() {
  const { t, i18n } = useTranslation();
  return (
    <section className="landing__section">
      <Container>
        <Row>
          <h3 className="landing__section-title">
            <b>{t("pages.landing.benefits.content.header")}</b>
          </h3>
        </Row>
        <Row>
          <Col md={12}>
            <div className="landing__features-wrap">
              <div className="landing__feature">
                <div className="landing__feature-img-wrap">
                  <img
                    src={innovative}
                    alt={t("pages.landing.benefits.imageAlt.innovative")}
                  />
                </div>
                <p className="landing__feature-title">
                  {t("pages.landing.benefits.content.subHeader.innovative")}
                </p>
                <p className="landing__feature-caption">
                  {t(
                    "pages.landing.benefits.content.subHeaderContent.innovative"
                  )}
                </p>
              </div>
              <div className="landing__feature">
                <div className="landing__feature-img-wrap">
                  <img
                    src={easy}
                    alt={t("pages.landing.benefits.imageAlt.easy")}
                  />
                </div>
                <p className="landing__feature-title">
                  {t("pages.landing.benefits.content.subHeader.easy")}
                </p>
                <p className="landing__feature-caption">
                  {t("pages.landing.benefits.content.subHeaderContent.easy")}
                </p>
              </div>
              {/* <div className="landing__feature">
                <div className="landing__feature-img-wrap">
                  <img
                    src={economic}
                    alt={t("pages.landing.benefits.imageAlt.economic")}
                  />
                </div>
                <p className="landing__feature-title">
                  {t("pages.landing.benefits.content.subHeader.economic")}
                </p>
                <p className="landing__feature-caption">
                  {t(
                    "pages.landing.benefits.content.subHeaderContent.economic"
                  )}
                </p>
              </div> */}
              <div className="landing__feature">
                <div className="landing__feature-img-wrap">
                  <img
                    src={cost}
                    alt={t("pages.landing.benefits.imageAlt.cost")}
                  />
                </div>
                <p className="landing__feature-title">
                  {t("pages.landing.benefits.content.subHeader.cost")}
                </p>
                <p className="landing__feature-caption">
                  {t("pages.landing.benefits.content.subHeaderContent.cost")}
                </p>
              </div>
              <div className="landing__feature">
                <div className="landing__feature-img-wrap">
                  <img
                    src={competitive}
                    alt={t("pages.landing.benefits.imageAlt.competitive")}
                  />
                </div>
                <p className="landing__feature-title">
                  {t("pages.landing.benefits.content.subHeader.competitive")}
                </p>
                <p className="landing__feature-caption">
                  {t(
                    "pages.landing.benefits.content.subHeaderContent.competitive"
                  )}
                </p>
              </div>
              <div className="landing__feature">
                <div className="landing__feature-img-wrap">
                  <img
                    src={efficiency}
                    alt={t("pages.landing.benefits.imageAlt.efficiency")}
                  />
                </div>
                <p className="landing__feature-title">
                  {t("pages.landing.benefits.content.subHeader.efficiency")}
                </p>
                <p className="landing__feature-caption">
                  {t(
                    "pages.landing.benefits.content.subHeaderContent.efficiency"
                  )}
                </p>
              </div>
              <div className="landing__feature">
                <div className="landing__feature-img-wrap">
                  <img
                    src={visualize}
                    alt={t("pages.landing.benefits.imageAlt.visualize")}
                  />
                </div>
                <p className="landing__feature-title">
                  {t("pages.landing.benefits.content.subHeader.visualize")}
                </p>
                <p className="landing__feature-caption">
                  {t(
                    "pages.landing.benefits.content.subHeaderContent.visualize"
                  )}
                </p>
              </div>
              <div className="landing__feature">
                <div className="landing__feature-img-wrap">
                  <img
                    src={industy4}
                    alt={t("pages.landing.benefits.imageAlt.industy4")}
                  />
                </div>
                <p className="landing__feature-title">
                  {t("pages.landing.benefits.content.subHeader.industy4")}
                </p>
                <p className="landing__feature-caption">
                  {t(
                    "pages.landing.benefits.content.subHeaderContent.industy4"
                  )}
                </p>
              </div>
              <div className="landing__feature">
                <div className="landing__feature-img-wrap">
                  <img
                    src={security}
                    alt={t("pages.landing.benefits.imageAlt.security")}
                  />
                </div>
                <p className="landing__feature-title">
                  {t("pages.landing.benefits.content.subHeader.security")}
                </p>
                <p className="landing__feature-caption">
                  {t(
                    "pages.landing.benefits.content.subHeaderContent.security"
                  )}
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
