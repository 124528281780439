import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import Image404 from "shared/img/404/404.png";

export default function NotFound404() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="not-found">
      <div className="not-found__content">
        <img className="not-found__image" src={Image404} alt="404" />
        <h3 className="not-found__info">{t("notFound.notFound")}</h3>
        <Button color="primary" onClick={() => navigate(-1)}>
          {t("notFound.back")}
        </Button>
      </div>
    </div>
  );
}
