import styled from 'styled-components';
import { CardBody } from 'shared/components/Card';
import { colorAdditional, colorDashboard } from 'utils/palette';

export const DashboardExecutiveChartCard = styled(CardBody)`
  
  * {
    text-align: center;
    min-width:180px;

  }
`;

export const DashboardExecutiveChartWrap = styled.div`
  position: relative;
`;

export const DashboardExecutiveChartInfo = styled.div`
  position: absolute;
  width: 100%;
  top: calc(50% - 55px);
  animation: label 1.5s ease-in;

  p {
    margin: 0;
  }
`;

export const DashboardExecutiveChartNumber = styled.p`
  font-size: 56px;
  line-height: 60px;
  color: ${colorDashboard};
`;

export const DashboardExecutiveChartUnits = styled.p`
  color: ${colorAdditional};
  margin-top: 5px;
`;

export const DashboardExecutiveGoal = styled.p`
  font-size: 12px;
  color: ${colorAdditional};
  margin-top: 10px;
`;
