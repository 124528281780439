import React, { useEffect } from 'react';

function MapComponent({lat, lng}) {
    useEffect(() => {
        const google = window.google; // Google Haritalar API'sini kullanabilmek için global `google` nesnesini kullanın
        const mapOptions = {
          center: { lat: lat, lng: lng }, // Şirketinizin koordinatlarını burada kullanın
          zoom: 14, // Yakınlaştırma düzeyi
        };
    
        const map = new google.maps.Map(document.getElementById('map'), mapOptions);
    
        // Şirketinizin konumunu işaretlemek için bir işaretleyici ekleyebilirsiniz
        const marker = new google.maps.Marker({
          position: { lat: lat, lng: lng },
          map: map,
          title: 'Sanai',
        });
    }, []);
    
    

  return (
    <div id="map" style={{ width: '100%', height: '400px' }}></div>
  );
}

export default MapComponent;
