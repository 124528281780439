export default function passwordFormFields(passwordFormType) {
  switch (passwordFormType) {
    case "forgotPasswordBeforeCode":
      return {
        headerText: "password.header.passwordForgotHeaderBeforeCode",
        buttonText: "password.button.passwordForgotButtonBeforeCode",
      };
    case "forgotPasswordAfterCode":
      return {
        headerText: "password.header.passwordForgotHeaderAfterCode",
        buttonText: "password.button.passwordForgotButtonAfterCode",
      };
    case "changePassword":
      return {
        headerText: "password.header.passwordChangeHeader",
        buttonText: "password.button.passwordChangeButton",
      };
    case "changeTemporaryPassword":
      return {
        headerText: "password.header.temporaryPasswordResetHeader",
        buttonText: "password.button.temporaryPasswordResetButton",
      };
    default:
      return {
        headerText: "password.header.passwordChangeHeader",
        buttonText: "password.button.passwordChangeButton",
      };
  }
}
