import React, { createContext, useState } from "react";

// Adding functions to the inital context
// helps in IDE auto completition
const PasswordContext = createContext({
  currentPasswordValue: "",
  currentPasswordIsValid: false,
  currentPasswordValueHandler: (value) => {},
  newPasswordValue: "",
  newPasswordIsValid: false,
  newPasswordValueHandler: (value) => {},
  confirmPasswordValue: "",
  confirmPasswordIsValid: false,
  confirmNewPasswordValueHandler: (value) => {},
  resetContext: () => {},
  passwordIsValid: () => {},
  // If the user does not sign out, the app stays authorized for a long time
  // For security reasons, to prevent another person to change the password
  // We prefer to send a code to the email address when password change
  // is requested
  passwordFormType: "forgotPasswordBeforeCode",
  passwordFormTypeHandler: (value) => {},
  confirmationCode: "",
  confirmationCodeHandler: (value) => {},
});

export function PasswordContextProvider(props) {
  // Value of current password
  const [currentPasswordValue, setCurrentPasswordValue] = useState("");
  // Value of new password
  const [newPasswordValue, setNewPasswordValue] = useState("");
  // Confirmed value of new password
  const [confirmNewPasswordValue, setConfirmNewPasswordValue] = useState("");
  // Current password validity
  const [currentPasswordIsValid, setCurrentPasswordValidity] = useState(false);
  // New password validity
  const [newPasswordIsValid, setNewPasswordValidity] = useState(false);
  // Confirmation password validity
  const [confirmNewPasswordIsValid, setConfirmNewPasswordValidity] =
    useState(false);
  // Password form types:
  // forgotPasswordBeforeCode
  // forgotPasswordAfterCode
  // changePassword
  // changeTemporaryPassword
  // completeNewPassword
  const [passwordFormType, setPasswordFormType] = useState(
    "forgotPasswordBeforeCode"
  );
  // Confirmation code
  const [confirmationCode, setConfirmationCode] = useState("");

  function passwordIsValid(passwordValue) {
    return passwordValue.trim().length >= 6;
  }

  function currentPasswordValueHandler(value) {
    setCurrentPasswordValue((prevState) => value);
  }

  function newPasswordValueHandler(value) {
    setNewPasswordValue((prevState) => value);
  }

  function confirmNewPasswordValueHandler(value) {
    setConfirmNewPasswordValue((prevState) => value);
  }

  function passwordFormTypeHandler(value) {
    setPasswordFormType((prevState) => value);
  }

  function confirmationCodeHandler(value) {
    setConfirmationCode((prevState) => value.trim());
  }

  function resetContext() {
    setCurrentPasswordValue((prevState) => "");
    setNewPasswordValue((prevState) => "");
    setConfirmNewPasswordValue((prevState) => "");
    setCurrentPasswordValidity(() => false);
    setNewPasswordValidity(() => false);
    setConfirmNewPasswordValidity(() => false);
    setPasswordFormType((prevState) => "forgotPasswordBeforeCode");
    setConfirmationCode((prevState) => "");
  }

  const context = {
    currentPasswordValue,
    currentPasswordIsValid,
    currentPasswordValueHandler,
    newPasswordValue,
    newPasswordIsValid,
    newPasswordValueHandler,
    confirmNewPasswordValue,
    confirmNewPasswordIsValid,
    confirmNewPasswordValueHandler,
    resetContext,
    passwordIsValid,
    passwordFormType,
    passwordFormTypeHandler,
    confirmationCode,
    confirmationCodeHandler,
  };

  return (
    <PasswordContext.Provider value={context}>
      {props.children}
    </PasswordContext.Provider>
  );
}

export default PasswordContext;
