import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Field, Form } from 'react-final-form';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import manualDataOperationHandler from "../Settings/settingsHelpers/manualDataOperationHandler";
import DatePicker from "react-datepicker";
import tr from "date-fns/locale/tr";
import { API, graphqlOperation } from "aws-amplify";
import { getDeviceForManualDataOperations } from "graphql/mutations";
import {
    cognitoColoredSuccessModalRenderHandler,
    cognitoErrorModalRenderHandler,
    cognitoColoredWarningModalRenderHandler
} from "redux/slices/modalSlice";

import {
    Card, CardBody, Col, Button,
} from 'reactstrap';

export default function AddManualData() {
    const dispatch = useDispatch();
    const { lang } = useSelector((state) => state.lang);
    const [complateFunc, setComplateFunc] = useState(false);

    const { t, i18n } = useTranslation();
    const [choosenFacilityData, setChoosenFacilityData] = useState(null);
    const [choosenEquipmentData, setChoosenEquipmentData] = useState(null);
    const [choosenDurationType, setChoosenDurationType] = useState(choosenEquipmentData?.value?.dataUtilizationCode == "otherEnteredData" ? null : { value: 86400, label: t("durationTypes.day") });

    const themeData = useSelector((state) => state.theme.className);
    const { facilities } = useSelector((state) => state.facility.facilityState);
    const { devices } = useSelector((state) => state.deviceData.deviceDataState);
    const { subId } = useSelector((state) => state.user.userState);
    const [dataDate, setDataDate] = useState(new Date(Date.now()))
    const userData = useSelector((state) => state.user.userState);

    const [facilityStatusOptions, setFacilityStatusOptions] = useState([])
    useEffect(() => {
        const newFacilityStatusOptions = facilities.map((facilityItem) => {
            return { value: facilityItem, label: facilityItem.unitName }
        })
        setFacilityStatusOptions(newFacilityStatusOptions)
    }, [facilities])
    const [equipmentStatusOptions, setEquipmentStatusOptions] = useState(null);
    const durationType = [
        { value: 86400, label: t("durationTypes.day") },
        { value: 3600, label: t("durationTypes.hour") },
        { value: 60, label: t("durationTypes.minute") },
        { value: 1, label: t("durationTypes.second") }
    ]


    const { currentFacility } = useSelector(
        (state) => state.facility.facilityState
    );
    const tenantId = currentFacility.id;
    const facilityId = currentFacility.SK;

    useEffect(() => {
        if (facilityStatusOptions && facilityStatusOptions.length === 1) {
            setChoosenFacilityData(facilityStatusOptions[0])
        }

    }, [facilityStatusOptions])



    const onSubmit = async (dataOnSubmit) => {
        // // TODO The attribures to be sent to the API
        // tenantId,
        // facilityId,
        // descriptiveCode,  // descriptive code of the device
        // dataSendingDeviceChannelName,
        // dataCategory,
        // dataUtilizationCode,
        // equipmentName,
        // dataUnit,
        // mutationType,  // "newData" | "updateData"
        // operatorId,  // SubId of the user
        // arrayOfDataObject 

        /* const manualData = {
             tenantId: tenantId,
             facilityId: facilityId,
             descriptiveCode: choosenEquipmentData.value.descriptiveCode,
             dataSendingDeviceChannelName: choosenEquipmentData.value.dataSendingDeviceChannelName,
             dataCategory: choosenEquipmentData.value.dataCategory,
             dataUtilizationCode: choosenEquipmentData.value.dataUtilizationCode,
             equipmentName: choosenEquipmentData.value.equipmentName,
             dataUnit: choosenEquipmentData.value.dataUnit,
             industryCategory: choosenEquipmentData.value.industryCategory,
             equipmentTimeZone: choosenEquipmentData.value.equipmentTimeZone,
             mutationType: "newData",
             operatorId: userData.subId,
             arrayOfDataObject: choosenEquipmentData.value.dataUtilizationCode === "electricity" ? {
                 singleTimeIndexValue: dataOnSubmit.singleTimeIndexValue,
                 dayTimeIndexValue: dataOnSubmit.dayTimeIndexValue,
                 nightTimeIndexValue: dataOnSubmit.nightTimeIndexValue,
                 peakIndexValue: dataOnSubmit.peakIndexValue,
                 dataDurationSeconds: dataOnSubmit.durationOfData * choosenDurationType.value
 
             } : {
                 indexValue: dataOnSubmit.indexValue,
                 dataDurationSeconds: dataOnSubmit.durationOfData * choosenDurationType.value
             }
 
         }*/


        let objectForArrayToSendApi = {};
        const dataDurationSeconds = (dataOnSubmit.durationOfData) ? dataOnSubmit.durationOfData * choosenDurationType.value : null
        if (choosenEquipmentData.value.dataUtilizationCode === "machine") {
            objectForArrayToSendApi.dataDurationSeconds = dataDurationSeconds
            // One device can have multiple data sending channels
            // To prevent any confusion at the database query, we are sending the data to the API with the dataSendingDeviceChannelName
            objectForArrayToSendApi[choosenEquipmentData.value.dataSendingDeviceChannelName] = dataOnSubmit.indexValue;
        } else if (choosenEquipmentData.value.dataUtilizationCode === "electricity") {
            objectForArrayToSendApi = {
                singleTimeIndexValue: dataOnSubmit.singleTimeIndexValue,
                dayTimeIndexValue: dataOnSubmit.dayTimeIndexValue,
                nightTimeIndexValue: dataOnSubmit.nightTimeIndexValue,
                peakIndexValue: dataOnSubmit.peakIndexValue,
                dataDurationSeconds: dataDurationSeconds
            }
        } else {
            objectForArrayToSendApi = {
                indexValue: dataOnSubmit.indexValue,
                dataDurationSeconds: dataDurationSeconds
            }
        }

        const newManual = {
            ...choosenEquipmentData.value, //TODO direkt olarak gelen objenin içinde değişiklik yapılmış hali
            mutationType: "newData",
            operatorId: userData.subId,
            arrayOfDataObject: [objectForArrayToSendApi]
        }

        addManualData(newManual) //TODO veri eklemek için gidilen fonksiyona yukarıdaki iki objeden birinin gönderilmesi gerek. 
    }


    const addManualData = (data) => { //TODO Yetkin
        try {
            // // TODO The attribures to be sent to the API
            // tenantId,
            // facilityId,
            // descriptiveCode,  // descriptive code of the device
            // dataSendingDeviceChannelName,
            // dataCategory,
            // dataUtilizationCode,
            // equipmentName,
            // dataUnit,
            // mutationType,  // "newData" | "updateData"
            // operatorId,  // SubId of the user
            // arrayOfDataObject 
            manualDataOperationHandler(data)
                .then((response) => {
                    const successMessage = {
                        name: t(
                            "modal.modalMessage.onSuccess.name.attributeUpdateSuccess"
                        ),
                        message: t(
                            "modal.modalMessage.onSuccess.message.attributeUpdateSuccess"
                        ),
                        acceptFunc: () => setComplateFunc(!complateFunc),
                        rejectFunc: () => setComplateFunc(!complateFunc)
                    };
                    dispatch(cognitoColoredSuccessModalRenderHandler(successMessage))
                })
                .catch((err) => {
                    //   console.log("err",err)

                });
        } catch (errorManualDataOperation) {
        }
    };
    useEffect(() => {
        setChoosenEquipmentData(null)
        setChoosenFacilityData(null)
        setEquipmentStatusOptions(null)

    }, [complateFunc])

    let selectedLanguage;
    switch (lang) {
        case "tr":
            selectedLanguage = tr;
            break;
        default:
            selectedLanguage = tr;
            break;
    }


    useEffect(() => {
        if (choosenFacilityData?.value) {
            getDevicesOptions()

        }

    }, [
        choosenFacilityData?.value
    ]);

    async function getDevicesOptions() {
        setChoosenEquipmentData(null)
        //machineStatusOptions
        //setEquipmentStatusOptions
        const getDevicesData = {

            tenantId: tenantId,
            facilityId: choosenFacilityData?.value.SK,
        };
        // API call to create manual device data
        await API.graphql(
            graphqlOperation(
                getDeviceForManualDataOperations, {
                input: getDevicesData
            })
        ).then((response) => {
            const filteredDevices = Object.entries(response.data.getDeviceForManualDataOperations)
                .filter(([key, value]) => value.equipmentCategory === "manualDataEntry")
                .map(([key, value]) => ({ value: value, label: value.equipmentName }));

            if (filteredDevices.length === 1) {
                setChoosenEquipmentData(filteredDevices[0])
            }

            setEquipmentStatusOptions(filteredDevices)

            //equipmentCategory: "manualDataEntry"

        }).catch((err) => {
            throw new Error(err.errors[0]);
        });

        return getDevicesData;
    };


    useEffect(() => {
        setChoosenEquipmentData(null)
        if (choosenFacilityData) {


            const filteredDevices = devices
                .filter(({ equipmentCategory }) => equipmentCategory === "manualDataEntry")
                .map((value, index) => ({ value: value, label: value.equipmentName }));
            setEquipmentStatusOptions(filteredDevices)
        }
    }, [devices]);

    return (
        <div style={{ marginLeft: 20, marginRight: 20 }}>
            <Col md={12} lg={12}>
                <Card>
                    <CardBody>
                        <div className="card__title">
                            <h5 className="bold-text">{t("sidebar.addManualData.title")}</h5>
                        </div>
                        <Form onSubmit={onSubmit}>
                            {({ handleSubmit, form }) => (
                                <form className="form form--horizontal" onSubmit={handleSubmit}>
                                    {//ocation.state == null && userData.userStatusInt >= 5 &&
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">{t("pages.formItems.facilityName")}</span>
                                            <div className="form__form-group-field" style={{ display: 'block' }}>
                                                <Select
                                                    isDisabled={facilityStatusOptions.length === 1 ? true : false}
                                                    name="facilityName"
                                                    value={choosenFacilityData}
                                                    onChange={setChoosenFacilityData}
                                                    options={facilityStatusOptions}
                                                    clearable={false}
                                                    className="react-select"
                                                    placeholder={t("pages.formItems.facilityName")}
                                                    styles={{
                                                        control: (provided) => ({
                                                            ...provided,
                                                            backgroundColor: themeData === "theme-dark" && "#232329",

                                                        }),
                                                        singleValue: (provided) => ({
                                                            ...provided,
                                                            color: themeData === "theme-dark" && "#dddddd",
                                                        }),

                                                    }}
                                                />

                                            </div>
                                        </div>}
                                    {equipmentStatusOptions &&
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">{t("pages.formItems.equipmentName")}</span>
                                            <div className="form__form-group-field" style={{ display: 'block' }}>
                                                <Select
                                                    isDisabled={equipmentStatusOptions.length === 1 ? true : false}
                                                    name="equipmentName"
                                                    value={choosenEquipmentData}
                                                    onChange={setChoosenEquipmentData}
                                                    options={equipmentStatusOptions}
                                                    clearable={false}
                                                    className="react-select"
                                                    placeholder={t("pages.formItems.equipmentName")}
                                                    styles={{
                                                        control: (provided) => ({
                                                            ...provided,
                                                            backgroundColor: themeData === "theme-dark" && "#232329",

                                                        }),
                                                        singleValue: (provided) => ({
                                                            ...provided,
                                                            color: themeData === "theme-dark" && "#dddddd",
                                                        }),
                                                    }}
                                                />
                                            </div>
                                        </div>}
                                    {choosenEquipmentData &&
                                        <div style={{ width: '100%' }}>
                                            {choosenEquipmentData.value.dataUtilizationCode === "electricity" &&
                                                <div>
                                                    {/*  <div className="form__form-group">
                                                        <span className="form__form-group-label">{t("pages.addManualData.dataDate")} </span>
                                                        <div className="form__form-group-field" style={{ display: 'block' }}>
                                                            <DatePicker
                                                            disabled={true}
                                                                onChange={(time) => setDataDate(time)}
                                                                //selected={endTime < new Date(startTime.getFullYear(), startTime.getMonth(), startTime.getDate(), startTime.getHours(), (startTime.getMinutes() + 15), 0) ?
                                                                 //   new Date(startTime.getFullYear(), startTime.getMonth(), startTime.getDate(), startTime.getHours(), (startTime.getMinutes() + 15), 0) : endTime}
                                                                //dateFormat={t("pages.dashboardPage.time.dateFormat")}
                                                                dateFormat="dd-MM-yyyy hh:mm"
                                                                timeFormat={"hh:mm"}
                                                                selected={dataDate}
                                                                showTimeSelect
                                                                locale={selectedLanguage}
                                                                placeholderText={t("pages.addManualData.dataDate")}
                                                            />
                                                            <span className="form__form-group-error" style={{ color: 'lightgray' }}>{t("pages.addManualData.itemDescription.dataDate")}</span>
                                                        </div>
                                                    </div>*/}
                                                    <div className="form__form-group">
                                                        <span className="form__form-group-label">{t("pages.addManualData.singleTimeIndex")}</span>
                                                        <div className="form__form-group-field" style={{ display: 'block' }}>
                                                            <Field
                                                                name="singleTimeIndexValue"
                                                                component="input"
                                                                type="number"
                                                                placeholder={t("pages.addManualData.singleTimeIndex")}
                                                                maxLength={20}
                                                            />
                                                            <span className="form__form-group-error" style={{ color: 'lightgray' }}>{t("pages.addManualData.itemDescription.singleTimeIndex")}</span>

                                                        </div>
                                                    </div>
                                                    <div className="form__form-group">
                                                        <span className="form__form-group-label">{t("pages.addManualData.dayTimeIndexValue")}</span>
                                                        <div className="form__form-group-field" style={{ display: 'block' }}>
                                                            <Field
                                                                name="dayTimeIndexValue"
                                                                component="input"
                                                                type="number"
                                                                placeholder={t("pages.addManualData.dayTimeIndexValue")}
                                                                maxLength={20}
                                                            />
                                                            <span className="form__form-group-error" style={{ color: 'lightgray' }}>{t("pages.addManualData.itemDescription.dayTimeIndexValue")}</span>
                                                        </div>
                                                    </div>
                                                    <div className="form__form-group">
                                                        <span className="form__form-group-label">{t("pages.addManualData.nightTimeIndexValue")}</span>
                                                        <div className="form__form-group-field" style={{ display: 'block' }}>
                                                            <Field
                                                                name="nightTimeIndexValue"
                                                                component="input"
                                                                type="number"
                                                                placeholder={t("pages.addManualData.nightTimeIndexValue")}
                                                                maxLength={20}

                                                            />
                                                            <span className="form__form-group-error" style={{ color: 'lightgray' }}>{t("pages.addManualData.itemDescription.nightTimeIndexValue")}</span>

                                                        </div>
                                                    </div>
                                                    <div className="form__form-group">
                                                        <span className="form__form-group-label">{t("pages.addManualData.peakTimeIndexValue")}</span>
                                                        <div className="form__form-group-field" style={{ display: 'block' }}>
                                                            <Field
                                                                name="peakIndexValue"
                                                                component="input"
                                                                type="number"
                                                                placeholder={t("pages.addManualData.peakTimeIndexValue")}
                                                                maxLength={20}
                                                            />
                                                            <span className="form__form-group-error" style={{ color: 'lightgray' }}>{t("pages.addManualData.itemDescription.peakTimeIndexValue")}</span>

                                                        </div>
                                                    </div>
                                                </div>}
                                            {choosenEquipmentData.value.dataUtilizationCode !== "electricity" &&
                                                //dataUnit
                                                <div className="form__form-group">
                                                    <span className="form__form-group-label">{t("pages.addManualData.value")} ({choosenEquipmentData.value.dataUnit}) </span>
                                                    <div className="form__form-group-field" style={{ display: 'block' }}>
                                                        <Field
                                                            name="indexValue"
                                                            component="input"
                                                            type="number"
                                                            placeholder={choosenEquipmentData.value.dataUnit}
                                                            maxLength={20}
                                                            onKeyPress={e => {
                                                                const key = e.key;
                                                                // Sadece sayılar ve nokta (ondalık ayraç) kabul edilsin, diğer karakterleri engelle
                                                                if (!/[\d.]/.test(key)) {
                                                                    e.preventDefault();
                                                                }
                                                            }}


                                                        />
                                                        <span className="form__form-group-error" style={{ color: 'lightgray' }}>{t("pages.addManualData.itemDescription.value")}</span>

                                                    </div>
                                                </div>}
                                            <div className="form__form-group" >
                                                <span className="form__form-group-label">{t("pages.addManualData.durationOfType")}</span>
                                                <div className="form__form-group-field" style={{ display: 'block' }}>
                                                    <Select
                                                        name="durationOfType"
                                                        value={choosenDurationType}
                                                        isDisabled={choosenEquipmentData?.value?.dataUtilizationCode === "machine" ? false : true}
                                                        onChange={setChoosenDurationType}
                                                        options={durationType}
                                                        clearable={false}
                                                        className="react-select"
                                                        placeholder={t("pages.addManualData.durationOfType")}
                                                        styles={{
                                                            control: (provided) => ({
                                                                ...provided,
                                                                backgroundColor: themeData === "theme-dark" && "#232329",

                                                            }),
                                                            singleValue: (provided) => ({
                                                                ...provided,
                                                                color: choosenEquipmentData?.value?.dataUtilizationCode === "otherEnteredData" ? themeData === "theme-dark" && "#dddddd" : "gray",
                                                            }),
                                                        }}
                                                    />
                                                    <span className="form__form-group-error" style={{ color: 'lightgray' }}>{t("pages.addManualData.itemDescription.durationOfType")}</span>
                                                </div>
                                            </div>

                                            <div className="form__form-group">
                                                <span className="form__form-group-label">{t("pages.addManualData.durationOfData")}</span>
                                                <div className="form__form-group-field" style={{ display: 'block' }}>
                                                    <Field
                                                        name="durationOfData"
                                                        component="input"
                                                        type="number"
                                                        placeholder={t("pages.addManualData.durationOfData")}
                                                        maxLength={10}
                                                    />
                                                    <span className="form__form-group-error" style={{ color: 'lightgray' }}>{t("pages.addManualData.itemDescription.durationOfData")}</span>

                                                </div>
                                            </div>
                                            <div className="form__form-group-field">
                                                <div style={{ textAlign: 'end', flex: 1 }}>
                                                    <Button color="primary" type="submit">{t("pages.formItems.save")}</Button>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </form>
                            )}
                        </Form>
                    </CardBody>
                </Card>
            </Col>
        </div>
    )
}
