import { Auth } from "aws-amplify";

export default async function completeNewPassword(
  username,
  currentPasswordValue,
  cognitoPassword
) {
  try {
    await Auth.signIn(username, currentPasswordValue).then((user) => {
     // console.log("Sign In user    ****  ",user)
      Auth.completeNewPassword(user, cognitoPassword);
    });
  } catch (error) {
    throw new Error(JSON.stringify(error));
  }
}
