import React from "react";
import { useSelector, useDispatch } from "react-redux";
import TopbarSidebarButton from "./TopbarSidebarButton";
import TopbarProfile from "./TopbarProfile";
import TopbarLanguage from "./TopbarLanguage";
import { useNavigate } from "react-router-dom";
import sanaiinfoDarkLogo from 'assets/sanaiinfoDarkLogo.png';
import sanaiinfoLightLogo from 'assets/sanaiinfoLightLogo.png';
import TopbarNotification from "./TopbarNotification";

import {
  changeSidebarVisibility,
  changeMobileSidebarVisibility,
} from "redux/slices/sidebarSlice";
import theme from "styled-theming";

const Topbar = () => {
  const { logIn } = useSelector((state) => state.login);
  const themeData = useSelector((state) => state.theme.className);
  const navigate = useNavigate();
  const { userDefaultFacility } = useSelector((state) => state.facility.facilityState);

  const userData = useSelector((state) => state.user.userState);

  const dispatch = useDispatch();
  const changeMobileSidebar = () => dispatch(changeMobileSidebarVisibility());
  const changeSidebar = () => dispatch(changeSidebarVisibility());

  return (
    <div className="topbar topbar--navigation">
      <div className="topbar__left">
        <TopbarSidebarButton
          changeMobileSidebarVisibility={() => changeMobileSidebar()}
          changeSidebarVisibility={() => changeSidebar()}
          logInStatus={logIn}
        />
        <div onClick={() => userDefaultFacility.SK ? navigate("/dashboard/" + userData.subId + "/" + userDefaultFacility.SK) : navigate("/")}>
          <img className="topbar__logo" src={themeData == "theme-dark" ? sanaiinfoDarkLogo : sanaiinfoLightLogo} alt={"logo"} />
        </div>
      </div>

      <div className="topbar__right">
        <div className="topbar__right-over" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          {logIn === "authenticated" && <TopbarProfile />}
            <TopbarNotification />
          <TopbarLanguage />
        </div>
      </div>
    </div>
  );
};

export default Topbar;
