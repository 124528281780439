import React from "react";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useSelector } from "react-redux";

export default function MuiStyleHandler ({ children }) {

  const themeState = useSelector((state) => state.theme.className);

  const stylingMode = createTheme({
    palette: {
      mode: themeState==="theme-light" ? "light" : "dark",
      background: {
          default: "inherit",
      }
    },
  });

  return (
    <ThemeProvider theme={stylingMode}>
      {children}
    </ThemeProvider>
  )
};