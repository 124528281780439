import { createSlice } from "@reduxjs/toolkit";

const initialUserState = {
  subId: "",
  userName: "",
  givenName: "",
  familyName: "",
  email: "",
  phoneNumber: "",
  userStatus: "",
  userStatusInt: 0,
  cookies: {}
};

function userStatusIntHandler(userStatusFromPayload) {
  /*
    The greatest status takes the greatest value
    owner   - 6
    admin   - 5
    manager  - 4
    user    - 3
    viewer  - 2
    operator- 1
    logOut - 0
  */
  let userStatusAsInteger = 0
  switch (userStatusFromPayload) {
    case "owner":
      userStatusAsInteger = 6
      break;
    case "admin":
      userStatusAsInteger = 5
      break;
    case "manager":
      userStatusAsInteger = 4
      break;
    case "user":
      userStatusAsInteger = 3
      break;
    case "viewer":
      userStatusAsInteger = 2
      break;
    case "operator":
      userStatusAsInteger = 1
      break;
    default:
      userStatusAsInteger = 0
      break;
  }
  return userStatusAsInteger;
};

// Name and surname starting with lowercase will be uppercased
function capitalizeFirstLetters(wordToCapitalize) {
  const words = wordToCapitalize.split(" ");

  return words
    .map((word) => word[0].toUpperCase() + word.substring(1))
    .join(" ");
};

const userSlice = createSlice({
  name: "user",
  initialState: {
    userState: initialUserState,
  },
  reducers: {
    userDataHandler(state, action) {
      let userData = action.payload;
      if ("userStatus" in userData) {
        const userStatusInt = userStatusIntHandler(userData.userStatus);
        userData.userStatusInt = userStatusInt;
       // console.log("userData login", userData);
      };

      if ("givenName" in userData) {
        let { givenName } = userData;

        if (givenName) givenName = capitalizeFirstLetters(givenName);

        userData = {
          ...userData,
          givenName
        };
      };

      if ("familyName" in userData) {
        let { familyName } = userData;

        if (familyName) familyName = capitalizeFirstLetters(familyName);

        userData = {
          ...userData,
          familyName
        };
      };

      state.userState = {
        ...state.userState,
        ...userData,
      };
    },

    returnToInitialUserState(state) {
      state.userState = initialUserState;
    },
  },
});

export const { 
  userDataHandler,
  returnToInitialUserState 
} = userSlice.actions;

export default userSlice.reducer;