import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import EquipmentPanel from '../../Settings/components/EquipmentPanel/EquipmentPanel';
import Panel from "shared/components/Panel";


export default function EquipmentPanelPage() {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const { machine } = useSelector((state) => state.executiveDashboardData.executiveDashboardState);
    const { devices } = useSelector((state) => state.deviceData.deviceDataState);
    const userLang = useSelector((state) => state.lang.lang);
    const [equipmentGraphs, setEquipmentGraphs] = useState(null)
    const findEquipmentName = (equipmentKey) => devices.filter(device => device.descriptiveCode === equipmentKey)[0]?.equipmentName;
    useEffect(() => {

        setEquipmentGraphs(() =>
            Object.entries(machine).map(([equipmentKey, equipmentValue]) =>
            (
                equipmentKey
                // Check if the equipment has equipmentStateDurations
                // Especially during the very first initialization, the equipment may not have equipmentStateDurations
                // In that case the app will throw an error on the console
                && equipmentValue.equipmentStateDurations
                && Object.keys(equipmentValue.equipmentStateDurations).length > 0

                && <Panel
                    key={`${equipmentKey}-panel`}
                    title={findEquipmentName(equipmentKey)}
                    md={12} xl={6} lg={12} sm={12} xs={12}
                    padding={screenWidth > 500 ? 10 : 0}
                    minHeight={380}
                >
                    <EquipmentPanel equipmentKey={equipmentKey} equipmentValue={equipmentValue} />
                    <div>
                        {//new Date(equipmentValue.equipmentTimeTrackValues.timeUtcBeginningEquipmentTimeTrack)
                        }
                    </div>
                </Panel>
            )
            ));
    }, [machine, devices, userLang]);
    return (
        <div style={{ display: 'flex', flexWrap: 'wrap' }} >
            {equipmentGraphs}
        </div >
    )
}
