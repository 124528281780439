import { createSlice } from "@reduxjs/toolkit";

export const sidebarSlice = createSlice({
  name: "sidebar",
  initialState: {
    show: false,
    collapse: false,
  },
  reducers: {
    changeSidebarVisibility(state) {
      state.collapse = !state.collapse;
    },
    changeMobileSidebarVisibility(state) {
      state.show = !state.show;
    },
  },
});

export const { changeSidebarVisibility, changeMobileSidebarVisibility } =
  sidebarSlice.actions;

export default sidebarSlice.reducer;
