import { API, graphqlOperation } from "aws-amplify";
import { getDeviceByFacilityId } from "graphql/queries";

/** 
 * Appsync query to get the device data from DynamoDB
 * Deletes the attributes that are not needed in the object
 * @param  {string} tenantId
 * @param  {string} facilityId
 * @returns {Array<{key:string}>} Array of device attributes object
 */
export default async function getDeviceData(tenantId, facilityId) {
    let objectToReturn = {
      deviceList: [],
      deviceFacilityValues: {
        electricity: {},
        water: {},
        naturalGas: {},
        machine: {},
      }
    };

    // Get device data
    const graphQlData = await API.graphql(
      graphqlOperation(getDeviceByFacilityId, {
        tenantId,
        facilityId
      })
    ).catch((err) => {
    //  console.log("Error from getDeviceByFacilityId query at graphQlData", err.errors[0]); 
      throw new Error(err.errors[0]);
    });

    const arrayOfDevices = graphQlData?.data?.getDeviceByFacilityId;

    if (arrayOfDevices.length > 0) {
      // Remove the attributes that are not needed in the object
      objectToReturn.deviceList = arrayOfDevices.map((device) => {
        // IPC and device data should not end up in this function
        if (device?.deviceFacilityValues && Object.keys(device.deviceFacilityValues).length > 0) {
          objectToReturn.deviceFacilityValues[device.dataUtilizationCode][device.descriptiveCode] = JSON.parse(device.deviceFacilityValues);
        }
        
        delete device.dateToDelete;
        delete device.deviceBrand;
        delete device.deviceModel;
        delete device._TYPE;
        delete device.deviceFacilityValues;

        return device;
      });
    }

    return objectToReturn;
}