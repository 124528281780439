import React, { Fragment, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";
import Loading from "shared/components/Loading";
import Topbar from "../../Layout/topbar/Topbar";
import LogInFormSharedFields from "./components/SharedFieldsLogin";
import { loginStatusHandler } from "redux/slices/logInStatusSlice";
import ConfirmationCodeFieldHandler from "./components/inputFields/ConfirmationCodeFieldHandler";
import PasswordContext from "context/PasswordContext";
import resendConfirmationCode from "./AwsCognito/ResendConfirmationCode";
import confirmSignUp from "./AwsCognito/confirmSignUp";
import {
  cognitoColoredSuccessModalRenderHandler,
  cognitoErrorModalRenderHandler,
  cognitoPrimaryModalRenderHandler,
} from "redux/slices/modalSlice";

export default function ConfirmationCodeForm() {
  const { t, i18n } = useTranslation();
  const { email } = useSelector((state) => state.user.userState);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { confirmationCode, resetContext } = useContext(PasswordContext);
  // Async fonksiyonlar dursun
  const [isLoading, setIsLoading] = useState(false);
  // Show warning message for invalid password field
  const [
    showConfirmationCodeWarningMessage,
    setShowConfirmationCodeWarningMessageHandler,
  ] = useState(false);
  // This variable will be used for the localization of modal messages
  const errorFromService = "cognito";
  // If email address is empty, the code functions will not work
  // So the user should enter the email address and password again
  if (!email) navigate("/sign_in", { replace: true });
  async function handleResendCode() {
    await resendConfirmationCode(email)
      // A modal is opened to inform user about sent code
      .then(() => {
        const modalMessage = {
          name: t("modal.modalMessage.onSuccess.name.forgotPasswordBeforeCode"),
          message: t(
            "modal.modalMessage.onSuccess.message.forgotPasswordBeforeCode"
          ),
        };
        dispatch(cognitoPrimaryModalRenderHandler(modalMessage));
      });
  }
  async function handleSubmit() {
    // Validity of confirmation code is checked at password context
    // trim is used to get rid of whitespaces
    // To be valid, confirmation code should not be empty
    if (!confirmationCode) {
      setShowConfirmationCodeWarningMessageHandler(true);
    } else {
      setIsLoading(true);
      try {
        await confirmSignUp(email, confirmationCode)
          // A modal is opened to inform the user about password change
          .then(() => {
            const successMessage = {
              name: t(
                "modal.modalMessage.onSuccess.name.confirmationCodeSuccess"
              ),
              message: t(
                "modal.modalMessage.onSuccess.message.confirmationCodeSuccess"
              ),
            };
            dispatch(cognitoColoredSuccessModalRenderHandler(successMessage));
          })
          .then(() => dispatch(loginStatusHandler("formSignIn")))
          // If confirmation code is verified, go to the main page
          .then(() => navigate("/sign_in", { replace: true }));
      } catch (err) {
        // In case of an error, user is informed by an error message
        dispatch(
          cognitoErrorModalRenderHandler({
            errorFromService,
            errorState: err.message,
          })
        );
      } finally {
        setIsLoading(false);
        resetContext();
      }
    }
  }
  return (
    <Fragment>
      {isLoading && <Loading loading={isLoading} />}
      <Topbar />
      <LogInFormSharedFields>
        <div>
          <h3
            className="account__subhead subhead"
            style={{
              color: "#2293bc",
              fontWeight: "bold",
              marginBottom: "20px",
            }}
          >
            {t("confirmationCode.header.confirmationCodeHeader", {
              email,
            })}
          </h3>
        </div>
        <form className="form">
          <ConfirmationCodeFieldHandler
            renderName={t("auth.confirmationCodeFieldRenderName")}
            placeholder={t("auth.confirmationCodeFieldPlaceholder")}
            showWarningMessage={showConfirmationCodeWarningMessage}
            showWarningMessageHandler={
              setShowConfirmationCodeWarningMessageHandler
            }
            invalidFieldMessage={t(
              "confirmationCode.confirmationCodeFieldEmptyWarningMessage"
            )}
          />
          <Button
            className="btn btn-primary account__btn account__btn--small"
            color="primary"
            onClick={() => handleSubmit()}
          >
            {t("auth.confirmationCodeButton")}
          </Button>
          <Button
            outline
            className="btn btn-outline-primary account__btn account__btn--small"
            color="primary"
            onClick={() => handleResendCode()}
          >
            {t("auth.reSendConfirmationCodeButton")}
          </Button>
        </form>
      </LogInFormSharedFields>
    </Fragment>
  );
}
