/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getTenant = /* GraphQL */ `
  query GetTenant($id: ID!) {
    getTenant(id: $id) {
      id
      SK
      _TYPE
      organizationId
      brokerId
      authorizeBroker
      authorizeBrokerStatusDate
      tenantName
      tenantTier
      activeTenant
      allowedActiveUserNumber
      cookies
      ownerId
      invoice {
        cursor
        invoices {
          id
          SK
          _TYPE
          unitName
          contactDetails
          taxNo
          taxOffice
          nextPaymentDate
          lastPaymentDate
          createdAt
          createdBy
          updatedAt
          updatedBy
          dateToDelete
        }
      }
      facilities {
        cursor
        facilities {
          id
          SK
          _TYPE
          brokerId
          unitName
          industryCategory
          contactDetails
          userSubIds
          createdAt
          createdBy
          updatedAt
          updatedBy
          dateToDelete
        }
      }
      users {
        cursor
        users {
          id
          SK
          subId
          _TYPE
          userName
          givenName
          familyName
          email
          phoneNumber
          locale
          timezone
          userStatus
          firstTimeSignIn
          cookies
          userDefaultFacility
          tenantDetails {
            tenant {
              id
              SK
              _TYPE
              organizationId
              brokerId
              authorizeBroker
              authorizeBrokerStatusDate
              tenantName
              tenantTier
              activeTenant
              allowedActiveUserNumber
              cookies
              ownerId
              invoice {
                cursor
              }
              facilities {
                cursor
              }
              users {
                cursor
              }
              createdAt
              createdBy
              updatedAt
              updatedBy
              dateToDelete
            }
            nextToken
          }
          facilities {
            cursor
            facilities {
              id
              SK
              _TYPE
              brokerId
              unitName
              industryCategory
              contactDetails
              userSubIds
              createdAt
              createdBy
              updatedAt
              updatedBy
              dateToDelete
            }
          }
          lastActivityTime
          createdAt
          createdBy
          updatedAt
          updatedBy
          dateToDelete
        }
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      dateToDelete
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($subId: String!) {
    getUser(subId: $subId) {
      id
      SK
      subId
      _TYPE
      userName
      givenName
      familyName
      email
      phoneNumber
      locale
      timezone
      userStatus
      firstTimeSignIn
      cookies
      userDefaultFacility
      tenantDetails {
        tenant {
          id
          SK
          _TYPE
          organizationId
          brokerId
          authorizeBroker
          authorizeBrokerStatusDate
          tenantName
          tenantTier
          activeTenant
          allowedActiveUserNumber
          cookies
          ownerId
          invoice {
            cursor
            invoices {
              id
              SK
              _TYPE
              unitName
              contactDetails
              taxNo
              taxOffice
              nextPaymentDate
              lastPaymentDate
              createdAt
              createdBy
              updatedAt
              updatedBy
              dateToDelete
            }
          }
          facilities {
            cursor
            facilities {
              id
              SK
              _TYPE
              brokerId
              unitName
              industryCategory
              contactDetails
              userSubIds
              createdAt
              createdBy
              updatedAt
              updatedBy
              dateToDelete
            }
          }
          users {
            cursor
            users {
              id
              SK
              subId
              _TYPE
              userName
              givenName
              familyName
              email
              phoneNumber
              locale
              timezone
              userStatus
              firstTimeSignIn
              cookies
              userDefaultFacility
              tenantDetails {
                nextToken
              }
              facilities {
                cursor
              }
              lastActivityTime
              createdAt
              createdBy
              updatedAt
              updatedBy
              dateToDelete
            }
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
          dateToDelete
        }
        nextToken
      }
      facilities {
        cursor
        facilities {
          id
          SK
          _TYPE
          brokerId
          unitName
          industryCategory
          contactDetails
          userSubIds
          createdAt
          createdBy
          updatedAt
          updatedBy
          dateToDelete
        }
      }
      lastActivityTime
      createdAt
      createdBy
      updatedAt
      updatedBy
      dateToDelete
    }
  }
`;
export const getInvoice = /* GraphQL */ `
  query GetInvoice($id: ID!) {
    getInvoice(id: $id) {
      id
      SK
      _TYPE
      unitName
      contactDetails
      taxNo
      taxOffice
      nextPaymentDate
      lastPaymentDate
      createdAt
      createdBy
      updatedAt
      updatedBy
      dateToDelete
    }
  }
`;
export const getDeviceByFacilityId = /* GraphQL */ `
  query GetDeviceByFacilityId($facilityId: String!, $tenantId: String!) {
    getDeviceByFacilityId(facilityId: $facilityId, tenantId: $tenantId) {
      descriptiveCode
      boxId
      _TYPE
      deviceBrand
      deviceModel
      brokerId
      tenantId
      facilityId
      dataUtilizationCode
      dataCategory
      equipmentName
      equipmentDescription
      equipmentCategory
      equipmentLocation
      dataUnit
      operatorId
      equipmentTimeZone
      industryCategory
      dataSendingDeviceDescriptiveCode
      dataSendingDeviceChannelName
      deviceFacilityValues
      alarmConditions
      deviceFacilitySettings
      createdAt
      createdBy
      updatedAt
      updatedBy
      dateToDelete
    }
  }
`;
export const getUsersByTenantId = /* GraphQL */ `
  query GetUsersByTenantId($tenantId: String!, $subId: String!) {
    getUsersByTenantId(tenantId: $tenantId, subId: $subId) {
      id
      SK
      subId
      _TYPE
      userName
      givenName
      familyName
      email
      phoneNumber
      locale
      timezone
      userStatus
      firstTimeSignIn
      cookies
      userDefaultFacility
      tenantDetails {
        tenant {
          id
          SK
          _TYPE
          organizationId
          brokerId
          authorizeBroker
          authorizeBrokerStatusDate
          tenantName
          tenantTier
          activeTenant
          allowedActiveUserNumber
          cookies
          ownerId
          invoice {
            cursor
            invoices {
              id
              SK
              _TYPE
              unitName
              contactDetails
              taxNo
              taxOffice
              nextPaymentDate
              lastPaymentDate
              createdAt
              createdBy
              updatedAt
              updatedBy
              dateToDelete
            }
          }
          facilities {
            cursor
            facilities {
              id
              SK
              _TYPE
              brokerId
              unitName
              industryCategory
              contactDetails
              userSubIds
              createdAt
              createdBy
              updatedAt
              updatedBy
              dateToDelete
            }
          }
          users {
            cursor
            users {
              id
              SK
              subId
              _TYPE
              userName
              givenName
              familyName
              email
              phoneNumber
              locale
              timezone
              userStatus
              firstTimeSignIn
              cookies
              userDefaultFacility
              tenantDetails {
                nextToken
              }
              facilities {
                cursor
              }
              lastActivityTime
              createdAt
              createdBy
              updatedAt
              updatedBy
              dateToDelete
            }
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
          dateToDelete
        }
        nextToken
      }
      facilities {
        cursor
        facilities {
          id
          SK
          _TYPE
          brokerId
          unitName
          industryCategory
          contactDetails
          userSubIds
          createdAt
          createdBy
          updatedAt
          updatedBy
          dateToDelete
        }
      }
      lastActivityTime
      createdAt
      createdBy
      updatedAt
      updatedBy
      dateToDelete
    }
  }
`;
export const getUsersByTenantNBrokerId = /* GraphQL */ `
  query GetUsersByTenantNBrokerId($tenantId: String!, $brokerId: String) {
    getUsersByTenantNBrokerId(tenantId: $tenantId, brokerId: $brokerId) {
      id
      SK
      subId
      _TYPE
      userName
      givenName
      familyName
      email
      phoneNumber
      locale
      timezone
      userStatus
      firstTimeSignIn
      cookies
      userDefaultFacility
      tenantDetails {
        tenant {
          id
          SK
          _TYPE
          organizationId
          brokerId
          authorizeBroker
          authorizeBrokerStatusDate
          tenantName
          tenantTier
          activeTenant
          allowedActiveUserNumber
          cookies
          ownerId
          invoice {
            cursor
            invoices {
              id
              SK
              _TYPE
              unitName
              contactDetails
              taxNo
              taxOffice
              nextPaymentDate
              lastPaymentDate
              createdAt
              createdBy
              updatedAt
              updatedBy
              dateToDelete
            }
          }
          facilities {
            cursor
            facilities {
              id
              SK
              _TYPE
              brokerId
              unitName
              industryCategory
              contactDetails
              userSubIds
              createdAt
              createdBy
              updatedAt
              updatedBy
              dateToDelete
            }
          }
          users {
            cursor
            users {
              id
              SK
              subId
              _TYPE
              userName
              givenName
              familyName
              email
              phoneNumber
              locale
              timezone
              userStatus
              firstTimeSignIn
              cookies
              userDefaultFacility
              tenantDetails {
                nextToken
              }
              facilities {
                cursor
              }
              lastActivityTime
              createdAt
              createdBy
              updatedAt
              updatedBy
              dateToDelete
            }
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
          dateToDelete
        }
        nextToken
      }
      facilities {
        cursor
        facilities {
          id
          SK
          _TYPE
          brokerId
          unitName
          industryCategory
          contactDetails
          userSubIds
          createdAt
          createdBy
          updatedAt
          updatedBy
          dateToDelete
        }
      }
      lastActivityTime
      createdAt
      createdBy
      updatedAt
      updatedBy
      dateToDelete
    }
  }
`;
export const getTenantsByBrokerId = /* GraphQL */ `
  query GetTenantsByBrokerId($brokerId: String!) {
    getTenantsByBrokerId(brokerId: $brokerId) {
      id
      SK
      _TYPE
      organizationId
      brokerId
      authorizeBroker
      authorizeBrokerStatusDate
      tenantName
      tenantTier
      activeTenant
      allowedActiveUserNumber
      cookies
      ownerId
      invoice {
        cursor
        invoices {
          id
          SK
          _TYPE
          unitName
          contactDetails
          taxNo
          taxOffice
          nextPaymentDate
          lastPaymentDate
          createdAt
          createdBy
          updatedAt
          updatedBy
          dateToDelete
        }
      }
      facilities {
        cursor
        facilities {
          id
          SK
          _TYPE
          brokerId
          unitName
          industryCategory
          contactDetails
          userSubIds
          createdAt
          createdBy
          updatedAt
          updatedBy
          dateToDelete
        }
      }
      users {
        cursor
        users {
          id
          SK
          subId
          _TYPE
          userName
          givenName
          familyName
          email
          phoneNumber
          locale
          timezone
          userStatus
          firstTimeSignIn
          cookies
          userDefaultFacility
          tenantDetails {
            tenant {
              id
              SK
              _TYPE
              organizationId
              brokerId
              authorizeBroker
              authorizeBrokerStatusDate
              tenantName
              tenantTier
              activeTenant
              allowedActiveUserNumber
              cookies
              ownerId
              invoice {
                cursor
              }
              facilities {
                cursor
              }
              users {
                cursor
              }
              createdAt
              createdBy
              updatedAt
              updatedBy
              dateToDelete
            }
            nextToken
          }
          facilities {
            cursor
            facilities {
              id
              SK
              _TYPE
              brokerId
              unitName
              industryCategory
              contactDetails
              userSubIds
              createdAt
              createdBy
              updatedAt
              updatedBy
              dateToDelete
            }
          }
          lastActivityTime
          createdAt
          createdBy
          updatedAt
          updatedBy
          dateToDelete
        }
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      dateToDelete
    }
  }
`;
