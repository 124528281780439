import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

import {
    Card, CardBody, Col, Table
} from 'reactstrap';
import { OpacityOutlined } from '@mui/icons-material';
export default function MyTasks() {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const themeData = useSelector((state) => state.theme.className);

    const maxTextSize = 20

    const myTasksItems = [
        { id: 0, title: "30 Takım ayak", responsible: "Halil Sezai", startDate: "12/05/1895", endDate: "12/09/1895", status: { value: 1, label: "Tamamlandı" }, statusColor: "teal" },
        { id: 1, title: "22 adet kol", responsible: "Halit Ziya Uşaklıgil", startDate: "12/05/1895", endDate: "12/09/1895", status: { value: 1, label: "Tamamlandı" }, statusColor: "teal" },
        { id: 2, title: "12 adet tabla", responsible: "Tarkan Ferah", startDate: "12/05/1895", endDate: "12/09/1895", status: { value: 3, label: "Devam Ediyor" }, statusColor: "orange" },
        { id: 3, title: "41 Takım sandalye", responsible: "Halil Sezai", startDate: "12/05/1895", endDate: "12/09/1895", status: { value: 1, label: "Tamamlandı" }, statusColor: "teal" },
        { id: 4, title: "30 Takım ayak", responsible: "Şebnem Ferah", startDate: "12/05/1895", endDate: "12/09/1895", status: { value: 2, label: "İptal Edildi" }, statusColor: "red" },


    ];

    const myTasksHeaders = [
        { id: 1, title: "Status" },
        { id: 2, title: "Başlık" },
        { id: 3, title: "Atayan" },
        { id: 4, title: "Planlanan Başlangıç" },
        { id: 5, title: "Planlanan bitiş" },

    ];

    const statusDropdownData = [
        { value: 1, label: "Tamamlandı" },
        { value: 2, label: "İptal Edildi" },
        { value: 3, label: "Devam Ediyor" },
        { value: 4, label: "Durduruldu" },
    ];


    return (
        <div style={{ marginLeft: 20, marginRight: 20 }}>
            <Col md={12} lg={12}>
                <Card>
                    <CardBody>
                        <div className="card__title">
                            <h5 className="bold-text">Görevlerim</h5>
                        </div>

                        <Table responsive hover >
                            <thead>
                                <tr>
                                    {myTasksHeaders.map(item => (
                                        <th key={item.id} style={{ minWidth: 150, padding: '0 20px', verticalAlign: 'middle' }}>{item.title}</th>

                                    ))}
                                </tr>
                            </thead>
                            <tbody >
                                {myTasksItems.map((item, index) => (

                                    <tr
                                        style={{ backgroundColor: index % 2 == 0 && 'ButtonShadow', height: 60, verticalAlign: 'middle' }}
                                    >
                                        <td>
                                            <div className="form__form-group-field" style={{ display: 'block' }}>
                                                <Select
                                                    name="facilityName"
                                                    value={item.status}
                                                    //onChange={setChoosenFacilityData}
                                                    options={statusDropdownData}
                                                    clearable={false}
                                                    className="react-select"
                                                    menuPortalTarget={document.body}

                                                    placeholder={t("pages.formItems.facilityName")}
                                                    styles={{
                                                        zIndex: 20,
                                                        control: (provided) => ({
                                                            ...provided,
                                                            backgroundColor: index % 2 == 0 && 'ButtonShadow',
                                                            borderColor: item.statusColor,
                                                            verticalAlign: 'middle',


                                                        }),
                                                        singleValue: (provided) => ({
                                                            ...provided,
                                                            //color: themeData === "theme-dark" && "gray" ,
                                                            color: item.statusColor,

                                                        }),
                                                        dropdownIndicator: base => ({
                                                            ...base,
                                                            color: item.statusColor,
                                                        })



                                                    }}

                                                />

                                            </div>
                                        </td>
                                        <td style={{ padding: '0 20px', verticalAlign: 'middle' }}>
                                        {item.title.length > maxTextSize ? item.title.substring(0, maxTextSize) + "..." : item.title}
                                        </td>
                                        <td style={{ padding: '0 20px', verticalAlign: 'middle' }}>
                                            {item.responsible.length > maxTextSize ? item.responsible.substring(0, maxTextSize) + "..." : item.responsible}
                                        </td>
                                        <td style={{ padding: '0 20px', verticalAlign: 'middle' }}>
                                            {item.startDate}
                                        </td>
                                        <td style={{ padding: '0 20px', verticalAlign: 'middle' }}>
                                            {item.endDate}
                                        </td>





                                        <td style={{ padding: '0 20px', verticalAlign: 'middle', color: 'teal' }} /*onClick={() => console.log("from detail")}*/>
                                            Detay
                                        </td>
                                    </tr>

                                ))}
                            </tbody>
                        </Table>


                    </CardBody>
                </Card>
            </Col>
        </div>
    )
}
