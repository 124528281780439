import React from "react";
import { Col, Row, Container } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { cognitoPrimaryModalRenderHandler } from "redux/slices/modalSlice";
import PaymentLogos from 'assets/paymentLogos.png'
const background = `${process.env.PUBLIC_URL}/img/landing/background/footer_bg.png`;
export default function Footer() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const openModal = (title, message) => {
    dispatch(
      cognitoPrimaryModalRenderHandler({
        name: title,
        message: message,
      })
    );
  }
  return (
    <footer className="landing__footer" style={{ marginBottom: 40 }}>
      <img className="landing__footer-background" src={background} alt="" />
      <Container>
        <Row>
          <Col md={12}>
            <Row >
              <Col md={12}>
                <div >
                  <img className="payment__all-credit-cards-logo" src={PaymentLogos} alt="mc" />
                </div>
              </Col>
            </Row>
            <p >
              <b
                style={{ textDecoration: 'underline', color: 'black' }}
                onClick={() => openModal(t("pages.landing.distanceSalesAgreement.title"), t("pages.landing.distanceSalesAgreement.message"))}
              >
                {" "}
                {t("pages.landing.distanceSalesAgreement.title")}
              </b>
              <b> & </b>
              <b
                style={{ textDecoration: 'underline', color: 'black' }}
                onClick={() => openModal(t("pages.landing.deliveryAndReturns.title"), t("pages.landing.deliveryAndReturns.message"))}
              >
                {" "}
                {t("pages.landing.deliveryAndReturns.title")}
              </b>
            </p>
            <p >
              {t("pages.landing.footnote.topLinkHeader")}
              <b >
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.sanai.com.tr"
                  style={{ color: 'black' }}
                >
                  {" "}
                  Sanai Bilişim ve Otomasyon San. ve Tic. A.Ş.
                </a>
              </b>
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}


