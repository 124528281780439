import { createSlice } from "@reduxjs/toolkit";

const initialExecutiveDashboardState = {
    electricity: {},
    water: {},
    naturalGas: {},
    machine: {},
  }

function sortObjectByKey(keyName, objectToBeSorted) {
  return Object.keys(objectToBeSorted)
    .sort((a, b) => objectToBeSorted[b][keyName] - objectToBeSorted[a][keyName])
    .reduce((acc, key) => {
      acc[key] = objectToBeSorted[key];
      return acc;
    }, {});
};

const executiveDashboardSlice = createSlice({
    name: "executiveDashboardData",
    initialState: {
        executiveDashboardState: initialExecutiveDashboardState,
    },
    reducers: {
        executiveDashboardFirstRenderDataHandler(state, action) {
            const executiveDashboardData = action.payload;
            // console.log("executiveDashboardData: ", JSON.stringify(executiveDashboardData, null, 2))

            let objectForElectricity = {};
            let objectForWater = {};
            let objectForNaturalGas = {};
            let objectForMachine = {};

            if ( Object.keys(executiveDashboardData.electricity).length > 0 ) {
                Object.entries(executiveDashboardData.electricity).forEach(([key, value]) => {
                    if (Object.keys(value).length !== 0) {
                        const dateBeginningDayElectricity = value.beginnig_day_tariff1ImportActiveIndex.split("#")[0];
                        const dateBeginningWeekElectricity = value.beginnig_week_tariff1ImportActiveIndex.split("#")[0];
                        const dateBeginningMonthElectricity = value.beginnig_month_tariff1ImportActiveIndex.split("#")[0];
                        const currentTariff = parseFloat(value.tariff1ImportActiveIndex).toFixed(2);
                        const tariffAtTheBeginningOfDay = parseFloat(value.beginnig_day_tariff1ImportActiveIndex.split("#")[1]).toFixed(2);
                        const tariffAtTheBeginningOfWeek = parseFloat(value.beginnig_week_tariff1ImportActiveIndex.split("#")[1]).toFixed(2);
                        const tariffAtTheBeginningOfMonth = parseFloat(value.beginnig_month_tariff1ImportActiveIndex.split("#")[1]).toFixed(2);
                        const timeIsoOfData = value.time_tariff1ImportActiveIndex;

                        objectForElectricity[key] = {
                            dateBeginningDayElectricity,
                            dateBeginningWeekElectricity,
                            dateBeginningMonthElectricity,
                            averagePowerFactor: value.averagePowerFactor,
                            difference_tariff1ImportActiveIndex: value.difference_tariff1ImportActiveIndex,
                            tariffAtTheBeginningOfDay,
                            tariffAtTheBeginningOfWeek,
                            tariffAtTheBeginningOfMonth,
                            dailyConsumptionElectricity: currentTariff - tariffAtTheBeginningOfDay,
                            weeklyConsumptionElectricity: currentTariff - tariffAtTheBeginningOfWeek,
                            monthlyConsumptionElectricity: currentTariff - tariffAtTheBeginningOfMonth,
                            timeIso: timeIsoOfData
                        };
                    }
                });
            };

            objectForMachine = { ...executiveDashboardData.machine };
            // if ( Object.keys(executiveDashboardData.machine).length > 0 ) {
                // Object.entries(executiveDashboardData.machine).forEach(([keyAsDescriptiveCode, valueOfDescriptiveCode]) => {
                //     // Create key for the descriptive code
                //     objectForMachine[keyAsDescriptiveCode] = {};
                //     Object.entries(valueOfDescriptiveCode).forEach(([keyOfDataObject, valueOfDataObject]) => {
                //         if (keyOfDataObject === "currentEquipmentStatus"
                //             || keyOfDataObject === "equipmentStateDurations"
                //         ) {
                //             objectForMachine[keyAsDescriptiveCode][keyOfDataObject] = valueOfDataObject;
                //             return;
                //         } else {
                //             return;
                //         };
                //     });
                // });
            // };

            const sortedObjectForElectricity = (Object.keys(objectForElectricity).length > 0) ? sortObjectByKey("monthlyConsumptionElectricity", objectForElectricity) : {};
            const sortedObjectForWater = (Object.keys(objectForWater).length > 0) ? sortObjectByKey("monthlyConsumptionWater", objectForWater) : {};
            const sortedObjectForNaturalGas = (Object.keys(objectForNaturalGas).length > 0) ? sortObjectByKey("monthlyConsumptionNaturalGas", objectForNaturalGas) : {};
            // const sortedObjectForMachine = (Object.keys(objectForMachine).length > 0) ? sortObjectByKey("monthlyConsumptionMachine", objectForMachine) : {};

            // Reducers can only make copies of the original values, and then they can mutate the copies
            // This is safe, because we made a copy
            state.executiveDashboardState = {
                ...state.executiveDashboardState,
                electricity: {...sortedObjectForElectricity},
                water: {...sortedObjectForWater},
                naturalGas: {...sortedObjectForNaturalGas},
                machine: {...objectForMachine},
            };
        },

        executiveElectricityDashboardDataHandler(state, action) {
            const dataFromWebSocket = action.payload;
            
            const objectForElectricity = {};
            // const objectForWater = {};
            // const objectForNaturalGas = {};
            // const objectForMachine = {};

            if ( dataFromWebSocket.dataUtilizationCode === "electricity" ) {
                const {
                    dateBeginningDayElectricity,
                    tariffAtTheBeginningOfDay,
                    tariffAtTheBeginningOfWeek,
                    tariffAtTheBeginningOfMonth,
                } = state.executiveDashboardState.electricity[dataFromWebSocket.descriptiveCode];

                if (dataFromWebSocket?.averagePowerFactor) objectForElectricity.averagePowerFactor = dataFromWebSocket.averagePowerFactor;
                
                // Update the state with the parameters from websocket
                if (dataFromWebSocket?.tariff1ImportActiveIndex) {
                    objectForElectricity.dailyConsumptionElectricity = dataFromWebSocket.tariff1ImportActiveIndex - tariffAtTheBeginningOfDay;
                    objectForElectricity.weeklyConsumptionElectricity = dataFromWebSocket.tariff1ImportActiveIndex - tariffAtTheBeginningOfWeek;
                    objectForElectricity.monthlyConsumptionElectricity = dataFromWebSocket.tariff1ImportActiveIndex - tariffAtTheBeginningOfMonth;
                };
                
                const timeBeginningOfDay = dataFromWebSocket.timeIso.split('T')[0] + 'T00:00:00.000Z';

                // If the date of the beginning of the day is different from the date of the beginning of the day in the state, then we reset the daily consumption
                if (dateBeginningDayElectricity !== timeBeginningOfDay && dataFromWebSocket?.tariff1ImportActiveIndex) {
                    objectForElectricity.tariffAtTheBeginningOfDay = dataFromWebSocket.tariff1ImportActiveIndex;
                    objectForElectricity.dailyConsumptionElectricity = 0;
                    objectForElectricity.dateBeginningDayElectricity = timeBeginningOfDay;
                    // objectForElectricity.tariffAtTheBeginningOfDay = dataFromWebSocket.tariff1ImportActiveIndex;
                };

                state.executiveDashboardState.electricity[dataFromWebSocket.descriptiveCode] = {
                    ...state.executiveDashboardState.electricity[dataFromWebSocket.descriptiveCode],
                    ...objectForElectricity,
                };
            };
       

        },

        executiveMachineDashboardDataHandler(state, action) {
            const dataFromWebSocket = action.payload;

            let objectForWebSocketMachine = {};

            if ( 
                dataFromWebSocket.dataUtilizationCode === "machine" 
                && Object.keys(dataFromWebSocket.deviceFacilityValues).length > 0
            ) {
                objectForWebSocketMachine = dataFromWebSocket.deviceFacilityValues;
            };

            state.executiveDashboardState.machine[dataFromWebSocket.descriptiveCode] = {
                ...state.executiveDashboardState.machine[dataFromWebSocket.descriptiveCode],
                ...objectForWebSocketMachine,
            };
        },

        returnToInitialExecutiveDashboardDataState(state) {
            state.executiveDashboardState = initialExecutiveDashboardState;
        }
    }
});

export const {
    executiveDashboardFirstRenderDataHandler,
    executiveElectricityDashboardDataHandler,
    executiveMachineDashboardDataHandler,
    returnToInitialExecutiveDashboardDataState
} = executiveDashboardSlice.actions;

export default executiveDashboardSlice.reducer;