import React from "react";
import { Col, Container, Row } from "reactstrap";
import { useTranslation } from "react-i18next";

export default function HubPage() {
  const { t, i18n } = useTranslation();
  return (
    <Container className="dashboard" style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' ,textAlign:'center'}}>
      <Row>
        <Col md={12}>
          <h3 className="page-title"  style={{ color: 'gray' }}>{t("pages.hub.hubPageTitle")}</h3>
        </Col>
      </Row>
    </Container>
  );
}
