import { createSlice } from "@reduxjs/toolkit";

export const themeSlice = createSlice({
  name: "theme",
  initialState: {
    className: "theme-light",
  },
  reducers: {
    changeThemeToDark(state) {
      state.className = "theme-dark";
    },
    changeThemeToLight(state) {
      state.className = "theme-light";
    },
  },
});

export const { changeThemeToDark, changeThemeToLight } = themeSlice.actions;

export default themeSlice.reducer;
